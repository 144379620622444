// import { Icon } from '@chakra-ui/icons'
import { Avatar, Divider, Flex, Stack, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import Social from './SocialMedia'
import { useRecoilValue } from 'recoil'
import { businessDataAtom } from '../../../../recoil/atoms/businessData'

export const Sidebar = () => {
  const businessData = useRecoilValue(businessDataAtom)

  return (
    <Flex as='section' bg='bg-canvas'>
      <Flex
        flex='1'
        bg={'rgba(180, 224, 226, 0.1)'}
        overflowY='auto'
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        maxW={{
          base: 'full',
          // sm: 'xs',
        }}
        py={{
          base: '6',
          sm: '8',
        }}
        px={{
          base: '2',
          sm: '2',
        }}
      >
        <Stack justify='space-between' spacing='1'>
          <Flex align={'center'}>
            <Avatar
              m={'4'}
              size='lg'
              name='Code Shock'
              // *** fallback is profile icon unless you add name and that will default to initials ***
              bg={'#03989E'}
            />
          </Flex>

          <Stack
            spacing={{
              base: '5',
              sm: '6',
            }}
          >
            <Divider />
            <Social />
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  )
}
