import React, { useMemo, useState } from 'react'
import {
    chakra,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    SimpleGrid,
    Text,
    useDisclosure,
    useMediaQuery,
} from '@chakra-ui/react'
import TestimonialVideoCard from '../../../components/TestimonialVideoCard/TestimonialVideoCard'
import VideoPopup from '../../../components/VideoPopup'
import Pagination from '../../../components/Pagination'

const TestimonialAccordionItem = ({ campaign, idx }) => {

    const reviews = useMemo(() => campaign?.testimonialData || [], [campaign])

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cardInfoState, setCardInfo] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState([])
    const [isGreaterThan1970] = useMediaQuery('(min-width: 1970px)')
    const [isGreaterThan1690] = useMediaQuery('(min-width: 1690px)')


    const onPaginationChange = (data) => {
        const { currentPage, cardsPerPage } = data;

        const offset = (currentPage - 1) * cardsPerPage;
        const currentTestimonials = reviews.slice(offset, offset + cardsPerPage);

        // currentPage !== 1 && scrollToElement("#campaigns")
        setCurrentPage(currentPage)
        setPaginationData(currentTestimonials)
    };

    return (
        <AccordionItem
            mt={[2]}
            width="full">
            <h1>
                <AccordionButton _expanded={{ bg: 'brand.1', color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                        <Text fontSize={["lg", "xl", "2xl"]} textTransform={'capitalize'} fontWeight={'500'}>
                            {campaign.campaignDetails.internalCampaignName}{" "}
                            ({campaign.testimonialData?.length || 0})
                        </Text>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h1>
            <AccordionPanel pb={4}>
                <SimpleGrid
                    spacing={["10px", "15px"]}
                    mt={2}
                    minChildWidth={{ base: "full", sm: "250px", md: "260px" }}
                    justifyItems={["center", "stretch", "stretch"]}
                    maxW={["80%", "100%", "100%"]}
                    mx={["auto", "0", 0]}
                >
                    {!paginationData.length ?
                        <chakra.div minH={'52'} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={"center"}>
                            <Text fontWeight={'bold'} fontSize={'2rem'} color={'brand.1'}>
                                No Reviews!
                            </Text>
                        </chakra.div>
                        : (paginationData)?.map((cardInfo, index) => (
                            <TestimonialVideoCard
                                key={cardInfo.id + index}
                                cardInfo={{
                                    ...cardInfo,
                                    campaignName: campaign?.campaignDetails?.publicCampaignName || campaign?.campaignDetails?.internalCampaignName
                                }}
                                onOpen={onOpen}
                                setCardInfo={setCardInfo}
                                showInformationTags={true}
                                sx={{
                                    maxW: ["full", "360px", "350px"],
                                    minW: ["320px", "250px", "260px"]
                                }}
                            />
                        ))}
                    {(paginationData.length > 0 && paginationData.length < (isGreaterThan1970 ? 6 : isGreaterThan1690 ? 5 : 4)) && [...Array((isGreaterThan1970 ? 6 : isGreaterThan1690 ? 5 : 4) - paginationData.length).keys()].map(no => (
                        <Box
                            display={["none", "none", "block", "block"]}
                            key={no}
                            bg="white"
                            sx={{
                                maxW: ["full", "360px", "350px"],
                                minW: ["320px", "250px", "260px"]
                            }}
                        />
                    ))}
                </SimpleGrid>
                <Pagination
                    currentPage={currentPage}
                    cardsPerPage={8}
                    totalCards={reviews.length}
                    onPaginationChange={onPaginationChange}
                    dataArr={reviews || []}
                    sx={{ width: "full", my: '10', justifyContent: "center" }}
                />
            </AccordionPanel>
            <VideoPopup isOpen={isOpen} onClose={(e) => { onClose(e); setCardInfo(null); }} cardInfo={cardInfoState} />
        </AccordionItem>
    )
}

export default TestimonialAccordionItem