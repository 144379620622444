import React from 'react'

import { NewBlog } from './NewBlog'
import { Seo } from '../../components/Seo'

const Blog = () => {
  return (
    <>
      <Seo
        title='Sendmea Real Video Reviews | Lastest News Blog'
        description='Get the lastest Sendmea video reviews news including new features, tips, tricks, and how to. Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand.'
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='blog, news, sendmea news, latest news, updates, new features,video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />

      <NewBlog />
    </>
  )
}

export default Blog
