import {
  Box,
  Stack,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  Icon,
  Divider,
  FormControl,
  FormErrorMessage,
  VStack,
  InputLeftAddon,
  InputGroup,
  InputRightElement,
  IconButton,
  InputRightAddon,
  chakra,
  Checkbox,
} from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { FcGoogle } from 'react-icons/fc'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { userState } from '../../recoil/atoms/auth'
import { auth, db } from '../../firebase/config'
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth'
import { doc, setDoc, getDoc, addDoc, collection } from 'firebase/firestore'
import { FaFacebook, FaLock, FaLockOpen } from 'react-icons/fa'
import { useCustomToast } from '../../hooks/customToast'
import {
  cloudfunctionsBaseURL,
  countries,
  deleteCookie,
  fetchReferral,
  firstPromoterApi,
  readCookie,
  registerCodeshockContact,
  sendNotification,
  setCookie,
  zacSmith,
} from '../../utils'
import ControlledSelect from '../../components/ControlledSelect/ControlledSelect'
import { AiFillCheckCircle } from 'react-icons/ai'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { generateDiscountText } from '../../firebase/stripeConfig'
import Marketing from './components/Marketing'
import InputMask from 'react-input-mask';

const provider = new GoogleAuthProvider()
const providerFacebook = new FacebookAuthProvider()

export default function JoinOurTeam() {
  const { addToast } = useCustomToast()
  const setUser = useSetRecoilState(userState)
  const [googleLoader, setGoogleLoader] = useState(false)
  const [facebookLoader, setFacebookLoader] = useState(false)
  const { search } = useLocation()
  const [referralLoading, setReferralLoading] = useState(false)
  const [referralApplied, setReferralApplied] = useState('')
  const [askForReferral, setAskForReferral] = useState(false)

  const [showPass, setShowPass] = useState({
    password: false,
    confirmPassword: false,
  })

  const handleShowPass = (name) => {
    return setShowPass((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      referral: '',
      referralCode: '',
      referralEmail: '',
      referredBy: null,
      receiveUpdates: true,
      agreeToTerms: false,
      businessPhone: '',
      businessDetails: {
        country: { label: 'United States', value: 'US' },
      },
    },
  })
  const handleCheckReferral = async () => {
    let referral = search && search.split('=')[0] === '?fpr' && search.split('=')[1].trim()
    try {
      const result =
        readCookie('referral') || referral
          ? await fetchReferral({ ref_id: readCookie('referral') || referral })
          : null

      if (!result?.isEnrolled && result?.error) throw new Error(result?.error)

      if (result) {
        if (!readCookie('referral')) {
          setCookie('referral', String(result?.default_ref_id).toLowerCase())
          setValue('referral', String(result?.default_ref_id).toLowerCase())
          // console.log('cookie undefined so setCookie and setReferral')
          addToast({
            id: 'referral',
            title: 'Referral',
            description: 'Referral discount applied',
            status: 'success',
            variant: 'left-accent',
            duration: 4500,
          })
        } else {
          setValue('referral', String(result?.default_ref_id).toLowerCase())
          // console.log('cookie exist  so just setReferral')
        }
        setValue('referredBy', result)
        setValue('referralEmail', result?.email)
        setReferralApplied('right')
      }
    } catch (error) {
      console.log({ error })
      if (error?.message === 'Promoter not found.') {
        let err = `Referral ${!!readCookie('referral') ? 'In cookie' : 'In URL'} is invalid`
        addToast({
          id: 'referral',
          title: 'Referral',
          description: err,
          status: 'error',
          variant: 'left-accent',
          duration: 4500,
        })
        readCookie('referral') && deleteCookie('referral')
      }
      addToast({
        id: 'referral',
        title: 'Referral',
        description: error?.message || error,
        status: 'error',
        variant: 'left-accent',
        duration: 4500,
      })
    }
  }

  useEffect(() => {
    !referralLoading && referralApplied !== 'right' && handleCheckReferral()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // console.log(watch('referredBy'), ' aaaa')

  const onSubmit = async (values) => {
    const {
      country: { label, code },
    } = values.businessDetails
    values = {
      ...values,
      referral: referralApplied !== 'right' ? '' : watch('referral'),
      referralEmail: watch('referralEmail'),
      referralApplied,
    }

    try {
      if (watch('referral') && referralApplied === '') {
        const { referralApplied, referralEmail, referral } = await applyReferralCode(
          watch('referral')
        )
        values = {
          ...values,
          referralApplied,
          referralEmail: referralEmail || '',
          referral: referralApplied === 'right' ? referral : '',
        }
        await new Promise((resolve) => setTimeout(resolve, 1500))
      }

      if (values.referralApplied === 'wrong') {
        setAskForReferral(true)
      } else if (
        ((!readCookie('referral') || !values.referral) && values.referralApplied === '') ||
        ((readCookie('referral') || values.referral) && values.referralApplied === 'right')
      ) {
        const businessDetails = {
          businessName: values.firstName + ' ' + values.lastName,
          businessEmail: values.email,
          businessPhone: values.businessPhone,
          countryCode: code || '',
          publicVisible: {
            businessPhone: false,
            businessEmail: false,
            businessAddress: false,
          },
          country: label,
        }
        const payload = {
          input_id: 'ce44m6',
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          county: label,
          referralCode: values?.referral || readCookie('referral'),
          paid: false,
        }
        let PromoterSignup = ''

        const { user } = await createUserWithEmailAndPassword(auth, values.email, values.password)
        registerCodeshockContact(payload)

        if (readCookie('referral') || values?.referral) {
          if (!readCookie('referral') && values.referral) {
            setCookie('referral', values.referral)
          }
          const resip = await fetch('https://api.ipify.org/?format=json')
          const { ip } = await resip.json()
          const referralId = values?.referral || readCookie('referral')
          const payload = {
            email: values.email,
            uid: user.uid,
            ref_id: String(referralId).toLowerCase(),
            ip,
          }
          await firstPromoterApi('https://firstpromoter.com/api/v1/track/signup', payload)
        }
        if (search && search === '?enroll=true') {
          const payload = {
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            cust_id: user.uid,
            temp_password: values.password,
          }
          PromoterSignup = await firstPromoterApi(
            'https://firstpromoter.com/api/v1/promoters/create',
            payload
          )
        }
        const referralId = String(values?.referral || readCookie('referral')).toLowerCase()
        const referredBy = watch('referredBy')
          ? watch('referredBy')
          : referralId
            ? referralId
            : null

        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          // phoneNumber: values?.phoneNumber,
          uid: user.uid,
          email: user.email,
          referral: referralId,
          ref_emailId: watch('referralEmail') || '',
          receiveUpdates: values.receiveUpdates,
          agreeToTerms: values.agreeToTerms,
        }
        // console.log({ data, referredBy, referralId })
        const docRef = doc(db, 'users', data.uid)
        await setDoc(docRef, data)
        await addDoc(collection(db, 'businesses'), { ...businessDetails, userId: user.uid })
        setUser(data)

        if (PromoterSignup && PromoterSignup.promotions?.length > 0) {
          addToast({
            title: 'Welcome!',
            description: 'You have become a Promoter',
            status: 'success',
            variant: 'left-accent',
          })
        }
        handleSuperAdminNotification(data, referredBy)
        addToast({
          title: 'Welcome to Sendmea!',
          description: 'Login Successfully',
          status: 'success',
          variant: 'left-accent',
        })
      }
      // navigate('/dashboard', { replace: true })
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      console.log(error)
    }
  }

  const handleGoogleSignIn = async () => {
    if (!watch('agreeToTerms')) {
      return setError('agreeToTerms', { message: "This is required!" })
    }
    let values = {
      referral: referralApplied !== 'right' ? '' : watch('referral'),
      referralEmail: watch('referralEmail'),
      referralApplied,
    }

    try {
      setGoogleLoader(true)

      if (watch('referral') && referralApplied === '') {
        const { referralApplied, referralEmail, referral } = await applyReferralCode(
          watch('referral')
        )
        values = {
          ...values,
          referralApplied,
          referralEmail: referralEmail || '',
          referral: referralApplied === 'right' ? referral : '',
        }
        await new Promise((resolve) => setTimeout(resolve, 1700))
        referralApplied !== 'right' && setGoogleLoader(false)
      }

      if (values.referralApplied === 'wrong') {
        setAskForReferral(true)
        setGoogleLoader(false)
      } else if (
        ((!readCookie('referral') || !values.referral) && values.referralApplied === '') ||
        ((readCookie('referral') || values.referral) && values.referralApplied === 'right')
      ) {
        let PromoterSignup = ''
        const { user } = await signInWithPopup(auth, provider)

        const docRef = doc(db, 'users', user.uid)
        const userSnap = await getDoc(docRef)

        const payload = {
          input_id: 'ce44m6',
          email: user.email,
          firstName: user.displayName.split(' ')[0] || '',
          lastName: user.displayName.split(' ')[1] || '',
          county: watch('businessDetails.country.label'),
          referralCode: values.referral || readCookie('referral') || '',
          paid: false,
        }

        !userSnap.exists() && registerCodeshockContact(payload)

        if (!userSnap.exists() && (readCookie('referral') || values.referral)) {
          await handleReferralSignup(user)
        }
        if (!userSnap.exists() && search && search === '?enroll=true') {
          const payload = {
            email: user.email,
            first_name: user.displayName.split(' ')[0] || '',
            last_name: user.displayName.split(' ')[1] || '',
            cust_id: user.uid,
          }
          PromoterSignup = await firstPromoterApi(
            'https://firstpromoter.com/api/v1/promoters/create',
            payload
          )
          if (PromoterSignup.promotions.length > 0) {
            addToast({
              title: 'Welcome!',
              description: 'You have become a Promoter',
              status: 'success',
              variant: 'left-accent',
            })
          }
        }

        const referralId = String(values?.referral || readCookie('referral')).toLowerCase()
        const referredBy = watch('referredBy')
          ? watch('referredBy')
          : referralId
            ? referralId
            : null

        const data = {
          firstName: user.displayName.split(' ')[0] || '',
          lastName: user.displayName.split(' ')[1] || '',
          // phoneNumber: user?.phoneNumber,
          email: user.email,
          uid: user.uid,
          photoURL: '',
          referral: referralId || '',
          ref_emailId: watch('referralEmail') || '',
          receiveUpdates: watch('receiveUpdates'),
          agreeToTerms: watch('agreeToTerms'),
          // lifetime,
        }

        // console.log({ data, referredBy, referralId })

        if (!userSnap.exists()) {
          const businessDetails = {
            businessName: data.firstName + ' ' + data.lastName,
            businessEmail: data.email,
            country: watch('businessDetails.country.label') || '',
            businessPhone: watch('businessPhone') || '',
            countryCode: watch('businessDetails.country.code') || '',
            publicVisible: {
              businessPhone: false,
              businessEmail: false,
              businessAddress: false,
            },
          }
          await setDoc(docRef, data)
          await addDoc(collection(db, 'businesses'), { ...businessDetails, userId: user.uid })
        }
        setUser(userSnap.exists() ? userSnap.data() : data)

        !userSnap.exists() && handleSuperAdminNotification(data, referredBy)

        addToast({
          title: 'Welcome to Sendmea!',
          description: 'Login Successfully',
          status: 'success',
          variant: 'left-accent',
        })
        setGoogleLoader(false)
        // navigate('/dashboard', { replace: true })
      }
    } catch (error) {
      setGoogleLoader(false)
      console.log({ error })
      if (!error?.code?.includes('auth/popup-closed-by-user')) {
        addToast({
          title: 'Error',
          description: error.message,
          status: 'error',
          variant: 'left-accent',
        })
      }
    }
  }

  const handleFacebookSignIn = async () => {
    if (!watch('agreeToTerms')) {
      return setError('agreeToTerms', { message: "This is required!" })
    }
    let values = {
      referral: referralApplied !== 'right' ? '' : watch('referral'),
      referralEmail: watch('referralEmail'),
      referralApplied,
    }

    try {
      setFacebookLoader(true)
      if (watch('referral') && referralApplied === '') {
        const { referralApplied, referralEmail, referral } = await applyReferralCode(
          watch('referral')
        )
        values = {
          ...values,
          referralApplied,
          referralEmail: referralEmail || '',
          referral: referralApplied === 'right' ? referral : '',
        }
        await new Promise((resolve) => setTimeout(resolve, 1700))
        referralApplied !== 'right' && setFacebookLoader(false)
      }

      if (values.referralApplied === 'wrong') {
        setAskForReferral(true)
        setFacebookLoader(false)
      } else if (
        ((!readCookie('referral') || !values.referral) && values.referralApplied === '') ||
        ((readCookie('referral') || values.referral) && values.referralApplied === 'right')
      ) {
        let PromoterSignup = ''

        const { user } = await signInWithPopup(auth, providerFacebook)
        const docRef = doc(db, 'users', user.uid)
        const userSnap = await getDoc(docRef)

        const payload = {
          input_id: 'ce44m6',
          email: user.email,
          firstName: user.displayName.split(' ')[0] || '',
          lastName: user.displayName.split(' ')[1] || '',
          county: '',
          referralCode: values.referral || readCookie('referral'),
          paid: false,
        }
        !userSnap.exists() && registerCodeshockContact(payload)

        if (!userSnap.exists() && (readCookie('referral') || values.referral)) {
          await handleReferralSignup(user)
        }
        if (!userSnap.exists() && search && search === '?enroll=true') {
          const payload = {
            email: user.email,
            first_name: user.displayName.split(' ')[0] || '',
            last_name: user.displayName.split(' ')[1] || '',
            cust_id: user.uid,
          }
          PromoterSignup = await firstPromoterApi(
            'https://firstpromoter.com/api/v1/promoters/create',
            payload
          )
          if (PromoterSignup.promotions.length > 0) {
            addToast({
              title: 'Welcome!',
              description: 'You have become a Promoter',
              status: 'success',
              variant: 'left-accent',
            })
          }
        }
        const referralId = String(values?.referral || readCookie('referral')).toLowerCase()
        const referredBy = watch('referredBy')
          ? watch('referredBy')
          : referralId
            ? referralId
            : null
        const data = {
          firstName: user.displayName.split(' ')[0] || '',
          lastName: user.displayName.split(' ')[1] || '',
          // phoneNumber: user?.phoneNumber,
          email: user.email,
          uid: user.uid,
          photoURL: user.photoURL || '',
          referral: referralId,
          ref_emailId: watch('referralEmail') || '',
          receiveUpdates: watch('receiveUpdates'),
          agreeToTerms: watch('agreeToTerms'),
        }

        if (!userSnap.exists()) {
          const businessDetails = {
            businessName: data.firstName + ' ' + data.lastName,
            businessEmail: data.email,
            country: watch('businessDetails.country.label') || '',
            businessPhone: watch('businessPhone') || '',
            countryCode: watch('businessDetails.country.code') || '',
            publicVisible: {
              businessPhone: false,
              businessEmail: false,
              businessAddress: false,
            },
          }
          await setDoc(docRef, data)
          await addDoc(collection(db, 'businesses'), { ...businessDetails, userId: user.uid })
        }
        setUser(userSnap.exists() ? userSnap.data() : data)

        !userSnap.exists() && handleSuperAdminNotification(data, referredBy)

        addToast({
          title: 'Welcome to Sendmea!',
          description: 'Login Successfully',
          status: 'success',
          variant: 'left-accent',
        })
        setFacebookLoader(false)
        // navigate('/dashboard', { replace: true })
      }
    } catch ({ message, code }) {
      setFacebookLoader(false)
      if (!code?.includes('auth/popup-closed-by-user')) {
        addToast({ title: 'Error', description: message, status: 'error', variant: 'left-accent' })
      }
    }
  }

  const handleReferralSignup = async (user) => {
    try {
      if (!readCookie('referral') && watch('referral')) {
        setCookie('referral', watch('referral'))
      }
      const resip = await fetch('https://api.ipify.org/?format=json')
      const { ip } = await resip.json()
      const referralId = watch('referral') || readCookie('referral')
      const payload = {
        email: user.email,
        uid: user.uid,
        ref_id: referralId,
        ip,
      }
      await firstPromoterApi('https://firstpromoter.com/api/v1/track/signup', payload)
    } catch (error) {
      addToast({
        title: 'Error',
        description: error?.message,
        status: 'error',
        variant: 'left-accent',
      })
    }
  }

  const handleSuperAdminNotification = (data, referredBy) => {
    const notiPayload = {
      title: `🥳 New User!`,
      message: `${data?.firstName} ${data?.lastName
        } signed up. Email <a style="text-decoration: underline" href="mailto:${data?.email}">${data?.email
        }</a>. ${referredBy
          ? `Referred by ${referredBy?.profile?.first_name || referredBy || ''} ${referredBy?.profile?.last_name || ''
          }`
          : ' No referral used!'
        }`, // ${data?.ref_emailId !== zacSmith ? `${referredBy?.profile?.first_name || ""} ${referredBy?.profile?.last_name || ""}` : 'you'}
      sender_id: data.uid,
      receiver_id: zacSmith,
      admin: true,
    }
    sendNotification(notiPayload)
    if (referredBy) {
      //&& data?.ref_emailId !== zacSmith
      const forReferral = {
        ...notiPayload,
        receiver_id: referredBy?.email,
        message: `${data?.firstName} ${data?.lastName} signed up. Email <a style="text-decoration: underline" href="mailto:${data?.email}">${data?.email}</a>. Referred by you.`,
      }
      sendNotification(forReferral)
    }
  }

  const applyReferralCode = async (referral) => {
    setReferralLoading(true)
    try {
      const result = await fetchReferral({ ref_id: referral || watch('referral') })
      if (!result?.isEnrolled && result?.error) throw new Error(result?.error)
      setReferralApplied('right')
      setValue('referral', result?.default_ref_id)
      setValue('referralEmail', result?.email)
      setValue('referredBy', result)
      clearErrors('referral')
      setReferralLoading(false)

      return {
        referral: result?.default_ref_id,
        referralEmail: result?.email,
        referralApplied: 'right',
      }
    } catch (error) {
      console.log(error)
      const message = error?.message || error

      setReferralApplied('wrong')
      setAskForReferral(message === 'Promoter not found.' && true)
      setError('referral', { message })
      setReferralLoading(false)

      return {
        referralApplied: 'wrong',
      }
    }
  }


  return (
    <Box position={'relative'}>
      <Container
        as={SimpleGrid}
        maxW={'1400px'}
        columns={{ base: 1, md: 1, lg: 2 }}
        spacing={['2rem', '3rem', '5rem']}
        py={{ base: 10, sm: 20, lg: '50px' }}
        justifyItems={{ base: 'center', md: 'center' }}
      >
        <Marketing />
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={['600px']}
        >
          <Stack spacing={4}>
            <Text
              fontWeight='bold'
              color={'brand.1'}
              lineHeight={1.1}
              fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
            // onClick={() => {
            //   console.log({
            //     cookie: readCookie('referral'), watchRef: watch('referral'), referralApplied, error: errors?.referral,
            //     label: watch('businessDetails.country.label')
            //   })
            // }}
            >
              Grow Your{' '}
              <chakra.span bg='brand.2' bgClip='text' ml={1}>
                Business!
              </chakra.span>
            </Text>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }} fontWeight='500'>
              Sendmea is the easiest way to increase social proof by collecting authentic video
              reviews for your brand or businesses!
            </Text>
          </Stack>
          <VStack
            spacing={5}
            alignItems='stretch'
            as={'form'}
            mt={10}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl isInvalid={!!errors?.firstName} as={Stack} spacing={4}>
              <Input
                placeholder='First Name'
                bg={'gray.100'}
                border={0}
                // color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                {...register('firstName', {
                  required: 'This is required',
                })}
              />
              {errors.firstName && <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={!!errors?.lastName} as={Stack} spacing={4}>
              <Input
                placeholder='Last Name'
                bg={'gray.100'}
                border={0}
                // color={'gray.900'}
                _placeholder={{
                  color: 'gray.500',
                }}
                {...register('lastName', {
                  required: 'This is required',
                })}
              />
              {errors.lastName && <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={!!errors?.email} as={Stack} spacing={4}>
              <Input
                placeholder='Email'
                bg={'gray.100'}
                border={0}
                // color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                {...register('email', {
                  required: 'This is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={!!errors?.businessPhone} as={Stack} spacing={4}>
              <InputMask
                mask="(999) 999-9999" // Set the desired phone number format
                maskChar="_" // Placeholder character for input
                {...register('businessPhone', {
                  pattern: {
                    value: /^\(\d{3}\) \d{3}-\d{4}$/i, 
                    message: 'Invalid phone number',
                  }
                })}
              >
                {(inputProps) => <Input
                  {...inputProps}
                  placeholder='Phone Number'
                  bg={'gray.100'}
                  border={0}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                />}
              </InputMask>
              {errors.businessPhone && <FormErrorMessage>{errors.businessPhone.message}</FormErrorMessage>}
            </FormControl>

            <ControlledSelect
              p={'0'}
              control={control}
              name='businessDetails.country'
              options={countries}
              placeholder='Select Country'
              rules={{ required: 'Please select a Country' }}
            />

            <FormControl isInvalid={!!errors?.password} as={Stack} spacing={4}>
              <InputGroup>
                <Input
                  placeholder='Password'
                  type={showPass.password ? 'text' : 'password'}
                  bg={'gray.100'}
                  border={0}
                  // color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  {...register('password', {
                    required: 'This is required',
                    minLength: { value: 6, message: 'Minimum length should be 6' },
                  })}
                />
                <InputRightElement>
                  <IconButton
                    variant='ghost'
                    color='brand.1'
                    size='sm'
                    onClick={() => handleShowPass('password')}
                  >
                    {showPass.password ? <FaLockOpen /> : <FaLock />}
                  </IconButton>
                </InputRightElement>
              </InputGroup>

              {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={!!errors?.confirmPassword} as={Stack} spacing={4}>
              <InputGroup>
                <Input
                  placeholder='Confirm Password'
                  type={showPass.confirmPassword ? 'text' : 'password'}
                  bg={'gray.100'}
                  border={0}
                  // color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  {...register('confirmPassword', {
                    required: 'This is required',
                    minLength: { value: 6, message: 'Minimum length should be 6' },
                    validate: (val) => watch('password') === val || 'Your password do no match',
                  })}
                />
                <InputRightElement>
                  <IconButton
                    variant='ghost'
                    color='brand.1'
                    size='sm'
                    onClick={() => handleShowPass('confirmPassword')}
                  >
                    {showPass.confirmPassword ? <FaLockOpen /> : <FaLock />}
                  </IconButton>
                </InputRightElement>
              </InputGroup>
              {errors.confirmPassword && (
                <FormErrorMessage>{errors.confirmPassword.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors?.referral} as={Stack} spacing={4}>
              <InputGroup>
                <AnimatePresence>
                  {(readCookie('referral') || watch('referral')) && referralApplied === 'right' && (
                    <InputLeftAddon
                      as={motion.div}
                      key={readCookie('referral') || referralApplied === 'right'}
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ y: 0, opacity: 0 }}
                      transition={{ duration: 0.6, ease: 'ease-out' }}
                    >
                      <Icon as={AiFillCheckCircle} fontSize={'2xl'} color={'brand.2'} />
                    </InputLeftAddon>
                  )}
                </AnimatePresence>
                <Input
                  placeholder='Referral Code'
                  bg={'gray.100'}
                  border={0}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  disabled={watch('referral')?.length >= 3 && referralApplied === 'right'}
                  value={watch('referral')}
                  onChange={(e) => {
                    setValue('referral', e.target.value)
                  }}
                />
                <InputRightAddon px={0}>
                  <Button
                    size='sm'
                    variant='solid'
                    colorScheme='teal'
                    rounded='0px 4px 4px 0px'
                    height='108%'
                    onClick={() => applyReferralCode()}
                    isLoading={referralLoading}
                    disabled={watch('referral')?.length < 3 || referralApplied === 'right'}
                  >
                    {watch('referral') && referralApplied === 'right' ? 'Applied' : 'Apply'}
                  </Button>
                </InputRightAddon>
              </InputGroup>
              {!errors.referral &&
                (readCookie('referral') || watch('referral')) &&
                referralApplied === 'right' && (
                  <Text fontSize='sm' fontWeight='bold' color='gray.500' mt={'6px !important'}>
                    {generateDiscountText(watch('referral')?.toLowerCase())}
                  </Text>
                )}
              {!!errors?.referral && (
                <FormErrorMessage>{errors?.referral?.message}</FormErrorMessage>
              )}
            </FormControl>
            {!readCookie('referral') && !referralApplied && (
              <Text fontSize='sm' maxWidth='520px' fontWeight='medium' color='gray.600'>
                Get our premium plan for
                <chakra.span fontWeight='600' mx={1}>
                  $169/month
                </chakra.span>
                or apply a referral code above to receive your lifetime discounted price. Sign up
                now!
              </Text>
            )}
            {!readCookie('referral') && referralApplied === 'wrong' && askForReferral && (
              <Text
                fontSize='sm'
                maxWidth='400px'
                fontWeight='medium'
                color='gray.600'
                mt={'10px !important'}
              >
                Referral might be wrong, try another one and click `Apply` or signup without
                referral by
                <chakra.span
                  ml={1}
                  fontStyle='italic'
                  cursor='pointer'
                  fontWeight='bold'
                  color='gray.600'
                  onClick={() => {
                    setAskForReferral(false)
                    setReferralApplied('')
                    setValue('referral', '')
                    clearErrors('referral')
                  }}
                >
                  click here
                </chakra.span>
              </Text>
            )}
            <VStack spacing={4} py='10px' alignItems={'flex-start'}>
              <Checkbox
                type='checkbox'
                colorScheme='teal'
                color='gray.600'
                alignItems={'flex-start'}
                {...register(`receiveUpdates`)}
              >
                <Text fontSize={'sm'} mt={'-4px'}>
                  I agree to receive occasional SMS and email from Sendmea. Your information will
                  not be provided to any other parties.
                </Text>
              </Checkbox>
              <Checkbox
                type='checkbox'
                colorScheme='teal'
                color='gray.600'
                alignItems={'flex-start'}
                {...register(`agreeToTerms`, {
                  required: 'This is required',
                  validate: (val) => val === true || 'Agree to the terms.',
                })}
              >
                <Text fontSize={'sm'} mt={'-4px'}>
                  I agree to the terms of service of Sendmea. (
                  <chakra.span as={Link} to='/terms' color='teal.500'>
                    See
                  </chakra.span>
                  )
                </Text>
              </Checkbox>
              {errors.agreeToTerms && <Text color={'red.500'} fontSize={'sm'}>Agreeing to terms of service is Required!</Text>}
            </VStack>
            <VStack spacing={[2.5, 4]} alignItems='stretch'>
              <Button
                type='submit'
                isLoading={isSubmitting}
                variant='solid'
              // disabled={!watch('agreeToTerms')}
              >
                Sign up
              </Button>
              <Divider sx={{ marginTop: ['0.8rem !important', '1rem!important'] }} />
              <Button
                onClick={handleGoogleSignIn}
                isLoading={googleLoader}
                // disabled={!watch('agreeToTerms')}
                // isDisabled={!watch('agreeToTerms')}
                variant={'solid'}
                bg={'white'}
                border={'1px solid gray'}
                borderColor={'gray.300'}
                color={'black'}
                _hover={{
                  bg: 'rgba(0,0,0,0.05)',
                  borderColor: 'gray.300',
                }}
                sx={{ marginTop: ['0.8rem !important', '1rem!important'] }}
                leftIcon={<Icon as={FcGoogle} />}
              >
                Google
              </Button>
              <Divider sx={{ marginTop: ['0.8rem !important', '1rem!important'] }} />
              <Button
                colorScheme='facebook'
                leftIcon={<FaFacebook />}
                // disabled={!watch('agreeToTerms')}
                // isDisabled={!watch('agreeToTerms')}
                variant={'solid'}
                bg={'facebook.400'}
                border={'1px solid'}
                _hover={{
                  bg: 'facebook.300',
                  borderColor: 'facebook.300',
                }}
                onClick={handleFacebookSignIn}
                isLoading={facebookLoader}
              >
                Facebook
              </Button>
            </VStack>
          </VStack>
        </Stack>
      </Container>
      {/* <Blur position={'absolute'} top={-10} left={-10} style={{ filter: 'blur(70px)' }} /> */}
    </Box>
  )
}

export const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height='560px'
      viewBox='0 0 528 560'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {/* <circle cx='71' cy='61' r='111' fill='#F56565' />
      <circle cx='244' cy='106' r='139' fill='#ED64A6' />
      <circle cy='291' r='139' fill='#ED64A6' />
      <circle cx='80.5' cy='189.5' r='101.5' fill='#ED8936' />
      <circle cx='196.5' cy='317.5' r='101.5' fill='#ECC94B' />
      <circle cx='70.5' cy='458.5' r='101.5' fill='#48BB78' />
      <circle cx='426.5' cy='-0.5' r='101.5' fill='#4299E1' /> */}
    </Icon>
  )
}
