import { Button, chakra, Flex, Spinner, Stack, Text, HStack } from '@chakra-ui/react'
import { collection, getDocs, onSnapshot, query, where, orderBy } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import DataTable from '../../components/DataTable'
import { db } from '../../firebase/config'
import { useCustomToast } from '../../hooks/customToast'
import { userState } from '../../recoil/atoms/auth'
import { BsPatchQuestionFill } from 'react-icons/bs'
import { InformationModal } from '../../components/InformationModal'
import { modalData } from './campaignInformationData'
import WelcomeUserModal from '../../components/WelcomeUserModal'
import { ExpressButton } from '../../components/ExpressButton'

const Campaigns = () => {
  const navigate = useNavigate()
  const [myCampaigns, setMyCampaigns] = useState([])
  const [accessCampaign, setAccessCampaign] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const { addToast } = useCustomToast()
  const [user] = useRecoilState(userState)

  const dataHead = [
    'Name',
    'Visits',
    'Requested',
    'Reviews',
    'Avg Rating',
    // 'Status',
    ' Record Review',
    'My Reviews',
    'Actions',
  ]

  const getCampaigns = async () => {
    try {
      const collRef = collection(db, 'campaigns')
      // let campaignRef = query(collRef, where("userId", "==", user.uid), orderBy("createdAt", "desc"))
      let campaignRef = query(
        collRef,
        where('userId', '==', user.uid),
        orderBy('campaignDetails.internalCampaignName', 'asc')
      )

      let teamCampaignQuery = query(collRef, where('specialAccess', 'array-contains', user.email))

      const unsub1 = onSnapshot(teamCampaignQuery, async (specialSnap) => {
        const unsub = onSnapshot(campaignRef, async (campaignSnap) => {
          let combineDocs = specialSnap.empty
            ? campaignSnap.docs
            : campaignSnap.docs.concat(specialSnap.docs)

          let myCampaignsArr = await Promise.all(
            combineDocs.map(async (campaign) => {
              const subCollectionRef = collection(db, 'campaigns', campaign.id, 'vanity')
              const subCollSnapshot = await getDocs(subCollectionRef)

              let vanity = {}
              if (!subCollSnapshot.empty) {
                let vanityName = String(subCollSnapshot.docs[0]?.data()?.vanityName || '')
                  .toLowerCase()
                  .trim()
                let updatedAt = subCollSnapshot.docs[0]?.data()?.updatedAt
                let id = subCollSnapshot.docs[0]?.id
                vanity = { vanityName, updatedAt, id }
              }
              const testQueryRef = query(
                collection(db, 'reviews'),
                where('campaignId', '==', campaign.id)
              )
              const getReviews = await getDocs(testQueryRef)

              let avgRating = 0
              if ('avgRating' in campaign.data()) {
                avgRating = campaign.data()?.avgRating
                // console.log('from database', { avgRating })
              }
              // else {
              //   avgRating = getReviews.docs
              //     .map((testimonial) => testimonial.data()?.rating)
              //     .filter((rating) => !!rating)
              //   avgRating = avgRating?.length > 0
              //     ? (avgRating.reduce((sum, rating) => sum + Number(rating), 0) / avgRating.length)
              //     : 0
              // }

              let { campaignDetails, active, visits, specialAccess, sentRequests } = campaign.data()

              let object = {
                id: campaign.id,
                name: campaignDetails.internalCampaignName,
                visits: visits || 0,
                reviews: 0,
                sentRequests: sentRequests || 0,
                status: active,
                collectingPage: `review/${vanity?.vanityName || campaign.id}`,
                wallPage: `wall/${vanity?.vanityName || campaign.id}`,
                vanity,
                avgRating,
                isTeam: Array.isArray(specialAccess),
              }
              return {
                ...object,
                reviews: getReviews.size,
              }
            })
          )
          setMyCampaigns(myCampaignsArr)
          setDataLoading(false)
        })
        return () => unsub()
      })

      return () => unsub1()
    } catch (error) {
      addToast({
        title: 'Campaign!',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      console.log(error)
      setDataLoading(false)
    }
  }

  useEffect(() => {
    getCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex mt={[8]} direction='column' gap={'2rem'}>
      <Flex
        justify={'space-between'}
        // pt={[4, 10, 4]}
        flexDirection={['column', 'row']}
        alignItems={['stretch', 'center']}
        rowGap={'10px'}
      >
        <HStack align='left'>
          <Text fontSize={['20px', '24px']} fontWeight='600' lineHeight={'38px'} color='#474747'>
            List of Campaigns
          </Text>

          <InformationModal
            icon={<BsPatchQuestionFill />}
            iconColor='teal.300'
            iconSize='1.7rem'
            modalData={modalData}
          />
        </HStack>
        {/* <Button
          leftIcon={<BsPlusLg />}
          size={['sm', 'md']}
          variant='solid'
          py={'4'}
          onClick={(_) => navigate('/dashboard/new-campaign')}
        >
          Create a Business Campaign
        </Button> */}
        <ExpressButton user={user} />
      </Flex>
      {dataLoading ? (
        <Stack justifyContent='center' alignItems='center' width='full' minHeight='350px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='teal.500'
            size={['md', 'xl']}
          />
        </Stack>
      ) : (
        <chakra.div>
          <DataTable
            variant={'simple'}
            sx={{
              // border: '1px solid',
              // borderColor: 'gray.300',
              // borderRadius: '16px',
              // boxShadow:
              //   'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
            }}
            theadSx={{ bg: 'gray.50' }}
            dataHead={dataHead}
            data={[...accessCampaign, ...myCampaigns]}
            user={user}
            getCampaigns={getCampaigns}
            funcIfCampaignNotExist={() => navigate('/dashboard/new-campaign')}
          />
        </chakra.div>
      )}
    </Flex>
  )
}

export default Campaigns
