import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  chakra,
  Image,
  useDisclosure,
  Hide,
  HStack,
  Text,
  VStack,
  Button
} from '@chakra-ui/react';
import Step1 from './components/step1';
import { useForm } from 'react-hook-form';
import Step2 from './components/step2';
import Step3 from './components/step3';
import Footer from '../../components/Footer';
import { useParams } from 'react-router-dom';
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { db, storage } from '../../firebase/config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import Completed from './components/completed';
import { useCustomToast } from '../../hooks/customToast';
import {
  blobToBase64,
  cloudfunctionsBaseURL,
  sendNotification,
  isSafari,
  fnBrowserDetect,
  convertToMb
} from '../../utils';
import emailjs from '@emailjs/browser';
import { WaitModal } from './components/waitModal';
import {
  isBrowser,
  isMobile,
  browserName,
  browserVersion,
  fullBrowserVersion,
  deviceType,
  engineName,
  engineVersion,
  osName,
  osVersion
} from 'react-device-detect';
import WelcomeVideoModal from './components/WelcomeVideoModal';

const initialValues = {
  name: '',
  companyName: '',
  email: '',
  position: '',
  profileURL: '',
  testimonial: '',
  duration: '',
  date: '',
  id: '',
  userId: '',
  campaignId: '',
  like: false,
  progress: 0
};

function Review() {
  const { addToast } = useCustomToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenWelcomeVideo,
    onOpen: onOpenWelcomeVideo,
    onClose: onCloseWelcomeVideo
  } = useDisclosure();
  const { id } = useParams();
  const [recordScreen, setRecordScreen] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [campaign, setCampaign] = useState(null);
  const [visited, setVisited] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState(false);
  const [storagePath, setStoragePath] = useState('');
  const [inviteId, setInviteId] = useState('');
  const [customError, setCustomError] = useState('');
  const submitRef = useRef(null);
  const [submitClicked, setSubmitClicked] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    clearErrors,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: initialValues
  });
  const [newDocId, setNewDocId] = useState('');
  const reviewCollRef = collection(db, 'reviews');

  // console.log({ newDocId }, watch('testimonial'))

  useEffect(() => {
    if (!newDocId) {
      setNewDocId(doc(reviewCollRef).id);
    }
  }, []);

  const getUserBusiness = userId => {
    return new Promise(async (resolve, reject) => {
      try {
        const ref = query(
          collection(db, 'businesses'),
          where('userId', '==', userId)
        );
        const docSnap = await getDocs(ref);
        if (!docSnap.empty) {
          docSnap.docs.map(doc => {
            let business = { ...doc.data(), id: doc.id };
            return resolve(business);
          });
        } else {
          const userSnap = await getDoc(doc(db, 'users', userId));
          const { firstName, lastName, email } = userSnap.data();
          let business = {
            businessName: firstName + ' ' + lastName,
            businessEmail: email,
            userId: userSnap.id
          };
          return resolve(business);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    (async () => {
      const campaignId = id;
      const vanityCollRefQuery = query(
        collectionGroup(db, 'vanity'),
        where('vanityName', '==', String(campaignId).toLowerCase().trim())
      );
      const vanityData = await getDocs(vanityCollRefQuery);
      let getCampaignId = !vanityData.empty
        ? vanityData?.docs[0]?.ref?.parent?.parent?.id
        : campaignId;

      const docRef = doc(db, 'campaigns', getCampaignId);
      let campaign = await getDoc(docRef);
      if (!campaign.exists()) {
        setCampaign(false);
      } else {
        getUserBusiness(campaign.data().userId)
          .then(async business => {
            let campaignData = {
              ...campaign.data(),
              id: campaign.id,
              campaignOwnerBusiness: business
            };
            const customUrlRef = collection(db, 'campaigns', getCampaignId, 'vanity');
            let customUrlData = await getDocs(customUrlRef);

            if (!customUrlData.empty) {
              let customUrlName = String(
                customUrlData.docs[0]?.data()?.vanityName || ''
              )
                .toLowerCase()
                .trim();
              campaignData = { ...campaignData, customUrlName };
            }
            reset({
              ...initialValues,
              campaignId: campaign.id,
              userId: campaign.data().userId
            });
            setCampaign(campaignData);
            setStoragePath(`reviews/${campaign?.id}`);
            const searchParams = new URLSearchParams(window.location.search);
            const getInviteId = searchParams.get('inviteId');
            setInviteId(getInviteId || '');
            if (!visited && campaign.data().active) {
              let visits = campaign.data().visits
                ? campaign.data().visits + 1
                : 1;
              await updateDoc(docRef, { visits });
              setVisited(true);
            }
          })
          .catch(error => {
            addToast({
              title: 'Business!',
              description: error.message,
              status: 'error',
              variant: 'left-accent'
            });
            return false;
          });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadToFirestore = async (values, testimonial) => {
    const {
      campaignOwnerBusiness: { businessEmail, businessName },
      campaignDetails: { publicCampaignName, internalCampaignName }
    } = campaign;
    let { newDocId, ...rest } = values;
    const logDocId = localStorage.getItem('LogId');

    const queryColl = query(
      collection(db, 'reviews'),
      where('inviteId', '==', inviteId)
    );
    const checkIfInviteUsed = !(await getDocs(queryColl)).empty;
    await setDoc(doc(db, 'reviews', newDocId), {
      ...rest,
      reviewed: false,
      review: testimonial,
      date: serverTimestamp(),
      inviteId: checkIfInviteUsed ? '' : inviteId,
      logDocId: logDocId || ''
    });
    onClose();

    // fetch(`http://127.0.0.1:5001/sendmea-c7d45/us-central1/updatedMediaUploadToCloudinary`, {
    fetch(`${cloudfunctionsBaseURL}updatedMediaUploadToCloudinary`, {
      method: 'POST',
      body: JSON.stringify({
        file: testimonial,
        resource_type: 'video',
        raw_convert: 'google_speech:srt:vtt:en-US',
        filename: newDocId,
        folder: 'sendmea/reviews',
        isSafari: isSafari(),
        storagePath: `${storagePath}/${newDocId}`,
        logDocId: logDocId
      })
    });

    const payload = {
      title: `Review Added!`,
      message: `New Review added ${
        (values?.email || values?.name) &&
        'by ' + (values?.email || values?.name)
      }`,
      sender_id: values?.email || values?.name || '',
      receiver_id: values.userId,
      reviewRef: newDocId
    };
    addToast({
      title: 'Review',
      description: 'Review submitted for the campaign',
      status: 'success',
      variant: 'left-accent'
    });
    setStep(prev => prev + 1);
    sendNotification(payload);

    window.scrollTo({
      top: 20,
      behavior: 'smooth'
    });
    const templateParams = {
      to_email: businessEmail,
      to_name: businessName,
      from_name: values?.name,
      from_email: values?.email,
      message: `
                <p>
                    A video review was created for the campaign <b>"${
                      publicCampaignName || internalCampaignName
                    }"</b> 
                    ${
                      values?.reviewMessage
                        ? `<br/> with a message "${values.reviewMessage}"`
                        : ''
                    }
                    ${
                      values?.rating
                        ? `<br/> and give it a "${values.rating}" star rating`
                        : ''
                    }
                    <br/>Checkout whole review by logging in to your sendmea dashboard
                    <a href="https://sendmea.io/dashboard/" target="_blank" rel="noreferrer">
                        https://sendmea.io/dashboard/
                    </a>
                </p>
            `
    };

    const response = await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_REVIEW_NOTIFICATION_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_PUBLIC_KEY
    );

    if (response.status === 200) {
      addToast({
        title: 'Email Status!',
        description: `Campaign owner notified via email`,
        status: 'success',
        variant: 'left-accent'
      });
    }
    setIsSubmitting(false);
  };

  const onSubmit = async state => {
    handleLogAtSave();
    setCustomError('');
    setIsSubmitting(true);
    onOpen();
    let { testimonial, progress, ...values } = state;
    try {
      setSubmitClicked(true);
      // if (typeof (testimonial) !== "object" && testimonial?.includes('blob')) {
      //     testimonial = await fetch(testimonial).then((r) => r.blob())
      // }
      // const base64Video = await blobToBase64(testimonial)
      // if (!isSafari() && convertToMb(testimonial) < 20) {
      //     // let result = await fetch(`http://127.0.0.1:5001/sendmea-c7d45/us-central1/mediaUploadToCloudinary`, {
      //     let result = await fetch(`${cloudfunctionsBaseURL}mediaUploadToCloudinary`, {
      //         method: 'POST',
      //         body: JSON.stringify({
      //             file: base64Video,
      //             resource_type: 'video',
      //             filename: newDocId,
      //             folder: 'sendmea/reviews',
      //             isSafari: false,
      //         }),
      //     })
      //     result = await result.json()
      //     uploadToFirestore(
      //         { ...values, duration: result.data?.duration || values.duration, newDocId },
      //         result.data.secure_url
      //     )
      // } else {
      //     uploadFile({ file: testimonial, storagePath: `${storagePath}/${newDocId}` }, (e) =>
      //         uploadToFirestore({ ...values, newDocId }, e, convertToMb(testimonial) < 20)
      //     )
      // }
    } catch (error) {
      const logCollRef = collection(db, 'logs');
      const newLogId = doc(logCollRef).id;
      const resip = await fetch('https://api.ipify.org/?format=json');
      const { ip } = await resip.json();
      await setDoc(doc(db, 'logs', newLogId), {
        createdAt: serverTimestamp(),
        errorMessage: error?.message || ' ',
        errorName: error?.name || ' ',
        browserName: browserName || fnBrowserDetect(),
        type: 'error',
        logId: id,
        userEmail: values?.email || ' ',
        originatedFrom: 'record_a_review',
        campaignLinkId: watch('campaignId'),
        campaignName:
          campaign?.campaignDetails?.publicCampaignName ||
          campaign?.campaignDetails?.testimonialCampaignName,
        ip,
        isBrowser,
        isMobile,
        browserVersion,
        fullBrowserVersion,
        deviceType,
        engineName,
        engineVersion,
        osName,
        osVersion
      });
      setCustomError(error.message);
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent'
      });
      setIsSubmitting(false);
      onClose();
      setSubmitClicked(false);
    }
  };

  const handleRecordVideo = () => {
    setRecordScreen(true);
    setTimeout(() => {
      const section = document.querySelector('#screenRecorder');
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // console.log('recording started')
    }, 100);
  };

  // const uploadFile = async ({ file, storagePath }, callback = () => { }) => {
  //     if (!file) return
  //     const storageRef = ref(storage, storagePath)
  //     const uploadTask = uploadBytesResumable(storageRef, file)
  //     uploadTask.on(
  //         'state_changed',
  //         () => { },
  //         (error) => {
  //             addToast({
  //                 title: 'Error',
  //                 description: error.message,
  //                 status: 'error',
  //                 variant: 'left-accent',
  //             })
  //         },
  //         () => {
  //             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //                 setValue('testimonial', downloadURL)
  //                 callback(downloadURL)
  //             })
  //         }
  //     )
  // }

  useEffect(() => {
    if (
      watch('campaignId') &&
      !localStorage.getItem('sendmeaRecordLog' + watch('campaignId')) &&
      campaign?.campaignDetails
    ) {
      (async () => {
        const logCollRef = collection(db, 'logs');
        const newLogId = doc(logCollRef).id;
        localStorage.setItem('LogId', newLogId);
        // const resip = await fetch('https://ip-api.com/json') //! http only works in local and https will work on prod
        const resip = await fetch('https://api.ipify.org/?format=json');
        const { ip } = await resip.json();
        await setDoc(doc(db, 'logs', newLogId), {
          createdAt: serverTimestamp(),
          browserName: browserName || fnBrowserDetect(),
          type: 'log',
          logId: id,
          originatedFrom: 'record_a_review',
          campaignLinkId: watch('campaignId'),
          campaignName:
            campaign.campaignDetails?.publicCampaignName ||
            campaign?.campaignDetails?.testimonialCampaignName,
          ip,
          isBrowser,
          isMobile,
          browserVersion,
          fullBrowserVersion,
          deviceType,
          engineName,
          engineVersion,
          osName,
          osVersion
        });
        localStorage.setItem('sendmeaRecordLog' + watch('campaignId'), true);
        console.log('log sent!');
      })();
    }
  }, [campaign, id, watch]);

  useEffect(() => {
    if (
      campaign?.active &&
      campaign?.liveWelcomeVideo &&
      campaign?.welcomeVideo
    ) {
      onOpenWelcomeVideo();
    }
  }, [campaign]);

  const handleLogAtSave = async () => {
    try {
      const docId = localStorage.getItem('LogId');
      if (docId) {
        const docRef = doc(db, 'logs', docId);
        await updateDoc(docRef, {
          savedAt: serverTimestamp()
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      submitClicked &&
      watch('progress') == 100 &&
      watch('testimonial')?.includes('firebasestorage')
    ) {
      let { testimonial, progress, ...values } = getValues();
      uploadToFirestore({ ...values, newDocId }, watch('testimonial'));
    }
  }, [watch('progress'), submitClicked, watch('testimonial')]);

  return (
    <chakra.div
      bg={'gray.50'}
      minH="100vh"
      as={'form'}
      onSubmit={handleSubmit(onSubmit)}>
      <WelcomeVideoModal
        campaign={campaign}
        isOpen={isOpenWelcomeVideo}
        onClose={onCloseWelcomeVideo}
      />
      <Box minHeight={['93vh']} pb={6}>
        {campaign ? (
          campaign.active ? (
            <VStack justify={'center'} pr={2}>
              <HStack
                justifyContent={'center'}
                alignItems={'center'}
                mt={3}
                w={['100%', null, '500px']}>
                <chakra.div
                  display={'flex'}
                  as={Hide}
                  below="md"
                  justifyContent={'center'}
                  pt={'6'}>
                  {campaign.campaignDetails?.campaignLogo && (
                    <Image
                      objectFit="cover"
                      maxW={{ base: '150px' }}
                      src={campaign.campaignDetails?.campaignLogo || ''}
                      alt="Business Logo"
                      borderRadius="lg"
                    />
                  )}
                </chakra.div>
              </HStack>
            </VStack>
          ) : (
            <chakra.h1
              fontWeight={'bold'}
              fontSize={'2rem'}
              minH={'68vh'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'brand.1'}>
              Campaign is no longer active!
            </chakra.h1>
          )
        ) : (
          <chakra.h1
            fontWeight={'bold'}
            fontSize={'2rem'}
            minH={'68vh'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            color={'brand.1'}>
            {campaign === null ? 'Loading...' : "Campaign doesn't exist!"}
          </chakra.h1>
        )}
        {campaign &&
          campaign.active &&
          (step === 1 ? (
            <Step1
              setStep={setStep}
              step={step}
              useForm={{ setValue, watch }}
              handleRecordVideo={handleRecordVideo}
              forRecorder={{
                setRecordScreen,
                recordScreen,
                campaign,
                isOpenWelcomeVideo,
                newDocId,
                storagePath
              }}
              campaign={campaign}
              setUploadedVideo={setUploadedVideo}
            />
          ) : uploadedVideo && step === 2 ? (
            <Step2
              setStep={setStep}
              useForm={{ setValue, watch }}
              setUploadedVideo={setUploadedVideo}
            />
          ) : !uploadedVideo && step === 2 ? (
            <Step3
              useForm={{
                handleSubmit,
                clearErrors,
                register,
                setValue,
                watch,
                reset
              }}
              errors={errors}
              customError={customError}
              isSubmitting={isSubmitting}
              formDetails={campaign.formDetails}
              setStep={setStep}
              step={step}
              submitRef={submitRef}
            />
          ) : (
            <Completed campaign={campaign} />
          ))}
      </Box>
      {/* <Button onClick={onOpen}>open wait modal</Button> */}
      <WaitModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          onClose();
          setIsSubmitting(false);
        }}
        watch={watch}
      />
      <Footer />
    </chakra.div>
  );
}

export default Review;
