import {
	Avatar,
	Box,
	Button,
	Heading,
	HStack,
	Image,
	SimpleGrid,
	Stack,
	Text,
	chakra,
} from '@chakra-ui/react'
// import { FacebookShareButton, FacebookIcon } from 'react-share';
import { useState, useRef, useEffect } from 'react'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { useCollection } from '../../hooks/useCollection'
import { NewsletterForm } from './HeaderNewsletter'
import LazyLoad from 'react-lazyload'

const blogLimit = 3
//set to three to show functionality

export const NewBlog = () => {
	const [next, setNext] = useState(blogLimit)
	// const user = useRecoilValue(userState)
	const handleBlogLimit = () => {
		setNext(next + blogLimit)
		bottomRef.current.scrollIntoView({ behavior: 'smooth' })
	}
	const [bList, setBList] = useState([])

	// const [selectedCategory, setSelectedCategory] = useState('')

	const bottomRef = useRef()

	const { documents: blogDocs } = useCollection('blogs', [
		'publish',
		'==',
		true,
	])

	const sorted = blogDocs?.sort((a, b) => b.publishedAt - a.publishedAt)

	// function handleCategoryChange(event) {
	//   setSelectedCategory(event.target.value);
	// }
	// console.log(sorted)
	useEffect(() => {
		setBList(sorted)
		// console.log(sorted?.map(obj => obj?.category))
	}, [sorted])

	// function getFilteredList() {
	//   if (!selectedCategory) {
	//     return bList;
	//   }
	//   return bList?.filter((item) => item.category === selectedCategory || item?.category?.value === selectedCategory);
	// }

	// const filteredList = useMemo(getFilteredList, [selectedCategory, bList]);

	// useEffect(() => {
	//   setSelectedCategory(user ? 'tips' : '')
	// }, [user])

	return (
		<>
			<Box
				mb='20'
				w='full'
			>
				<Stack
					my={3}
					spacing='0'
					align='center'
					direction={{ base: 'column' }}
					width='full'
					justify='center'
					// boxShadow="md"
					rounded='md'
					w='full'
				>
					<Box
						bg='teal.500'
						w='full'
						py={3}
						overflow='hidden'
					>
						<Text
							textAlign='center'
							fontSize={['3xl', '5xl']}
							color='white'
							fontWeight='bold'
						>
							Sendmea{' '}
							<chakra.span color={'brand.2'}>
								{' '}
								Training
							</chakra.span>
						</Text>
					</Box>

					{/* <VStack py={6} w="full" alignItems="center" px={["2"]} spacing={4}>
            <Text textAlign="center" fontSize={{ base: 'md', md: 'lg' }} fontWeight="500" maxW='2xl' color='gray.600'>
              Catch up on what you missed or subscribe to our newsletter
            </Text>
            <HStack flexWrap={["wrap", "nowrap"]} spacing={0} gap="2">
              <Input name='email' placeholder='Enter email' minW={["250px", "300px"]} />
              <Button size='md'>Subscribe</Button>
            </HStack>
          </VStack> */}
					{/* <NewsletterForm /> */}
				</Stack>
				{/* <Flex px='4' w='full' justify={'flex-end'} align={'center'}>
          <Text pr='3' color='gray.600' fontWeight='500' fontSize={'lg'}> Category: </Text>

          <Select w={{ base: '60%', md: '20%' }} onChange={handleCategoryChange} value={selectedCategory}>
            <option value="">All</option>
            <option value="news">News</option>
            <option value="tips">Sendmea Tips</option>
          </Select>
        </Flex> */}

				<Stack
					spacing={{
						base: '12',
						md: '16',
					}}
					mt='2'
					bg='rgba(180, 224, 226, 0.1)'
					py='10'
					px='4'
				>
					<LazyLoad>
						<SimpleGrid
							columns={{
								base: 1,
								md: 2,
								lg: 3,
							}}
							gap={{
								base: '12',
								lg: '8',
							}}
						>
							{bList?.slice(0, next)?.map((blogDoc) => (
								<Link
									key={blogDoc.id}
									to={`/blog/${
										blogDoc.wordsUrl || blogDoc.id
									}`}
									_hover={{
										textDecor: 'none',
									}}
									role='group'
									onClick={() => console.log(blogDoc)}
								>
									<Stack
										h='full'
										bg='white'
										spacing='8'
										sx={{
											boxShadow:
												'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
										}}
									>
										<Box overflow='hidden'>
											<Image
												src={blogDoc.headerImg}
												alt={blogDoc.blogTitle}
												width='full'
												// height='15rem'
												// width="full"
												height={{
													base: '15rem',
													md: '15rem',
												}}
												objectFit='cover'
												transition='all 0.2s'
												_groupHover={{
													transform: 'scale(1.05)',
												}}
											/>
										</Box>
										<Stack
											px='4'
											spacing='3'
											h='12rem'
										>
											{blogDoc?.category === 'news' ? (
												<Text
													fontSize='sm'
													fontWeight='semibold'
													color='brand.1'
												>
													News
												</Text>
											) : (
												<Text
													fontSize='sm'
													fontWeight='semibold'
													color='brand.1'
												>
													Sendmea Tips
												</Text>
											)}
											<Heading
												minH={'79.2px'}
												as={Text}
												fontSize={['22px']}
												noOfLines={3}
											>
												{blogDoc.blogTitle}
											</Heading>
											<Text
												fontSize='sm'
												fontWeight='semibold'
												color=''
											>
												{blogDoc.blogDesc}
											</Text>
											{/* <Text color="muted">{blogDoc.blogContent}</Text> */}
										</Stack>
										<HStack px='4'>
											<Avatar
												src={
													'https://res.cloudinary.com/codeshock/image/upload/v1675392551/cool_q9bunp.png'
												}
												boxSize='10'
											/>
											<Box fontSize='sm'>
												<Text
													color='brand.1'
													fontWeight='medium'
												>
													Sendmea
												</Text>
												<Text color='muted'>
													<chakra.span
														color='gray.600'
														fontWeight='500'
													>
														Published at{' '}
													</chakra.span>
													{format(
														new Date(
															blogDoc?.publishedAt
																?.toDate()
																?.toDateString() ||
																'01-01-2023'
														),
														'MMM dd yyyy'
													)}
												</Text>
											</Box>
										</HStack>

										<Stack p='3'>
											<Button
												variant={'link'}
												// as={Link}
												to={`/blog/${
													blogDoc.wordsUrl ||
													blogDoc.id
												}`}
												state={blogDoc}
											>
												Read more...
											</Button>
										</Stack>
									</Stack>
								</Link>
							))}
						</SimpleGrid>
					</LazyLoad>
					{next < blogDocs?.length && (
						<Button
							alignSelf='center'
							onClick={handleBlogLimit}
							width={{
								base: 'full',
								md: '20%',
							}}
							size='lg'
						>
							Show More
						</Button>
					)}
					<Box ref={bottomRef}></Box>
				</Stack>
			</Box>
		</>
	)
}
