// import { Box, Button, Container, Heading, Stack, Text, chakra } from '@chakra-ui/react'

// export const SignUp = () => (
//   <Box py={6} as="section" bg="bg.surface">
//     <Container py={{ base: '16', md: '24' }}>
//     <Box
//       bg="brand.1"
//       color="fg.accent.default"
//       borderRadius="xl"
//     //   px={{ base: '6', lg: '16' }}
//     //   py={{ base: '10', lg: '16' }}
//     >
//       <Stack spacing={{ base: '8', md: '10' }}>
//         <Stack spacing={{ base: '4', md: '5' }} align="center">

//           <Text
//                 fontSize={['1.85rem', '2rem']}
//                 fontWeight={'600'}
//                 my={'4'}
//                 textAlign={'center'}
//                 pt={'6'}
//             >
//                 <chakra.span color={'brand.1'}>Appointment </chakra.span>
//                 <chakra.span color={'brand.2'}>and Sign Up</chakra.span>
//             </Text>
//           <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
//           </Text>
//         </Stack>
//         <Stack spacing="3" direction={{ base: 'column', sm: 'row' }} justify="center">
//           <Button w={40} p={4} variant={'solid'} size="xl">
//             Set Appointment
//           </Button>
//           <Button w={40} size="xl">Sign Up</Button>
//         </Stack>
//       </Stack>
//       </Box>
//     </Container>
//   </Box>
// )

import { Box, Button, Container, Heading, Stack, Text } from '@chakra-ui/react'

export const SignUp = () => (
//   <Container py={{ base: '16', md: '24' }}>
    <Box
      bg="brand.1"
      color="white"
      borderRadius="xl"
      mx={20}
      my={20}
      px={{ base: '10', lg: '16' }}
      py={{ base: '10', lg: '20' }}
    >
      <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} justify="space-between">
        <Stack spacing="4" maxW="xl">
          <Heading size="sm">Ready to Grow?</Heading>
          <Text color="fg.accent.muted" fontSize={{ base: 'lg', lg: 'xl' }}>
          Say goodbye to fake reviews and hello to a powerful tool that helps you stand out from the competition.
          </Text>
        </Stack>
        <Stack spacing="8" py='8' direction={{ base: 'column', sm: 'row' }} justify={{ base: 'end', sm: 'center' }}>
          <Button color="white"  p={2} >
            Book Meeting
          </Button>
          <Button px={6} bg={"brand.2"} >
            Sign Up
          </Button>
        </Stack>
      </Stack>
    </Box>
//   </Container>
)