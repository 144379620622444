import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Box,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Text,
  Image,
  useMediaQuery,
  useDisclosure,
  Spinner,
  useOutsideClick,
  Tooltip,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import {
  AiFillCloseCircle,
  AiFillDollarCircle,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from 'react-icons/ai'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { HamburgerIcon } from '@chakra-ui/icons'
import { auth, db } from '../firebase/config'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import { sidebarState, stripePlanId, userLoggedIn, userState } from '../recoil/atoms/auth'
import fullLogo from '../assets/images/sendmea-logo.png'
import shortLogo from '../assets/images/android-chrome-192x192.png'
import { Link as RouterLink } from 'react-router-dom'
import VideoPopup from '../components/VideoPopup'
import SendInvite from '../components/SendInvite'
import { FiBarChart2, FiList } from 'react-icons/fi'
import { MdOutlineOndemandVideo, MdOutlineRateReview } from 'react-icons/md'
import { CgFileDocument } from 'react-icons/cg'
import { HiDatabase, HiNewspaper } from 'react-icons/hi'
import { useRef } from 'react'
import { LeaveUsAReview } from '../components/LeaveSendmeaReview'
import WelcomeUserModal from '../components/WelcomeUserModal'
import { getMyCampaigns } from '../recoil/atoms/campaigns'
import HelpMenu from './Components/DashboardLayout/HelpMenu'
import ProfileMenu from './Components/DashboardLayout/ProfileMenu'
import NotificationMenu from './Components/DashboardLayout/NotificationMenu'
import { getUserStripeRole } from '../firebase/stripeConfig'
import AskToPayModal from '../components/AskToPayModal'
import LoadingScreen from './Components/DashboardLayout/LoadingScreen'
import { fetchReferral, firstPromoterApi } from '../utils'
import { useCustomToast } from '../hooks/customToast'
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore'
import { SiTiktok } from 'react-icons/si'
import { saveLogoutLogs } from '../pages/Login/utils'
import { FaBlog } from 'react-icons/fa'

const LinkItems = [
  { name: 'Home', href: '/dashboard', icon: FiBarChart2 },
  { name: 'My Campaigns', href: '/dashboard/my-campaigns', icon: FiList },
  {
    name: 'Recorded Reviews',
    href: '/dashboard/received-testimonials',
    icon: MdOutlineOndemandVideo,
  },
  { name: 'Business Details', href: '/dashboard/business-details', icon: CgFileDocument },
  // { name: 'Affiliate Data', href: '/dashboard/affiliate-data', icon: HiDatabase },
  { name: 'Sendmea Training', href: '/blog', icon: HiNewspaper },
  { name: 'Sendmea Blog', href: 'https://blog.sendmea.io', icon: FaBlog, externalLink: true },
]

export default function SidebarWithHeader({ children }) {
  const { addToast } = useCustomToast()
  const [loading, setLoading] = useState(false)
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [openSidebar, setOpenSideBar] = useRecoilState(sidebarState)
  const [user, setUser] = useRecoilState(userState)

  const { contents: campaignFromRecoil, state } = useRecoilValueLoadable(getMyCampaigns)
  const [isUserLoggedIn, setIsUserLoggedIn] = useRecoilState(userLoggedIn)
  const [getStripePlanId, setStripePlanId] = useRecoilState(stripePlanId)

  const [isWelcomeOpen, setIsWelcomeOpen] = useState(false)
  const [isAskToPayModalOpen, setIsAskToPayModalOpen] = useState(false)
  const [getRole, setGetRole] = useState('loading')
  const [userPromotionObj, setUserPromotionObj] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const userRole = await getUserStripeRole(false)
        // const userRole = auth?.currentUser ? 'premium' : 'loading';
        if (userRole === 'loading') {
          setGetRole(userRole)
        } else if (userRole === 'free') {
          setGetRole(userRole)
          setUser({ ...user, role: 'free' })
          setIsAskToPayModalOpen(true)
        } else if (userRole === 'premium') {
          setGetRole(userRole)
          setUser({ ...user, role: 'premium' })

          // fetchReferral({ email: user.email, cust_id: user.uid })
          //   .then(async (result) => {
          //     // console.log('fetch promoter', result)
          //     if (!result?.isEnrolled && result?.error === 'Promoter not found.') {
          //       const payload = {
          //         email: user.email,
          //         first_name: user.firstName,
          //         last_name: user.lastName,
          //         cust_id: user.uid,
          //       }
          //       const PromoterSignup = await firstPromoterApi(
          //         'https://firstpromoter.com/api/v1/promoters/create',
          //         payload
          //       )
          //       if (PromoterSignup?.promotions?.length > 0) {
          //         setUserPromotionObj(PromoterSignup)
          //         setUser({ ...user, default_ref_id: PromoterSignup?.default_ref_id })

          //         addToast({
          //           title: 'Welcome!',
          //           description: 'You have become a Promoter',
          //           status: 'success',
          //           variant: 'left-accent',
          //         })
          //       } else {
          //         addToast({
          //           title: 'First Promoter',
          //           description:
          //             'Email ' + PromoterSignup?.['company_user.user.email']?.[0] ||
          //             'Something went wrong!',
          //           status: 'error',
          //           variant: 'left-accent',
          //         })
          //       }
          //     } else {
          //       setUserPromotionObj(result?.default_ref_id ? result : null)
          //       setUser({ ...user, default_ref_id: result?.default_ref_id })
          //     }
          //   })
          // .catch((err) => {
          //   throw err
          // })
        }
      } catch (error) {
        console.log(error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser])

  useEffect(() => {
    try {
      if (state === 'hasValue' && getRole === 'premium') {
        if (!isUserLoggedIn && !campaignFromRecoil.size) {
          setIsWelcomeOpen(true)
          setIsUserLoggedIn(true)
        }
      }
      if (getRole === 'premium' && user?.role !== 'premium') {
        setUser({ ...user, role: 'premium' })
      }
    } catch (error) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, getRole])

  // useEffect(() => {
  //   console.log({ user: user })
  // }, [user])

  return (
    <Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
      {/* {(state === 'loading' || roleLoading || user?.role !== 'premium') && ( */}
      {getRole === 'loading' ? (
        <LoadingScreen />
      ) : getRole === 'free' ? (
        <AskToPayModal
          user={user}
          isOpen={isAskToPayModalOpen}
          onClose={() => setIsAskToPayModalOpen(false)}
          setIsUserLoggedIn={setIsUserLoggedIn}
          setStripePlanId={setStripePlanId}
          stripePlanId={getStripePlanId}
          setUser={setUser}
        />
      ) : (
        <>
          <SidebarContent
            openSidebar={openSidebar}
            setOpenSideBar={setOpenSideBar}
            isLargerThan768={isLargerThan768}
            user={user}
          />
          <MobileNav
            openSidebar={openSidebar}
            isLargerThan768={isLargerThan768}
            setOpenSideBar={setOpenSideBar}
            user={user}
            setUser={setUser}
            setLoading={setLoading}
            setIsUserLoggedIn={setIsUserLoggedIn}
            setStripePlanId={setStripePlanId}
            userPromotionObj={userPromotionObj}
            // campaignFromRecoil={campaignFromRecoil}
          />

          {/* open only once when user logged in */}
          <WelcomeUserModal
            user={user}
            isOpen={isWelcomeOpen}
            onClose={() => setIsWelcomeOpen(false)}
          />

          <Box
            p={['4']}
            pb={{ base: '24' }}
            ml={{ base: '0px', md: openSidebar ? '270px' : '50px' }}
            transition='0.25s ease'
            background='#fff'
            minH='calc(100vh)'
            pt={['100px', '100px', '100px']}
            // mt={['90px']}
          >
            {loading ? (
              <VStack
                justifyContent='center'
                alignItems='center'
                width='full'
                minH='calc(100vh - 20vh)'
              >
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='teal.500'
                  size={['md', 'xl']}
                />
              </VStack>
            ) : (
              children
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

const SidebarContent = ({
  onClose,
  user,
  openSidebar,
  setOpenSideBar,
  isLargerThan768,
  ...rest
}) => {
  const navigate = useNavigate()
  const sidebarRef = useRef()
  const [newBlog, setNewBlog] = useState(false)
  // const { documents: blogDocs } = useCollection('blogs', ['publish', '==', true])

  // const sorted = blogDocs?.sort((a, b) => b.publishedDate - a.publishedDate)

  useEffect(() => {
    setOpenSideBar(isLargerThan768)
  }, [isLargerThan768, setOpenSideBar])

  useEffect(() => {
    ;(async () => {
      try {
        const startOfDay = new Date()
        startOfDay.setHours(0, 0, 0, 0)

        const endOfDay = new Date()
        endOfDay.setHours(23, 59, 59, 999)

        const collBlog = collection(db, 'blogs')
        const queryBlogs = query(
          collBlog,
          where('publish', '==', true),
          where('publishedDate', '>=', startOfDay),
          where('publishedDate', '<=', endOfDay),
          limit(1)
        )

        const unsubscribe = onSnapshot(queryBlogs, async (blogsSnap) => {
          setNewBlog(!blogsSnap.empty)
        })
        return () => unsubscribe()
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useOutsideClick({
    ref: sidebarRef,
    handler: () => !isLargerThan768 && setOpenSideBar(false),
  })

  const gotoAnchor = (url) => {
    let anchor = document.createElement('a')
    anchor.target = '_blank'
    anchor.href = url
    anchor.click()
    anchor.remove()
  }

  return (
    <Box
      ref={sidebarRef}
      transition='0.25s ease'
      bg={'brand.1'}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{
        base: openSidebar ? '270px' : '0px',
        md: openSidebar ? '270px' : '50px',
      }}
      pos={{ base: 'fixed' }}
      zIndex={openSidebar ? 11 : 10}
      h='full'
      overflow='hidden'
      {...(!openSidebar && {
        display: 'flex',
        flexDirection: 'column',
      })}
      {...rest}
      display={'flex'}
      flexDirection={'column'}
    >
      <Flex
        alignItems='center'
        mx={openSidebar ? '3' : '2'}
        gap={'5px'}
        justifyContent={['center', 'space-between', 'center']}
        minH='80px'
        max='85px'
        overflow='hidden'
      >
        <Image
          cursor='pointer'
          src={openSidebar ? fullLogo : shortLogo}
          w={['200px', '200px', '100%']}
          onClick={(_) => navigate('/')}
          {...(!openSidebar && {
            width: '33px',
            height: '33px',
          })}
        />
        <IconButton
          sx={{ display: { base: 'flex', md: 'none' } }}
          // mt={openSidebar && '2'}
          onClick={() => {
            setOpenSideBar((prev) => !prev)
          }}
          _hover={{
            bg: 'rgba(0,0,0,0.08)',
          }}
          variant='ghost'
          aria-label='open menu'
          size={'md'}
          color='white'
          icon={openSidebar ? <AiFillCloseCircle fontSize={18} /> : <HamburgerIcon fontSize={18} />}
        />
      </Flex>

      <Flex flex={1} direction='column'>
        <Flex direction='column' gap='10px' mt={2}>
          {LinkItems.map((link) => (
            <NavItem
              key={link.name}
              href={link.href}
              icon={link.icon}
              isIcon={link?.isIcon}
              openSidebar={openSidebar}
              setOpenSideBar={setOpenSideBar}
              isLargerThan768={isLargerThan768}
              externalLink={link?.externalLink}
            >
              <Text fontSize='16px' fontWeight='500' color='white' transition='all 0.25s ease'>
                {link.name}
              </Text>
              {/* new blog update */}
              {link.name === 'Sendmea Training' && newBlog ? (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    default: {
                      // duration: 5,
                      ease: [0, 0.21, 0.2, 1.01],
                    },
                    scale: {
                      type: 'spring',
                      damping: 2,
                      stiffness: 100,
                      restDelta: 0.001,
                    },
                    duration: 400,
                  }}
                >
                  <HStack>
                    <Text color='brand.2' fontWeight={'bold'}>
                      NEW
                    </Text>
                    <Image boxSize='27px' src='/images/airplane.png' />
                  </HStack>
                </motion.div>
              ) : null}
            </NavItem>
          ))}
        </Flex>

        <VStack flex={1} py={3}>
          {openSidebar && (
            <Box order={1}>
              <LeaveUsAReview />
            </Box>
          )}
          {isLargerThan768 && (
            <Tooltip
              label='Give sendmea a review'
              placement='right'
              color='white'
              bg='teal.400'
              px={2}
              py={1}
              ml={2}
              fontSize='1rem'
              fontWeight='medium'
              rounded='md'
              hasArrow
            >
              <IconButton
                order={3}
                alignSelf='center'
                variant='solid'
                display={openSidebar ? 'none' : 'inline-flex'}
                icon={<MdOutlineRateReview />}
                color='white'
                rounded='full'
                bg='#7ED957'
                _hover={{
                  bg: '#7ED957',
                  // transform: 'scale(1.2)',
                }}
                size='md'
                fontSize='1.4rem'
                mb='10px'
                minW='40px !important'
                minH='25px !important'
                padding='1px !important'
                as={'a'}
                href='https://sendmea.io/review/YjJsN8HRWhnqHva7jBXF'
                target={'_blank'}
              />
            </Tooltip>
          )}

          <Flex
            order={2}
            justifyContent={'center'}
            alignItems={'center'}
            direction={openSidebar ? 'row' : 'column'}
            flexWrap='wrap'
            mt={'auto !important'}
            gap={[2]}
            spacing={0}
            pb={3}
            maxW='230px'
          >
            <IconButton
              size='md'
              rounded='full'
              color='#e1f7f7'
              _hover={{
                bg: 'teal.400',
                color: 'white',
              }}
              icon={<AiFillYoutube fontSize='25px' />}
              onClick={() => gotoAnchor('https://www.youtube.com/@sendmea')}
            />
            <IconButton
              size='md'
              rounded='full'
              color='#e1f7f7'
              _hover={{
                bg: 'teal.400',
                color: 'white',
              }}
              icon={<AiFillInstagram fontSize='25px' />}
              onClick={() => gotoAnchor('https://www.youtube.com/@sendmea')}
            />
            <IconButton
              size='md'
              rounded='full'
              color='#e1f7f7'
              _hover={{
                bg: 'teal.400',
                color: 'white',
              }}
              icon={<FaFacebookF fontSize='20px' />}
              onClick={() => gotoAnchor('https://www.facebook.com/100088104594727')}
            />
            <IconButton
              size='md'
              rounded='full'
              color='#e1f7f7'
              _hover={{
                bg: 'teal.400',
                color: 'white',
              }}
              icon={<FaLinkedinIn fontSize='20px' />}
              onClick={() => gotoAnchor('https://www.linkedin.com/company/sendmea/')}
            />
            <IconButton
              size='md'
              rounded='full'
              // variant={'solid'}
              // bg='#e1f7f7'
              color='#e1f7f7'
              _hover={{
                bg: 'teal.400',
                color: 'white',
              }}
              icon={<AiOutlineTwitter fontSize='22px' />}
              onClick={() => gotoAnchor('https://twitter.com/sendmea_review')}
            />
            <IconButton
              size='md'
              rounded='full'
              // variant={'solid'}
              // bg='#e1f7f7'
              color='#e1f7f7'
              _hover={{
                bg: 'teal.400',
                color: 'white',
              }}
              icon={<SiTiktok fontSize='22px' />}
              onClick={() => gotoAnchor('https://www.tiktok.com/@teamsendmea')}
            />
          </Flex>
        </VStack>
      </Flex>
      {/* {!hideLogin && ( */}
    </Box>
  )
}

const NavItem = ({
  openSidebar,
  setOpenSideBar,
  isLargerThan768,
  icon,
  children,
  href,
  isIcon,
  externalLink,
  ...rest
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Link
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={() => {
        externalLink ? window.open(href, '_blank') : navigate(href)
        !isLargerThan768 && setOpenSideBar(false)
      }}
    >
      <Flex
        align='center'
        pr='4'
        pl={openSidebar ? '4' : '2.5'}
        py='2'
        role='group'
        cursor='pointer'
        gap={'20px'}
        bg={location.pathname === href ? '#47B2BA' : 'transparent'}
        _hover={{
          bg: location.pathname !== href && 'whiteAlpha.200',
        }}
        transition='all 0.25s ease'
        minW={'260px'}
        {...rest}
      >
        {icon && (
          <Tooltip
            label={children}
            fontSize='smaller'
            placement='right'
            color='white'
            bg='teal.400'
            px={2}
            rounded='md'
            hasArrow
            visibility={openSidebar && 'hidden'}
          >
            <HStack
              width={['28px']}
              height={['28px']}
              borderRadius='full'
              border='1px solid'
              bg={location.pathname === href ? '#7ED957' : 'transparent'}
              borderColor={location.pathname === href ? '#7ED957' : 'transparent'}
              alignItems='center'
              p={1}
              transition='all .1s ease-in-out'
              _hover={
                !openSidebar && {
                  transform: 'scale(1.2)',
                }
              }
            >
              <Icon mr='4' fontSize='lg' fontWeight='bold' color={'white'} as={icon} />
            </HStack>
          </Tooltip>
        )}
        {children}
      </Flex>
    </Link>
  )
}

const MobileNav = ({
  isLargerThan768,
  openSidebar,
  setOpenSideBar,
  user,
  setUser,
  setLoading,
  setIsUserLoggedIn,
  setStripePlanId,
  userPromotionObj,
  ...rest
}) => {
  const navigate = useNavigate()
  const logout = async () => {
    await auth.signOut()
    navigate('/login')
    setUser(null)
    setIsUserLoggedIn(false)
    setStripePlanId('')
    await saveLogoutLogs({
      userEmail: user?.email,
      userId: user.uid,
      loginId: user?.loginId || '',
    })
  }

  const [isLargerThan870] = useMediaQuery('(min-width: 870px)')

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [cardInfoState, setCardInfo] = useState(null)
  const [referralCopied, setReferralCopied] = useState(false)

  return (
    <Flex
      w={{ base: '100%', md: `calc(100% - ${openSidebar ? '270px' : '52px'})` }}
      position={{ base: 'fixed' }}
      ml={{ base: '0px', md: openSidebar ? '270px' : '52px' }}
      px={{ base: 4, md: 4 }}
      pl={{ base: 1 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('#F8FCFD', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('#F8FCFD', 'gray.700')}
      justifyContent={{ base: 'space-between' }}
      transition='all 0.25s ease'
      zIndex={8}
      {...rest}
    >
      <Flex align='center' gap={{ base: '6px', md: '4px' }}>
        <IconButton
          display={{ base: 'block', md: 'block' }}
          onClick={() => {
            setOpenSideBar((prev) => !prev)
          }}
          variant='ghost'
          aria-label='open menu'
          fontWeight='bold'
          icon={<HamburgerIcon />}
        />
      </Flex>

      <HStack spacing={{ base: '1', md: '3' }} flexWrap='no-wrap'>
        <SendInvite />
        <Box>
          <HelpMenu />
        </Box>
        <Box>
          <NotificationMenu
            user={user}
            onOpen={onOpen}
            setLoading={setLoading}
            setCardInfo={setCardInfo}
          />
        </Box>
        <Box>
          <ProfileMenu isLargerThan870={isLargerThan870} user={user} logout={logout} />
        </Box>
      </HStack>
      <VideoPopup
        isOpen={isOpen}
        onClose={(e) => {
          onClose(e)
          setCardInfo(null)
        }}
        cardInfo={cardInfoState}
      />
    </Flex>
  )
}
