import { Button, HStack, Heading, SimpleGrid, Spinner, Stack, Text, VStack } from '@chakra-ui/react'
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import React, { useState } from 'react'
import { db } from '../../../../firebase/config'
import ErrorCard from './ErrorCard'
import LoggingCard from './LoggingCard'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../recoil/atoms/auth'
import SimpleLogCard from './SimpleLogCard'
import { isValid } from 'date-fns'

const Logs = () => {
    const [loading, setLoading] = useState(false)
    const [allLogs, setAllLogs] = useState(null)
    const user = useRecoilValue(userState)

    const fetchLoggingLogs = async () => {
        setLoading(true)
        try {
            const collRef = collection(db, 'logs')
            const queryRef = query(collRef, orderBy('createdAt', 'desc'), where('type', 'in', ['login', 'logout']), limit(50))
            let data = await getDocs(queryRef)

            let getLogs = await Promise.all(
                data.docs.map(async (logSnap) => {
                    const userData = await getDoc(doc(db, 'users', logSnap.data().userId))
                    return {
                        ...logSnap.data(),
                        id: logSnap.id,
                        user: { ...userData.data(), userId: userData.id }
                    }
                }))
            setAllLogs(getLogs)
            // console.log(getLogs)
            setLoading(false)
        } catch (error) {
            console.log(error.message)
            setLoading(false)
        }
    }
    const fetchErrorLogs = async () => {
        setLoading(true)
        try {
            const collRef = collection(db, 'logs')
            const queryRef = query(collRef, where('type', '==', 'error'), orderBy('createdAt', 'desc'), limit(50))
            // let data = await getDocs(queryRef)
            const unsubscribe = onSnapshot(queryRef, async dataSnap => {

                const logsIncludingUser = await Promise.all(
                    dataSnap.docs.map(async (logSnap) => {
                        let iconsStatus = (logSnap?.data()?.iconsStatus || {})
                        if (!!Object.keys(iconsStatus).length) {
                            await Promise.all(
                                Object.keys(iconsStatus).map(async (iconName) => {
                                    const user = iconsStatus[iconName]?.isMarked ? await getDoc(doc(db, 'users', iconsStatus[iconName]?.markedBy)) : null
                                    const iconData = {
                                        ...iconsStatus[iconName],
                                        markedAt: isValid(new Date(iconsStatus[iconName]?.markedAt)) ? iconsStatus[Object.keys(iconsStatus).find(icon => iconsStatus[icon]?.markedAt)]?.markedAt : iconsStatus[iconName]?.markedAt,
                                        markedByUser: user ? { ...user.data(), userId: user.id } : {}
                                    }
                                    Object.assign(iconsStatus, { [iconName]: iconData })
                                    // console.log(iconsStatus[Object.keys(iconsStatus).find(icon => iconsStatus[icon]?.markedAt)]?.markedAt)
                                })
                            )
                        }

                        return {
                            ...logSnap.data(),
                            iconsStatus,
                            id: logSnap.id,
                        }
                    })
                )
                setAllLogs(logsIncludingUser)
                // console.log(logsIncludingUser)
                setLoading(false)
            })
            return () => unsubscribe()
        } catch (error) {
            console.log(error.message)
            setLoading(false)
        }
    }
    const fetchReviewLogs = async () => {
        setLoading(true)
        try {
            const collRef = collection(db, 'logs')
            const queryRef = query(collRef, where('type', '==', 'log'), orderBy('createdAt', 'desc'), limit(50))
            let data = await getDocs(queryRef)
            let getLogs = await Promise.all(
                data.docs.map(async (logSnap) => {
                    // console.log(logSnap.data().campaignLinkId)
                    const docRef = doc(db, 'campaigns', logSnap.data().campaignLinkId || '')
                    const docSnap = await getDoc(docRef)
                    let campaignData = { ...docSnap.data(), id: docSnap.id }
                    // console.log(campaignData.userId)
                    const userDocRef = doc(db, 'users', campaignData.userId || '123')
                    const userDocSnap = await getDoc(userDocRef)
                    const userData = { ...userDocSnap.data() }

                    const customUrlRef = collection(db, 'campaigns', logSnap.data().campaignLinkId || '', 'vanity')
                    let customUrlData = await getDocs(customUrlRef)

                    if (!customUrlData.empty) {
                        let customUrlName = String(customUrlData.docs[0]?.data()?.vanityName || '')
                            .toLowerCase()
                            .trim()
                        campaignData = { ...campaignData, vanity: customUrlName }
                    }
                    // console.log({ userData, campaignData })
                    return {
                        ...logSnap.data(),
                        id: logSnap.id,
                        userData,
                        campaignData,
                    }
                }))
            setAllLogs(getLogs)
            // console.log(getLogs)
            setLoading(false)
        } catch (error) {
            console.log(error.message)
            setLoading(false)
        }
    }


    return (
        <Stack
            // mt={10}
            bg={'gray.50'}
            rounded={'xl'}
            py={{ base: 4, sm: 6, md: 8 }}
            px={{ base: 2, sm: 4, md: 8 }}
            width='full'
            spacing={8}
        >
            <HStack w='full' justifyContent='space-between' alignItems='flex-start'>
                <VStack alignItems='flex-start' spacing={3}>
                    <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
                        Logs
                    </Heading>
                    <Text fontSize={['lg']}>
                        Below are the latest 50 Logs
                    </Text>
                </VStack>
                <HStack>
                    <Button size='sm' onClick={fetchLoggingLogs} variant='solid' disabled={loading}>
                        Fetch Logs
                    </Button>
                    <Button size='sm' onClick={fetchErrorLogs} variant='solid' disabled={loading}>
                        Fetch Errors
                    </Button>
                    <Button size='sm' onClick={fetchReviewLogs} variant='solid' disabled={loading}>
                        Fetch Review Logs
                    </Button>
                </HStack>
            </HStack>
            <VStack w='full' py={4} minH='200px'>
                {loading ? (
                    <VStack
                        justifyContent='center'
                        alignItems='center'
                        width='full'
                    >
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='teal.500'
                            size={['md', 'xl']}
                        />
                    </VStack>
                ) : !allLogs?.length ?
                    <Text fontSize={'xl'} fontStyle='italic' mt={5} color='gray.500' fontWeight='bold'>
                        Logs Not Fetched yet
                    </Text>
                    : (
                        <SimpleGrid
                            spacing={['4']}
                            // columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
                            // mx={'auto'}
                            maxW='1450px'
                            minChildWidth={{ base: 'full', sm: '180px', md: '200px' }}
                            justifyItems='stretch'
                            w='full'
                        >
                            {allLogs.map((log) => (
                                allLogs[0]?.type === 'log' ? <SimpleLogCard log={log} key={log.id} /> :
                                    allLogs[0]?.type === 'error' ?
                                        <ErrorCard user={user} key={log.id} log={log} /> : (
                                            allLogs[0]?.type.includes('log') ?
                                                <LoggingCard key={log.id} log={log} />
                                                : null
                                        )
                            ))}

                        </SimpleGrid>
                    )}
            </VStack >
        </Stack >
    )
}

export default Logs