import { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    useDisclosure,
    Button,
    Flex,
    Tooltip,
    Text,
    Stack,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, LinkIcon } from '@chakra-ui/icons'
import { ImQrcode, ImClipboard, ImDownload } from 'react-icons/im'
import { useBreakpointValue } from '@chakra-ui/react'

import QRCode, { QRCodeCanvas } from 'qrcode.react'
import { getBlobFromImageElement, copyBlobToClipboard } from 'copy-image-clipboard'
import { useCustomToast } from '../../../hooks/customToast'

export const MobileQModal = ({ data }) => {
    const isMobile = useBreakpointValue({
        base: true, sm: true, md: false
    })
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { addToast } = useCustomToast()
    const [reviewLinkCopied, setReviewLinkCopied] = useState(false)
    const name = 'qr'.replace(/ /g, '')
    // download QR code
    const downloadQRCode = () => {
        const qrCodeURL = document
            .getElementById('qrCodeEl')
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
        //console.log(qrCodeURL)
        let aEl = document.createElement('a')
        aEl.href = qrCodeURL
        aEl.download = `${name}QRcode.png`
        document.body.appendChild(aEl)
        aEl.click()
        document.body.removeChild(aEl)
    }

    const copyImage = async () => {
        const imageElement = document.getElementById('qrCodeEl')
        await getBlobFromImageElement(imageElement)
            .then((blob) => {
                copyBlobToClipboard(blob)
            })
            .then(() => {
                addToast({
                    title: 'QR Code',
                    description: 'copied to clipboard!',
                    status: 'success',
                    variant: 'left-accent',
                })
            })
            .catch((e) => {
                console.log('Error: ', e.message)
            })
    }

    const toast = (value) => {
        addToast({
            title: `${value}`,
            description: 'to the clipboard.',
            status: 'success',
            variant: 'left-accent',
        })
    }

    return (
        <>
            <Tooltip
                color='white'
                bg='teal.400'
                px={2}
                rounded='md'
                hasArrow
                placement='bottom'
                label={'Quick copy for all campaign links'}
            >
                {/* <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    onClick={onOpen}
                    icon={<ImQrcode />}
                    size={'sm'}
                /> */}
                <Button w={{ md: '15rem', sm: 'full' }} onClick={onOpen}>
                    Campaign Links Quick Copy
                </Button>
            </Tooltip>

            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize='3xl' color='gray.700' textAlign={'center'}>
                        Campaign Links Quick Copy
                    </ModalHeader>
                    <ModalCloseButton />
                    {data?.map((code, i) => (
                        <Flex key={code?.id} p='2' mx='auto' alignSelf={'center'} direction={{ sm: 'column', base: 'column', md: 'row' }} justify={'space-between'} alignItems={'center'}>
                            <>
                                <Stack >
                                    <Text p='4' fontSize={'2xl'}>
                                        {code.campaignDetails.publicCampaignName}
                                    </Text>
                                    <Flex p='4' justify={'flex-end'}>
                                        <Button
                                            variant={'outline'}
                                            leftIcon={<ImClipboard />}
                                            mr='2'
                                            size={'sm'}
                                            onClick={copyImage}
                                        >
                                            Copy QR
                                        </Button>
                                        <Button
                                            variant={'outline'}
                                            size={'sm'}
                                            leftIcon={<ImDownload />}
                                            onClick={downloadQRCode}
                                        >
                                            Download QR
                                        </Button>
                                    </Flex>
                                    <Flex justify='center' align={'center'}>
                                        <QRCodeCanvas
                                            id='qrCodeEl'
                                            size={170}
                                            bgColor={'#ffffff'}
                                            fgColor={'#03989E'}
                                            level={'H'}
                                            includeMargin={true}
                                            crossOrigin='anonymous'
                                            imageSettings={{
                                                src: '/images/airplane.png',
                                                x: undefined,
                                                y: undefined,
                                                height: 40,
                                                width: 40,
                                                excavate: true,
                                            }}
                                            value={`${window.location.origin}/review/${code?.id}`}
                                        />
                                    </Flex>
                                    <Tooltip
                                        color='white'
                                        bg='teal.400'
                                        px={2}
                                        rounded='md'
                                        hasArrow
                                        placement='bottom'
                                        label={reviewLinkCopied ? 'Copied' : 'Click to Copy'}
                                    >
                                        <Button
                                            mr='2'
                                            w='90%'
                                            alignSelf={'center'}
                                            variant={'outline'}
                                            leftIcon={<LinkIcon />}
                                            size={'sm'}
                                            onClick={(_) => {
                                                navigator.clipboard.writeText(
                                                    `${window.location.origin}/review/${code?.id}`
                                                )
                                                setReviewLinkCopied(true)
                                                setTimeout(() => {
                                                    setReviewLinkCopied(false)
                                                }, 2000)
                                                toast('Request Review link copied to clipboard')
                                            }}
                                        >
                                            Copy Request Review URL
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        color='white'
                                        bg='teal.400'
                                        px={2}
                                        rounded='md'
                                        hasArrow
                                        placement='bottom'
                                        label={reviewLinkCopied ? 'Copied' : 'Click to Copy'}
                                    >
                                        <Button
                                            mr='2'
                                            w='90%'
                                            alignSelf={'center'}
                                            variant={'outline'}
                                            leftIcon={<LinkIcon />}
                                            size={'sm'}
                                            onClick={(_) => {
                                                navigator.clipboard.writeText(`${window.location.origin}/wall/${code?.customUrlName}`)
                                                setReviewLinkCopied(true)
                                                setTimeout(() => {
                                                    setReviewLinkCopied(false)
                                                }, 2000)
                                                toast('Review Wall link copied to clipboard')
                                            }}
                                        >
                                            Copy Review Wall URL
                                        </Button>
                                    </Tooltip>

                                </Stack>



                            </>
                        </Flex>
                    ))}

                    {/* <ModalFooter w='100%'> */}

                    {/* </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}
