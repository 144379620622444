// import { Button, Container, Text } from '@chakra-ui/react'
import React from 'react'
// import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { userState } from '../../recoil/atoms/auth'
import { AffiliateProgram } from './components/Affiliate'
import { Seo } from '../../components/Seo'

function Affiliate() {
  const [user, _] = useRecoilState(userState)
  return (
    <>
      <Seo
        title='Sendmea Real Video Reviews | Affilate Program'
        description='Sendmea pays a referral commision of $30 monthly recurring for the life of the referral! Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand. Video reviews are the most trusted and valuable of all reviews, and build consumer trust and confidence.'
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='affiliate, referral, referral income, video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />

      <AffiliateProgram user={user} />
    </>
  )
}

export default Affiliate
