import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Text,
  chakra,
  Button,
  Box,
  Image,
  SimpleGrid,
  Hide,
  VStack,
  Flex,
  Show,
  useBreakpointValue,
  Stack,
} from '@chakra-ui/react'
import React, { startTransition, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getPlans } from '../../../firebase/stripeConfig'
import { discoutConstant, readCookie } from '../../../utils'

// const openButton = "Detailed Features"
// const closeButton = "Close"

function Pricing() {

  const priceColor = useBreakpointValue({
    base: '#7ED957',
    sm: '#7ED957',
    md: 'white',
  })

  const [plans, setPlans] = useState(null)
  // const [strpeLoading, setStripeLoading] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const discount = useMemo(() => !!readCookie('referral'), [readCookie('referral')])
  console.log({ discount })
  useEffect(() => {
    ; (async () => {
      const plans = await getPlans()
      startTransition(() => {
        const filterSendmeaPlan = plans?.filter(plan => (plan.name?.toLowerCase() === "sendmea premium" || plan.role === "premium" || plan.metadata?.firebaseRole === "premium"))
        setPlans(filterSendmeaPlan)
      })
    })()
  }, [])

  return (
    <Box w={'inherit'} py={['8']}
      // id={'pricing'}
      maxW={['1250px']}
      mx='auto'
      bg={'rgba(180, 224, 226, 0.1)'} 
    >

      <Flex direction={{ base: 'column-reverse', md: 'row' }} alignItems={['stretch', 'center', 'center']} gap={[10, 10, 8]}
        sx={{
          "& > div": { flex: 1 }
        }}>
        <VStack
          justify={'space-between'}
          gap={[4, 8, 6]}
          spacing={0}
          mb={4}
          // w={{ base: 'full' }}
          mx='auto'
          px={[6, 8]}
        >

          <Hide below='md'>
            <Text fontSize={['1.85rem', '2rem']} fontWeight={'700'} textAlign={'center'}>
              <chakra.span color={'brand.1'}>Affordable Pricing</chakra.span>
         
            </Text>
            <Text fontSize={['1.85rem', '2rem']} fontWeight={'700'} mb={['6', '8']} textAlign={'center'}>
        
              <chakra.span color={'brand.2'} >
                For Every Business
              </chakra.span>
            </Text>
          </Hide>
          {/* <chakra.span color={'brand.2'}>Plan</chakra.span> */}
          <Text fontSize={['0.85rem', '1.2rem']} color={'gray.500'}>
            As a business owner, you know how important it is to build a strong online reputation.
            But with so many review sites and social media platforms out there, managing your online
            presence can be overwhelming.
          </Text>
          <Text fontSize={['0.85rem', '1.2rem']} color={'gray.500'}>
            That's where Sendmea comes in. With its easy-to-use video review app, you can collect
            authentic customer feedback and increase your online visibility.
          </Text>
          <Text fontSize={['0.85rem', '1.2rem']} color={'gray.500'}>
            Say goodbye to fake reviews and hello to a powerful tool that helps you stand out from
            the competition.
          </Text>
          <Text alignSelf='flex-start' fontStyle={'italic'} fontSize={['.9rem', '1.4rem']} color={'brand.1'}>
            **Trust us, your customers will thank you.**
          </Text>

          {/* 
          <Show below='md'>
            <chakra.div my={'10'} textAlign={'center'}>
              <Button w={'36'} as={Link} to={'/register'} variant={'solid'}>
                Sign up <ArrowForwardIcon w={'10'} />
              </Button>
            </chakra.div>
          </Show> 
          */}

          <chakra.div alignSelf='flex-start' my={'10'} textAlign={'center'}>
            <Button w={'36'} mt='2' as={Link} to={'/register'} variant={'solid'} fontStyle={'normal'}>
              Sign up <ArrowForwardIcon w={'10'} />
            </Button>
          </chakra.div>
        </VStack>
        <Box px={[6, 8]}>
          {/* <Box mx={'auto'} maxW={'400px'} minH={'sm'} bgImage={"url('/images/pricing.png')"} bgSize={'100%'} bgRepeat={'no-repeat'}> */}
          <Show below='md'>
            <Text fontSize={['1.85rem', '2rem']} fontWeight={'700'} mb={['6', '8']} textAlign={'center'}>
              <chakra.span color={'brand.1'}>Pric</chakra.span>
              <chakra.span color={'brand.2'} >
                ing
              </chakra.span>
            </Text>
          </Show>
          <Box
            bg={'brand.1'}
            maxW={'600px'}
            minH={'sm'}
            position={'relative'}
            borderRadius={'20px'}
            mx={'auto'}
          >
            <chakra.span
              sx={{
                position: 'absolute',
                minWidth: '1099px',
                borderRadius: '50%',
                height: '840px',
                top: ['50px', '30px'],
                left: '-100px',
                backgroundColor: '#ffffff12',
                zIndex: 0,
              }}
            ></chakra.span>
            <Hide below='md'>
              <Image
                src={'/images/pricing.png'}
                alt=''
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  mx: 'auto',
                  width: ['100%', discount ? '85%' : '100%'],
                }}
              />
            </Hide>
            <Text
              color={'white'}
              pt={['6', '8', '6']}
              display={'flex'}
              alignItems={'baseline'}
              justifyContent='center'
              position={'relative'}
              top={'-3'}
              zIndex={'1'}
            >
              <chakra.span fontSize={['6xl', '7xl', '6xl']} fontWeight={'800'}
                sx={{ color: priceColor }}>
                $
                {plans?.[0]?.prices?.[0]?.unit_amount
                  ? (
                    (plans?.[0]?.prices?.[0]?.unit_amount / 100) -
                    (discount ? discoutConstant : 0)
                  ).toFixed(0)
                  : '...'}
              </chakra.span>
              <chakra.span fontSize={['xl', '2xl', 'lg']}>/Month</chakra.span>
            </Text>

            <Box>
              <Box
                display={'flex'}
                alignItems={'baseline'}
                justifyContent='center'
                position={'relative'}
                zIndex={'1'}
              >
                <Box
                  fontSize={discount ? ['lg', 'md'] : ['xl', 'lg']}
                  fontWeight={'500'}
                  color={'white'}
                  position={'relative'}
                  top={'-6'}
                  sx={{ maxWidth: ['13rem', '16rem'], textAlign: 'center' }}
                >
                  {discount
                    ? `You are referred and receiving ${readCookie('referral').toLowerCase() === 'tom' ? '35+%' : '$50'
                    } discount.`
                    : null}
                </Box>
              </Box>
            </Box>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 2 }} spacing={'2'} mt={['110px', '32']} mb={['6', '16']}>
              {[
                'Easy video collection',
                'Unlimited Video Reviews',
                '3 steps to receive reviews',
                'Fully customizable',
                'Increased social proof',
                'Unlimited visitors/month',
                'Original Quality',
                'Better online reputation',
                'Improved SEO rankings',
                'Stronger brand identity',
                'Competitive advantage',
                'Positive business impact',
                'Enhanced customer loyalty',
                'Greater customer reach',
              ].map((v) => (
                <Box key={v} display={'flex'} px={'4'} alignItems={'center'}>
                  <Image src='/images/icons/tick.png' alt='' w={'4'} h={'4'} />
                  <Text color={'white'} pl={'2'} fontSize={['lg', 'xl', 'md']}>
                    {v}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
            <Stack p={['4', '6']}>
              {/* 
               <Button onClick={onOpen} bg={'brand.2'} variant={'solid'} m={'auto'} w={'full'}>
                Detailed Features
              </Button> 
              <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Detailed Features</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <UnorderedList>
                      {[
                        'Ad-Free Hosting',
                        'Unlimited Video Reviews',
                        '3 steps to receive reviews',
                        'Customization your app',
                        '5 minutes recording limit',
                        'Unlimited visitors/month',
                        'Original Quality',
                        '5 different campaigns',
                        'All features',
                        '24/7 customer support',
                      ].map((item) => (
                        <Box key={item} display={'flex'} px={'4'} alignItems={'center'}>
                          <ListItem>
                            {' '}
                            <Text fontSize={'s'}>{item}</Text>
                          </ListItem>
                        </Box>
                      ))}
                    </UnorderedList>
                  </ModalBody>

                  <ModalFooter>
                    <Button bg={'brand.2'} variant={'solid'} m={'auto'} w={'40%'} onClick={onClose}>
                      Close
                    </Button>
                    <Button w={'50%'} to={'/'} variant='ghost'>
                      How it Works
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            */}
            </Stack>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default Pricing
