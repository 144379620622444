import { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    useDisclosure,
    Button,
    Flex,
    Tooltip
} from '@chakra-ui/react'

import { ImQrcode, ImClipboard, ImDownload } from 'react-icons/im'
import QRCode, { QRCodeCanvas } from 'qrcode.react';
import {
    getBlobFromImageElement,
    copyBlobToClipboard,
} from 'copy-image-clipboard'
import { useCustomToast } from '../hooks/customToast';

export const QrCodeModal = ({ pageUrl, title }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { addToast } = useCustomToast()
    const name = title?.replace(/ /g, '')
    // download QR code
    const downloadQRCode = () => {
        const qrCodeURL = document.getElementById('qrCodeEl')
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        //console.log(qrCodeURL)
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = `${name}QRcode.png`;
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }


    const copyImage = async () => {
        const imageElement = document.getElementById('qrCodeEl')
        await getBlobFromImageElement(imageElement)
            .then((blob) => {
                copyBlobToClipboard(blob)
            })
            .then(() => {
                addToast({
                    title: 'QR Code',
                    description: 'copied to clipboard!',
                    status: 'success',
                    variant: 'left-accent',
                })
            })
            .catch((e) => {
                console.log('Error: ', e.message)
            })
    }



    return (
        <>
            <Tooltip
                color='white'
                bg='teal.400'
                px={2}
                rounded='md'
                hasArrow
                placement='bottom'
                label={'QR Code'}>
                <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    onClick={onOpen}
                    icon={<ImQrcode />}
                    size={['xs', 'sm']}
                />
            </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize='3xl' color='gray.700' textAlign={'center'}>{title} QR Code</ModalHeader>
                    <ModalCloseButton />
                    <Flex w='full' justify={'center'} alignItems={'center'}>

                        <QRCodeCanvas
                            id="qrCodeEl"
                            size={230}
                            bgColor={"#ffffff"}
                            fgColor={"#03989E"}
                            level={"H"}
                            includeMargin={true}
                            crossOrigin="anonymous"
                            imageSettings={{
                                src: "/images/airplane.png",
                                x: undefined,
                                y: undefined,
                                height: 40,
                                width: 40,
                                excavate: true
                            }}
                            value={pageUrl}
                        />

                    </Flex>


                    {/* <ModalFooter w='100%'> */}
                    <Flex p='4' justify={'flex-end'}>
                        <Button
                            variant={'outline'}
                            leftIcon={<ImClipboard />}
                            mr='4'
                            onClick={copyImage}

                        >
                            Copy
                        </Button>
                        <Button

                            variant={'outline'}
                            leftIcon={<ImDownload />}

                            onClick={downloadQRCode}

                        >
                            Download
                        </Button>



                    </Flex>

                    {/* </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}