import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { FaGreaterThan, FaLessThan } from "react-icons/fa"

const Pagination = ({
    currentPage,
    totalCards,
    cardsPerPage,
    pageNeighbours,
    onPaginationChange,
    dataArr,
    makeNumbersBtnDisabled,
    sx,
}) => {

    const initialPaginationState = {
        currentPage,
        totalPages: 0,
        cardsPerPage,
        totalCards,
        pageNeighbours,
        position: ""
    };
    /* pageNeighbours is actually "..." in between buttons   */
    const [paginationState, setPaginationState] = useState(initialPaginationState)
    // const [position, setPosition] = useState("")

    const LEFT_PAGE = "LEFT";
    const RIGHT_PAGE = "RIGHT";

    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    };

    useEffect(() => {
        (async _ => {
            let getCardsPerPage = typeof cardsPerPage === "number" ? cardsPerPage : 6;
            let getTotalCards = typeof totalCards === "number" ? totalCards : 0;

            let getPageNeighbours =
                typeof pageNeighbours === "number"
                    ? Math.max(0, Math.min(pageNeighbours, 2))
                    : 0;

            let getTotalPages = Math.ceil(getTotalCards / getCardsPerPage);

            let paginationData = {
                ...paginationState,
                cardsPerPage: getCardsPerPage,
                totalPages: getTotalPages,
                pageNeighbours: getPageNeighbours,
                totalCards: getTotalCards,
            }
            onPaginationChange(paginationData, true)
            setPaginationState(paginationData)
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCards, dataArr])

    const gotoPage = (page) => {
        const getCurrentPage = Math.max(0, Math.min(page, paginationState.totalPages));

        const paginationData = {
            ...paginationState,
            currentPage: getCurrentPage,
            position: getCurrentPage > paginationState.currentPage ? "next" : "prev"
        };
        setPaginationState(paginationData)
        onPaginationChange(paginationData);
    };

    const handleClick = (page) => {
        gotoPage(page);
    };

    const handleMoveLeft = () => {
        paginationState.currentPage > 1 && gotoPage(paginationState.currentPage - 1);
    };

    const handleMoveRight = () => {
        paginationState.currentPage < paginationState.totalPages && gotoPage(paginationState.currentPage + 1);
    };

    const fetchPageNumbers = () => {
        const getTotalPages = paginationState.totalPages;
        const getCurrentPage = paginationState.currentPage;
        const getPageNeighbours = paginationState.pageNeighbours;

        const totalNumbers = getPageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (getTotalPages > totalBlocks) {
            let pages = [];

            const leftBound = getCurrentPage - getPageNeighbours;
            const rightBound = getCurrentPage + getPageNeighbours;
            const beforeLastPage = getTotalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, getTotalPages];
        }

        return range(1, getTotalPages);
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pages = useMemo(() => fetchPageNumbers(), [paginationState]);

    if (!paginationState.totalCards) return null;

    return (
        <Box display={totalCards > cardsPerPage ? "block" : "none"}>
            <Flex gap={["7px", "10px"]} width={["full", "full", "500px"]} align="stretch" justify={["center", "center", "flex-start"]}
                ml={[0, 1]}
                sx={{
                    "& > *": {
                        userSelect: "none",
                        alignItems: "center",
                        fontSize: "12px",
                    },
                    ...sx
                }}
            >
                <Flex fontWeight="500" justify="center" align="center" cursor="pointer" px={[3, 5]} py={[1, 2]} borderRadius="4px" bg="#F3F3F3" textAlign="center" color="gray.500"
                    onClick={handleMoveLeft}
                    sx={{
                        ...(paginationState.currentPage === 1 && {
                            color: "gray.400",
                            cursor: "auto"
                        })
                    }}
                >
                    <FaLessThan fontSize="12px" fontWeight="500" />
                </Flex>
                {pages.map((page, index) => {
                    if (page === LEFT_PAGE)
                        return (
                            <Flex key={index} cursor="pointer" px={[2, 3]} py={[1, 2]} borderRadius="4px" bg="#F3F3F3"
                                transition="all 0.4s ease"
                                onClick={handleMoveLeft}
                            >
                                ...
                            </Flex>
                        );

                    if (page === RIGHT_PAGE)
                        return (
                            <Flex key={index} cursor="pointer" px={[2, 3]} py={[1, 2]} borderRadius="4px" bg="#F3F3F3"
                                transition="all 0.4s ease"
                                onClick={handleMoveRight}
                            >
                                ...
                            </Flex>
                        );

                    return (
                        <Flex fontWeight="500" key={index}
                            px={[2, 3]} py={[1, 2]} borderRadius="4px" bg="#F3F3F3"
                            transition="all 0.4s ease"
                            sx={{
                                ...(paginationState.currentPage === page && {
                                    bg: theme => theme.colors.brand["1"],
                                    color: "white",
                                    cursor: "auto"
                                })
                            }}
                            {...(!makeNumbersBtnDisabled && {
                                cursor: "pointer",
                                onClick: () => {
                                    paginationState.currentPage !== page && handleClick(page)
                                }
                            })}
                        >
                            {page}
                        </Flex>
                    );
                })}
                <Flex fontWeight="500" justify="center" align="center" cursor="pointer" px={[3, 5]} py={[1, 2]} borderRadius="4px" bg="#F3F3F3" textAlign="center" color="gray.500"
                    onClick={handleMoveRight}
                    sx={{
                        ...(paginationState.currentPage === paginationState.totalPages && {
                            color: "gray.400",
                            cursor: "auto"
                        })
                    }}
                >
                    <FaGreaterThan fontSize="12px" fontWeight="500" />
                </Flex>
            </Flex>
        </Box>
    )
}

export default Pagination