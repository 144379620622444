import React, { useEffect, useMemo, useState } from "react"
import {
    Box,
    Text,
    Button,
    useDisclosure,
    ModalOverlay,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    Image,
    AspectRatio,
    IconButton,
    Stack,
    useBreakpointValue,
    Heading,
    SkeletonText,
    HStack,
    chakra,
    Tooltip,
    Avatar,
    SimpleGrid
} from '@chakra-ui/react'
import { themeObj } from "../../TestimonialDetails/TestimonialDetails"
import Skeleton from "react-loading-skeleton"
import Rating from "../../../components/Rating"
import { BsGlobe2, BsCameraVideoFill } from 'react-icons/bs'
import { getNameInitials } from "../../../utils"
import { MdOutlineEmail } from "react-icons/md"
import { CampNav } from "../../TestimonialDetails/components/CampaignNav"
import LivelyTheme from "../../TestimonialDetails/components/CampaignBanner/LivelyTheme"
import ForestDarkTheme from "../../TestimonialDetails/components/CampaignBanner/ForestDarkTheme"
import MedicalTheme from "../../TestimonialDetails/components/CampaignBanner/MedicalTheme"
import { collection, collectionGroup, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore"
import { db } from "../../../firebase/config"
import TestimonialVideoCard from "../../../components/TestimonialVideoCard/TestimonialVideoCard"


export const WallPreviewModal = ({ values }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [dataLoading, setDataLoading] = useState(true)
    const [campaignData, setCampaignData] = useState(null)

    const noOfCards = useBreakpointValue({
        base: 2,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4
    })

    const wallTheme = useMemo(() => values?.wallTheme ? themeObj[values?.wallTheme] : themeObj['default'], [values])

    let detachSnap = () => null

    const getApprovedTestimonials = async () => {
        try {
            const campaignId = 'sendmea'
            const vanityCollRefQuery = query(
                collectionGroup(db, 'vanity'),
                where('vanityName', '==', String(campaignId).toLowerCase().trim())
            )
            const vanityData = await getDocs(vanityCollRefQuery)
            let getCampaignId = !vanityData.empty
                ? vanityData?.docs[0]?.ref?.parent?.parent?.id
                : campaignId

            const campaignRef = doc(db, 'campaigns', getCampaignId)
            // detachSnap = onSnapshot(campaignRef, async (getCampaign) => {
            const getCampaign = await getDoc(campaignRef)

            const queryTestimonial = query(
                collection(db, 'reviews'),
                where('campaignId', '==', getCampaignId),
                where('approve', '==', true),
                orderBy('date', 'desc'),
                limit(1)
            )

            let avgRating = getCampaign.data()?.avgRating || 0

            let approvedReviews = await getDocs(queryTestimonial)
            approvedReviews = approvedReviews.docs.map((doc) => {
                return { ...doc.data(), id: doc.id }
            })

            let obj = {
                reviews: [...approvedReviews, ...approvedReviews, ...approvedReviews, ...approvedReviews],
                ...getCampaign.data(),
                campaignId: getCampaignId,
                avgRating,
                totalTestimonials: 1,
            }
            setCampaignData(obj)
            setDataLoading(false)
        } catch (error) {
            setDataLoading(false)
            console.log(error.message)
        }
    }

    useEffect(() => {
        getApprovedTestimonials()
        return () => {
            detachSnap()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Box sx={{
            '& .chakra-modal__content-container': {
                height: 'auto !important'
            }
        }}>
            <Box>
                <Button
                    mt={[2, 2, 1]}
                    minWidth={'140px'}
                    size={['xs', 'sm']}
                    variant='outline'
                    display='flex'
                    onClick={() => {
                        onOpen()
                        // console.log(values)
                    }}
                >
                    See Preview
                </Button>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(5deg)'
                />
                <ModalContent maxW={['100%', '1000px', '1400px']} mx={['2', '4']} >
                    <ModalHeader color='brand.1'>Wall Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody bg={wallTheme['background']} py={['6']}>
                        {wallTheme && <CampNav wallTheme={wallTheme} />}
                        {!dataLoading ?
                            (campaignData ?
                                <>
                                    {values?.wallTheme === '' &&
                                        <LivelyTheme
                                            avgRating={5}
                                            campaignData={{
                                                ...campaignData,
                                                campaignDetails: values
                                            }}
                                            loading={dataLoading}
                                            disableIcon
                                        />}
                                    {values?.wallTheme === 'forest_dark' &&
                                        <ForestDarkTheme
                                            avgRating={5}
                                            campaignData={{
                                                ...campaignData,
                                                campaignDetails: values
                                            }}
                                            loading={dataLoading}
                                            wallTheme={wallTheme}
                                            disableIcon
                                        />}
                                    {values?.wallTheme === 'medical' &&
                                        <MedicalTheme
                                            avgRating={5}
                                            campaignData={{
                                                ...campaignData,
                                                campaignDetails: values
                                            }}
                                            loading={dataLoading}
                                            wallTheme={wallTheme}
                                            disableIcon
                                        />}

                                    <SimpleGrid
                                        width='100%'
                                        // maxW='1300px'
                                        spacing={'20px'}
                                        id='testimonials'
                                        pt={10}
                                        my={5}
                                        columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }}
                                        justifyItems={['stretch', 'stretch', 'stretch']}
                                        maxWidth={['fit-content', 'fit-content', 'full']}
                                        mx={['auto', 'auto', 0]}
                                    >
                                        {campaignData?.reviews.slice(0, noOfCards).map((cardInfo, idx) => (
                                            <TestimonialVideoCard
                                                key={cardInfo.id + idx}
                                                aspectRatio={5 / 4.5}
                                                showReviewInfo={true}
                                                hideThumbnailInfo={true}
                                                cardInfo={cardInfo}
                                                showShadow={!values?.wallTheme}
                                                sx={{
                                                    p: [4, 5],
                                                    maxW: ['400px', '435px'],
                                                    minW: ['330px', '435px', 'auto'],
                                                    // width: "100%",
                                                }}
                                            />
                                        ))}
                                    </SimpleGrid>
                                </>
                                : null
                            ) : null
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}