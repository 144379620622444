import { defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { StepsStyleConfig, } from 'chakra-ui-steps';

const CustomSteps = {
    ...StepsStyleConfig,
    // stepContainer: {
    //     flexDirection: 'column!important'
    // },
    sizes: {
        ...StepsStyleConfig.sizes,
        lg: {
            ...StepsStyleConfig.sizes.lg,
            description: {
                ...StepsStyleConfig.sizes.lg.description,
                textAlign: 'left',
            }
        },
    }
};


const Button = defineStyleConfig({
    // The styles all button have in common
    baseStyle: {
        // fontWeight: 'bold',
        // textTransform: 'uppercase',
        borderRadius: '5px', // <-- border radius is same for all variants and sizes
    },
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: 'sm',
            px: 4, // <-- px is short for paddingLeft and paddingRight
            py: 3, // <-- py is short for paddingTop and paddingBottom
        },
        md: {
            fontSize: 'md',
            px: 6, // <-- these values are tokens from the design system
            py: 4, // <-- these values are tokens from the design system
        },
    },
    // Two variants: outline and solid
    variants: {
        outline: {
            border: '1px solid',
            borderColor: 'brand.1',
            color: 'brand.1',
        },
        solid: {
            bg: 'brand.1',
            color: 'white',
            _hover: {
                backgroundColor: "brand.10"
            }
        },
    },
    // The default size and variant values
    defaultProps: {
        size: 'md',
        fontSize: 'md',
        fontWeight: 400,
        color: 'white',
        variant: 'outline',
    },
})

const Tooltip = defineStyleConfig({
    baseStyle: {
        placement: "bottom",
        color: "white",
        bg: "teal.400",
        px: [1, 2],
        rounded: "md",
    },
})

export const colors = {
    brand: {
        1: '#03989E',
        2: '#7ED957',
        3: '#999999',
        4: '#D9D9D9',
        10: "#03989ede"
    },
}

const theme = extendTheme({
    colors,
    components: {
        Button,
        Tooltip,
        Steps: CustomSteps,
    }
})

export default theme;