import { Flex, Heading, SimpleGrid, Spinner, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import TestimonialCard from '../../components/TestimonialCard'
import Pagination from '../../components/Pagination'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { Seo } from '../../components/Seo'

const dataArr = [
  {
    title: 'Joe McCall',
    buisnessDesc: 'Real Estate Investing Training & Education',
    reviews: 5.0,
    noOfReviews: 523,
    image: require('../../assets/images/testimonial1.png'),
  },
  {
    title: 'Real Estate',
    buisnessDesc: 'Real Estate',
    reviews: 5.0,
    noOfReviews: 963,
    image: require('../../assets/images/testimonial2.png'),
  },
  {
    title: 'Inside Music',
    buisnessDesc: 'Music',
    reviews: 5.0,
    noOfReviews: 700,
    image: require('../../assets/images/testimonial3.png'),
  },
  {
    title: 'Joe McCall',
    buisnessDesc: 'Real Estate Investing Training & Education',
    reviews: 5.0,
    noOfReviews: 523,
    image: require('../../assets/images/testimonial1.png'),
  },
  {
    title: 'Real Estate',
    buisnessDesc: 'Real Estate',
    reviews: 5.0,
    noOfReviews: 963,
    image: require('../../assets/images/testimonial2.png'),
  },
  {
    title: 'Inside Music',
    buisnessDesc: 'Music',
    reviews: 5.0,
    noOfReviews: 700,
    image: require('../../assets/images/testimonial3.png'),
  },
  {
    title: 'Joe McCall',
    buisnessDesc: 'Real Estate Investing Training & Education',
    reviews: 5.0,
    noOfReviews: 523,
    image: require('../../assets/images/testimonial1.png'),
  },
  {
    title: 'Real Estate',
    buisnessDesc: 'Real Estate',
    reviews: 5.0,
    noOfReviews: 963,
    image: require('../../assets/images/testimonial2.png'),
  },
  {
    title: 'Inside Music',
    buisnessDesc: 'Music',
    reviews: 5.0,
    noOfReviews: 700,
    image: require('../../assets/images/testimonial3.png'),
  },
].map((obj, idx) => ({ ...obj, id: idx + 1 }))

const TestimonialsNew = () => {
  const [paginatedData, setPaginatedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const [allCampaigns, setAllCampaigns] = useState([])
  const [dataLoading, setDataLoading] = useState(true)

  const getRandomCampaigns = async () => {
    setDataLoading(true)
    try {
      const collRef = query(
        collection(db, 'campaigns'),
        where('active', '==', true),
        orderBy('campaignDetails.publicCampaignName', 'asc')
      )
      let campaigns = await getDocs(collRef)
      // console.log({ totalCount, random3No, campaigns: campaigns.docs.length })
      campaigns = await Promise.all(
        campaigns.docs.map(async (campaignSnap) => {
          const queryTestimonial = query(
            collection(db, 'reviews'),
            where('campaignId', '==', campaignSnap.id),
            where('approve', '==', true)
          )
          const getReviews = await getDocs(queryTestimonial)
          let avgRating = getReviews.docs
            .map((review) => review.data()?.rating)
            .filter((rating) => !!rating)
          avgRating =
            avgRating.reduce((sum, rating) => sum + Number(rating), 0) / avgRating.length || 0

          const customUrlRef = collection(db, 'campaigns', campaignSnap.id, 'vanity')
          let customUrlData = await getDocs(customUrlRef)

          let campaignData = {
            ...campaignSnap.data(),
            id: campaignSnap.id,
            avgRating: avgRating || 0,
            totalReviews: getReviews.size || 0,
          }

          if (!customUrlData.empty) {
            let customUrlName = String(customUrlData.docs[0]?.data()?.vanityName || '')
              .toLowerCase()
              .trim()
            campaignData = { ...campaignData, customUrlName }
          }
          return campaignData
        })
      )
      setAllCampaigns(campaigns || [])
      setDataLoading(false)
    } catch (error) {
      console.log(error.message)
      setDataLoading(false)
    }
  }

  useEffect(() => {
    getRandomCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPaginationChange = (data) => {
    const { currentPage, cardsPerPage } = data

    const offset = (currentPage - 1) * cardsPerPage
    const currentPageData = allCampaigns.slice(offset, offset + cardsPerPage)

    setCurrentPage(currentPage)
    setPaginatedData(currentPageData)
  }

  return (
    <>
      <Seo
        title='Sendmea Real Video Reviews | All Video Review Campaigns'
        description='Sendmea pays a referral commision of $30 monthly recurring for the life of the referral! Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand. Video reviews are the most trusted and valuable of all reviews, and build consumer trust and confidence.'
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='affiliate, referral, referral income, video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />
      <Flex px='3' mt={10} py={4} direction={'column'} width={'full'} gap={5} mb={20}>
        <Heading as='h1' size='lg' noOfLines={1} color='brand.1' textAlign='center'>
          All Video Review Campaigns
        </Heading>
        {dataLoading ? (
          <VStack justifyContent='center' alignItems='center' width='full' minH='300px'>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='teal.500'
              size={['md', 'xl']}
            />
          </VStack>
        ) : (
          paginatedData.length > 0 && (
            <SimpleGrid
              spacing={['10px', '20px', '30px']}
              columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
              mt={5}
              mx={'auto'}
              px={['2.5', '16']}
              className='campaigns'
            >
              {paginatedData.map((cardInfo) => (
                <TestimonialCard key={cardInfo.id} cardInfo={cardInfo} />
              ))}
            </SimpleGrid>
          )
        )}
        <Pagination
          currentPage={currentPage}
          cardsPerPage={8}
          totalCards={allCampaigns?.length || 0}
          onPaginationChange={onPaginationChange}
          sx={{
            justifyContent: 'center',
            width: 'full',
            mt: '4',
          }}
        />
      </Flex>
    </>
  )
}

export default TestimonialsNew
