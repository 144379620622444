
import {
    Box,
    // SimpleGrid,
    chakra,
    Text,
    VStack,
    List,
    // UnorderedList,
    ListItem,
    ListIcon,
    Flex,
    AspectRatio
} from '@chakra-ui/react'
import React from 'react'
import { MdCheckCircle } from 'react-icons/md'

function SalesVideoSection() {
    return (
        <chakra.div pt='8' bg={'rgba(180, 224, 226, 0.1)'} py={'12'}
        // id='getStarted'
        >
            <Text
                fontSize={['1.85rem', '2rem']}
                fontWeight={'600'}
                my={'4'}
                textAlign={'center'}
                pt={'6'}
                color={'brand.1'}
            >
                Powerful Features to
                <chakra.span pl='2' fontSize={['2.5rem']} color={'brand.2'}>Amplify </chakra.span>   Your Business
                {/* <chakra.span color={'brand.1'}>Video </chakra.span>
                <chakra.span color={'brand.2'}>Sales</chakra.span> */}
            </Text>

            <Flex direction={{ base: 'column', sm: 'column', md: 'row' }} py={8} px={['4', '5', '6']}
                alignItems={['center', 'center']}
                justifyContent={['center', 'center']}
                gap={[10, 10, 6, 10]}
            >

                <Box my={'auto'} w={'50%'}>

                    <AspectRatio ratio={1}>
                        <iframe src="https://www.youtube.com/embed/VPV0hxjS_LI?si=PRXgZ4syEYN2opwz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </AspectRatio>
                </Box>
                <VStack
                    justifyContent='space-between'
                    w={'30%'}
                >
                    <VStack
                        spacing={4}
                        sx={{
                            '& > p': {
                                color: 'gray.700',
                                fontSize: ['md', 'lg'],
                            },
                        }}
                    >
                        <List spacing={8}>
                            <ListItem fontSize={'1.2rem'}>
                                <ListIcon as={MdCheckCircle} color='brand.2' />
                                <Text color='brand.1' fontWeight={'bold'} as='span'> Enhancing Understanding: </Text>
                                Video reviews give potential customers a thorough understanding of a product or service, with <a color='#03989E' href='https://www.wyzowl.com/video-marketing-statistics/'><Text fontWeight={'bold'} color='brand.1' as='span'>91%</Text></a>  of people watching explainer videos to learn more about a product​ ​.
                            </ListItem>
                            <ListItem fontSize={'1.2rem'}>
                                <ListIcon color='brand.2' as={MdCheckCircle} /> <Text color='brand.1' fontWeight={'bold'} as='span'>
                                    Building Trust and Credibility: </Text>
                                Seeing and hearing from actual customers builds trust and credibility, with
                                <a color='#03989E' href='https://www.wyzowl.com/video-marketing-statistics/'><Text fontWeight={'bold'} color='brand.1' as='span'> 82%</Text></a>  of people convinced to buy a product after watching a video review​​.
                            </ListItem>
                            <ListItem fontSize={'1.2rem'}>
                                <ListIcon color='brand.2' as={MdCheckCircle} />
                                <Text color='brand.1' fontWeight={'bold'} as='span'>
                                    Engagement and Memorability: </Text> Videos are more engaging and memorable than text, with videos on landing pages boosting conversions by up to  <a color='#03989E' href='https://www.smallbizgenius.net/by-the-numbers/video-marketing-statistics/'><Text fontWeight={'bold'} color='brand.1' as='span'>  86% </Text></a>.
                            </ListItem>
                            <ListItem fontSize={'1.2rem'}>
                                <ListIcon as={MdCheckCircle} color='brand.2' />
                                <Text color='brand.1' fontWeight={'bold'} as='span'> Social Media Visibility and Reach: </Text> Videos are highly shareable, increasing visibility and reach.  <a color='#03989E' href='https://vidico.com/news/short-form-video-statistics/'><Text fontWeight={'bold'} color='brand.1' as='span'>  90% </Text></a>of consumers watch videos in their free time, making them ideal for social media engagement​​.

                            </ListItem>
                        </List>
                    </VStack>
                </VStack>
            </Flex>
            <chakra.div m={'6'} textAlign={'center'}>
                {/* <Button w={'36'} as={Link} to={'/register'} variant={'solid'} mb={'6'}>
                    Sign up <ArrowForwardIcon w={'10'} />
                </Button> */}
            </chakra.div>
        </chakra.div>
    )
}

export default SalesVideoSection
