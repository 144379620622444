import {
  Flex,
  Text,
  chakra,
  SimpleGrid,
  VStack,
  HStack,
  Image,
  Stack,
  Box,
  Button,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'

import { useRecoilState } from 'recoil'
import { userState } from '../../recoil/atoms/auth'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '../../components/Pagination'
import { useCollection } from '../../hooks/useCollection'
import VideoPopup from '../../components/VideoPopup'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
  orderBy,
} from 'firebase/firestore'
import { db } from '../../firebase/config'
import TestimonialVideoCard from '../../components/TestimonialVideoCard/TestimonialVideoCard'
import dashboardCheck from '../../assets/images/dashboardCheck.png'
import dashboardGlobal from '../../assets/images/dashboardGlobal.png'
import dashboardFile from '../../assets/images/dashboardFile.png'
import { BsPlusLg } from 'react-icons/bs'
import { Counter, discoutConstant, sendNotification, zacSmith } from '../../utils'
import { getCustomClaimRole } from '../../firebase/stripeConfig'
import { ExpressButton } from '../../components/ExpressButton'
import { QrCodeModal } from '../../components/QRcodeModal'
import { useCustomToast } from '../../hooks/customToast'
import { HomeQrCodeModal } from './components/HomeQRModal'

const scrollToElement = (id) => {
  const section = document.querySelector(id)
  section.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

const Home = () => {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userState)

  const [reviewDocs, setReviewDocs] = useState(null)

  const { documents: allReviews } = useCollection('reviews', ['userId', '==', user.uid])

  const { documents: campaignDocs } = useCollection('campaigns', ['userId', '==', user.uid])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [cardInfoState, setCardInfo] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [myCampaigns, setMyCampaigns] = useState(null)

  const { addToast } = useCustomToast()

  const getCampaigns = async () => {
    try {
      const collRef = collection(db, 'campaigns')
      // let campaignRef = query(collRef, where("userId", "==", user.uid), orderBy("createdAt", "desc"))
      let campaignRef = query(
        collRef,
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc')
        // orderBy('campaignDetails.internalCampaignName', 'asc')
      )

      let teamCampaignQuery = query(collRef, where('specialAccess', 'array-contains', user.email))

      const unsub1 = onSnapshot(teamCampaignQuery, async (specialSnap) => {
        const unsub = onSnapshot(campaignRef, async (campaignSnap) => {
          let combineDocs = specialSnap.empty
            ? campaignSnap.docs
            : campaignSnap.docs.concat(specialSnap.docs)

          let myCampaignsArr = await Promise.all(
            combineDocs.map(async (campaign) => {
              const subCollectionRef = collection(db, 'campaigns', campaign.id, 'vanity')
              const subCollSnapshot = await getDocs(subCollectionRef)

              let vanity = {}
              if (!subCollSnapshot.empty) {
                let vanityName = String(subCollSnapshot.docs[0]?.data()?.vanityName || '')
                  .toLowerCase()
                  .trim()
                let updatedAt = subCollSnapshot.docs[0]?.data()?.updatedAt
                let id = subCollSnapshot.docs[0]?.id
                vanity = { vanityName, updatedAt, id }
              }
              const reviewsQueryRef = query(
                collection(db, 'reviews'),
                where('campaignId', '==', campaign.id)
              )
              const getReviews = await getDocs(reviewsQueryRef)
              let avgRating = getReviews.docs
                .map((review) => review.data()?.rating)
                .filter((rating) => !!rating)
              avgRating =
                avgRating.reduce((sum, rating) => sum + Number(rating), 0) / avgRating.length || 0

              let { campaignDetails, sentRequests } = campaign.data()

              let object = {
                ...campaign.data(),
                id: campaign.id,
                name: campaignDetails.internalCampaignName,
                reviews: 0,
                sentRequests: sentRequests || 0,
                customUrlName: vanity?.vanityName || campaign.id,
                avgRating,
              }
              return {
                ...object,
                reviews: getReviews.size,
              }
            })
          )
          // console.log(myCampaignsArr)
          setMyCampaigns(myCampaignsArr)
          setDataLoading(false)
        })
        return () => unsub()
      })

      return () => unsub1()
    } catch (error) {
      addToast({
        title: 'Campaign!',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      console.log(error)
      setDataLoading(false)
    }
  }

  useEffect(() => {
    getCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      ; (async () => {
        try {
          const userPaymentRef = collection(db, 'users', user.uid, 'subscriptions')
          const snapUserSubs = await getDocs(userPaymentRef)
          const result = await Promise.all(
            snapUserSubs.docs.map(async (doc) => {
              const obj = { ...doc.data(), docId: doc.id }
              return obj
            })
          )
          const role = await getCustomClaimRole()
          if (result?.[0]?.status === 'active' && role === 'premium' && !user?.isSaled) {
            user?.referral && (await handleSales(result, role))
            const notificationPayload = {
              title: `💰 Subscriber Alert!`,
              message: `Payment for premium service has been made by email: <a style='text-decoration: underline' href="mailto:${user?.email}">${user.email}</a>`,
              sender_id: user.uid,
              receiver_id: zacSmith,
            }
            sendNotification(notificationPayload)
            if (user?.ref_emailId) {
              const notificationPayloadRef = {
                ...notificationPayload,
                receiver_id: user?.ref_emailId,
              }
              await sendNotification(notificationPayloadRef)
            }
            !user?.referral && (await handleIsSaled())
            console.log('success')
          }
        } catch (err) {
          console.log(err.message)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSales = async (result, role) => {
    let myHeaders = new Headers()
    myHeaders.append('x-api-key', process.env.REACT_APP_FIRSTPROMOTORER_API_KEY)
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
    let urlencoded = new URLSearchParams()
    urlencoded.append('email', user.email)
    // urlencoded.append("uid", user.uid);
    urlencoded.append('currency', 'USD')
    urlencoded.append('event_id', user?.stripeId)
    urlencoded.append('plan', role)
    urlencoded.append('amount', result?.[0]?.items?.[0]?.plan.amount * discoutConstant)

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    }

    const response = await fetch('https://firstpromoter.com/api/v1/track/sale', requestOptions)
    const salesResult = await response.text()
    if (salesResult) {
      await handleIsSaled()
    }
  }

  const handleIsSaled = async () => {
    const docRef = doc(db, 'users', user.uid)
    await updateDoc(docRef, {
      isSaled: true,
    })
    setUser({ ...user, isSaled: true })
  }

  useEffect(() => {
    if (user) {
      ; (async () => {
        try {
          const reviewRefQuery = query(
            collection(db, 'reviews'),
            where('userId', '==', user.uid),
            where('reviewed', '==', false)
          )
          const unsub = onSnapshot(reviewRefQuery, (getReviews) => {
            setReviewDocs(getReviews.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
          })
          return () => unsub()
        } catch (error) {
          console.log(error.message)
        }
      })()
    }
  }, [user])

  const data = [
    {
      title: 'Active Campaigns',
      total: campaignDocs ? campaignDocs?.filter(({ active }) => active)?.length : 0,
      image: dashboardCheck,
      link: '/dashboard/my-campaigns',
    },
    {
      title: 'Total Visits',
      total: campaignDocs
        ? campaignDocs.reduce((visit, object) => {
          return visit + (object.visits || 0)
        }, 0)
        : 0,
      image: dashboardGlobal,
    },
    {
      title: 'Reviews Collected',
      total: allReviews?.length || 0,
      image: dashboardFile,
      link: '/dashboard/received-testimonials',
    },
  ]
  const [reviewLinkCopied, setReviewLinkCopied] = useState(false)
  const [paginationData, setPaginationData] = useState([])
  const [allTestimonials, setAllTestimonials] = useState([])
  const [dataLoading, setDataLoading] = useState(true)

  const onPaginationChange = (data) => {
    const { currentPage, cardsPerPage } = data

    const offset = (currentPage - 1) * cardsPerPage
    const currentUserData = allTestimonials.slice(offset, offset + cardsPerPage)

    currentPage !== 1 && scrollToElement('#campaigns')
    setCurrentPage(currentPage)
    setPaginationData(currentUserData)
  }

  const getCombineTestimonials = async () => {
    try {
      let data = !reviewDocs?.length
        ? []
        : await Promise.all(
          reviewDocs.map(async (tst) => {
            const collRef = doc(db, 'campaigns', tst.campaignId)
            const getCampaign = await getDoc(collRef)
            return {
              campaignData: {
                name: getCampaign.data()?.campaignDetails?.publicCampaignName,
              },
              ...tst,
            }
          })
        )

      data = data.sort(function (a, b) {
        return (
          b.date?.toDate() - a.date?.toDate() ||
          a.campaignData.name.localeCompare(b.campaignData.name)
        )
      })

      setAllTestimonials(data.filter((val) => !val?.archive))
      if (reviewDocs) {
        data?.length === reviewDocs.length && setDataLoading(false)
      }
    } catch (error) {
      setDataLoading(false)
    }
  }

  useEffect(() => {
    getCombineTestimonials()
    getCampaigns()
    // console.log(campaignDocs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewDocs])

  return (
    <Flex direction='column' gap={'2rem'} px={['2', '4']}>
      <Text fontSize={['20px', '24px']} fontWeight='500' lineHeight={'38px'} color='#474747'>
        Welcome <chakra.span color='brand.1'>{user?.firstName}</chakra.span>
      </Text>
      {(myCampaigns && myCampaigns?.length > 0) ? <HomeQrCodeModal data={myCampaigns} /> : null}

      <SimpleGrid
        // columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
        spacing={['10px', '15px']}
        mt={2}
        minChildWidth={{ base: 'full', sm: '260px', md: '285px' }}
      >
        {data.map((cardInfo, index) => (
          <VStack
            spacing='20px'
            key={index}
            borderRadius='5px'
            boxShadow='0px 0px 24px rgba(234, 234, 234, 0.7)'
            bg='white'
            align={'flex-start'}
            p={'4'}
            maxW={'container.sm'}
            as={Link}
            to={cardInfo?.link || ''}
            cursor={cardInfo?.link ? 'pointer' : 'auto'}
          >
            <Text fontSize='16px' fontWeight='500' lineHeight={'24px'}>
              {cardInfo.title}
            </Text>
            <HStack justify='space-between' spacing='1' w='full'>
              {/* <Text fontSize={['24px', '32px']} fontWeight='500'>
                {('0' + cardInfo.total).slice(-2)}
              </Text> */}
              <Counter from={0} to={+cardInfo.total} toFixed={0} duration={1} />
              <Image src={cardInfo.image} width={['40px', '50px']} height={['40px', '50px']} />
            </HStack>
          </VStack>
        ))}
      </SimpleGrid>

      <Stack
        width={'full'}
        spacing={'2'}
        justify='space-between'
        id='campaigns'
        direction={['column', 'row']}
      >
        <Text fontSize={['20px', '24px']} fontWeight='500' lineHeight={'38px'} color='#474747'>
          Recent Reviews
        </Text>
        {/* <Button
          leftIcon={<BsPlusLg />}
          size={['sm', 'md']}
          variant='solid'
          py={'4'}
          onClick={(_) => navigate('/dashboard/new-campaign')}
        >
          Create a Business Campaign
        </Button> */}
        <ExpressButton user={user} />
      </Stack>

      <SimpleGrid
        spacing={['10px', '15px']}
        mt={2}
        minChildWidth={{ base: 'full', sm: '280px', md: '260px' }}
        justifyItems={['center', 'stretch', 'stretch']}
      // maxW={["fit-content", "100%", "100%"]}
      // mx={["auto", "0", 0]}
      >
        {dataLoading ? (
          <chakra.div
            minH={'52'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            <Text fontWeight={'bold'} fontSize={'2rem'} color={'brand.1'}>
              Loading...
            </Text>
          </chakra.div>
        ) : paginationData?.length === 0 ? (
          <chakra.div
            minH={'52'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            <VStack
              bg={'teal.50'}
              rounded={'xl'}
              p={{ base: 6, sm: 6, md: 8 }}
              py={[8, 10, 12]}
              width='full'
              maxWidth='750px'
              alignItems={'center'}
              textAlign={'center'}
              spacing={'4'}
            >
              <Text fontWeight={'bold'} fontSize={['xl', '2xl']} color={'brand.1'}>
                {allReviews?.length > 0
                  ? 'All reviews approved, no pending reviews left!'
                  : "You haven't received reviews to any campaign!"}
              </Text>
              <Text fontWeight={'bold'} fontSize={['md', 'xl']} color={'teal.400'}>
                {allReviews?.length > 0
                  ? 'Send more video review requests'
                  : 'Send your first video review request.'}
              </Text>
            </VStack>
          </chakra.div>
        ) : (
          paginationData
            ?.filter((v, i) => i < 4 && !!v?.review)
            ?.map((cardInfo) => (
              <TestimonialVideoCard
                key={cardInfo.id}
                cardInfo={{
                  ...cardInfo,
                  campaignName: cardInfo?.campaignData?.name,
                }}
                onOpen={onOpen}
                setCardInfo={setCardInfo}
                // showReviewInfo
                showInformationTags={true}
                fetchAgain={getCombineTestimonials}
              />
            ))
        )}

        {
          // its bcz when testimonials length <less than 4 in a row grid, then below dummy boxes added to fill gaps in the row otherwise all boxes have large gaps between them
          paginationData.length > 0 &&
          paginationData.length < 4 &&
          [...Array(4 - paginationData.length).keys()].map((no) => (
            <Box
              display={['none', 'none', 'none', 'block']}
              key={no}
              bg='white'
              maxW={['360px', '320px']}
              maxH={['280px']}
              minH={['200px']}
            />
          ))
        }
      </SimpleGrid>

      {paginationData?.filter((v) => v.reviewed === false)?.length >= 4 && (
        <chakra.div my={'4'} mx={'auto'} display={'flex'} justifyContent={'center'}>
          <Button variant={'solid'} as={Link} to={'/dashboard/received-testimonials'}>
            View more
          </Button>
        </chakra.div>
      )}

      <VideoPopup
        isOpen={isOpen}
        onClose={(e) => {
          onClose(e)
          setCardInfo(null)
        }}
        cardInfo={cardInfoState}
        fetchAgain={getCombineTestimonials}
      />

      <Pagination
        currentPage={currentPage}
        cardsPerPage={8}
        totalCards={allTestimonials.length}
        dataArr={allTestimonials || []}
        onPaginationChange={onPaginationChange}
        sx={{ mt: '10' }}
      />
    </Flex>
  )
}

export default Home
