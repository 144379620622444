import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure,
  VStack,
  chakra,
  Heading,
  Stack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { DeletePrompt, errorToast, handleDeleteCloudinaryMedia, updateStatus } from '../../../utils'
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from 'firebase/firestore'
import { db, storage } from '../../../firebase/config'
import { useCustomToast } from '../../../hooks/customToast'
import { AiOutlineGlobal } from 'react-icons/ai'
import { format } from 'date-fns'
import { MdDelete, MdModeEditOutline } from 'react-icons/md'
import { deleteObject, ref } from 'firebase/storage'

function BlogPosts({ setTabIndex, setFetchBlog, user }) {
  const [blogDocs, setBlogDocs] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [publishLoading, setPublishLoading] = useState(false)
  const [dltLoading, setDltLoading] = useState(false)
  const { addToast } = useCustomToast()
  const { isOpen: openDlt, onOpen, onClose: closeDlt } = useDisclosure()
  const [cardInfo, setCardInfo] = useState('')

  const handleDlt = async () => {
    try {
      setDltLoading(true)
      if (cardInfo?.headerImg.includes('cloudinary')) {
        await handleDeleteCloudinaryMedia({
          filename: cardInfo.id,
          folder: 'sendmea/imgs',
          resource_type: 'image',
        })
      } else {
        const storageRef = ref(storage, cardInfo.headerImg)
        await deleteObject(storageRef)
      }
      const docRef = doc(db, 'blogs', cardInfo.id)
      await deleteDoc(docRef)
      addToast({
        title: 'Blog Status!',
        description: `Blog Deleted`,
        status: 'warning',
        variant: 'left-accent',
      })
      closeDlt()
      setCardInfo(null)
      setDltLoading(false)
    } catch (err) {
      errorToast(err, addToast)
      setDltLoading(false)
    }
  }

  const handleApprove = (id, approve) => {
    setPublishLoading(true)
    updateStatus('blogs', id, { publish: !approve, publishedDate: serverTimestamp() }, addToast, {
      title: 'Blog Status!',
      description: `Blog ${approve ? 'Unpublished' : 'Published'}`,
      variant: approve ? 'left-accent' : 'solid',
    })
    setPublishLoading(false)
  }

  let unsubscribe

  useEffect(() => {
    if (db && user) {
      ; (async () => {
        try {
          setDataLoading(true)
          const blogsRef = collection(db, 'blogs')
          const blogsRefQuery = query(
            blogsRef,
            where('userId', '==', user.uid),
            orderBy('publishedAt', 'desc')
          )

          // eslint-disable-next-line react-hooks/exhaustive-deps
          unsubscribe = onSnapshot(blogsRefQuery, (blogsSnap) => {
            // const blogsSnap = await getDocs(blogsRefQuery)
            let results = []
            blogsSnap.docs.forEach((doc) => {
              results.push({ ...doc.data(), id: doc.id })
            })
            // console.log(results)
            setBlogDocs(results)
            setDataLoading(false)
          })
        } catch (error) {
          errorToast(error, addToast)
          setDataLoading(false)
        }
      })()
    }
    return () => unsubscribe()
  }, [user])

  return (
    <Stack
      // mt={10}
      bg={'gray.50'}
      rounded={'xl'}
      py={{ base: 4, sm: 6, md: 8 }}
      px={{ base: 2, sm: 4, md: 8 }}
      width='full'
      spacing={8}
    // maxW={{ lg: 'lg' }}
    >
      <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
        Blog Posts
      </Heading>
      <VStack w='full'>
        {dataLoading ? (
          <VStack
            justifyContent='center'
            alignItems='center'
            width='full'
            minH='calc(100vh - 25vh)'
          >
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='teal.500'
              size={['md', 'xl']}
            />
          </VStack>
        ) : !blogDocs?.length ? (
          <Text fontSize={'lg'} mt={5} color='gray.500' fontWeight='bold'>
            You have not added any blog yet!{' '}
            <Button variant={'link'} onClick={() => setTabIndex(1)}>
              Add Blog
            </Button>
          </Text>
        ) : (
          <SimpleGrid
            spacing={['4']}
            // columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
            // mx={'auto'}
            maxW='1450px'
            minChildWidth={{ base: 'full', sm: '260px', md: '285px' }}
            justifyItems='stretch'
            w='full'
          >
            {blogDocs.map((blogDoc) => (
              <VStack
                key={blogDoc.id}
                w='full'
                bg='white'
                shadow='lg'
                rounded='lg'
                border='1px solid'
                borderColor={'teal.200'}
                spacing={0}
                overflow='hidden'
                maxW={[blogDocs.length === 1 ? '370px' : 'full']}
                _hover={{
                  '& img': {
                    transform: 'scale(1.1)',
                  },
                }}
              >
                <VStack px={4} py={2} w='full' flex={1} alignItems='flex-start'>
                  <chakra.h1
                    py={2}
                    noOfLines={3}
                    color='gray.800'
                    fontWeight='bold'
                    fontSize='xl'
                    _dark={{
                      color: 'white',
                    }}
                  >
                    {blogDoc.blogTitle}
                  </chakra.h1>
                  <chakra.p
                    noOfLines={3}
                    mt={1}
                    fontSize='sm'
                    color='gray.600'
                    _dark={{
                      color: 'gray.400',
                    }}
                    flex={1}
                  >
                    {blogDoc.blogDesc}
                  </chakra.p>
                  <Text fontSize={['sm']} color='gray.500' fontWeight='500'>
                    <chakra.span color='gray.600' mr={1}>
                      Created At:{' '}
                    </chakra.span>
                    {format(
                      new Date(blogDoc?.publishedAt?.toDate()?.toDateString() || '01-01-2023'),
                      'MMM dd yyyy'
                    )}
                  </Text>
                </VStack>

                <Box h={'48'} w='full' overflow='hidden'>
                  <Image
                    h={'full'}
                    w='full'
                    objectFit='cover'
                    mt={2}
                    src={blogDoc.headerImg}
                    alt='blog header'
                    transition='all 0.6s ease'
                  />
                </Box>
                <HStack
                  w='full'
                  alignItems='center'
                  justifyContent='space-between'
                  px={2}
                  py={2}
                  bg='teal.500'
                  roundedBottom='lg'
                >
                  <Box>
                    <Button
                      size='sm'
                      bg='white'
                      isLoading={publishLoading}
                      _disabled={{ opacity: 0.9 }}
                      onClick={() => handleApprove(blogDoc.id, blogDoc.publish)}
                    >
                      {blogDoc.publish ? 'Unpublish' : 'Publish'}
                    </Button>
                  </Box>
                  <HStack spacing={0} gap='2'>
                    <IconButton
                      size='sm'
                      rounded='full'
                      bg='white'
                      color='teal.400'
                      _hover={{ transform: 'scale(1.1)' }}
                      onClick={() => {
                        setFetchBlog(blogDoc)
                        setTabIndex(2)
                      }}
                    >
                      <MdModeEditOutline fontSize={20} />
                    </IconButton>
                    <IconButton
                      size='sm'
                      rounded='full'
                      bg='white'
                      color='teal.400'
                      _hover={{ transform: 'scale(1.1)' }}
                      isLoading={dltLoading}
                      onClick={() => {
                        setCardInfo(blogDoc)
                        onOpen()
                      }}
                    >
                      <MdDelete fontSize={20} />
                    </IconButton>
                    <IconButton
                      size='sm'
                      rounded='full'
                      bg='white'
                      color='teal.400'
                      _hover={{ transform: 'scale(1.1)' }}
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/blog/${blogDoc?.wordsUrl || blogDoc.id}`
                        )
                      }
                    >
                      <AiOutlineGlobal fontSize={20} />
                    </IconButton>
                  </HStack>
                </HStack>
              </VStack>
            ))}
          </SimpleGrid>
        )}
        <DeletePrompt isOpen={openDlt} onClose={closeDlt} accept={handleDlt} loading={dltLoading} />
      </VStack>
    </Stack>
  )
}

export default BlogPosts
