import {
    AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, HStack, IconButton, Text,
    Tooltip,
    chakra,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react'
import {
    BrowserView, MobileView, isBrowser, isMobile, browserName, browserVersion, BrowserTypes, fullBrowserVersion,
    deviceType, deviceDetect, engineName, engineVersion, osName, osVersion,
} from 'react-device-detect';
import { BsPatchQuestionFill } from 'react-icons/bs';
import { secToMin, suggestedBrowserList } from '../../../utils';
import { useRecoilState } from 'recoil';
import { permissionTimerState } from '../../../recoil/atoms/auth';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { LockIcon } from '@chakra-ui/icons';
import { useCustomToast } from '../../../hooks/customToast';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';


export const browserPermissionData = {
    chrome: {
        isBrowser: {
            refreshWorks: false,
            instructions: () => (
                <p>
                    {' '}
                    Click the icon on the left side of the URL
                    <LockIcon mx={1} fontSize={'15px'} display={'inline-block'} /> and you should be
                    able to grant permission.
                </p>
            )
        },
        isMobile: {
            refreshWorks: true,
            instructions: () => (
                <p>
                    {' '}
                    Click the icon on the left side of the URL
                    <LockIcon mx={1} fontSize={'15px'} display={'inline-block'} /> and you should be
                    able to grant permission.
                </p>
            )
        }
    },
    safari: {
        isBrowser: {
            refreshWorks: true,
        },
        isMobile: {
            iOS: {
                refreshWorks: true,
            },
        }
    },
    firefox: {
        isBrowser: {
            refreshWorks: true,
        },
        isMobile: {
            iOS: {
                refreshWorks: false,
                copyURL: 'Click to copy link and use in safari or chrome'
            },
            Android: {
                refreshWorks: false,
                copyURL: 'Click to copy link and use in safari or chrome'
            }
        }
    },
    facebook: {
        isMobile: {
            iOS: {
                refreshWorks: true,
            },
            Android: {
                refreshWorks: false,
                copyURL: 'Click to copy link and use in safari or chrome',
                // Instructions:`You are using social media built in browsers which don't have permission features
                // Go to the menu and find options 'open in browser' or similar option to open in chrome or safari`,
                instructions: () => (
                    <p>
                        {' '}
                        if you're using built in browser try clicking the
                        <BiDotsHorizontalRounded
                            style={{ marginInline: '2px', display: 'inline-block' }}
                        />{' '}
                        icon in right corner {'>'} <b>open system browser or open with etc</b> if you can't
                        find option like that copy the link and open paste it in different browser.
                    </p>
                )
            }
        }
    },

}

const noHardRefresh = ['GSA'].includes(browserName) //'Opera', 'Firefox'

const GivePermissionModal = ({
    onClose,
    isOpen,
    onOpen,
    leastDestructiveRef,
    error, stream,
    campaign
}) => {
    const { addToast } = useCustomToast(false)
    const [permissionTimer, setPermissionTimer] = useState(15);
    const [permissionTimerCompleted, setPermissionTimerCompleted] = useRecoilState(permissionTimerState);
    const intervalIdRef = useRef(null);

    useEffect(() => {
        (async () => {
            if (isOpen) {
                const docId = localStorage.getItem('LogId')
                if (docId) {
                    const docRef = doc(db, 'logs', docId)

                    await updateDoc(docRef, {
                        permission: error || ''
                    })
                }
            }
        })()
    }, [isOpen])

    useEffect(() => {
        if (isOpen && !permissionTimerCompleted && isMobile && !noHardRefresh) {
            // console.log('khgcjyg')
            intervalIdRef.current = setInterval(() => {
                setPermissionTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(intervalIdRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (permissionTimer === 0) {
            // console.log({ permissionTimerCompleted })
            clearInterval(intervalIdRef.current);
            setPermissionTimerCompleted(true)
            window.location.reload(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionTimer]);
    // setPermissionTimerCompleted(false)

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            addToast({
                id: 'url',
                title: 'Copied fallback!',
                description: 'Review Url copied',
                status: 'success',
                variant: 'left-accent',
            })
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
            addToast({
                id: 'url',
                title: 'Copied! navigator',
                description: 'Review Url copied',
                status: 'success',
                variant: 'left-accent',
            })
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    const handleOpenInExternalBrowser = () => {
        // window.open(`${window.origin}/review/${campaign?.id}`, '_blank');
        // navigator.clipboard.writeText(`${window.origin}/review/${campaign?.id}`)
        copyTextToClipboard(`${window.origin}/review/${campaign?.id}`)
    };

    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={leastDestructiveRef}
            onClose={() => {
                onClose()
                clearInterval(intervalIdRef.current);
                setPermissionTimerCompleted(true)
            }}
            isOpen={isOpen}
            isCentered
        >
            <AlertDialogOverlay />

            <AlertDialogContent bgColor={'red.500'} color={'white'} maxWidth={['29rem']}>
                <AlertDialogHeader>You Must Give Permission To Record!</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    <HStack justifyContent={'space-between'} alignItems={'center'}>
                        <Text>
                            Opps, you will need to grand permission{stream && error ? '' : 's'} to record{' '}
                            <chakra.span fontWeight={'bold'}>
                                {stream && error ? 'voice' : 'video and voice'}.
                            </chakra.span>{' '}
                            {isMobile
                                // && !suggestedBrowserList.includes(browserName) 
                                && `it looks like you are using ${browserName} on ${osName}`}
                        </Text>
                        {/* <Tooltip
                            label='Need Help ?'
                            placement='bottom'
                            color='white'
                            bg='teal.400'
                            px={2}
                            rounded='md'
                            hasArrow
                        >
                            <AnimatePresence>
                                <motion.div
                                    animate={{
                                        scale: [1, 1.2, 1],
                                    }}
                                    transition={{
                                        duration: 0.75,
                                        delay: 2,
                                        repeat: 5,
                                        repeatType: 'reverse',
                                    }}
                                >
                                    <IconButton
                                        color={'white'}
                                        variant='ghost'
                                        size='md'
                                        rounded={'full'}
                                        onClick={onOpen}
                                        _hover={{ color: 'gray.100' }}
                                    >
                                        <BsPatchQuestionFill fontSize={'26px'} />
                                    </IconButton>
                                </motion.div>
                            </AnimatePresence>
                        </Tooltip> */}
                    </HStack>
                    {(!permissionTimerCompleted && isMobile && !noHardRefresh) &&
                        <Text color={'white'} mt={2}>
                            The page will hard refresh in{' '}
                            <chakra.span color='red.100' fontWeight='600' mx='1'>
                                {secToMin(permissionTimer)}
                            </chakra.span>
                        </Text>
                    }
                    <chakra.ol pl={4}>
                        {(isMobile && !noHardRefresh) &&
                            <Text mt={1} as={'li'}>
                                {permissionTimerCompleted ? 'Hard refresh the page by' : 'Or do it yourSelf by'}{' '}
                                <chakra.span cursor={'pointer'} fontWeight={'bold'} fontStyle={'italic'} onClick={() => {
                                    setPermissionTimer(0)
                                    clearInterval(intervalIdRef.current);
                                    setPermissionTimerCompleted(true)
                                    window.location.reload(true);
                                }}>
                                    Clicking Here
                                </chakra.span>
                            </Text>
                        }
                        {isMobile && !suggestedBrowserList.includes(browserName) && <Text mt={1} as={'li'}> <Button onClick={handleOpenInExternalBrowser} bg='red.100' borderColor={'red.700'} color='red.700' size={'sm'}>
                            Copy Url
                        </Button> and use Chrome, Safari or Brave.</Text>}
                        <Text mt={1} sx={{ '&>*': { display: 'inline', }, cursor: 'pointer' }} as={'li'} onClick={onOpen}>
                            Click here <Tooltip
                                label='Need Help ?'
                                placement='bottom'
                                color='white'
                                bg='teal.400'
                                px={2}
                                rounded='md'
                                hasArrow
                            >
                                <AnimatePresence>
                                    <motion.div
                                        animate={{
                                            scale: [1, 1.3, 1],
                                        }}
                                        transition={{
                                            duration: 0.75,
                                            delay: 2,
                                            repeat: 5,
                                            repeatType: 'reverse',
                                        }}
                                    >
                                        <IconButton
                                            color={'white'}
                                            variant='ghost'
                                            size='md'
                                            rounded={'full'}
                                            // onClick={onOpen}
                                            _hover={{ color: 'gray.100' }}
                                        >
                                            <BsPatchQuestionFill fontSize={'26px'} />
                                        </IconButton>
                                    </motion.div>
                                </AnimatePresence>
                            </Tooltip> to see instruction to grant permission.
                        </Text>
                    </chakra.ol>
                </AlertDialogBody>
                <AlertDialogFooter>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default GivePermissionModal