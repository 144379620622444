import React, { useCallback, useEffect } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { userState } from './recoil/atoms/auth'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import AuthLayout from './layout/AuthLayout'
import DashboardLayout from './layout/DashbaordLayout'
import SalesPagesLayout from './layout/SalesPagesLayout/SalesPageLayout'
import Landing from './pages/Landing/Landing'
import Login from './pages/Login/Login'
import Register from './pages/Register/Register'
import HowTo from './pages/HowTo/HowTo'
import About from './pages/About/About'
import AllCampaigns from './pages/AllCampaigns/AllCampaigns'
import TestimonialDetails from './pages/TestimonialDetails/TestimonialDetails'
import Numbers from './pages/Benefits/Numbers'
import VideoPage from './pages/VideoPages/VideoPage'
import Partners from './pages/Partners/Partners'
import Reset from './pages/PasswordReset/Reset'
// import NewPassword from './pages/PasswordReset/NewPassword'
import NewPassword from './pages/NewPassword/NewPassword'

import Home from './pages/Home/Home'
import Campaigns from './pages/Campaigns/Campaigns'
import ReceivedTestimonials from './pages/ReceivedTestimonials/ReceivedTestimonials'
import BusinessDetails from './pages/BusinessDetails'
import PartnerData from './pages/PartnerData/PartnerData'
import CreateCampign from './pages/CreateCampign/CreateCampign'

import NotFound from './components/NotFound'
import Seth from './pages/VideoPages/Seth'
import Zac from './pages/VideoPages/Zac'
import theme from './theme'
import Affiliate from './pages/Affiliate-program/affiliate-program'
import Manage from './pages/Manage-cookies/manage-cookies'
import Terms from './pages/Terms-and-conditions/terms'
import Policy from './pages/Privacy-policy/policy'
import Setting from './pages/Settings/setting'
import Profile from './pages/Profile/Profile'
import Billing from './pages/Billing/billing'
import Team from './pages/Team/Team'
// import Review from './pages/OldReviewProcess/Review'
import Faq from './pages/Faq/Faq'
import GetStartedPage from './pages/Sales/GetStarted'
import { ChakraProvider } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import './App.css'
import { ConsentCookie } from './components/CookieConsent'
import { Sotellus } from './pages/comparisons/Sotellus'
import { VideoT } from './pages/comparisons/VideoTouch'
import { Boast } from './pages/comparisons/Boastio'
import { TestimonialHero } from './pages/comparisons/TestimonialHero'
import Logout from './pages/Logout/logout'
import Loader from './components/Loader'
import SEO from './components/SEOForm/SEO'
import Roadmap from './pages/Roadmap/Roadmap'
import Blog from './pages/Blog/Blog'
import BlogDetails from './pages/Blog/BlogDetails'
import Widgets from './pages/Widget/Widgets'
import { CustomRequestMessage } from './components/CustomReviewMessage/CustomReviewMessage'
import LogRocket from 'logrocket'
import UploadCustomReview from './pages/UploadCustomReview/UploadCustomReview'
import PreviewEmbed from './pages/PreviewEmbed/PreviewEmbed'
import Contact from './pages/Contact/Contact'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './firebase/config'
import RecordView from './pages/NativeRecorderReviewProcess/Review'
import TestRecordView from './pages/TestNativeRecorderReviewProcess/Review'
import { ProtectedRoute } from './components/ProtectedRoute'
import { teamEmailIds } from './utils'
// LogRocket.init('9xa8a5/sendmea')

const App = () => {
  const { pathname, hash, state } = useLocation()
  const resetUser = useResetRecoilState(userState)

  const user = useRecoilValue(userState)
  LogRocket.identify(user?.uid, {
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
  })
  // console.log(user)

  useEffect(() => {
    if (!state?.preventScroll && !hash) {
      window.scroll({ behavior: 'smooth', top: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, hash, state])

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(auth, async (user) => {
        // console.log('App.js ', { user })
        if (!user) {
          resetUser()
          auth?.signOut()
        }
      })
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <Routes>
          {/* <Route path='/' element={<AuthLayout children={<Suspense fallback={<>Loading...</>}><Landing /> </Suspense>} />} /> */}
          <Route path='/' element={<AuthLayout children={<Landing />} />} />
          <Route
            path='/login'
            element={<AuthLayout children={user === null ? <Login /> : <Loader user={user} />} />}
          />
          <Route
            path='/new-password'
            element={
              <AuthLayout
                hideFooter={user === null}
                hideNavbar={user === null}
                children={user === null ? <NewPassword /> : <Loader user={user} />}
              />
            }
          />
          <Route
            path='/password-reset'
            element={<AuthLayout children={user === null && <Reset />} />}
          />
          <Route
            path='/register'
            element={
              <AuthLayout children={user === null ? <Register /> : <Loader user={user} />} />
            }
          />
          <Route path='/affiliate-program' element={<AuthLayout children={<Affiliate />} />} />
          <Route path='/get-started' element={<SalesPagesLayout children={<GetStartedPage />} />} />
          <Route path='/manage-cookies' element={<AuthLayout children={<Manage />} />} />
          <Route path='/terms' element={<AuthLayout children={<Terms />} />} />
          <Route path='/roadmap' element={<AuthLayout children={<Roadmap />} />} />
          <Route path='/policy' element={<AuthLayout children={<Policy />} />} />
          <Route path='/faq' element={<AuthLayout children={<Faq />} />} />
          <Route path='/blog' element={<ProtectedRoute user={user}>
            <AuthLayout children={<Blog />} />
          </ProtectedRoute>
          } />
          <Route path='/contact' element={<AuthLayout children={<Contact />} />} />
          <Route path='/blog/:id' element={
            <ProtectedRoute user={user}>
              <AuthLayout children={<BlogDetails />} />
            </ProtectedRoute>
          } />
          <Route path='/sendmea-vs-sotellus' element={<AuthLayout children={<Sotellus />} />} />
          <Route path='/sendmea-vs-videotouch' element={<AuthLayout children={<VideoT />} />} />
          <Route path='/sendmea-vs-boastio' element={<AuthLayout children={<Boast />} />} />
          <Route
            path='/sendmea-vs-testimonialhero'
            element={<AuthLayout children={<TestimonialHero />} />}
          />

          <Route path='/test-review/:id' element={<TestRecordView />} />
          {/* <Route path='/review/:id' element={<Review />} /> */}
          <Route path='/review/:id' element={<RecordView />} />
          <Route path='/how-to' element={<AuthLayout children={<HowTo />} />} />
          <Route path='/about' element={<AuthLayout children={<About />} />} />
          <Route path='/all-campaigns' element={<AuthLayout children={<AllCampaigns />} />} />
          <Route path='/wall/:campaignId' element={<TestimonialDetails user={user} />} />
          <Route path='/numbers' element={<AuthLayout children={<Numbers />} />} />
          <Route path='/partners' element={<AuthLayout children={<Partners />} />} />
          <Route path='/sample' element={<AuthLayout children={<VideoPage />} />} />
          <Route path='/logout' element={<AuthLayout children={<Logout />} />} />
          <Route path='/seth' element={<AuthLayout children={<Seth />} />} />
          <Route path='/zac' element={<AuthLayout children={<Zac />} />} />
          <Route path='/codeshock-embed' element={<AuthLayout hideNavbar hideFooter children={<PreviewEmbed />} />} />
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<Home />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/setting'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<Setting />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/seo'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<SEO />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/profile'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<Profile />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/billing'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<Billing />} />
              </ProtectedRoute>
            }
          />
          {teamEmailIds?.includes(user?.email) && <Route
            path='/dashboard/team'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<Team />} />
              </ProtectedRoute>
            }
          />}
          <Route
            path='/dashboard/my-campaigns'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<Campaigns />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/new-campaign'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout
                  children={
                    <AnimatePresence mode='wait'>
                      <CreateCampign />
                    </AnimatePresence>
                  }
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/edit-campaign/:id'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout
                  children={
                    <AnimatePresence mode='wait'>
                      <CreateCampign />
                    </AnimatePresence>
                  }
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/upload-review/:id'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<UploadCustomReview />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/received-testimonials'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<ReceivedTestimonials />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/business-details'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<BusinessDetails />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/affiliate-data'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<PartnerData />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/widget/:wallId'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<Widgets />} />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard/request-message'
            element={
              <ProtectedRoute user={user}>
                <DashboardLayout children={<CustomRequestMessage />} />
              </ProtectedRoute>
            }
          />

          <Route path='404' element={<AuthLayout children={<NotFound />} />} />
          <Route path='*' element={<AuthLayout children={<NotFound />} />} />
        </Routes>
        {!(
          window.location.pathname.startsWith('/review') ||
          window.location.pathname.startsWith('/wall')
        ) && <ConsentCookie />}
      </ChakraProvider>
    </HelmetProvider>
  )
}

export default App
