import { useState } from 'react'
import {
    Button,
    Flex,
    Tooltip,
    Text,
} from '@chakra-ui/react'
import {
    // ChevronDownIcon,
    // ChevronLeftIcon,
    // ChevronRightIcon,
    LinkIcon
} from '@chakra-ui/icons'
// import {
//     getBlobFromImageElement,
//     copyBlobToClipboard,
// } from 'copy-image-clipboard'
import { useCustomToast } from '../../../hooks/customToast';
import { QModal } from './Modal';
import { QrCodeModal } from '../../../components/QRcodeModal';

export const HomeQrCodeModal = ({ data }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure()

    const [reviewLinkCopied, setReviewLinkCopied] = useState(false)
    const { addToast } = useCustomToast()
    // const name = 'qr'.replace(/ /g, '')
    // download QR code
    // const downloadQRCode = () => {
    //     const qrCodeURL = document.getElementById('qrCodeEl')
    //         .toDataURL("image/png")
    //         .replace("image/png", "image/octet-stream");
    //     //console.log(qrCodeURL)
    //     let aEl = document.createElement("a");
    //     aEl.href = qrCodeURL;
    //     aEl.download = `${name}QRcode.png`;
    //     document.body.appendChild(aEl);
    //     aEl.click();
    //     document.body.removeChild(aEl);
    // }


    // const copyImage = async () => {
    //     const imageElement = document.getElementById('qrCodeEl')
    //     await getBlobFromImageElement(imageElement)
    //         .then((blob) => {
    //             copyBlobToClipboard(blob)
    //         })
    //         .then(() => {
    //             addToast({
    //                 title: 'QR Code',
    //                 description: 'copied to clipboard!',
    //                 status: 'success',
    //                 variant: 'left-accent',
    //             })
    //         })
    //         .catch((e) => {
    //             console.log('Error: ', e.message)
    //         })
    // }
    // console.log(data)


    return (
        <>
            {data?.length > 1 ? <QModal data={data} /> :
                <>
                    <Flex alignItems={'center'}>

                        <Text fontWeight={'bold'} mr='2' fontSize={'md'}>Quick Copy: </Text>

                        <Tooltip
                            color='white'
                            bg='teal.400'
                            px={2}
                            rounded='md'
                            hasArrow
                            placement='bottom'
                            label={reviewLinkCopied ? 'Copied' : 'Click to Copy'}
                        >
                            <Button
                                mr='2'
                                variant={'outline'}
                                leftIcon={<LinkIcon />}
                                size={'sm'}
                                onClick={(_) => {
                                    navigator.clipboard.writeText(`${window.location.origin}/review/${data[0]?.id}`)
                                    setReviewLinkCopied(true)
                                    addToast({
                                        title: 'Review Link Copied',
                                        description: 'copied to clipboard!',
                                        status: 'success',
                                        variant: 'left-accent',
                                    })
                                    setTimeout(() => {
                                        setReviewLinkCopied(false)
                                    }, 2000)
                                }}

                            >
                                Record a Review Link
                            </Button>
                        </Tooltip>
                        <QrCodeModal title={`Request ${data[0]?.campaignDetails?.internalCampaignName}  Review`}
                            pageUrl={`${window.location.origin}/review/${data[0]?.id}`}
                        />
                    </Flex>
                </>
            }
        </>
    )
}