import { Spinner, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCustomClaimRole, getPlans, subscribeToPlan } from '../firebase/stripeConfig'
import { freeSendmeaPremium } from '../utils'
import { useSetRecoilState } from 'recoil'
import { stripePlanId, userState } from '../recoil/atoms/auth'

function Loader({ user }) {
    const navigate = useNavigate()
    // const [strpeLoading, setStripeLoading] = useState(false)
    const setStripePlanId = useSetRecoilState(stripePlanId)
    const setUser = useSetRecoilState(userState)


    useEffect(() => {
        (async () => {
            if (user) {
                const plans = await getPlans()
                const role = await getCustomClaimRole()
                handleEverything(role, plans)
            }
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    const handleEverything = async (role, plans) => {
        let premiumPlanPriceDocId = plans?.find(plan => (plan.name?.toLowerCase() === "sendmea premium" || plan.role === "premium" || plan.metadata?.firebaseRole === "premium"))?.prices?.[0]?.docId
        if ((user && role === 'premium') || freeSendmeaPremium.includes(user?.email)) {
            setUser({ ...user, role: 'premium' })
            setStripePlanId('')
            navigate('/dashboard')
        } else if (user && premiumPlanPriceDocId && role !== 'premium') {
            handleStripeSubscription(premiumPlanPriceDocId)
            setStripePlanId(premiumPlanPriceDocId)
            setUser({ ...user, role: '' })
        }
    }

    const handleStripeSubscription = async priceDocId => {
        await subscribeToPlan(priceDocId, user)
    }

    return (
        <VStack alignItems="center" width="full" minH="calc(100vh - 20vh)" bg={'rgba(0, 0, 0, 0.35)'} position={'absolute'} top={'0'} left={0} bottom={0} right={0} m={'auto'} zIndex={'999'}>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='teal.500'
                mt={'25%'}
                size={['md', 'xl']}
            />
        </VStack>
    )
}

export default Loader