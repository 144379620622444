import { Box, Button, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const SitemapUpdater = () => {
  const [myUrl, setMyUrl] = useState("");
  const [message, setMessage] = useState("");
  const [xmlData, setXmlData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadXmlFile();
  }, []);

  const loadXmlFile = async () => {
    try {
      // Approach 1: Using public folder
      const response = await fetch("/sitemap.xml");

      // Approach 2: Using import (uncomment to use)
      // const response = await import('../sitemap.xml');

      const text = await response.text();
      setXmlData(text);
      setMessage("XML file loaded successfully");
    } catch (error) {
      console.error("Error loading XML file:", error);
      setMessage("Error loading XML file");
    }
  };

  const updateSitemap = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!xmlData) {
        setMessage("XML data not loaded");
        return;
      }
      const res = await fetch(
        "http://127.0.0.1:5001/sendmea-c7d45/us-central1/updateSitemap",
        {
          // const res = await fetch(
          //   `${cloudfunctionsBaseURL}updateSitemap`,
          //   {
          method: "POST",
          body: JSON.stringify({
            // xmlData: xmlData,
            url: myUrl,
          }),
        }
      );
      const response = await res.json();
      // console.log({ response });
      setMyUrl("");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      as='form'
      onSubmit={updateSitemap}
      sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
      <Input
        type='text'
        value={myUrl}
        onChange={(e) => setMyUrl(e.target.value)}
        placeholder='Enter new URL'
        className='border p-2 mr-2'
        required
      />
      <Button
        isLoading={loading}
        type='submit'
        className='bg-blue-500 text-white px-4 py-2 rounded'>
        Update Sitemap
      </Button>
      {message && <p className='mt-2'>{message}</p>}
    </Box>
  );
};

export default SitemapUpdater;
