import React, { useState } from "react";
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from "../../../firebase/config";
import { chakra, useDisclosure, Text } from "@chakra-ui/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialVideoCard from "./TestimonialCard";
import VideoPopup from "./VideoPopup";
import { videoData } from "./VideoData";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const sliderImageUrl = [
  //First image url
  {
    url:
      "https://i2.wp.com/www.geeksaresexy.net/wp-content/uploads/2020/04/movie1.jpg?resize=600%2C892&ssl=1"
  },
  {
    url:
      "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-kids-movies-2020-call-of-the-wild-1579042974.jpg?crop=0.9760858955588091xw:1xh;center,top&resize=480:*"
  },
  //Second image url
  {
    url:
      "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-movies-for-kids-2020-sonic-the-hedgehog-1571173983.jpg?crop=0.9871668311944719xw:1xh;center,top&resize=480:*"
  },
  //Third image url
  {
    url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQS82ET2bq9oTNwPOL8gqyoLoLfeqJJJWJmKQ&usqp=CAU"
  },


];
const VideoCarousel = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cardInfoState, setCardInfo] = useState(null)
  return (
    <chakra.div px={2} py={6} className="parent">
          <Text
                fontSize={['1.85rem', '2rem']}
                fontWeight={'600'}
                my={'4'}
                textAlign={'center'}
                pt={'6'}
            >
                <chakra.span color={'brand.1'}>Check out the reviews Send</chakra.span>
                <chakra.span color={'brand.2'}>mea </chakra.span>
                <chakra.span color={'brand.1'}>clients are recieving!</chakra.span>
            </Text>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {videoData.map((video, index) => {
          return (
            <chakra.div px={4} py={6} className="slider" key={index}>
              {/* <img src={imageUrl.url} alt="movie" /> */}
              <TestimonialVideoCard 
                       cardInfo={{
                        ...video,
                        campaignName: video?.campaignDetails?.publicCampaignName,
                      }}

                      onOpen={onOpen}
                     setCardInfo={setCardInfo}
                      video={video}
                      />
            </chakra.div>
          );
        })}
      </Carousel>
      <VideoPopup
          isTestimonialWall
          isOpen={isOpen}
          onClose={(e) => {
            onClose(e)
            setCardInfo(null)
          }}
          cardInfo={cardInfoState}
        />
    </chakra.div>
  );
};
export default VideoCarousel;
