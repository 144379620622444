import React, { useEffect, useState } from 'react'
import {
    Text,
    ModalOverlay,
    Modal,
    ModalBody,
    ModalHeader,
    ModalContent,
    chakra,
    ModalCloseButton,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Checkbox,
    Button,
    ModalFooter,
    Textarea,
    VStack
} from '@chakra-ui/react'
import { useCustomToast } from '../../hooks/customToast'
import { useForm } from 'react-hook-form'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { isDatePassedDuration } from '../../utils'



const ApproveRoadmapModal = ({
    isOpen,
    onClose,
    roadmapTask
}) => {

    const {
        handleSubmit,
        register,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            comment: "",
            approved: false,
            approvedAt: ''
        },
    })

    const { addToast } = useCustomToast()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async (state) => {
        try {
            const { comment, approved } = state
            setIsSubmitting(true)
            const roadmapPayload = {
                comment,
                approved,
                approvedAt: serverTimestamp(),
            }
            // console.log({ roadmapPayload })
            const roadmapRef = doc(db, 'roadmap', roadmapTask.id)
            await updateDoc(roadmapRef, roadmapPayload)
            setIsSubmitting(false)
            addToast({
                title: 'Roadmap!',
                description: 'Roadmap task approved',
                status: 'success',
            })
            onClose()
        } catch (error) {
            addToast({
                title: 'Error',
                description: error.message,
                status: 'error',
            })
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        (async () => {
            if (roadmapTask && roadmapTask?.approvedAt) {
                const { comment, approved, approvedAt } = roadmapTask
                reset({ comment: comment || '', approved, approvedAt: approvedAt || '' })
                const { isDateCrossed, durationLeft } = isDatePassedDuration(roadmapTask?.approvedAt, {
                    days: 45,
                })
                // console.log({ isDateCrossed, durationLeft, durationPassed })
                if (!isDateCrossed || !durationLeft) {
                    setError('approvedAt', { message: `The task is already approved` })
                } else {
                    clearErrors('approvedAt')
                }
            }
        })()
        return () => reset({ comment: '', approved: false, approvedAt: '' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roadmapTask])

    return (
        <Modal isCentered isOpen={isOpen} blockScrollOnMount={false} closeOnOverlayClick={false} onClose={onClose}>
            <ModalOverlay />
            <ModalContent mx={['2', '4']} maxW={['full', '330px', '450px']} as='form' onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader pt={2} pb={2}>
                    <Text
                        fontSize={['20px', '24px']}
                        fontWeight='600'
                        lineHeight={'38px'}
                        color='#474747'
                        mt={[2]}
                    >
                        Approve <chakra.span color='brand.1'>Roadmap</chakra.span>
                    </Text>
                </ModalHeader>
                <ModalCloseButton
                    bg={{ base: 'blackAlpha.300', sm: 'whiteAlpha.500' }}
                    color={{ base: 'white', sm: 'black' }}
                    zIndex={'999999'}
                    stroke={'currentColor'}
                    mt={1}
                />
                <ModalBody mb={[2]}>
                    <VStack gap={1.5} alignItems='flex-start'>
                        <FormControl isInvalid={!!errors?.comment}>
                            <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                Brief text or comment
                            </FormLabel>
                            <Textarea
                                // size={['sm', 'md', 'lg']}
                                placeholder='Add a comment'
                                bg={'gray.100'}
                                border={0}
                                color={'gray.500'}
                                resize='none'
                                h={'100px !important'}
                                _placeholder={{
                                    color: 'gray.500',
                                }}
                                _disabled={{
                                    bg: 'gray.100',
                                }}
                                {...register('comment', {
                                    required: 'This is required',
                                    minLength: { value: 10, message: 'Too Short' },
                                })}
                                disabled={errors?.approvedAt}
                            />
                            {errors.comment && (
                                <FormErrorMessage>{errors.comment.message}</FormErrorMessage>
                            )}
                            <Text
                                color={errors?.approvedAt?.message ? 'red.500' : 'gray.500'}
                                mt={2}
                                fontSize='sm'
                            >
                                {errors?.approvedAt?.message ||
                                    "Once you approved it won't be change before 45 days"}
                            </Text>
                        </FormControl>
                        <Checkbox
                            type='checkbox'
                            colorScheme='teal'
                            {...register(`approved`, {
                                required: 'Required',
                            })}
                            userSelect='none'
                            disabled={errors?.approvedAt}
                        >
                            <Text color='gray.600' fontSize={['sm', 'md']} userSelect='none'>
                                Approved {" "}
                                {/* <chakra.span color='gray.500'></chakra.span>{" "} */}
                                {errors.approved && <chakra.span fontSize='sm' color='red.500'>{errors.approved?.message}</chakra.span>}
                            </Text>
                        </Checkbox>
                    </VStack>
                </ModalBody>
                <ModalFooter justifyContent='flex-start'>
                    <Button type='submit' variant='solid' disabled={isSubmitting || errors?.approvedAt}>
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent >
        </Modal >
    )
}

export default ApproveRoadmapModal
