import { Button, HStack, Heading, Select, SimpleGrid, Spinner, Stack, Text, VStack } from '@chakra-ui/react'
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../../../../../firebase/config'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../recoil/atoms/auth'
import { isValid } from 'date-fns'
import SupportCard from './SupportCard'

const DashboardSupport = () => {

    const [loading, setLoading] = useState(false)
    const [allLogs, setAllLogs] = useState(null)
    const [noOfContactLogs, setNoOfContactLogs] = useState(10)
    const user = useRecoilValue(userState)

    const fetchSupportLogs = async (val) => {
        setLoading(true)
        try {
            val && setNoOfContactLogs(val)
            const collRef = collection(db, 'logs')
            const queryRef = query(collRef, where('type', '==', 'support'), orderBy('createdAt', 'desc'), limit(50))
            // let data = await getDocs(queryRef)
            const unsubscribe = onSnapshot(queryRef, async dataSnap => {

                const logsIncludingUser = await Promise.all(
                    dataSnap.docs.map(async (logSnap) => {
                        let iconsStatus = (logSnap?.data()?.iconsStatus || {})
                        if (!!Object.keys(iconsStatus).length) {
                            await Promise.all(
                                Object.keys(iconsStatus).map(async (iconName) => {
                                    const user = iconsStatus[iconName]?.isMarked ? await getDoc(doc(db, 'users', iconsStatus[iconName]?.markedBy)) : null
                                    const iconData = {
                                        ...iconsStatus[iconName],
                                        // markedAt: isValid(new Date(iconsStatus[iconName]?.markedAt)) ? iconsStatus[Object.keys(iconsStatus).find(icon => iconsStatus[icon]?.markedAt)]?.markedAt : iconsStatus[iconName]?.markedAt,
                                        markedByUser: user ? { ...user.data(), userId: user.id } : {}
                                    }
                                    Object.assign(iconsStatus, { [iconName]: iconData })
                                    // console.log(iconsStatus[Object.keys(iconsStatus).find(icon => iconsStatus[icon]?.markedAt)]?.markedAt)
                                })
                            )
                        }

                        return {
                            ...logSnap.data(),
                            iconsStatus,
                            id: logSnap.id,
                        }
                    })
                )
                setAllLogs(logsIncludingUser)
                // console.log(logsIncludingUser)
                setLoading(false)
            })
            return () => unsubscribe()
        } catch (error) {
            console.log(error.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchSupportLogs()
    }, [])


    return (
        <Stack
            // mt={10}
            bg={'gray.50'}
            rounded={'xl'}
            py={{ base: 4, sm: 6, md: 8 }}
            // px={{ base: 2, sm: 4, md: 8 }}
            width='full'
            spacing={8}
        >
            <HStack w='full' justifyContent='space-between' alignItems='flex-start'>
                <VStack alignItems='flex-start' spacing={3} w='full'>
                    <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
                        Support
                    </Heading>
                    <HStack justify={'space-between'} w='full'>
                        <Text fontSize={['lg']}>
                            Below are the latest {noOfContactLogs} Support Logs
                        </Text>
                        <Select
                            onChange={(val) => fetchSupportLogs(val.target.value)}
                            value={noOfContactLogs}
                            placeholder='Select Value'
                            width={'100px'}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </Select>
                    </HStack>
                </VStack>
                {/* <HStack>
                    <Button size='sm' onClick={fetchSupportLogs} variant='solid' disabled={loading}>
                        Fetch
                    </Button>
                </HStack> */}
            </HStack>
            <VStack w='full' py={4} minH='200px'>
                {loading ? (
                    <VStack
                        justifyContent='center'
                        alignItems='center'
                        width='full'
                    >
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='teal.500'
                            size={['md', 'xl']}
                        />
                    </VStack>
                ) : !allLogs?.length ?
                    <Text fontSize={'xl'} fontStyle='italic' mt={5} color='gray.500' fontWeight='bold'>
                        Logs Not Fetched yet
                    </Text>
                    : (
                        <SimpleGrid
                            spacing={['4']}
                            // columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
                            // mx={'auto'}
                            maxW='1450px'
                            minChildWidth={{ base: 'full', sm: '180px', md: '200px' }}
                            justifyItems='stretch'
                            w='full'
                        >
                            {allLogs.map((log) => (
                                <SupportCard user={user} key={log.id} log={log} />
                            ))}

                        </SimpleGrid>
                    )}
            </VStack >
        </Stack >
    )
}

export default DashboardSupport