import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Stack,
  Tooltip,
  IconButton,
  Box,
} from '@chakra-ui/react'
import { useState } from 'react'
import { cloudfunctionsBaseURL } from '../../../utils'
import { FaRegEdit } from 'react-icons/fa'

const Customize = ({ addToast, user, refId, setData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [state, setState] = useState(refId || '')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const options = {
        method: 'POST',
        body: JSON.stringify({
          cust_id: user.uid,
          new_ref_id: state,
        }),
      }
      const url = cloudfunctionsBaseURL + 'modifyPromoter'
      // const url = 'http://127.0.0.1:5002/sendmea-c7d45/us-central1/modifyPromoter'
      const resp = await fetch(url, options)
      const result = await resp.json()
      // console.log('result: ', result)
      if (result.statusCode === 200) {
        setData(result.data)
        addToast({
          title: 'Updated!',
          description: 'Referral Code updated successfully!',
          status: 'success',
          variant: 'left-accent',
        })
        onClose()
      }

      setLoading(false)
    } catch ({ message }) {
      addToast({ title: 'Error', description: message, status: 'error', variant: 'left-accent' })
      setLoading(false)
    }
  }
  return (
    <>
      <Box p='3'>
        <Tooltip
          color='white'
          bg='teal.400'
          px={2}
          py={2}
          mb='2'
          placement={'top'}
          label={'customize link'}
          fontSize='1rem'
          fontWeight='medium'
          rounded='md'
          hasArrow
          closeOnPointerDown
        >
          <IconButton
            onClick={() => {
              onOpen()
            }}
            icon={<FaRegEdit />}
            color={'brand.1'}
            aria-label='information'
            size='sm'
            fontSize={'2rem'}
            border='none'
            isRound={true}
            _hover={{
              transform: 'scale(1.2)',
              bg: 'whiteAlpha.300',
              color: 'brand.2',
              type: 'ghost',
            }}
          />
        </Tooltip>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Customize</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={state}
              onChange={(e) => setState(e.target.value)}
              placeholder={'Enter new referral'}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='solid' onClick={handleSubmit} isLoading={loading}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Customize
