import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Text, chakra, Box, SimpleGrid, Image, Button } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { ReadMoreModal } from '../../../components/ReadMoreModal'
import { benefits } from './benefitsData'

function Benefits() {

  const navigate = useNavigate()

  return (
    <chakra.div
      w={'inherit'}
      // id={'benefits'} 
      py={["4"]}
    >
      <Text
        fontSize={['1.85rem', '2rem']}
        fontWeight={'600'}
        my={'8'}
        textAlign={'center'}
      >
        <chakra.span color={'brand.1'}>Social Benefits of</chakra.span>
        <chakra.span color={'brand.2'} ml={2}>Video Reviews</chakra.span>
      </Text>
      <SimpleGrid
        columns={{ md: 2, sm: 1 }}
        spacing={['4', '6']}
        px={['1.5', '4', '8']}
      >
        {benefits.map((v, idx) => (
          <Box
            key={v.imgPath}
            sx={{
              filter: 'drop-shadow(0px 0px 24px rgba(234, 234, 234, 0.7));',
            }}
            px={['4', '6']}
            py={['4', '8']}
            rounded='md'
            bg='white'
            display={'flex'}
          >
            <chakra.span
              position={'relative'}
              w={['auto', '16']}
              mb={'auto'}
              mr={['3']} //</Box> sx={{ px: 3, py: 3, borderRadius: "50%" }} border={'1px solid'} borderColor={'brand.1'} alignSelf={'baseline'} mr={'4'}>
            >
              <chakra.div sx={{ width: '48px', height: '48px' }}>
                <Image
                  src='/images/icons/circle.png'
                  alt=''
                  position={'absolute'}
                  sx={{ top: 0, bottom: 0, left: 0, right: 0 }}
                  m={'auto'}
                />
              </chakra.div>
              <Image
                src={v.imgPath}
                alt=''
                position={'absolute'}
                sx={{
                  width: 34,
                  height: 34,
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
                m={'auto'}
              />
            </chakra.span>
            <div>
              <Text color={'#000'} fontWeight={'600'}>
                {v.heading}
              </Text>
              <Text my={['2', '4']} color={'brand.3'}>
                {v.desc}
              </Text>
              {/* <Text color={'#000'} cursor={'pointer'}>Read more <ArrowForwardIcon w={"10"} /></Text> */}
              {/* {idx !== 0 && <ReadMoreModal title={v.heading} info={v.body} />} */}
            </div>
          </Box>
        ))}
      </SimpleGrid>
      <chakra.div m={'12'} textAlign={'center'}>
        <Button w={'36'} variant={'solid'}
          onClick={() => {
            navigate('/faq')
            setTimeout(() => {
              window.scrollTo({
                top: 5,
                behavior: 'auto',
              })
            }, 20)
          }}
        >
          FAQs <ArrowForwardIcon w={'10'} />
        </Button>
      </chakra.div>
    </chakra.div>
  )
}

export default Benefits
