import {
  collection,
  collectionGroup,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { promoCode } from '../utils';
import { auth, db } from './config';
import { v4 as uuidv4 } from 'uuid';

export const getCustomClaimRole = async boolean => {
  //   await auth?.currentUser?.getIdToken(true);
  //   const decodedToken = await auth?.currentUser?.getIdTokenResult(boolean);
  //   return decodedToken?.claims?.stripeRole;
  const user = auth?.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userSubscriptionRef = collection(userRef, 'subscriptions');
  const q = query(
    userSubscriptionRef,
    where('status', 'in', ['trialing', 'active'])
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.size > 0 ? 'premium' : 'free';
};
export const getUserStripeRole = async (boolean, resetUser = () => null) => {
  const user = auth?.currentUser;
  let userRole = 'loading';
  if (user && !user?.stsTokenManager?.isExpired) {
    // new method start
    const userRef = doc(db, 'users', user.uid);
    const userSubscriptionRef = collection(userRef, 'subscriptions');
    const q = query(
      userSubscriptionRef,
      where('status', 'in', ['trialing', 'active'])
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.size > 0 ? (userRole = 'premium') : (userRole = 'free');
    // new method end

    //! old method
    // await user?.getIdToken(true);
    // const decodedToken = await user?.getIdTokenResult(boolean);
    // userRole = decodedToken?.claims?.stripeRole ?? 'free'; // consider undefined as free role
  }
  //   console.log(userRole);
  return userRole;
};

export const getPlans = async () => {
  const docRef = query(collection(db, 'products'), where('active', '==', true));
  const productsSnapshot = await getDocs(docRef);
  const plans = await Promise.all(
    productsSnapshot.docs.map(async doc => {
      const obj = { ...doc.data(), docId: doc.id };
      const priceSnap = await getDocs(collection(doc.ref, 'prices'));
      obj.prices = priceSnap.docs
        .filter(doc => doc.data().active)
        .map(doc => ({ ...doc.data(), docId: doc.id }));
      return obj;
    })
  );
  return plans;
};

const idempotencyKey = uuidv4();

export function generateDiscountText(referral) {
  let lifetimeDiscount = '$50 lifetime';
  let monthlyTotal = '$119';
  let totalSavings = '$50 per month,';

  if (referral && referral.toLocaleLowerCase() === 'tom') {
    lifetimeDiscount = '35+% lifetime';
    monthlyTotal = '$108.00!';
    totalSavings = '$61.00 per month,';
  } else if (referral && referral.toLocaleLowerCase() === 'hassan22') {
    lifetimeDiscount = '100% for 1 month';
    monthlyTotal = '$0.00 for 1st month,';
    totalSavings = 'will be either $59.15 or $61 after this';
  }

  return `Your ${lifetimeDiscount} discount has been applied, new monthly total is ${monthlyTotal} Total savings ${totalSavings}.`;
}

function getPromoCodeByReferral(name) {
  const tomsPromoCode = 'promo_1Mj6UXCV2kX2LuVwImjNeAwl';
  const zacsPromoCode = 'promo_1MjSQPCV2kX2LuVwaEP1e8cw';
  const referralsObj = {
    tom: tomsPromoCode,
    zacalexandra: zacsPromoCode,
    hassan22: 'promo_1MjGNyCV2kX2LuVwMnJdiOlZ'
  };

  // Check if the name is in the referralsObj, otherwise return the default value
  return name in referralsObj ? referralsObj[name] : promoCode;
}

export const subscribeToPlan = async (priceDocId, user) => {
  try {
    const docRef = doc(
      db,
      `users/${user.uid}/checkout_sessions`,
      Date.now().toString()
    );
    await setDoc(docRef, {
      price: priceDocId,
      allow_promotion_codes: true,
      promotion_code: user?.referral
        ? getPromoCodeByReferral(String(user?.referral).toLowerCase())
        : null,
      success_url: `${window.location.origin}/dashboard`,
      cancel_url: `${window.location.origin}/logout`,
      idempotency_key: idempotencyKey
    });

    onSnapshot(docRef, async snap => {
      const { error, url } = snap.data();
      if (error) {
        console.log(`An error occured: ${error.message}`);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  } catch (err) {
    console.log(err.message);
  }
};
