import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Spinner,
  Stack,
  Tooltip,
  Icon,
  HStack,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import WelcomePage from './components/WelcomePage'
import VideoDetails from './components/VideoDetails'
import FormDetails from './components/FormDetails'
import FinishCampaign from './components/FinishCampaign'
import { useNavigate, useParams } from 'react-router-dom'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { db, storage } from '../../firebase/config'
import { userState } from '../../recoil/atoms/auth'
import { campaignFormRenderState } from '../../recoil/atoms/campaignForm'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useCustomToast } from '../../hooks/customToast'
import { useRef } from 'react'
import { blobToBase64, cloudfunctionsBaseURL, convertToMb, getTotalCampaigns, isSafari } from '../../utils'
import { MdVideoSettings } from 'react-icons/md'
import CampaignDetails from './components/CampaignDetails'
import ReviewMessageDetails from './components/ReviewMessageDetails'
import isValidHTMLProp from '@emotion/is-prop-valid'

const ChakraStep = chakra(Step, {
  shouldForwardProp: (prop) => {
    const isValidProp = isValidHTMLProp(prop) || shouldForwardProp(prop)
    if (isValidProp) return true
    return ['isSm'].includes(prop)
  },
  baseStyle: {
    '& > div > div:last-of-type': {
      mt: ['0px', '3px'],
      '& > span': {
        p: '4px',
      },
    },
    _activeStep: {
      '& > div:first-of-type': {
        // background: props => props.isSm && 'teal.400',
        background: ['teal.400', 'transparent'],
        borderRadius: '5px',
        p: ['5px', '0px'],
      },
      '& > div > div[data-clickable]': {
        color: 'teal.500',
        boxShadow: '0px 1px 5px #00000066',
        '& span': {
          color: 'teal.500',
          fontWeight: 'bold',
        },
      },
      '& > div > div:last-of-type': {
        mt: ['0px', '3px'],
        '& > span': {
          p: '4px 6px',
          color: 'white',
          fontWeight: 'bold',
          fontStyle: 'italic',
          borderRadius: '5px',
          background: 'teal.400',
        },
      },
    },
  },
})

const CreateCampaign = () => {
  // const isSm = useBreakpointValue({
  //   base: true,
  //   sm: true,
  //   md: false
  // })

  const navigate = useNavigate()
  const { id } = useParams()
  const { addToast } = useCustomToast()
  const [user] = useRecoilState(userState)
  const campaignRenderState = useRecoilValue(campaignFormRenderState)
  const resetCampaignRender = useResetRecoilState(campaignFormRenderState)
  const initialState = {
    welcomeVideo: '',
    welcomeVideoTitle: '',
    liveWelcomeVideo: false,
    active: true,
    visits: 0,
    videoDetails: {},
    campaignDetails: {},
    reviewPageDetails: {},
    formDetails: {},
  }
  const [state, setState] = useState(initialState)
  const [business, setBusiness] = useState(null)
  const [campaignFilesURL, setCampaignFilesURL] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newCampaignId, setNewCampaignId] = useState('')
  const collRef = collection(db, 'campaigns')
  const newDoc = doc(collRef)

  const submitStepRef1 = useRef(null)
  const submitStepRef2 = useRef(null)
  const submitStepRef3 = useRef(null)
  const submitStepRef4 = useRef(null)

  const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
    initialStep: id ? 2 : 0,
  })

  const [dataLoading, setDataLoading] = useState(id ? true : false)

  const steps = [
    { label: 'Welcome Video', value: 0 },
    { label: 'Video Details', value: 1 },
    { label: 'Campaign Details', value: 2 },
    { label: 'Review Message Details', value: 3 },
    { label: 'Form Details', value: 4 },
  ]

  const getData = (data) => {
    setState({ ...state, ...data })
  }

  const uploadToFirestore = async (values, { campaignLogo, welcomeVideo }, isUploaded = true) => {
    try {
      let finalData = {
        ...values,
        isExpressSetup: false,
        welcomeVideo,
        campaignDetails: { ...values.campaignDetails, campaignLogo },
      }

      if (!values.id) {
        getTotalCampaigns.then(async (totalCampaigns) => {
          await setDoc(newDoc, {
            ...finalData,
            docNo: totalCampaigns + 1,
            createdAt: serverTimestamp(),
            userId: user.uid,
          })
          setNewCampaignId(newDoc.id)
          addToast({
            title: 'Campaign!',
            description: 'New campaign added',
            status: 'success',
            variant: 'left-accent',
          })
          setIsSubmitting(false)
        })
      } else {
        const { userId, ...rest } = finalData
        const docRef = doc(db, 'campaigns', id)
        await updateDoc(docRef, rest)
        addToast({
          title: 'Campaign!',
          description: 'Updated successfully',
          status: 'success',
          variant: 'left-accent',
        })
        setIsSubmitting(false)
        navigate('/dashboard/my-campaigns')
      }
      if (!isUploaded) {
        // const result = await fetch(`http://127.0.0.1:5001/sendmea-c7d45/us-central1/mediaUploadToCloudinary`, {
        await fetch(`${cloudfunctionsBaseURL}mediaUploadToCloudinary`, {
          method: 'POST',
          body: JSON.stringify({
            file: welcomeVideo,
            resource_type: 'video',
            raw_convert: 'google_speech:srt:vtt:en-US',
            filename: state?.id || newDoc.id,
            folder: `sendmea/campaign_videos/${user.uid}`,
            isSafari: isSafari(),
            storagePath: `campaign_videos/${user.uid}/${state?.id || newDoc.id}`,
            isUploaded: !isUploaded,
          }),
        })
      }
    } catch (err) {
      setIsSubmitting(false)
      addToast({
        title: 'Error!',
        description: err.message,
        status: 'error',
        variant: 'left-accent',
      })
    }
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    let {
      welcomeVideo,
      campaignDetails: { campaignLogo },
    } = state
    try {
      let files = { campaignLogo, welcomeVideo }
      // console.log({ state })
      await Promise.all(
        Object.keys(files).map(async (key) => {
          let result = null
          if (typeof files[key] === 'object') {
            const base64Video = await blobToBase64(files[key])
            if (convertToMb(files[key]) < 20) {
              const checkLocation = key === 'welcomeVideo' ? 'campaign_videos' : 'campaign_pics'
              // result = await fetch(`http://127.0.0.1:5001/sendmea-c7d45/us-central1/mediaUploadToCloudinary`, {
              result = await fetch(`${cloudfunctionsBaseURL}mediaUploadToCloudinary`, {
                method: 'POST',
                body: JSON.stringify({
                  file: base64Video,
                  resource_type: key === 'welcomeVideo' ? 'video' : 'image',
                  filename: state?.id || newDoc.id,
                  folder: `sendmea/${checkLocation}/${user.uid}`,
                  isSafari: isSafari(),
                }),
              })
              result = await result.json()
            }
            else {
              result = await uploadFile(files[key], key === 'welcomeVideo')
            }
            return Object.assign(files, { [key]: result?.data?.secure_url || result })
          } else return Object.assign(files, { [key]: files[key] })
        })
      )

      uploadToFirestore(state, files, convertToMb(files.welcomeVideo) < 20)
      setCampaignFilesURL({})
    } catch (error) {
      addToast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setIsSubmitting(false)
    }
    setIsDone(false)
  }

  const uploadFile = (file, recorded = false, path = false) =>
    new Promise((resolve, reject) => {
      if (!file) return
      const storageRef = ref(
        storage,
        path
          ? path
          : `${recorded ? 'campaign_videos' : 'campaign_pics'}/${user.uid}/${state?.id || newDoc.id}`
      )
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on(
        'state_changed',
        // don't want progress
        () => null,
        (error) => {
          addToast({
            title: 'Error',
            description: error.message,
            status: 'error',
            variant: 'left-accent',
          })
          reject(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            addToast({
              title: recorded ? 'Video!' : 'Logo!',
              description: recorded ? 'Campaign Video Uploaded' : 'Campaign Logo Uploaded',
              status: 'success',
              variant: 'left-accent',
            })
            resolve(downloadURL)
          })
        }
      )
    })

  useEffect(() => {
    if (activeStep === steps.length && isDone) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, isDone])

  const getCampaignById = async () => {
    const docRef = doc(db, 'campaigns', id)
    try {
      let campaignDoc = await getDoc(docRef)
      if (campaignDoc.exists()) {
        const {
          welcomeVideo,
          campaignDetails: { campaignLogo },
        } = campaignDoc.data()
        let campaignData = { ...campaignDoc.data(), id: campaignDoc.id }
        setState(campaignData)
        setCampaignFilesURL({ welcomeVideo, campaignLogo })
      } else {
        addToast({
          title: 'Error',
          description: 'campaign not found',
          status: 'error',
        })
      }
      setDataLoading(false)
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setDataLoading(false)
    }
  }

  useEffect(() => {
    id && getCampaignById()
    return () => resetCampaignRender()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    ; (async () => {
      const collRef = collection(db, 'businesses')
      const q = query(collRef, where('userId', '==', user.uid))
      let businessDoc = await getDocs(q)
      if (!businessDoc.empty) {
        let data = []
        businessDoc.forEach((obj) => data.push({ ...obj.data(), id: obj.id }))
        setBusiness(data[0])
        // console.log(data[0])
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id])

  const handleChangeSteps = (stepNo) => {
    const toastId = 'fill-form'

    if (stepNo < activeStep) setStep(stepNo)
    else if (activeStep === 0 && stepNo > activeStep) {
      setStep(1)
    } else if (activeStep === 1 && stepNo > activeStep) {
      submitStepRef1.current.click()
    } else if (activeStep <= 2 && stepNo > activeStep) {
      if (campaignRenderState.campaignDetails) submitStepRef2.current.click()
      else {
        addToast({
          id: toastId,
          title: 'Campaign Details',
          description: 'Please fill step 3 first',
          status: 'warning',
          variant: 'left-accent',
        })
      }
    } else if (activeStep <= 3 && stepNo > activeStep) {
      if (campaignRenderState.reviewPageDetails) submitStepRef3.current.click()
      else {
        addToast({
          id: toastId,
          title: 'Visual Design',
          description: 'Please fill step 4 first',
          status: 'warning',
          variant: 'left-accent',
        })
      }
    } else setStep(stepNo)
  }

  return (
    <Container maxW={['container.lg']} px={{ base: 0, sm: 2 }}>
      <Stack
        mt={5}
        bg={'gray.50'}
        rounded={'xl'}
        py={{ base: 4, sm: 6 }}
        px={{ base: 2, sm: 4, md: 6 }}
        width='full'
        spacing={8}
      >
        {id ? (
          <>
            <HStack alignItems={'center'}>
              <Heading
                color={'brand.1'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: '2xl' }}
                fontWeight={'bold'}
              >
                Edit Campaign{' '}
                (<chakra.span color={'brand.2'}>
                  {state?.campaignDetails?.publicCampaignName || '...'}
                </chakra.span>)
              </Heading>
              <Tooltip
                placement='right'
                bg='teal.400'
                fontSize='1rem'
                rounded='md'
                hasArrow
                closeOnPointerDown
                label="Need to change your welcome video, click on Step 1, 'Welcome Video'!"
              >
                <chakra.span pt='2'>
                  <Icon as={MdVideoSettings} w={7} h={7} color='teal.400' />
                </chakra.span>
              </Tooltip>
            </HStack>
          </>
        ) : (
          <>
            <Heading
              color={'brand.1'}
              lineHeight={1.1}
              fontSize={{ base: 'xl', sm: '2xl' }}
              fontWeight={'bold'}
            >
              Create Campaign
            </Heading>
          </>
        )}

        <Box>
          <Steps
            sx={{
              flexWrap: 'wrap',
              rowGap: '16px',
              columnGap: '12px',
            }}
            activeStep={activeStep}
            size={{ base: 'sm' }}
            orientation={'horizontal'}
            labelOrientation='vertical'
            colorScheme='teal'
            {...((!newCampaignId || activeStep !== steps.length) &&
              !isSubmitting && {
              onClickStep: (e) => {
                handleChangeSteps(e)
              },
            })}
          >
            {steps.map(({ label, value }) => (
              <ChakraStep
                label={label}
                key={label}
                cursor='ew-resize'
                aria-current={value === activeStep && 'step'}
              >
                {value === 0 ? (
                  <WelcomePage
                    step={{ label, value }}
                    stepMethods={{ nextStep, prevStep, reset, activeStep }}
                    getData={getData}
                    state={state}
                    setState={setState}
                  />
                ) : value === 1 ? (
                  <VideoDetails
                    step={{ label, value }}
                    stepMethods={{ nextStep, prevStep, reset, activeStep }}
                    business={business}
                    getData={getData}
                    state={state}
                    setState={setState}
                    submitStepRef1={submitStepRef1}
                  />
                ) : value === 2 ? (
                  <CampaignDetails
                    step={{ label, value }}
                    stepMethods={{ nextStep, prevStep, reset, activeStep }}
                    getData={getData}
                    state={state}
                    setState={setState}
                    dataLoading={dataLoading}
                    submitStepRef2={submitStepRef2}
                  />
                ) : value === 3 ? (
                  <ReviewMessageDetails
                    step={{ label, value }}
                    stepMethods={{ nextStep, prevStep, reset, activeStep }}
                    getData={getData}
                    state={state}
                    setState={setState}
                    submitStepRef3={submitStepRef3}
                  />
                ) : value === 4 ? (
                  <FormDetails
                    step={{ label, value }}
                    stepMethods={{ nextStep, prevStep, reset, activeStep }}
                    getData={getData}
                    state={state}
                    setState={setState}
                    setIsDone={setIsDone}
                    isSubmitting={isSubmitting}
                    submitStepRef4={submitStepRef4}
                  />
                ) : null}
              </ChakraStep>
            ))}
          </Steps>
          {activeStep === steps.length ? (
            isSubmitting ? (
              <Stack justifyContent='center' alignItems='center' width='full' minHeight='380px'>
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='teal.500'
                  size={['md', 'xl']}
                />
              </Stack>
            ) : (
              <Flex px={4} py={4} width='100%' flexDirection='column'>
                {!id && (
                  <FinishCampaign
                    isSubmitting={isSubmitting}
                    newCampaignId={newCampaignId}
                    user={user}
                  />
                )}
                <Button
                  mx='auto'
                  mt={6}
                  variant='solid'
                  size={['sm', 'md']}
                  onClick={(_) => navigate('/dashboard/my-campaigns')}
                >
                  See My Campaigns
                </Button>
              </Flex>
            )
          ) : null}
        </Box>
      </Stack>
    </Container>
  )
}

export default CreateCampaign
