import { Tab, TabList, TabPanel, TabPanels, Tabs, Container } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BusinessDetails from './components/BusinessDetails'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useRecoilState } from 'recoil'
import { userState } from '../../recoil/atoms/auth'
import SocialDetails from './components/SocialDetails'

const Business = () => {

    // const navigate = useNavigate()
    const [user] = useRecoilState(userState)
    const [fetchBusiness, setFetchBusiness] = useState(false)
    const [isEditable, setIsEditAble] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        (async () => {
            const collRef = collection(db, 'businesses')
            const q = query(collRef, where("userId", "==", user.uid))
            let businessDoc = await getDocs(q)
            if (!businessDoc.empty) {
                let data = []
                businessDoc.forEach(obj => data.push({ ...obj.data(), id: obj.id }))
                setIsEditAble(false)
                setFetchBusiness(data[0])
                // console.log(data[0])
            }
            else {
                setIsEditAble(true)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id])

    return (
        <Container
            maxW={["container.lg"]}
            px={{ base: 0, sm: 2 }}
            mb={['5%', 10]}
            mt={['2']}
        >
            <Tabs onChange={(index) => setTabIndex(index)}>
                <TabList gap="4px" justifyContent={["center", "center", "flex-start"]} mx={["auto", "auto", 0]}
                    sx={{
                        width: "max-content",
                        border: "2px solid",
                        borderRadius: "3px",
                    }}
                    borderColor={theme => theme.colors.teal["500"] + " !important"}
                >
                    <Tab
                        fontWeight="medium"
                        borderRadius="0px 2px 2px 0px"
                        mb={0}
                        _selected={{ color: 'white', bg: 'teal.500' }}>
                        Business Details
                    </Tab>
                    <Tab
                        fontWeight="medium"
                        borderRadius="2px 0 0 2px"
                        mb={0}
                        _selected={{ color: 'white', bg: 'teal.500' }}>
                        Social Media
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel p={[0, 2]}>
                        <BusinessDetails
                            isEditable={isEditable}
                            setIsEditAble={setIsEditAble}
                            fetchBusiness={fetchBusiness || {}}
                            setFetchBusiness={setFetchBusiness}
                            tabIndex={tabIndex}
                            user={user}
                        />
                    </TabPanel>
                    <TabPanel p={[0, 2]}>
                        <SocialDetails
                            isEditable={isEditable}
                            setIsEditAble={setIsEditAble}
                            setFetchBusiness={setFetchBusiness}
                            fetchBusiness={fetchBusiness || {}}
                            tabIndex={tabIndex}
                            user={user}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    )
}

export default Business