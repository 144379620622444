import { AspectRatio, Box, Button, chakra, Container, Divider, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'

function Step2({ setStep, useForm: { watch, setValue }, setUploadedVideo }) {

    const takeAgain = () => {
        setValue("testimonial", "")
        setValue("duration", "")
        setStep(prev => prev - 1)
        window.scrollTo({
            top: 30,
            behavior: "smooth"
        });
    }

    return (
        <chakra.div >
            <Container py={'10'} minH={'60vh'}>

                <Divider />
                <Box
                    direction={{ base: 'column', sm: 'row' }}
                    pt={'5'}
                    pb={'4'}
                    px={'4'}
                    borderRadius={'8px'}
                    sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;" }}
                >

                    <Text color={'brand.3'} fontWeight={'bold'} fontSize={'small'}>2/3 step</Text>
                    <Text color={'brand.1'} fontWeight={'bold'} fontSize={'xx-large'}>
                        Watch the video you uploaded
                    </Text>
                    <Container maxW={'500px'} my={'4'}>

                        <AspectRatio ratio={{ base: 6 / 9, sm: 6 / 4 }}>
                            <Image
                                borderRadius={'8px'}
                                objectFit='cover'
                                w={'100%'}
                                as={'video'}
                                controls
                                alt=''
                                preload='metadata'
                                // sx={{
                                //     transform: "scaleX(-1)",
                                //     WebkitTransform: 'scaleX(-1)'
                                // }}
                                // poster={'https://cdn.dribbble.com/users/975591/screenshots/11442961/3.png?compress=1&resize=400x300'}
                                // src={typeof watch("testimonial") === "object" ? URL.createObjectURL(watch("testimonial")) : watch("testimonial") + '#t=0.2'}
                                onClick={() => console.log('testimonial', watch('testimonial'))}
                            >
                                <source src={typeof watch("testimonial") === "object" ? URL.createObjectURL(watch("testimonial")) : watch("testimonial") + '#t=0.2'} type='video/mp4' />
                                Video can take up to 5 minutes to load after uploading.
                            </Image>
                        </AspectRatio>
                        {/* <AspectRatio ratio={{ base: 6 / 9, sm: 6 / 4 }}>
                
                        </AspectRatio> */}

                    </Container>
                </Box>
                <Box my={'4'}
                    sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;" }}
                    p={'4'}
                    bg={'teal.50'}
                    borderRadius={'8px'}
                    onClick={() => console.log('testimonial', watch('testimonial'))}
                >
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Button type='button' variant={'outline'} borderRadius={'10'} minW={'40%'} onClick={takeAgain}>Try again</Button>

                        <Button type='button' variant={'solid'} borderRadius={'10'} minW={'40%'} onClick={() => {
                            setUploadedVideo(false)
                        }}>Send</Button>
                    </Stack>
                </Box>
            </Container >
        </chakra.div >
    )
}

export default Step2