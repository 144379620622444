import React from "react"
import  {Hero } from './components/SalesPageHero'
import Pricing from "./components/Pricing";
import GetStarted from "../Landing/components/GetStarted";
import { SignUp } from "./components/SignUpSection";
import SalesVideoSection from "./components/SalesVideoSection";
import VideoCarousel from "./components/VideoCarousel";

const GetStartedPage = (props) => {
  return (
    <div>
      <Hero />
      <SalesVideoSection />
      <SignUp />
      <Pricing />
      <VideoCarousel />
      <SignUp />
    </div>
  )
};

export default GetStartedPage;
