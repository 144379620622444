import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Heading,
  Stack,
  chakra,
  Flex,
  Text,
  theme,
  VStack,
  FormControl,
  Input,
  Button,
  Image,
  HStack,
  FormErrorMessage,
  Fade,
  Textarea,
  useBreakpointValue,
  Icon,
  Spinner,
  Select,
  AspectRatio,
  FormLabel,
  Checkbox,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { campaignFormRenderState } from '../../../recoil/atoms/campaignForm'
// import { secToMin } from '../../../utils'
import { BsCamera } from 'react-icons/bs'
import { InformationModal } from '../../../components/InformationModal'
import { titleData, descriptionData, logoData, urlData } from './modalData'
import { BsPatchQuestionFill } from 'react-icons/bs'
import { useNavigate, useLocation } from 'react-router-dom'
import { IndustryDropDown } from './IndustryDropDown'
import { WallPreviewModal } from './WallPreviewModal'

const Circle = ({ no }) => (
  <chakra.div
    minWidth={'26px'}
    minHeight={'25px'}
    bg='brand.1'
    borderRadius='full'
    color='white'
    textAlign='center'
    display={'flex'}
    justifyContent='center'
    alignItems={'center'}
    mr='4px'
    fontSize={['sm', 'md']}
  >
    {no}
  </chakra.div>
)

const CampaignDetails = ({ stepMethods, getData, state, submitStepRef2, dataLoading }) => {
  let { nextStep, prevStep, activeStep } = stepMethods
  let { campaignDetails } = state
  const navigate = useNavigate()
  const location = useLocation()

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  })

  // const [showTooltip, setShowTooltip] = React.useState(false)

  const initialRenders = {
    language: true,
    publicCampaignName: true,
    campaignDescription: !!(
      campaignDetails.campaignDescription || campaignDetails.publicCampaignName
    ),
    industry: !!(campaignDetails.industry || campaignDetails.campaignDescription),
    wallTheme: !!(campaignDetails.wallTheme || campaignDetails.industry),
    campaignLogo: !!(campaignDetails.campaignLogo || campaignDetails.industry),
    reviewWallUrl: !!(campaignDetails.reviewWallUrl || campaignDetails.publicCampaignName),
    welcomeVideo: campaignDetails?.welcomeVideo ? !!campaignDetails.welcomeVideoTitle : true,
  }

  const [skipCampaignLogo, setSkipCampaignLogo] = useState(false)
  const [shouldRenders, setShouldRenders] = useState(initialRenders)
  const setCampaignRender = useSetRecoilState(campaignFormRenderState)

  const uploadInputRef = useRef(null)
  const inputsDivRef = useRef(null)

  const scrollToLastChild = (index) => {
    inputsDivRef.current?.children[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      language: 'English',
      internalCampaignName: '',
      publicCampaignName: '',
      campaignDescription: '',
      industry: '',
      wallTheme: '',
      campaignLogo: '',
      reviewWallUrl: '',
      ...state?.campaignDetails,
    },
  })

  const onSubmit = async (data) => {
    const { campaignLogo, ...rest } = data

    getData({
      campaignDetails: {
        ...rest,
        internalCampaignName: data.publicCampaignName,
        campaignLogo: skipCampaignLogo ? '' : campaignLogo,
      },
    })
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    nextStep()
  }

  const handleImage = (e) => {
    const fileObj = e.target.files[0]
    if (fileObj && !fileObj?.type?.includes('image'))
      return setError(
        `campaignLogo`,
        { type: 'required', message: 'only Image accepts' },
        { shouldFocus: true }
      )
    else if (fileObj) {
      setValue('campaignLogo', fileObj)
      setTimeout(() => {
        !shouldRenders.wallTheme && setShouldRenders({ ...shouldRenders, wallTheme: true })
        scrollToLastChild(shouldRenders.wallTheme ? 4 : 5)
      }, 150)
      clearErrors('campaignLogo')
    }
  }

  useEffect(() => {
    if (!Object.keys(shouldRenders).some((key) => !shouldRenders[key])) {
      setCampaignRender((prev) => ({ ...prev, campaignDetails: true }))
    } else {
      setCampaignRender((prev) => ({ ...prev, campaignDetails: false }))
    }
    console.log(campaignDetails)
  }, [setCampaignRender, shouldRenders])

  useEffect(() => {
    if (activeStep === 2 && state.id) {
      reset({
        language: 'English',
        internalCampaignName: '',
        publicCampaignName: '',
        campaignDescription: '',
        industry: '',
        wallTheme: '',
        campaignLogo: '',
        reviewWallUrl: '',
        welcomeVideo: '',
        ...campaignDetails,
      })
      setShouldRenders({
        ...shouldRenders,
        campaignDescription: !!campaignDetails.campaignDescription,
        industry: !!campaignDetails.industry || !!campaignDetails.campaignDescription,
        wallTheme: !!campaignDetails?.wallTheme || !!campaignDetails?.industry,
        campaignLogo: !!campaignDetails.campaignLogo || !!campaignDetails.industry,
        reviewWallUrl: !!campaignDetails.reviewWallUrl || !!campaignDetails.campaignDescription,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, activeStep, reset])

  // const [charCount, setCharCount] = useState(0)

  // const handleInput = e => {
  //     setCharCount(e.target.value.length)
  //     if (e.target.value.length > 150) {
  //         setError('campaignDescription', { type: "maxLength", message: "Max length reached" })
  //     } else {
  //         setError('campaignDescription', { type: "maxLength", message: "" })
  //     }
  // }

  // console.log({ shouldRenders, campaignDetails })

  return (
    <Stack width='full' spacing={4} my={[6, '8']}>
      <Heading
        color={'brand.1'}
        lineHeight={1.1}
        fontSize={{ base: 'lg', sm: 'xl', md: '3xl' }}
        textAlign='center'
        width={['full', '90%', '80%']}
        mx='auto'
        my={6}
      >
        Customize the visual display of your campaign
      </Heading>
      {dataLoading ? (
        <Stack justifyContent='center' alignItems='center' width='full' minHeight='380px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='teal.500'
            size={['md', 'xl']}
          />
        </Stack>
      ) : location.pathname.includes('edit-campaign') &&
        Object.keys(state.campaignDetails).length === 0 ? (
        <Stack alignItems='center'>
          <Heading
            color={'gray.500'}
            lineHeight={1.1}
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            textAlign='center'
            width={['full', '90%', '80%']}
            mx='auto'
            mb={6}
          >
            Nothing found or Id is incorrect
          </Heading>
          <Button
            size='md'
            variant='solid'
            minWidth='70px'
            onClick={(_) => navigate('/dashboard/my-campaigns')}
          >
            Go back to campaigns
          </Button>
        </Stack>
      ) : (
        <Stack
          spacing={10}
          as='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            '& .chakra-text': {
              textAlign: 'left',
            },
          }}
          ref={inputsDivRef}
        >
          <VStack spacing={2} alignItems='stretch' maxWidth={'700px'}>
            <Flex direction={'row'} alignItems={['center']}>
              <Circle no={1} />
              <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                Language Preference
              </Text>
            </Flex>
            <Box
              ml={'12px !important'}
              pl='24px'
              borderLeft={`2px solid ${theme.colors.gray['200']}`}
              pb='4'
            >
              <Text pb='16px' textAlign='left'>
                Choose which language you want to use on your video wall page
              </Text>
              <FormControl isInvalid={!!errors?.language}>
                <Select
                  width='150px'
                  size={['sm', 'md', 'lg']}
                  bg={'gray.100'}
                  // color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  {...register('language', {
                    required: 'This is required',
                  })}
                >
                  <option value='English'>English</option>
                  <option value='Spanish'>Spanish</option>
                </Select>
                {errors.language && <FormErrorMessage>{errors.language.message}</FormErrorMessage>}
              </FormControl>
            </Box>
          </VStack>

          <VStack
            spacing={3}
            alignItems='stretch'
            maxWidth={'700px'}
          // display={!shouldRenders.publicCampaignName ? 'none' : 'flex'}
          >
            <Flex direction={'row'} alignItems={['center']}>
              <Circle no={2} />
              <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                Enter a public review campaign name
              </Text>
              <InformationModal
                icon={<BsPatchQuestionFill />}
                iconColor='teal.300'
                iconSize='1.7rem'
                modalData={titleData}
              />
            </Flex>
            <Box
              ml={'12px !important'}
              pl='24px'
              borderLeft={`2px solid ${theme.colors.gray['200']}`}
              pb='4'
              width='full'
              pr={2}
            >
              <Text pb='16px' textAlign='left'>
                Displayed to users who submit review for your campaign
              </Text>
              <FormControl isInvalid={!!errors?.publicCampaignName}>
                <Input
                  size={['sm', 'md', 'lg']}
                  placeholder='e.g My business, any website name etc'
                  bg={'gray.100'}
                  border={0}
                  // color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  {...register('publicCampaignName', {
                    required: 'This is required',
                  })}
                  onFocus={() => {
                    setShouldRenders((prev) => ({
                      ...prev,
                      campaignDescription: true,
                    }))
                    scrollToLastChild(shouldRenders.campaignDescription ? 1 : 2)
                  }}
                />
                {errors?.publicCampaignName && (
                  <FormErrorMessage>{errors?.publicCampaignName.message}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </VStack>

          <Fade in={shouldRenders.campaignDescription}>
            <VStack
              spacing={2}
              alignItems='stretch'
              maxWidth={'700px'}
              display={!shouldRenders.campaignDescription ? 'none' : 'flex'}
            >
              <Flex direction={'row'} alignItems={['center']}>
                <Circle no={3} />
                <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                  Enter a campaign description
                </Text>
                <InformationModal
                  icon={<BsPatchQuestionFill />}
                  iconColor='teal.300'
                  iconSize='1.7rem'
                  modalData={descriptionData}
                />
              </Flex>
              <Box
                ml={'12px !important'}
                pl='24px'
                borderLeft={`2px solid ${theme.colors.gray['200']}`}
                pb='4'
                width='full'
                pr={2}
              >
                <Text pb='16px' textAlign='left'>
                  Provide a brief description about your brand or busines (SEO benefit)!
                </Text>
                <FormControl isInvalid={!!errors?.campaignDescription} size={['sm', 'md', 'lg']}>
                  <Textarea
                    placeholder='This is the campaign description...'
                    bg={'gray.100'}
                    border={0}
                    // color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    // minH="75px"
                    rows={3}
                    resize='none'
                    {...register('campaignDescription', {
                      required: 'This is required',
                      maxLength: 100,
                    })}
                    onFocus={() => {
                      setShouldRenders((prev) => ({ ...prev, industry: true }))
                      scrollToLastChild(shouldRenders.industry ? 2 : 3)
                    }}
                  // onInput={handleInput}
                  />
                  <Text
                    mt={2}
                    color={watch('campaignDescription')?.length > 100 ? 'red.500' : 'gray.600'}
                    fontSize={['sm']}
                  >
                    {100 - watch('campaignDescription')?.length} remaining
                  </Text>
                  {errors?.campaignDescription && (
                    <FormErrorMessage>{errors?.campaignDescription.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </VStack>
          </Fade>

          <IndustryDropDown
            register={register}
            setShouldRenders={setShouldRenders}
            errors={errors}
            scrollToLastChild={scrollToLastChild}
            shouldRenders={shouldRenders}
          />

          <Fade in={shouldRenders.campaignLogo}>
            <VStack
              spacing={2}
              alignItems='stretch'
              maxWidth={'700px'}
              display={!shouldRenders.campaignLogo ? 'none' : 'flex'}
            >
              <Flex direction={'row'} alignItems={['center']}>
                <Circle no={5} />
                <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                  A logo or picture that represents your campaign
                </Text>
                <InformationModal
                  icon={<BsPatchQuestionFill />}
                  iconColor='teal.300'
                  iconSize='1.7rem'
                  modalData={logoData}
                />
              </Flex>
              <Box
                ml={'12px !important'}
                pl='24px'
                borderLeft={`2px solid ${theme.colors.gray['200']}`}
                pb='4'
              >
                <Text pb='16px' textAlign='left'>
                  This will be the public image or logo, recommended 1080 x 1080.
                </Text>
                <Flex
                  flexWrap='wrap'
                  gap='10px'
                  alignItems='center'
                  minH={['max-content', 'max-content', '75px']}
                >
                  <FormControl
                    maxWidth='540px'
                    isInvalid={!!errors?.campaignLogo}
                    width='full'
                    pos='relative'
                  >
                    <HStack
                      alignItems='center'
                      px={['2']}
                      py={['2', '3']}
                      bg={'gray.200'}
                      borderRadius='4px'
                      maxH='45px'
                      whiteSpace='pre'
                      overflow='hidden'
                      position={['absolute', 'absolute', 'relative']}
                      width='full'
                    >
                      <Button
                        leftIcon={
                          <Icon
                            as={BsCamera}
                            fontSize={['18px', '18px', '22px']}
                            mr={['-2', '-2', '0']}
                          />
                        }
                        size={['sm']}
                        variant='solid'
                        minW={['32px', '32px', '90px']}
                        rounded={['full', 'full', 'md']}
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                        disabled={skipCampaignLogo}
                      >
                        {isMobile ? '' : 'Upload'}
                      </Button>
                      <Text
                        fontSize={['sm', 'md']}
                        pr={20}
                        overflow='hidden'
                        whiteSpace='pre'
                        userSelect='none'
                        color={skipCampaignLogo ? 'gray.600' : 'gray.700'}
                        fontStyle='italic'
                      >
                        {!skipCampaignLogo
                          ? watch('campaignLogo')
                            ? typeof watch('campaignLogo') === 'object'
                              ? watch('campaignLogo')?.name
                              : watch('campaignLogo')
                            : 'Click to upload logo'
                          : 'Logo skipped for campaign'}
                      </Text>
                    </HStack>
                    <input
                      type='file'
                      accept='image/*'
                      style={{ display: 'none' }}
                      {...register('campaignLogo', {
                        required: !skipCampaignLogo && 'This is required',
                      })}
                      ref={uploadInputRef}
                      onChange={handleImage}
                    />
                    {errors.campaignLogo && (
                      <FormErrorMessage>{errors.campaignLogo.message}</FormErrorMessage>
                    )}
                  </FormControl>

                  {watch('campaignLogo') && !skipCampaignLogo && (
                    <Image
                      mt={['50px', '50px', '0px']}
                      objectFit='contain'
                      src={
                        typeof watch('campaignLogo') === 'object'
                          ? URL.createObjectURL(watch('campaignLogo'))
                          : watch('campaignLogo')
                      }
                      alt='Dan Abrams'
                      width={['80px', '100px']}
                      height={['80px', '80px']}
                    />
                  )}
                </Flex>
                <Button
                  mt={[2, 2, 1]}
                  minWidth={'140px'}
                  size={['xs', 'sm']}
                  variant='outline'
                  display='flex'
                  onClick={() => {
                    setSkipCampaignLogo((prev) => !prev)
                    if (!skipCampaignLogo) {
                      setShouldRenders((prev) => ({
                        ...prev,
                        wallTheme: true,
                      }))
                      clearErrors('campaignLogo')
                      scrollToLastChild(shouldRenders.wallTheme ? 4 : 5)
                    }
                  }}
                >
                  {skipCampaignLogo ? 'Unskip' : 'Skip'} logo
                </Button>
              </Box>
            </VStack>
          </Fade>

          <Fade in={shouldRenders.wallTheme}>
            <VStack
              spacing={2}
              alignItems='stretch'
              maxWidth={'700px'}
              display={!shouldRenders.wallTheme ? 'none' : 'flex'}
            >
              <Flex direction={'row'} alignItems={['center']}>
                <Circle no={6} />
                <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                  Select a wall theme
                </Text>
                {/* <InformationModal
                  icon={<BsPatchQuestionFill />}
                  iconColor='teal.300'
                  iconSize='1.7rem'
                  modalData={descriptionData}
                /> */}
              </Flex>
              <Box
                ml={'12px !important'}
                pl='24px'
                borderLeft={`2px solid ${theme.colors.gray['200']}`}
                pb='4'
                width='full'
                pr={2}
              >
                <Flex
                  flexWrap='wrap'
                  gap='10px'
                  alignItems='center'
                  minH={['max-content', 'max-content', '75px']}
                >
                  <Select
                    maxWidth='540px'
                    width='full'
                    {...register('wallTheme')}
                    onFocus={() => {
                      setShouldRenders((prev) => ({
                        ...prev,
                        reviewWallUrl: true,
                      }))
                      scrollToLastChild(shouldRenders.reviewWallUrl ? 5 : 6)
                    }}
                  // placeholder='Select'
                  >
                    <option value=''>Lively Light (default)</option>
                    <option value='forest_dark'>Forest (Dark)</option>
                    <option value='medical'>Medical</option>
                  </Select>
                  <WallPreviewModal
                    values={{
                      ...watch(),
                      publicCampaignName: watch('publicCampaignName') || 'Campaign Name',
                      campaignDescription: watch('campaignDescription') || 'This will be the brief campaign description',
                      reviewWallUrl: watch('reviewWallUrl') || 'www.your-website.com',
                      campaignLogo: typeof watch('campaignLogo') === 'object'
                        ? URL.createObjectURL(watch('campaignLogo'))
                        : watch('campaignLogo')
                    }}
                  />
                </Flex>
              </Box>
              {errors?.wallTheme && (
                <FormErrorMessage>{errors?.wallTheme.message}</FormErrorMessage>
              )}
            </VStack>
          </Fade>


          <Fade in={shouldRenders.reviewWallUrl || watch('campaignLogo')}>
            <VStack
              spacing={2}
              alignItems='stretch'
              maxWidth={'700px'}
              display={!(shouldRenders.reviewWallUrl || watch('campaignLogo')) ? 'none' : 'flex'}
            >
              <Flex direction={'row'} alignItems={['center']}>
                <Circle no={7} />
                <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                  Provide your URL that will be shown on your review wall
                </Text>
                <InformationModal
                  icon={<BsPatchQuestionFill />}
                  iconColor='teal.300'
                  iconSize='1.7rem'
                  modalData={urlData}
                />
              </Flex>
              <Box
                ml={'12px !important'}
                pl='24px'
                borderLeft={`2px solid ${theme.colors.gray['200']}`}
                pb='4'
              >
                <Text pb='16px' textAlign='left'>
                  (i.e. website, social media, etc)
                </Text>
                <FormControl isInvalid={!!errors?.reviewWallUrl}>
                  <Input
                    size={['sm', 'md', 'lg']}
                    placeholder='e.g. https://sendmea.io'
                    bg={'gray.100'}
                    border={0}
                    // color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    {...register('reviewWallUrl', {
                      required: 'Please! provide a campaign Url link',
                      pattern: {
                        // eslint-disable-next-line no-useless-escape
                        value: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                        message: 'Invalid URL',
                      },
                    })}
                    onFocus={(_) => {
                      setShouldRenders((prev) => ({ ...prev, welcomeVideo: true }))
                      setTimeout(() => {
                        scrollToLastChild(7)
                      }, 150)
                    }}
                  />
                  {errors.reviewWallUrl && (
                    <FormErrorMessage>{errors.reviewWallUrl.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </VStack>
          </Fade>

          {state.welcomeVideo && <Fade in={shouldRenders.welcomeVideo || watch('reviewWallUrl')}>
            <VStack
              spacing={2}
              alignItems='stretch'
              maxWidth={'700px'}
              display={!(shouldRenders.welcomeVideo || watch('reviewWallUrl')) ? 'none' : 'flex'}
            >
              <Flex direction={'row'} alignItems={['center']}>
                <Circle no={8} />
                <Text pl='8px' fontSize={['lg', 'xl']} color='black' fontWeight='medium'>
                  Welcome Video
                </Text>
                <InformationModal
                  icon={<BsPatchQuestionFill />}
                  iconColor='teal.300'
                  iconSize='1.7rem'
                  modalData={urlData}
                />
              </Flex>
              <Box
                ml={'12px !important'}
                pl='24px'
                borderLeft={`2px solid ${theme.colors.gray['200']}`}
                pb='4'
              >
                <Text pb='16px' textAlign='left'>
                  (i.e. video message to the reviewer.)
                </Text>
                <FormControl isInvalid={!!errors?.reviewWallUrl} w={['100%', null, '50%']}>
                  <Input
                    mb={2}
                    size={['sm', 'md', 'lg']}
                    placeholder='Welcome video title'
                    bg={'gray.100'}
                    border={0}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    value={state.welcomeVideoTitle}
                    onChange={e => getData({ 'welcomeVideoTitle': e.target.value })}
                  />
                  {errors.welcomeVideo && (
                    <FormErrorMessage>{errors.welcomeVideo.message}</FormErrorMessage>
                  )}
                </FormControl>
                <HStack alignItems="baseline">
                  <AspectRatio ratio={{ base: 6 / 7.1, sm: 6 / 4 }} bg={'blackAlpha.300'} borderRadius={'8px'} w={['100%', null, '50%']}>
                    <chakra.video src={typeof (state.welcomeVideo) === 'object' ? URL.createObjectURL(state.welcomeVideo) : state.welcomeVideo}
                      borderRadius={'8px'}
                      objectFit='cover'
                      w={'100%'} loop controls playsInline
                    />
                  </AspectRatio>
                  <Checkbox
                    colorScheme='teal'
                    size={'lg'}
                    fontSize={'lg'}
                    type='checkbox'
                    isChecked={state?.liveWelcomeVideo}
                    value={state?.liveWelcomeVideo}
                    onChange={e => getData({ 'liveWelcomeVideo': e.target.checked })}
                  >
                    Live
                  </Checkbox>
                </HStack>
              </Box>
            </VStack>
          </Fade>}

          <HStack width='100%' justify='flex-end'>
            <Button
              isDisabled={activeStep === 0}
              mr={0}
              onClick={prevStep}
              size='sm'
              variant='outline'
              minWidth='70px'
            >
              Prev
            </Button>
            <Button
              size='sm'
              type='submit'
              variant='solid'
              minWidth={state.id ? '80px' : '70px'}
              disabled={!shouldRenders.reviewWallUrl}
              ref={submitStepRef2}
              onClick={() => {
                if (!skipCampaignLogo && !watch('campaignLogo')) {
                  setError('campaignLogo', { message: 'required' }, { shouldFocus: true })
                }
              }}
            >
              Next
            </Button>
          </HStack>
        </Stack>
      )}
    </Stack>
  )
}

export default CampaignDetails
