import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Select } from "chakra-react-select";
import { useController } from "react-hook-form";



const ControlledSelect = ({ options, control, name, id, label, rules, p, ...props }) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
    });

    return (
        <FormControl py={p ? p : 4} isInvalid={!!error} id={id}>
            {label && <FormLabel>{label}</FormLabel>}
            <Select
                size={["sm", "md"]}
                useBasicStyles
                name={name}
                ref={ref}
                options={options}
                onChange={onChange}
                selectedOptionColor="teal"
                onBlur={onBlur}
                value={value}
                // defaultValue={"US"}
                defaultValue={{ label: "United States", value: "US", code: "+1" }}
                {...props}
            />

            <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
    );
};
export default ControlledSelect
