import React from 'react'
import {
    Flex,
    HStack,
    Image,
    Stack,
    Text,
    VStack,
    theme,
} from '@chakra-ui/react'
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { Link } from "react-router-dom"
import Rating from './Rating'
import { add3Dots, getNameInitials } from '../utils'


const TestimonialCard = ({ cardInfo }) => {

    const { campaignDetails } = cardInfo

    return (
        <Flex id="campaign-card" flexDirection="column" minH="350px" borderRadius="20px" p={5} gap={5}
            boxShadow="0px 0px 24px rgba(234, 234, 234, 0.7)"
        >
            <Flex justify="center" align="center" borderRadius={20}
                height={["250px", "300px"]}
                sx={{ border: theme => "1px solid" + theme.colors.brand["1"] }}
                py={[2, 4]}
            >
                {campaignDetails?.campaignLogo ?
                    <Image
                        src={campaignDetails?.campaignLogo}
                        alt='campaign logo'
                        maxWidth={["90%", "85%"]}
                        minH={["160px", "auto", "220px"]}
                        maxHeight={["90%"]}
                        borderRadius={"lg"}
                        // height={["110px", "120px", "130px"]}
                        objectFit="contain"
                        // bg="gray.50"
                        fontSize=""
                    /> :
                    <Text color="teal.400" p={2} fontSize={["3xl", "4xl", "6xl"]}
                        textShadow={"0px 0px 1px" + theme.colors.teal[700]} textTransform="capitalize" textAlign="center"
                    >
                        {getNameInitials(campaignDetails?.publicCampaignName || "-")}
                    </Text>
                }
            </Flex>
            <VStack spacing={['14px', '20px']} alignItems="flex-start" flex={1}>
                <Text fontWeight="500" fontSize={["18", "20px"]} lineHeight="30px" textTransform="capitalize">
                    {campaignDetails?.publicCampaignName || "-"}
                </Text>
                <VStack spacing={"5px"} flex={1} minH={["70px", "90px"]} alignItems="flex-start">
                    <Text fontWeight="500" fontSize={["14", "16px"]}>
                        Campaign Description
                    </Text>
                    <Text fontSize="14px" color="gray.500" flex={1}>
                        {campaignDetails?.campaignDescription ? add3Dots(campaignDetails?.campaignDescription, 95) : "Not available..."}
                    </Text>
                </VStack>
                <Stack spacing={"5px"} w="full">
                    <Text fontWeight="500" fontSize={["14", "16px"]}>
                        Reviews
                    </Text>
                    <HStack direction="row" spacing={"10px"} alignItems="center">
                        <Rating
                            total={5}
                            value={Math.round(cardInfo?.avgRating) || 0}
                            size={["18px"]}
                            isStatic={true}
                            showNumber={false}
                        />
                        <Text fontSize="16px" lineHeight={0} color="gray.500" pb={2}>
                            ({Number(cardInfo?.avgRating).toFixed(cardInfo?.avgRating ? 2 : 0)})
                        </Text>
                    </HStack>
                    <Stack direction="row" justify="space-between" spacing={"20px"} mt={1}>
                        <Text fontSize={["14.4px", "16.4px"]} lineHeight="24px" color="gray.500" textTransform={"capitalize"}>
                            {cardInfo?.totalReviews} reviews
                        </Text>
                        <Text
                            // as={Link}
                            cursor={"pointer"}
                            fontSize={["14px", "16px"]}
                            fontWeight="500"
                            color="brand.1"
                            // to={`${window.location.origin}/wall/${cardInfo.id}`}
                            onClick={() => window.open(`${window.location.origin}/wall/${cardInfo?.customUrlName || cardInfo.id}`, '_blank')}
                        // target="_blank"
                        >
                            View reviews {" "}<ArrowForwardIcon />
                        </Text>
                    </Stack>
                </Stack>
            </VStack>
        </Flex>
    )
}

export default TestimonialCard