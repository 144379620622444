import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Text, chakra, Container, Image, Button, SimpleGrid, Box } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const getstarted = [
  {
    heading: '1. Create an Account',
    desc: `Create an account, complete payment, and start right away.`,
    // desc:
    //   'Create your account, choose your subscription plan, and complete your payment. Once done, you are re all set and ready to start collecting video reviews.',
  },
  {
    heading: '2. Express Campaign Setup',
    desc: `Request your first video review in just 30 seconds with our Express Setup feature. It's quick and easy!`,
    // desc: `Use our Express Setup to create a campaign in just 30 seconds and request your first video review. Customize your form with social profiles and job titles, and add incentives to increase customer participation.`,
  },
  {
    heading: '3. Send Video Review Request',
    desc: `Send a custom link via SMS or email to request a video review. Your customer clicks the link, records their video, and adds a few details. Done!`,
    // desc: `Easily send a custom link via SMS or email to your customer, inviting them to record a video review. `,
    // desc2: `They can record the video directly from their mobile or desktop browser, and include any additional information you request. It's quick, easy, and convenient for both you and your customer.`,
  },
  {
    heading: '4. Review Video & Approve',
    desc: `Approved videos are displayed on your custom review page and can be downloaded and shared on social media.`,
    // desc: `Once you've reviewed and approved the video, it will be displayed on your custom review web page. You can easily download and share the video on social media to increase your brand's visibility and reach.`,
  },
]

function StartWith() {
  return (
    <chakra.div px='3' py='4' bg={'rgba(180, 224, 226, 0.1)'} width={'inherit'}
    // id='startWith'
    >
      <Text fontSize={['1.85rem', '2rem']} fontWeight={'600'} my={['6', '8']} textAlign={'center'}>
        <chakra.span color={'brand.1'}>Start with 4</chakra.span>
        <chakra.span color={'brand.2'} ml={2}>
          Easy Steps
        </chakra.span>
      </Text>
      <chakra.div p={['2', '5', '8']}>
        <Image src='/images/wideimage.png' alt='' w={'100%'} height={['80px', 'auto']} />
      </chakra.div>
      <SimpleGrid mx='4' px='8' columns={{ md: 4, sm: 2 }} spacing={['6', '10']}>
        {getstarted.map((v) => (
          <Box key={v.heading}>
            <Text color={'#000'} fontSize={'20px'} fontWeight={'600'}>
              {v.heading}
            </Text>
            <Text p={'4'} color={'brand.3'}>
              {v.desc}
            </Text>
            <Text p={'4'} color={'brand.3'}>
              {v?.desc2}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
      <chakra.div m={'6'} textAlign={'center'}>
        <Button w={'36'} as={Link} to={'/register'} variant={'solid'}>
          Sign up <ArrowForwardIcon w={'10'} />
        </Button>
      </chakra.div>
    </chakra.div>
  )
}

export default StartWith
