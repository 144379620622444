import {
  Box,
  Button,
  chakra,
  Container,
  IconButton,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  theme,
  VStack,
} from '@chakra-ui/react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { IoMdLogOut } from 'react-icons/io'
import { userLoggedIn, userState } from '../recoil/atoms/auth'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { auth } from '../firebase/config'
import { useCustomToast } from '../hooks/customToast'
import { AiFillInstagram, AiFillYoutube, AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { BsPinterest, BsReddit } from 'react-icons/bs'
import { SiTiktok } from 'react-icons/si'
import { scrollToOtherPageElement } from './Navbar'
import { saveLogoutLogs } from '../pages/Login/utils'

export default function SmallCentered({ showLoginBtn, hideLogin = false, wallTheme = {} }) {
  const navigate = useNavigate()
  const { addToast } = useCustomToast()
  const setIsUserLoggedIn = useSetRecoilState(userLoggedIn)
  const [user, setUser] = useRecoilState(userState)

  const logout = async () => {
    await auth.signOut()
    await saveLogoutLogs({
      userEmail: user?.email,
      userId: user.uid,
      loginId: user?.loginId || '',
    })
    setUser(null)
    setIsUserLoggedIn(false)
    addToast({
      title: 'Logout',
      description: 'Logout Successfully!',
      status: 'success',
      variant: 'left-accent',
    })
  }

  // const scrollToElement = (id) => {
  //   const section = document.querySelector(id)
  //   section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  // }

  const handleNavigate = (id, navBarHeight) => {
    navigate('/', { state: { preventScroll: true } })
    return setTimeout(() => {
      scrollToOtherPageElement(id, true, navBarHeight)
    }, 200)
  }
  const gotoAnchor = (url) => {
    let anchor = document.createElement('a')
    anchor.target = '_blank'
    anchor.href = url
    anchor.click()
    anchor.remove()
  }
  const getYear = () => {
    return new Date().getFullYear()
  }

  const linksStyle = {
    fontWeight: '400',
    transition: 'all 0.2s ease',
    _hover: {
      color: 'teal.400',
      transform: 'scale(1.004)',
    },
  }

  return (
    <Box bg={wallTheme['footerBg'] || 'white'}>
      <Box
        maxW={'container.xl'}
        mx='auto'
        py={['10']}
        sx={{
          '& p, a': { color: wallTheme['footerLinksColor'] || 'black' },
        }}
      >
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={['20px', '30px', '40px']}>
          <Box>
            <Container
              as={Stack}
              maxW={'6xl'}
              py={4}
              px={[6, 6, 10]}
              spacing={4}
              fontWeight={'light'}
              // justify={'center'}
              // align={'center'}
            >
              <Stack direction='row' py={2} cursor={'pointer'} onClick={() => navigate('/')}>
                <Image src='/images/sendmea-logo.png' alt='logo' />
              </Stack>
              <Text>Start building your online presence with video reviews.</Text>
              <Text pt={12}>© Code Shock, LLC</Text>
              <Text px={4}>2021-{getYear()}</Text>
            </Container>
          </Box>
          <Box>
            <Container
              as={Stack}
              maxW={'6xl'}
              py={4}
              px={[4, 6, 8]}
              spacing={0}
              fontWeight={'light'}
              minH={['auto', 'auto', '254px']}
              // border="1px solid black"
            >
              <Text fontWeight={'bold'}>Quick Links</Text>

              <Text
                cursor={'pointer'}
                pt={'20px'}
                onClick={(_) => handleNavigate('#getting-started', 1)}
                {...linksStyle}
              >
                Getting Started
              </Text>
              <Text
                cursor={'pointer'}
                pt={'20px'}
                onClick={(_) => handleNavigate('#benefits')}
                {...linksStyle}
              >
                Social Benefits
              </Text>
              <Text cursor={'pointer'} pt={'20px'} as={RouterLink} to={'/contact'} {...linksStyle}>
                Contact Us
              </Text>
              <Text
                cursor={'pointer'}
                pt={'20px'}
                onClick={(_) => handleNavigate('#awareness')}
                {...linksStyle}
                minH={['0px']}
              >
                Social Proof
              </Text>
              <Text
                cursor={'pointer'}
                pt={'20px'}
                onClick={(_) => handleNavigate('#pricing')}
                {...linksStyle}
              >
                Pricing
              </Text>
            </Container>
          </Box>
          <Box>
            <Container
              minH={['auto', 'auto', '254px']}
              // border="1px solid black"
              as={Stack}
              maxW={'6xl'}
              py={4}
              px={[4, 6, 8]}
              spacing={0}
              fontWeight={'light'}
            >
              <Text fontWeight={'bold'}>Company</Text>
              <Text cursor={'pointer'} pt={'20px'} as={RouterLink} to={'/roadmap'} {...linksStyle}>
                Roadmap
              </Text>
              <Text cursor={'pointer'} pt={'20px'} as={RouterLink} to={'/blog'} {...linksStyle}>
                Sendmea Training
              </Text>
              <Text
                cursor={'pointer'}
                pt={'20px'}
                as={RouterLink}
                to={'/policy'}
                minH={['0px']}
                {...linksStyle}
              >
                Privacy Policy
              </Text>
              <Text cursor={'pointer'} pt={'20px'} as={RouterLink} to={'/terms'} {...linksStyle}>
                Terms of Service
              </Text>
              <Text
                as={RouterLink}
                to={'/manage-cookies'}
                cursor={'pointer'}
                pt={'20px'}
                {...linksStyle}
              >
                Manage Cookies
              </Text>
            </Container>
          </Box>
          <Box>
            <Container
              as={Stack}
              maxW={'6xl'}
              py={4}
              px={[4, 6, 8]}
              spacing={4}
              fontWeight={'light'}
            >
              <Text fontWeight={'bold'}>Social Links</Text>
              <chakra.div
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: '2rem!important',
                  maxW: ['300px', '250px'],
                  gap: [3, 3],
                }}
              >
                <IconButton
                  aria-label='Visit our YouTube'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<AiFillYoutube fontSize='25px' />}
                  onClick={() => gotoAnchor('https://www.youtube.com/@sendmea')}
                />
                <IconButton
                  aria-label='Visit our Instagram'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<AiFillInstagram fontSize='25px' />}
                  // onClick={() => gotoAnchor('https://www.youtube.com/@sendmea')}
                />
                <IconButton
                  aria-label='Visit our Facebook'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<FaFacebookF fontSize='20px' />}
                  onClick={() => gotoAnchor('https://www.facebook.com/100088104594727')}
                />
                <IconButton
                  aria-label='Visit our LinkedIn'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<FaLinkedinIn fontSize='20px' />}
                  onClick={() => gotoAnchor('https://www.linkedin.com/company/sendmea/')}
                />
                <IconButton
                  aria-label='Visit our Twitter'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<AiOutlineTwitter fontSize='25px' />}
                  onClick={() => gotoAnchor('https://twitter.com/sendmea_review')}
                />
                <IconButton
                  aria-label='Visit our Reddit'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<BsReddit fontSize='25px' />}
                  onClick={() => gotoAnchor('https://www.reddit.com/r/sendmea')}
                />
                <IconButton
                  aria-label='Visit our Pinterest'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<BsPinterest fontSize='25px' />}
                  onClick={() => gotoAnchor('https://www.pinterest.com/sendmea_review/')}
                />
                <IconButton
                  aria-label='Visit our TikTok'
                  boxSize='45px'
                  rounded='full'
                  variant={'solid'}
                  bg='#e1f7f7'
                  color='teal.500'
                  _hover={{
                    bg: 'teal.400',
                    color: 'white',
                  }}
                  icon={<SiTiktok fontSize='22px' />}
                  onClick={() => gotoAnchor('https://www.tiktok.com/@teamsendmea')}
                />
                <Box>
                  <Link href='https://madepublic.io/projects/sendmea?source=embed' isExternal>
                    <Image src='https://madepublic.io/images/embed.png' alt='madepublic logo' />
                  </Link>
                </Box>
              </chakra.div>
              {/* {!hideLogin && ( */}

              {!user ? (
                <VStack spacing={2} alignItems='flex-start'>
                  {showLoginBtn && (
                    <Button
                      w={'36'}
                      variant={'solid'}
                      rightIcon={<ArrowForwardIcon w={'10'} />}
                      onClick={() => navigate('/login')}
                    >
                      Sign In
                    </Button>
                  )}
                  <Button
                    w={'36'}
                    variant={'solid'}
                    onClick={() => navigate('/register')}
                    rightIcon={<ArrowForwardIcon w={'10'} />}
                  >
                    Sign up
                  </Button>
                </VStack>
              ) : (
                <Button
                  w={'36'}
                  variant={'outline'}
                  onClick={logout}
                  rightIcon={<IoMdLogOut w={'10'} />}
                  colorScheme='red'
                  color='red.400'
                  border='2px solid'
                  borderColor={theme.colors.red['200']}
                >
                  Logout
                </Button>
              )}
            </Container>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  )
}
