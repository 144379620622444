import React, { useEffect, useRef, useState } from 'react';
import {
  Text,
  ModalOverlay,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  chakra,
  HStack,
  Box,
  Icon,
  VStack
} from '@chakra-ui/react';
import { auth } from '../firebase/config';
import { IoLogOut } from 'react-icons/io5';
import { secToMin } from '../utils';
import { saveLogoutLogs } from '../pages/Login/utils';
import { useNavigate } from 'react-router-dom';

const AskToPayModal = ({
  isOpen,
  onClose,
  user,
  stripePlanId,
  setUser,
  setIsUserLoggedIn,
  setStripePlanId
}) => {
  const [countdown, setCountdown] = useState(15);
  const intervalIdRef = useRef(null);
  const navigate = useNavigate();

  const logout = async () => {
    await auth.signOut();
    await saveLogoutLogs({
      userEmail: user?.email,
      userId: user.uid,
      loginId: user?.loginId || ''
    });
    navigate('/login');
    setUser(null);
    setIsUserLoggedIn(false);
    onClose();
    setStripePlanId('');
  };

  useEffect(() => {
    if (isOpen) {
      intervalIdRef.current = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(intervalIdRef.current);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return (
    <Modal isCentered isOpen={isOpen} closeOnOverlayClick={false}>
      <ModalOverlay bg="teal.500" />
      <ModalContent mx={['2', '4']} maxW={['29rem']}>
        <ModalHeader pb={2}>
          <Text
            fontSize={['20px', '24px']}
            fontWeight="600"
            lineHeight={'38px'}
            color="#474747"
            mt={[2]}>
            Welcome <chakra.span color="brand.1">{user?.firstName}</chakra.span>
          </Text>
        </ModalHeader>
        <ModalBody mb={[6]} as={VStack} gap={[4]} alignItems="flex-start">
          <Text fontSize={['md', 'lg']} fontWeight="400">
            Welcome to Sendmea!
            <br />
            Looks like the internet might be slow! You will automatically
            redirected to the dashboard shortly. If not, please log out and log
            back in to enjoy all the amazing features of Sendmea.
          </Text>
          <Text color={'gray.700'}>
            {/* You will be redirected to login in {secToMin(time)} */}
            You will be redirected to login in
            <chakra.span color="red.400" fontWeight="600" mx="1">
              {secToMin(countdown)}
            </chakra.span>
          </Text>
          <HStack
            spacing="3"
            alignItems="stretch"
            justifyContent="center"
            width="full">
            <Box
              width={['full']}
              p={[3, 4]}
              bg="teal.50"
              shadow="md"
              transition="all 0.3s ease"
              rounded="md"
              cursor="pointer"
              _hover={{
                bg: 'teal.400',
                transform: 'scale(1.02)',
                '& >*': {
                  color: 'white'
                }
              }}
              onClick={() => logout()}>
              <Icon color="teal.500" as={IoLogOut} fontSize={['35px']} />
              <Text color="teal.500" fontSize={['md', 'lg']} fontWeight="500">
                Logout
              </Text>
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AskToPayModal;
