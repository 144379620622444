import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Seo = ({
  title,
  description,
  type,
  url,
  imgUrl,
  vidUrl,
  keywords
}) => {
  return null;
  // <Helmet prioritizeSeoTags>
  //   {/* COMMON TAGS */}
  //   <title>{title}</title>
  //   {/* Search Engine */}
  //   <meta name='description' content={description} />
  //   <meta name='image' content='https://sendmea.io/image.png' />
  //   <meta name='keywords' content={keywords} />
  //   {/* Schema.org for Google */}
  //   <meta itemprop='name' content='Sendmea Video Reviews' />
  //   <meta itemprop='description' content={description} />
  //   <meta itemprop='image' content='https://sendmea.io/image.png' />
  //   {/* Open Graph general (Facebook, Pinerest & Google) */}
  //   <meta property='og:type' content={type} />
  //   <meta property='og:url' content={url} />
  //   <meta property='og:title' content={title} />
  //   <meta property='og:description' content={description} />
  //   <meta property='og:image' content={imgUrl} />
  //   <meta property='og:image:alt' content='Sendmea image' />
  //   <meta property='og:video' content={vidUrl} />
  //   <meta property='og:keywords' content={keywords} />
  //   {/* Twitter */}
  //   <meta name='twitter:creator' content='Sendmea' />
  //   <meta name='twitter:card' content={type} />
  //   <meta name='twitter:title' content={title} />
  //   <meta name='twitter:description' content={description} />
  //   <meta name='twitter:site' content='@sendmea_review' />
  //   <meta name='twitter:image:alt' content={description} />
  //   <meta name='twitter:label1' content='Written by' />
  //   <meta name='twitter:data1' content='Team Sendmea' />
  //   <meta name='twitter:label2' content='Filed under' />
  //   <meta name='twitter:data2' content='Digital Communication' />
  // </Helmet>
};
