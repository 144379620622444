import React, { useState } from 'react'
import {
  Text,
  ModalOverlay,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  VStack,
  HStack,
  Button,
  // useDisclosure,
  // Tag,
  // TagLeftIcon,
  Input,
  FormControl,
  chakra,
  FormErrorMessage,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { getTotalCampaigns } from '../utils'
import { useCustomToast } from '../hooks/customToast'
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage'
import { db, storage } from '../firebase/config'
import {
  addDoc,
  collection,
  collectionGroup,
  getDocs,
  query,
  serverTimestamp,
  where,
} from '@firebase/firestore'
// import RecordView from '../pages/CreateCampign/components/recorder'
// import { DeleteIcon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { expressSetupInfo } from '../recoil/atoms/expressSetup'
import { RiVideoAddFill } from 'react-icons/ri'

const ExpressCampaignModal = ({ isOpen, onClose, user, closeWelcomeModal }) => {
  // const {
  //   isOpen: isRecordedOpen,
  //   onOpen: onRecordedOpen,
  //   onClose: onRecordedClose,
  // } = useDisclosure()

  const initialState = {
    welcomeVideo: '',
    active: true,
    visits: 0,
    videoDetails: {
      timerChecked: true,
      countdown: 3,
      minRecordLength: '',
      maxRecordLength: '',
    },
    reviewPageDetails: {
      buttonText: 'Visit Sendmea',
      redirectLinkForButton: 'https://sendmea.io',
      campaignTitleForUsers: 'Thank you for recording your short video review.',
      questionsForUsers: [],
      thanksForLeavingText:
        'Thank you for your time! We appreciate your testimony and hope to see you again soon.',
    },
    campaignDetails: {
      language: 'English',
      internalCampaignName: '',
      publicCampaignName: '',
      campaignDescription: "Thank you for visiting my review wall!'",
      campaignLogo: '',
      reviewWallUrl: 'https://sendmea.io',
      contactEmail: user?.email,
    },
    formDetails: {
      askUsers: [
        { label: 'Name', name: 'name', checked: true, required: true },
        { label: 'Email', name: 'email', checked: true, required: true },
        { label: 'Rating', name: 'rating', checked: true, required: true },
        { label: 'Review Message', name: 'reviewMessage', checked: true, required: true },
      ],
    },
  }

  const {
    handleSubmit,
    register,
    // setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialState,
    },
  })

  // const [skipVideo, setSkipVideo] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const setExpressSetup = useSetRecoilState(expressSetupInfo)

  const { addToast } = useCustomToast()
  // const uploadInputRef = useRef(null)

  // const handleVideo = (e, file = null) => {
  //   const fileObj = file || e?.target?.files?.[0]
  //   try {
  //     if (!fileObj) return
  //     let sizeInMB = convertToMb(fileObj)
  //     if (fileObj && !fileObj.type.includes('video')) {
  //       return addToast({ title: 'Video', description: 'Can only upload videos', status: 'error' })
  //     } else if (sizeInMB > 40 || sizeInMB < 0.23) {
  //       addToast({
  //         title: 'Video',
  //         description:
  //           sizeInMB < 0.23
  //             ? 'Size/length of video is less than 250kb'
  //             : 'Size is greater than 40mb',
  //         status: 'error',
  //       })
  //     } else {
  //       setValue('welcomeVideo', fileObj)
  //     }
  //   } catch (error) {
  //     addToast({ title: 'Video', description: error.message, status: 'error' })
  //   }
  // }

  const uploadToFirestore = async (values, { campaignLogo, welcomeVideo }) => {
    try {
      const collRef = collection(db, 'campaigns')
      let finalData = {
        ...values,
        isExpressSetup: true,
        welcomeVideo,
        campaignDetails: {
          ...values.campaignDetails,
          internalCampaignName: values.campaignDetails?.publicCampaignName,
          campaignLogo,
        },
      }
      getTotalCampaigns
        .then(async (totalCampaigns) => {
          let data = await addDoc(collRef, {
            ...finalData,
            docNo: totalCampaigns + 1,
            createdAt: serverTimestamp(),
            userId: user.uid,
          })
          const subCollectionRef = collection(db, 'campaigns', data.id, 'vanity')
          await addDoc(subCollectionRef, {
            vanityName: String(values.campaignDetails?.publicCampaignName)
              .trim()
              .split(' ')
              .join('-')
              .toLowerCase(),
            updatedAt: serverTimestamp(),
          })
          setExpressSetup({
            campaignId: data.id,
            shouldOpenSendInvite: true,
          })
          addToast({
            title: 'Congratulations!',
            description: 'First campaign Created',
            status: 'success',
            variant: 'left-accent',
          })
          setIsSubmitting(false)
          onClose()
          closeWelcomeModal()
        })
        .catch((err) => {
          throw err
        })
    } catch (err) {
      addToast({
        title: 'Error!',
        description: err.message,
        status: 'error',
        variant: 'left-accent',
      })
      setIsSubmitting(false)
    }
  }

  const onSubmit = async (state) => {
    setIsSubmitting(true)
    try {
      let {
        welcomeVideo,
        campaignDetails: { publicCampaignName },
      } = state
      const vanityCollRef = collectionGroup(db, 'vanity')
      const vanityCollRefQuery = query(
        vanityCollRef,
        where(
          'vanityName',
          '==',
          String(publicCampaignName).trim().split(' ').join('-').toLowerCase()
        )
      )
      const checkDuplicateVanity = (await getDocs(vanityCollRefQuery)).docs[0]?.id

      if (checkDuplicateVanity) {
        throw new Error('Custom name already occupied by another campaign')
      } else {
        const uploadedVideo =
          typeof welcomeVideo === 'object' ? await uploadFile(welcomeVideo, true) : welcomeVideo
        uploadToFirestore(state, { welcomeVideo: uploadedVideo, campaignLogo: '' })
      }
    } catch (error) {
      addToast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        variant: String(error.message).startsWith('Custom') ? 'left-accent' : 'solid',
      })
      setIsSubmitting(false)
    }
  }

  const uploadFile = (file, recorded = false) =>
    new Promise((resolve, reject) => {
      if (!file) return
      const storageRef = ref(
        storage,
        `${recorded ? 'campaign_videos' : 'campaign_pics'}/${user.uid}/${
          Date.now() * Math.floor(Math.random() * 100)
        }`
      )
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on(
        'state_changed',
        // don't want progress
        () => null,
        (error) => {
          addToast({
            title: 'Error',
            description: error.message,
            status: 'error',
            variant: 'left-accent',
          })
          reject(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            addToast({
              title: recorded ? 'Welcome Video!' : 'Campaign Logo!',
              description: recorded ? 'For your first campaign' : 'For your first campaign',
              status: 'success',
              variant: 'left-accent',
            })
            resolve(downloadURL)
          })
        }
      )
    })

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
      >
        <ModalOverlay bg='blackAlpha.400' backdropFilter='blur(4px) hue-rotate(5deg)' />
        <ModalContent mx={['2', '4']} maxW={['29rem']}>
          <ModalHeader pb={4}>
            <HStack
              w='full'
              spacing={0}
              gap={[1, 2]}
              alignItems='center'
              mt={[2, 4]}
              justifyContent='flex-start'
            >
              <Text fontSize={['20px', '24px']} fontWeight='600' color='#474747'>
                Express <chakra.span color='brand.1'>Campaign</chakra.span> Setup
              </Text>
              <Tooltip placement='bottom' bg='teal.400' rounded='md' hasArrow label='Watch video'>
                <IconButton
                  cursor='pointer'
                  variant='ghost'
                  icon={<RiVideoAddFill />}
                  rounded='full'
                  color='teal.300'
                  mt={'2px !important'}
                  fontSize={['1.2rem', '1.6rem']}
                  onClick={() => window.open(`https://www.youtube.com/watch?v=gP0W9Nfa_1w`)}
                  _hover={{
                    transform: 'scale(1.2)',
                    bg: 'whiteAlpha.300',
                    color: 'brand.2',
                  }}
                />
              </Tooltip>
            </HStack>
            <Text fontSize={['sm', 'md']} fontWeight='500' color={'teal.500'} mt={'4'}>
              NOTE: A campaign is a person or business that will get unique reviews. Imagine you are
              a realtor and in auto sales. Each would get their own reviews, thus they are separate
              campaigns. A personal brand would be a campaign.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={[6]}>
            <VStack gap='4' w='full' as='form' align='flex-start' onSubmit={handleSubmit(onSubmit)}>
              <VStack gap={['2']} w='full' alignItems='flex-start'>
                <Text fontSize={['sm', 'md']} fontWeight='500'>
                  Enter a name or business that will be your review URL and campaign name (3-15
                  characters):
                </Text>
                <Text color='gray.500' fontSize={['sm']} fontWeight='500' mt={1}>
                  EXAMPLE: john, john-williams, best coach
                </Text>
                <FormControl isInvalid={!!errors?.campaignDetails?.publicCampaignName}>
                  <Input
                    size={['sm', 'md']}
                    placeholder='Business Name, Website name etc'
                    bg={'gray.100'}
                    border={0}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    {...register('campaignDetails.publicCampaignName', {
                      required: 'This is required',
                      minLength: { value: 3, message: 'Too Short' },
                      maxLength: { value: 15, message: 'Too Long' },
                    })}
                  />
                  {!errors?.campaignDetails &&
                    watch('campaignDetails.publicCampaignName').length > 0 && (
                      <Text color='gray.500' fontSize='sm' mt={2}>
                        Your url will be: https://sendmea.io/wall/
                        {watch('campaignDetails.publicCampaignName')
                          .split(' ')
                          .join('-')
                          .toLowerCase()}
                      </Text>
                    )}
                  {errors?.campaignDetails?.publicCampaignName && (
                    <FormErrorMessage>
                      {errors?.campaignDetails?.publicCampaignName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </VStack>
              <Button
                type='submit'
                mt={6}
                variant='solid'
                size={['sm', 'md']}
                isLoading={isSubmitting}
              >
                Confirm and Get Started!
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* <Modal isOpen={isRecordedOpen} onClose={onRecordedClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent my={'auto'}>
          <ModalHeader pb={0}>
            <Text fontSize={['20px', '23px']} fontWeight='600' color='#474747' mt={[2]}>
              Record <chakra.span color='teal.400'>Video</chakra.span>
            </Text>
            <Text fontSize={['sm', 'md']} fontWeight='500' color='gray.500' mt={[2]}>
              Record welcome video for your campaign
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecordView
              isExpressSetup
              recorderView={isRecordedOpen}
              handleVideo={handleVideo}
              nextStep={() => onRecordedClose()}
            />
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  )
}

export default ExpressCampaignModal
