import React, { useEffect } from 'react'
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent'
import * as ReactGA from 'react-ga'

//need google analytics ID
export const initGA = (id) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(id)
  }
}

const handleAcceptCookie = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
  }
  loadNewChatbot()
}

const handleDeclineCookie = () => {
  //remove google analytics cookies
  Cookies.remove('_ga')
  Cookies.remove('_gat')
  Cookies.remove('_gid')
  loadNewChatbot()
}

const handleChat = () => {
  ;(function (d, t) {
    var BASE_URL = 'https://app.fastbots.ai/embed.js'
    var g = d.createElement(t),
      s = d.getElementsByTagName(t)[0]
    g.src = 'https://chatcloud.b-cdn.net' + '/packs/js/sdk.js'
    g.defer = true
    g.async = true
    s.parentNode.insertBefore(g, s)
    g.onload = function () {
      window.chatcloudSDK.run({
        websiteToken: process.env.REACT_APP_CHAT_WEBSITE_TOKEN,
        baseUrl: BASE_URL,
      })
    }
  })(document, 'script')
}

const loadNewChatbot = () => {
  const BASE_URL = 'https://app.fastbots.ai/embed.js'
  const script = document.createElement('script')
  script.src = BASE_URL
  script.setAttribute('data-bot-id', 'cllmwwo5z00bfpr90hget50q7')
  script.setAttribute(
    'data-fabicon',
    'http://d7keiwzj12p9.cloudfront.net/chatbubbles/reduced-sendmea-plane-1697163818624.webp'
  )
  script.defer = true
  script.async = true

  script.onload = function () {
    // Code to run after the script is loaded
  }

  document.head.appendChild(script)
}

// Call the function to load the new chatbot

export const ConsentCookie = () => {
  useEffect(() => {
    if (getCookieConsentValue() !== undefined) {
      // handleChat()
      loadNewChatbot()
    }
  }, [getCookieConsentValue()])
  return (
    <div>
      <CookieConsent
        // uncomment below to test
        // debug={true}
        style={{
          background: '#00989d',
          fontSize: '1rem',
          padding: '15px 5px',
        }}
        buttonStyle={{
          background: '#edf2f6',
          color: '#282c34',
          fontWeight: '5px',
          padding: '10px',
          borderRadius: '5px',
          margin: '10px',
        }}
        disableButtonStyles={{
          background: '#edf2f6',
          color: '#282c34',
          fontWeight: '5px',
          padding: '10%',
          borderRadius: '5px',
          margin: '20%',
        }}
        // overlay
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This website uses cookies to enhance the user experience. View{' '}
        <span style={{ color: 'red' }}>
          <a href='/policy'>Privacy Policy </a>
        </span>
        to learn more.
      </CookieConsent>
    </div>
  )
}
