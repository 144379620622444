import { Box, Button, Center, Heading, LightMode, SimpleGrid, Text } from '@chakra-ui/react'

export const Hero = () => {
  //
  return (
    <Box
      as="section"
      bg="gray.800"
      py="12"
      position="relative"
      h={{ base: '560px', md: '640px' }}
      bgImage="url(https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center flexDirection="column" textAlign="center" color="white" h="full">
          <Heading as='h1' size='2xl' fontWeight="extrabold">
            Boost Your Business with Authentic Video Reviews
          </Heading>
          <Text fontSize="2xl" fontWeight="medium" mt="3">
          Collect genuine customer feedback and enhance your online presence with ease.
          </Text>
          <LightMode>
            <Button  color="brand.2" size="xl" p='3' mt="6" fontWeight="extrabold" fontSize="xl">
              <a href='/register'>
              Sign Up Today
              </a>
            </Button>
          </LightMode>
        </Center>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        bg="blackAlpha.400"
      >
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
          <SimpleGrid px='3' columns={{ base: 1, md: 3 }}>
            <Box textAlign="center" color="white">
              <Text>Set Up Time:</Text>
              <Text fontSize="3xl">90 Seconds </Text>
              <Text> Only 90 Seconds to request first video!</Text>
            </Box>
            <Box pl='2' textAlign="center" color="white">
              <Text>Why Video Reviews? </Text>

              <Text fontSize="3xl">75%</Text>
              <Text>of consumers prefer videos over text!</Text>
            </Box>
            <Box pl='2' textAlign="center" color="white">
              <Text>Positive ROI</Text>
              <Text fontSize="3xl">88%</Text>
              <Text>of video marketers report a positive ROI from video marketing​</Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}