import { Box, RangeSlider, RangeSliderFilledTrack, RangeSliderMark, RangeSliderThumb, RangeSliderTrack } from "@chakra-ui/react"
import { MdGraphicEq } from "react-icons/md"

export function RangeSliderMarkExample({ start, end, setValue }) {
    return (
        <RangeSlider aria-label={['min', 'max']} value={[start, end]} my={3} onChange={(val) => {
            setValue('start_offset', val[0])
            setValue('end_offset', val[1])
        }
        }>
            {/* <RangeSliderMark value={25} mt='1' ml='-2.5' fontSize='sm'>
          25%
        </RangeSliderMark>
        <RangeSliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
          50%
        </RangeSliderMark>
        <RangeSliderMark value={75} mt='1' ml='-2.5' fontSize='sm'>
          75%
        </RangeSliderMark> */}
            {/* <RangeSliderMark
                value={start}
                textAlign='center'
                bg='blue.500'
                color='white'
                mt='-10'
                ml='-5'
                w='12'
            >
                {start}%
            </RangeSliderMark>
            <RangeSliderMark
                value={end}
                textAlign='center'
                bg='blue.500'
                color='white'
                mt='-10'
                ml='-5'
                w='12'
            >
                {end}%
            </RangeSliderMark> */}
            <RangeSliderTrack bg='teal.100'>
                <RangeSliderFilledTrack bg='teal' />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} bgColor={'transparent'}>
                <Box color='blackAlpha.900' as={MdGraphicEq} />
            </RangeSliderThumb>
            <RangeSliderThumb boxSize={6} index={1} bgColor={'transparent'}>
                <Box color='blackAlpha.900' as={MdGraphicEq} />
            </RangeSliderThumb>
        </RangeSlider>
    )
}