import {
  Button,
  Modal,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormErrorMessage,
  FormControl,
  Input,
  FormLabel,
  Text,
  useDisclosure,
  HStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCustomToast } from '../../../hooks/customToast'
import { ConfirmationPrompt, errorToast, isDatePassedDuration } from '../../../utils'
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { db } from '../../../firebase/config'

function SendInvite({ isOpen, onClose, campaignId, vanityObj, setFetchAgain, getCampaigns }) {
  const { addToast } = useCustomToast()
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(null)
  const {
    isOpen: isVanityOpen,
    onOpen: openVanityModal,
    onClose: closeVanityModal,
  } = useDisclosure()

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vanityName: '',
      updatedAt: '',
      ...vanityObj,
    },
  })

  const handleVanityOpen = () => {
    openVanityModal()
  }

  const handleVanityClose = (_) => {
    closeVanityModal()
    setFormValues(null)
  }

  const onSubmit = async (data) => {
    if (!!errors?.updatedAt || !data?.vanityName) return false
    handleVanityOpen()
    setFormValues(data)
  }

  const saveData = async (data) => {
    try {
      setLoading(true)
      const vanityCollRef = collectionGroup(db, 'vanity')
      const vanityCollRefQuery = query(
        vanityCollRef,
        where('vanityName', '==', String(data.vanityName).toLowerCase().trim())
      )
      const checkDuplicateVanity = (await getDocs(vanityCollRefQuery)).docs[0]?.id

      if (checkDuplicateVanity) {
        addToast({
          title: 'Campaign!',
          description: 'Vanity name already occupied by another campaign',
          status: 'error',
          variant: 'left-accent',
        })
        setLoading(false)
        return false
      } else if (data?.id) {
        const subcollectionDocRef = doc(db, 'campaigns', campaignId, 'vanity', data?.vanityId || data?.id)
        await updateDoc(subcollectionDocRef, {
          vanityName: String(data.vanityName).toLowerCase().trim(),
          updatedAt: serverTimestamp(),
        })
        addToast({
          title: 'Campaign!',
          description: 'custom vanity name updated',
          status: 'success',
          variant: 'left-accent',
        })
      } else {
        const subcollectionRef = collection(db, 'campaigns', campaignId, 'vanity')
        await addDoc(subcollectionRef, {
          vanityName: String(data.vanityName).toLowerCase().trim(),
          updatedAt: serverTimestamp(),
        })
        addToast({
          title: 'Campaign!',
          description: 'custom vanity name added',
          status: 'success',
          variant: 'left-accent',
        })
      }
      setLoading(false)
      handleVanityClose()
      onClose(true)
    } catch (err) {
      console.log(err)
      setLoading(false)
      errorToast(err, addToast)
    }
  }

  useEffect(() => {
    (async () => {
      if (campaignId && Object.keys(vanityObj || {}).length > 0) {
        reset({ ...vanityObj })
        const { isDateCrossed, durationLeft } = isDatePassedDuration(vanityObj?.updatedAt, {
          days: 0,
          months: 3,
        })
        // console.log({ vanityObj })
        if (!isDateCrossed || !durationLeft) {
          setError('updatedAt', { message: `You still have ${durationLeft} left to update again` })
        } else {
          clearErrors('updatedAt')
        }
      }
    })()
    return () => reset({ vanityName: '', updatedAt: '', id: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId])

  return (
    <>
      <ConfirmationPrompt
        isOpen={isVanityOpen}
        onClose={handleVanityClose}
        accept={() => saveData(formValues)}
        loading={loading}
        message='If you change the name, the previous URL will stop working and that could cause links to no longer work'
      />

      <Modal isOpen={isOpen && campaignId} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as={'form'} onSubmit={handleSubmit(onSubmit)} mx={['10px', '5px', 0]}>
          <ModalHeader>Custom URL Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={['4', '6']}>
            <FormControl isInvalid={!!errors?.vanityName}>
              <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                <Text>Type your custom url name for campaign</Text>
              </FormLabel>
              <Input
                placeholder='Name'
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                py={2}
                {...register('vanityName', {
                  required: 'This is required',
                  maxLength: {
                    value: 170,
                    vanityName: 'should be less than 170 characters',
                  },
                })}
              />
              {watch('vanityName').length > 0 && (
                <Text color='gray.700' fontSize='sm' mt={2}>
                  Your url will be: https://sendmea.io/wall/
                  {
                    watch('vanityName')
                      .split(' ')
                      .join('-')
                      .toLowerCase()
                  }
                </Text>
              )}
              <Text
                color={errors?.updatedAt?.message ? 'red.500' : 'gray.500'}
                mt={2}
                fontSize='sm'
              >
                {errors?.updatedAt?.message ||
                  '*Note that you can edit name only once in a 3 months'}
              </Text>

              <HStack spacing={1} color='gray.400' mt={1} fontSize='sm'>
                <Text color='gray.500'> If you think you made an error, email at</Text>
                <Text
                  as={Link}
                  textDecoration='none !important'
                  fontSize='sm'
                  color='blue.500'
                  href={`mailto:staff@sendmea.io`}
                >
                  Support
                </Text>
              </HStack>
              {errors?.vanityName && (
                <FormErrorMessage>{errors.vanityName.message}</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant='solid' type='submit' disabled={!!errors?.updatedAt}>
              {vanityObj?.vanityName ? 'Update' : 'Save'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SendInvite
