import { Box } from "@chakra-ui/react";

import React from "react";
import TeamMembersList from "./TeamMemberList";

const TeamInfo = () => {
  return (
    <Box>
      <TeamMembersList />
    </Box>
  );
};
export default TeamInfo;
