import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, chakra } from "@chakra-ui/react";
import { standardBrowserList } from "../../../utils";
import { LockIcon } from "@chakra-ui/icons";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { browserName } from 'react-device-detect';

export default function HelpRecorderModal({ isOpen, onClose }) {

    return (<Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        scrollBehavior='inside'
    >
        <ModalOverlay />
        <ModalContent mx={['2']}>
            <ModalHeader pb='2'>
                <Text fontSize={['20px']} color='brand.2'>
                    <chakra.span color='teal.500' fontWeight='bold' fontSize='20px'>
                        How to fix this
                    </chakra.span>{' '}
                    issue?
                </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody minHeight={['100px']}>
                This means your permissions are currently not allowing camera and microphone to be used.
                You will need to grant privileges.{' '}
                {standardBrowserList?.includes(browserName) ? (
                    <p>
                        {' '}
                        Click the icon on the left side of the URL
                        <LockIcon mx={1} fontSize={'15px'} display={'inline-block'} /> and you should be
                        able to grant permission.
                    </p>
                ) : (
                    <p>
                        {' '}
                        if you're using built in browser try clicking the
                        <BiDotsHorizontalRounded
                            style={{ marginInline: '2px', display: 'inline-block' }}
                        />{' '}
                        icon in right corner {'>'} <b>open system browser or open with etc</b> if you can't
                        find option like that copy the link and open paste it in different browser.
                    </p>
                )}
            </ModalBody>
            <ModalFooter>
                {/* <Button onClick={() => navigator.clipboard.writeText(window.location.href)}>Copy Link</Button> */}
            </ModalFooter>
        </ModalContent>
    </Modal>)
}