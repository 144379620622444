import React from 'react'

const EyesIcon = ({ ...rest }) => {
    return (
        // <?xml version="1.0" encoding="UTF-8"?>
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...rest}>
            <path d="m17.25,0c-2.166,0-4.032,1.635-5.25,4.284C10.782,1.635,8.916,0,6.75,0,2.902,0,0,5.159,0,12s2.902,12,6.75,12c2.166,0,4.032-1.635,5.25-4.284,1.218,2.649,3.084,4.284,5.25,4.284,3.848,0,6.75-5.159,6.75-12S21.098,0,17.25,0ZM6.75,21c-1.039,0-2.351-1.509-3.116-4.154.27.1.561.154.866.154,1.381,0,2.5-1.119,2.5-2.5s-1.119-2.5-2.5-2.5c-.56,0-1.077.184-1.494.495-.004-.163-.006-.328-.006-.495,0-5.576,2.184-9,3.75-9s3.75,3.424,3.75,9-2.184,9-3.75,9Zm10.5,0c-1.08,0-2.453-1.627-3.202-4.465.409.293.91.465,1.452.465,1.381,0,2.5-1.119,2.5-2.5s-1.119-2.5-2.5-2.5c-.805,0-1.52.38-1.977.971-.015-.316-.023-.64-.023-.971,0-5.576,2.184-9,3.75-9s3.75,3.424,3.75,9-2.184,9-3.75,9Z" />
        </svg>
    )
}

export default EyesIcon