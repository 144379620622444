import {
	Box,
	Button,
	Icon,
	Square,
	Stack,
	Text,
	chakra,
} from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'
import { features } from './data'
import { Link } from 'react-router-dom'
import SliderBox from './SliderBox'
import { Seo } from '../../../components/Seo'

export const AffiliateProgram = ({ user }) => (
	<>
		<Seo
			title='Sendmea Real Video Reviews | Affilate Program'
			description='Sendmea pays a referral commision of $30 monthly recurring for the life of the referral! Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand. Video reviews are the most trusted and valuable of all reviews, and build consumer trust and confidence.'
			type='webapp'
			url='https://sendmea.io'
			imgUrl='https://sendmea.io/images/sendmeaimage.png'
			vidUrl='https://youtu.be/aXOVKVrk1n4'
			keywords='affiliate, referral, referral income,video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
		/>

		<Box
			as='section'
			bg='#e1f7f7'
		>
			<Box
				py={{
					base: '16',
					md: '20',
				}}
				px='4'
			>
				<Text
					pb='4'
					textAlign='center'
					fontSize={'3rem'}
					my={'2'}
					color={'brand.1'}
					fontWeight={'500'}
				>
					Send<chakra.span color='brand.2'>mea </chakra.span>{' '}
					Affiliate Program
				</Text>
				<Stack
					spacing={{
						base: '12',
						md: '16',
					}}
				>
					<Stack
						direction={{
							base: 'column',
							md: 'row',
						}}
						spacing={{
							base: '12',
							lg: '16',
						}}
					>
						<Stack
							spacing={{
								base: '10',
								md: '12',
							}}
							maxW='xl'
							justify='center'
							width='full'
						>
							{features.map((feature) => (
								<Stack
									key={feature.name}
									spacing='4'
									direction='row'
								>
									<Square
										size={{
											base: '10',
											md: '12',
										}}
										bg='accent'
										color='inverted'
										borderRadius='lg'
									>
										<Icon
											as={feature.icon}
											color='brand.2'
											boxSize={{
												base: '5',
												md: '6',
											}}
										/>
									</Square>
									<Stack
										spacing={{
											base: '4',
											md: '5',
										}}
										pt={{
											base: '1.5',
											md: '2.5',
										}}
									>
										<Stack
											spacing={{
												base: '1',
												md: '2',
											}}
										>
											<Text
												fontSize={{
													base: 'lg',
													md: 'xl',
												}}
												fontWeight='medium'
											>
												{feature.name}
											</Text>
											<Text color='muted'>
												{feature.description}
											</Text>
										</Stack>
									</Stack>
								</Stack>
							))}
							{!user && !user?.ref_id ? (
								<Button
									variant={'solid'}
									my={'3'}
									as={'a'}
									target='_blank'
									href='https://earn.sendmea.io'
								>
									Enroll
								</Button>
							) : (
								<Button
									variant={'solid'}
									my={'3'}
									disabled
								>
									You are already Enrolled!
								</Button>
							)}
						</Stack>

						<Box
							width='100%'
							overflow='hidden'
						>
							<SliderBox />
						</Box>
					</Stack>
				</Stack>
			</Box>
		</Box>
	</>
)
