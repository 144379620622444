import React, { useEffect, useState } from 'react'
import { Avatar, Badge, Box, Button, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { formatDistance } from 'date-fns'
import { FaBell } from 'react-icons/fa'
import { DeleteIcon } from '@chakra-ui/icons'
import { collection, deleteDoc, doc, getDoc, limit, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { useCustomToast } from '../../../hooks/customToast'
import { errorToast } from '../../../utils'

const NotificationMenu = ({ user, onOpen, setLoading, setCardInfo }) => {

    const { addToast } = useCustomToast()
    const [receivedNoti, setReceivedNoti] = useState([])
    const [seenLoading, setSeenLoading] = useState(false)
    const [removeLoading, setRemoveLoading] = useState(false)

    const handleSeen = async (id, notiId) => {
        try {
            if (id) {
                // setLoading(true)
                const docRef = doc(db, 'reviews', id)
                let data = await getDoc(docRef)
                if (!data.exists()) {
                    addToast({ title: "Review Not found!", description: "Review was deleted by you", status: "info", variant: "left-accent" })
                } else {
                    const testimonial = { ...data.data(), id: data.id }
                    setCardInfo(testimonial)
                    onOpen()
                }
                setLoading(false)
            }
            const notificationRef = doc(db, 'notifications', notiId)
            await updateDoc(notificationRef, { seen: true })
        } catch (error) {
            errorToast(error)
        }
    }

    const handleAllRead = async () => {
        try {
            setSeenLoading(true)
            await Promise.all(
                receivedNoti
                    .filter((v) => !v.seen)
                    .map(async (v) => {
                        const notificationRef = doc(db, 'notifications', v.id)
                        await updateDoc(notificationRef, { seen: true })
                    })
            )
            setSeenLoading(false)
            addToast({
                title: 'Notification',
                description: 'All marked as read!',
                status: 'success',
                variant: 'left-accent',
            })
        } catch (error) {
            console.log(error)
            setSeenLoading(false)
        }
    }
    const handleRemoveNoti = async (e, notiId) => {
        try {
            e.stopPropagation()
            const notificationRef = doc(db, 'notifications', notiId)
            await deleteDoc(notificationRef)
            addToast({
                title: 'Notification',
                description: 'Notification Removed!',
                status: 'success',
                variant: 'left-accent',
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleRemoveAllNoti = async () => {
        try {
            setRemoveLoading(true)
            await Promise.all(
                receivedNoti.map(async (v) => {
                    const notificationRef = doc(db, 'notifications', v.id)
                    await deleteDoc(notificationRef)
                })
            )
            setRemoveLoading(false)
            addToast({
                title: 'Notification',
                description: 'All Notification Removed!',
                status: 'success',
                variant: 'left-accent',
            })
        } catch (error) {
            console.log(error)
            setRemoveLoading(false)
        }
    }
    // console.log(openSidebar)

    useEffect(() => {
        if (db && user) {
            const msgRef = collection(db, 'notifications')
            const receiverQ = query(
                msgRef,
                where('receiver_id', 'in', [user?.uid, user?.email]),
                // orderBy('createdAt', 'desc')
                limit(100)
            )

            const unsubscribe = onSnapshot(receiverQ, (snapshot) => {
                let results = []
                snapshot.docs.forEach((doc) => {
                    results.push({ ...doc.data(), id: doc.id })
                })
                setReceivedNoti(
                    results.sort((a, b) => {
                        const aDate = new Date(a?.createdAt?.toDate())
                        const bDate = new Date(b?.createdAt?.toDate())

                        return bDate.getTime() - aDate.getTime()
                    })
                )
            })
            return unsubscribe
        }
    }, [user])

    return (
        <Menu placement='bottom-end'>
            <MenuButton
                as={IconButton}
                size={['md']}
                variant='ghost'
                color='brand.1'
                position='relative'
                // mt={1}
                icon={
                    <>
                        <FaBell fontSize={20} />
                        {!!receivedNoti.filter((v) => !v.seen).length && (
                            <Badge
                                as={HStack}
                                position={'absolute'}
                                right={'1%'}
                                top={'-6%'}
                                zIndex='1'
                                colorScheme={'red'}
                                minW='20px'
                                minH='20px'
                                p={1}
                                fontSize={'10px'}
                                variant='solid'
                                rounded={'full'}
                                alignItems='center'
                            >
                                <Text>{receivedNoti?.filter((v) => !v.seen).length}</Text>
                            </Badge>
                        )}
                    </>
                }
                aria-label='Options'
            />
            <MenuList
                zIndex={10}
                py={0}
                pos='relative'
                sx={{
                    minW: '320px',
                    maxH: '350px',
                    minH: receivedNoti.length > 0 ? '240px' : '100px',
                    overflow: 'auto',
                }}
                display='flex'
                flexDirection='column'
            >
                {!!receivedNoti.length && (
                    <HStack
                        pos='sticky'
                        top='0%'
                        bg='gray.50'
                        py={['2', '3']}
                        px={['2']}
                        zIndex={5}
                        borderBottom={(theme) => `1px solid` + theme.colors.gray['200']}
                    >
                        <Button
                            size='xs'
                            variant={'outline'}
                            isLoading={seenLoading}
                            fontSize={'10px'}
                            fontWeight='600'
                            cursor={'pointer'}
                            disabled={!receivedNoti.filter((v) => !v.seen).length}
                            onClick={handleAllRead}
                        >
                            {seenLoading ? 'Loading' : 'Mark all as read'}
                        </Button>
                        <Button
                            size='xs'
                            variant={'outline'}
                            isLoading={removeLoading}
                            fontSize={'10px'}
                            fontWeight='600'
                            cursor={'pointer'}
                            // disabled={!receivedNoti.filter((v) => !v.seen).length}
                            onClick={handleRemoveAllNoti}
                            rightIcon={<DeleteIcon />}
                            border={'1px solid'}
                            color='red.300'
                            borderColor={'red.300'}
                            _hover={{
                                bg: 'red.50',
                            }}
                        >
                            {removeLoading ? 'Loading' : 'Remove All'}
                        </Button>
                    </HStack>
                )}
                {receivedNoti.length > 0 ? (
                    receivedNoti.map((v, i) => {
                        let newUser = 'N U'
                        return (
                            <MenuItem
                                key={i}
                                sx={{
                                    bg: !v.seen ? (v?.reviewRef ? 'teal.50 !important' : 'blue.100') : 'white',
                                    maxW: 'xs',
                                    flexWrap: 'wrap',
                                    borderBottom: (theme) => `1px solid ${theme.colors.gray['100']}`,
                                }}
                                _hover={{
                                    bg: !v.seen ? (v?.reviewRef ? 'teal.50' : 'blue.50') : 'gray.100',
                                }}
                                _focus={{
                                    bg: 'white',
                                }}
                                cursor='pointer'
                                pos='relative'
                                onClick={() => handleSeen(v?.reviewRef, v.id)}
                            >
                                <Box
                                    transition="all 0.3s ease"
                                    bg="red.50"
                                    px={1}
                                    rounded="sm"
                                    color="red.500"
                                    fontSize={["xs"]}
                                    position='absolute'
                                    top='5%'
                                    right='3%'
                                    onClick={(e) => handleRemoveNoti(e, v.id)}
                                    _hover={{
                                        bg: "red.100"
                                    }}
                                >
                                    <DeleteIcon />
                                </Box>
                                <HStack alignItems={'start'}>
                                    <Avatar
                                        size={'sm'}
                                        src={' '}
                                        name={(v?.reviewRef ? v?.sender_id : newUser) || ''}
                                        alt={v?.sender_id || ''}
                                    />
                                    <Text fontWeight={'medium'} color={v?.admin && 'green.400'}>
                                        {v?.title}
                                    </Text>
                                </HStack>
                                <Text
                                    fontSize={'small'}
                                    pl={'10'}
                                    position={'relative'}
                                    bottom={'1'}
                                    color={v?.admin && 'teal.500'}
                                    dangerouslySetInnerHTML={{ __html: v?.message }}
                                >
                                    {/* {v?.message} */}
                                </Text>
                                <Text
                                    fontSize={'xx-small'}
                                    pl={'10'}
                                    color={!v.seen ? 'teal.500' : 'gray.600'}
                                    fontWeight={!v.seen && 'bold'}
                                >
                                    {formatDistance(
                                        new Date(v?.createdAt && v?.createdAt?.toDate()),
                                        new Date(),
                                        { addSuffix: true }
                                    )}
                                </Text>
                            </MenuItem>
                        )
                    })
                ) : (
                    <HStack
                        justifyContent={'center'}
                        alignItems='center'
                        flex={1}
                        h='full'
                        _hover={{ bg: 'white' }}
                    >
                        <Text> No Notifications!</Text>
                    </HStack>
                )}
            </MenuList>
        </Menu>
    )
}

export default NotificationMenu