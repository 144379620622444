import React, { useState} from 'react'
import {
  Text,
  ModalOverlay,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  chakra,
  HStack,
  Box,
  Icon,
  VStack,
  useDisclosure,
  Tooltip,
  IconButton,
  Button
} from '@chakra-ui/react'
import { BiTimer } from 'react-icons/bi'
import { FaHandshake } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import ExpressCampaignModal from './ExpressCampaignModal'
import { RiVideoAddFill } from 'react-icons/ri'
import { BsPlusLg } from 'react-icons/bs'
import WelcomeUserModal from './WelcomeUserModal'
import { ExpressRegularModal } from './ExpressRegularModal'


export const ExpressButton = ({ user }) => {
  //   express setup modal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(false)

  const navigate = useNavigate()


  return (
    <>
 
      <ExpressRegularModal user={user}
        isOpen={isWelcomeOpen}
        onClose={() => setIsWelcomeOpen(false)}/>
        <Button
          leftIcon={<BsPlusLg />}
          size={['sm', 'md']}
          variant='solid'
          py={'4'}
          onClick={() => {
            setIsWelcomeOpen(true)
        }}
        >
          Create a New Campaign
        </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay bg='blackAlpha.400' backdropFilter='blur(4px) hue-rotate(5deg)' />
        <ModalContent mx={['2', '4']} maxW={['29rem']}>
          <ModalHeader pb={2}>
            <HStack w='full' spacing={0} gap={[1, 2]} alignItems='center' mt={[2, 4]} justifyContent='flex-start'>
              <Text
                fontSize={['18px', '24px']}
                fontWeight='600'
                color='#474747'
              // as={HStack} w='full' spacing={0} gap={2} alignItems='center'
              >
                Welcome to <chakra.span color='brand.1'>Sendmea</chakra.span> {user?.firstName},
              </Text>
              <Tooltip
                placement='bottom'
                bg='teal.400'
                rounded='md'
                hasArrow
                label="Watch video" >
                <IconButton
                  cursor='pointer'
                  variant='ghost'
                  icon={<RiVideoAddFill />}
                  rounded='full'
                  color='teal.300'
                  mt={'2px !important'}
                  fontSize={['1.2rem', '1.6rem']}
                  onClick={() => window.open(`https://www.youtube.com/watch?v=gP0W9Nfa_1w`)}
                  _hover={{
                    transform: 'scale(1.2)',
                    bg: 'whiteAlpha.300',
                    color: 'brand.2',
                  }}
                />
              </Tooltip>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={[6]} as={VStack} gap={[4]}>
            <Text fontSize={['sm', 'md']} fontWeight='400'>
              <chakra.span fontWeight='bold' color='teal.300'>
                Express Setup{' '}
              </chakra.span>
              takes 15 seconds to send your first request for a video review!
              <br />
              <br />
              <chakra.span fontWeight='bold' color='teal.300'>
                Business Setup{' '}
              </chakra.span>
              is for fully customizing your brand and video review wall! <br />
              <br />
              Don't worry, you can do Business Setup later!
            </Text>
            <HStack spacing='3' alignItems='center' justifyContent='center' width='full'>
              <Box
                width={['full']}
                p={[3, 4]}
                bg='teal.50'
                shadow='md'
                transition='all 0.3s ease'
                rounded='md'
                cursor='pointer'
                _hover={{
                  bg: 'teal.400',
                  transform: 'scale(1.02)',
                  '& >*': {
                    color: 'white',
                  },
                }}
                onClick={() => {
                  onOpen()
                  // onClose()
                }}
              >
                <Icon color='teal.500' as={BiTimer} fontSize={['35px']} />
                <Text color='teal.500' fontSize={['md', 'lg']} fontWeight='900'>
                  Express Setup
                </Text>
              </Box>
              <Box
                width={['full']}
                p={[3, 4]}
                bg='teal.50'
                shadow='md'
                transition='all 0.3s ease'
                rounded='md'
                cursor='pointer'
                _hover={{
                  bg: 'teal.400',
                  transform: 'scale(1.02)',
                  '& >*': {
                    color: 'white',
                  },
                }}
                onClick={() => {
                  navigate('/dashboard/business-details')
                  onClose()
                }}
              >
                <Icon color='teal.500' as={FaHandshake} fontSize={['35px']} />
                <Text color='teal.500' fontSize={['md', 'lg']} fontWeight='900'>
                  Business Setup
                </Text>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

