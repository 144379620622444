import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Text, chakra, Box, Image, Stack, Button, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { forceVisible } from 'react-lazyload'
import { Link } from 'react-router-dom'

const data = [
  {
    heading: 'When people trust and follow others when making decisions.',
    imgPath: '/images/icons/visibility.svg',
  },
  {
    heading: 'In video reviews, positive customer feedback influences others to buy.',
    imgPath: '/images/icons/seo.svg',
  },
  {
    heading: 'Social proof includes ratings, reviews, endorsements.',
    imgPath: '/images/icons/digital-campaign.svg',
  },
  {
    heading: 'Video reviews provide personal product insights.',
    imgPath: '/images/icons/sm.svg',
  },
  {
    heading:
      'Video reviews show products in real-life situations, allowing customers to hear from others.',
    imgPath: '/images/icons/vo.svg',
  },
  {
    heading:
      'Video reviews build trust and credibility, leading to increased sales and brand loyalty.',
    imgPath: '/images/icons/shuttle.svg',
  },
]

function Awareness() {

  useEffect(() => {
    forceVisible()
  }, [])

  return (
    <chakra.div
      // bg={'rgba(180, 224, 226, 0.1)'}
      position={'relative'}
      mt={['4']}
      // id='awareness'
      mb='4'
    >
      <Text
        fontSize={['1.85rem', '2rem']}
        fontWeight={'600'}
        mb={['6', '8']}
        pt={['10', '12']}
        textAlign={'center'}
      >
        <chakra.span color={'brand.1'}>What is Social </chakra.span>
        <chakra.span color={'brand.2'}>Proof?</chakra.span>
      </Text>

      <Flex direction={{ base: 'column', sm: 'column', md: 'row' }} py={8} px={['4', '5', '6']}
        alignItems={['stretch', 'center', 'center']}
        justifyContent={['center', 'center']}
        gap={[10, 10, 6, 10]}
      >

        <Box>
          <chakra.span
            sx={{
              position: 'absolute',
              minWidth: { sm: '100%', md: '45%' },
              borderRadius: '0 50% 50% 0',
              height: '100%',
              top: '0px',
              backgroundColor: (theme) => theme.colors.brand['1'] + '12',
              zIndex: 0,
            }}
          ></chakra.span>
          <chakra.div p={['90px', '100px', '32']}>
            <Image src='/images/awareness.png' alt='' />
          </chakra.div>
        </Box>
        <Box pt={'8'} ml={3} mb={'3'}>
          {data.map((v) => (
            <Stack key={v.heading} pr={['8', '24', '36']} py={'3'} direction={'row'}>
              <chakra.span
                position={'relative'}
                w={['16']}
                mb={'auto'}
                mr={'2'} //</Box> sx={{ px: 3, py: 3, borderRadius: "50%" }} border={'1px solid'} borderColor={'brand.1'} alignSelf={'baseline'} mr={'4'}>
              >
                <chakra.div sx={{ width: ['48px', '54px'], height: ['48px', '54px'] }}>
                  <Image
                    src='/images/icons/circle-dashed.svg'
                    alt=''
                    position={'absolute'}
                    sx={{ top: 0, bottom: 0, left: 0, right: 0 }}
                    m={'auto'}
                  />
                </chakra.div>
                <Image
                  src={v.imgPath}
                  alt=''
                  position={'absolute'}
                  sx={{
                    width: ['25px', 33],
                    height: ['25px', 33],
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                  m={'auto'}
                />
              </chakra.span>
              <Text fontWeight='medium' sx={{ marginTop: '5px !important' }} fontSize={['1.1rem']}>
                {v.heading}
              </Text>
            </Stack>
          ))}
          <chakra.div my={'10'} right={[null, null, '16', null, null]} position={'relative'}>
            <Button as={Link} to={'/register'} w={'36'} variant={'solid'}>
              Sign up <ArrowForwardIcon w={'10'} />
            </Button>
          </chakra.div>
        </Box>
      </Flex>

    </chakra.div>
  )
}

export default Awareness
