import { Text, chakra, SimpleGrid, Box, Image, VStack, Tooltip } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'


const data = [
  {
    heading: 'B2B companies',
    imgPath: '/images/icons/b2b.svg',
  },
  {
    heading: 'Catering',
    imgPath: '/images/icons/food-delivery.svg',
  },
  {
    heading: 'Cleaning Services',
    imgPath: '/images/icons/mop.svg',
  },
  {
    heading: 'Creators',
    imgPath: '/images/icons/bulb.svg',
  },
  {
    heading: 'Coaches',
    imgPath: '/images/icons/coach.svg',
  },

  {
    heading: 'Dentists',
    imgPath: '/images/icons/floss.svg',
  },
  {
    heading: 'Event Planners',
    imgPath: '/images/icons/confetti.svg',
  },

  {
    heading: 'Freelancers',
    imgPath: '/images/icons/freelancer.svg',
  },
  {
    heading: 'Home Services',
    imgPath: '/images/icons/house.svg',
  },
  {
    heading: 'Landscaping',
    imgPath: '/images/icons/park.svg',
  },

  {
    heading: 'Marketers',
    imgPath: '/images/icons/analytics.svg',
  },


  {
    heading: 'Real Estate Agents',
    imgPath: '/images/icons/realestate.svg',
  },
  {
    heading: 'Real Estate Investors',
    imgPath: '/images/icons/profit.svg',
  },

  {
    heading: 'Travel Agencies',
    imgPath: '/images/icons/passport.svg',
  },
  {
    heading: 'Web & Software',
    imgPath: '/images/icons/computer.svg',
  },
]

function Sales() {


  return (
    <VStack
      bg={'rgba(180, 224, 226, 0.1)'}
      pb={'8'}
      py={['8', '12']}
      my={10}
      w={'inherit'}
      id='sales'
      alignItems='center'
    >
      <Text fontSize={['1.85rem', '2rem']} fontWeight={'600'} mb={['6', '8']} textAlign={'center'}>
        <chakra.span color={'brand.1'}>Who Uses</chakra.span>
        <chakra.span color={'brand.2'} ml={2}>
          Sendmea?
        </chakra.span>
      </Text>
      <SimpleGrid
        columns={{ base: 3, md: 5 }}
        spacing={0}
        gap={['4', '8', '12']}
        maxW={'container.lg'}
        mx='auto'
        pb={5}
      >
        {data.map((v, idx) => (
          <VStack key={v.imgPath + idx} px={['4', '8']} alignItems='center'>
            <Tooltip label='companies'>
            <chakra.div position={'relative'} w={'16'} my={['4', '6']} mr={'2'}>
              <chakra.div sx={{ width: '66px', height: '66px' }}>
                <Image
                  src='/images/icons/circle pro.png'
                  alt=''
                  position={'absolute'}
                  sx={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: 999 }}
                  m={'auto'}
                />
              </chakra.div>
              <Image
                src={v.imgPath}
                alt=''
                position={'absolute'}
                sx={{
                  width: '34px',
                  height: '34px',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
                m={'auto'}
              />
            </chakra.div>
            </Tooltip>
            <Text
              color={'gray.600'}
              fontWeight={'600'}
              fontSize={['md', 'lg', 'xl']}
              textAlign='center'
            >
              {v.heading}
            </Text>
          </VStack>
        ))}
      </SimpleGrid>
    </VStack>
  )
}

export default Sales
