import React, { useState } from "react";
import {
  Button,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import CampaignURLCard from "./Card";
import SitemapUpdater from "../../../../components/sitemap-updater";

const CampaignUrl = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState(null);

  const fetchLatestCampaigns = async () => {
    setLoading(true);
    try {
      const collRef = collection(db, "campaigns");
      const queryRef = query(collRef, orderBy("createdAt", "desc"), limit(10));
      let data = await getDocs(queryRef);

      let getCampaigns = await Promise.all(
        data.docs.map(async (campaignSnap) => {
          const customURlQuery = await getDocs(
            query(collection(db, "campaigns", campaignSnap.id, "vanity"))
          );
          const customName = customURlQuery?.empty
            ? null
            : customURlQuery?.docs[0]?.data()?.vanityName;

          let avgRating = 0;
          let approvedReviews = 0;

          if (
            "avgRating" in campaignSnap.data() &&
            "approvedReviews" in campaignSnap.data()
          ) {
            avgRating = campaignSnap.data()?.avgRating;
            approvedReviews = campaignSnap.data()?.approvedReviews;
            // console.log('from database', { avgRating, approvedReviews })
          } else {
            const queryTestimonial = query(
              collection(db, "reviews"),
              where("campaignId", "==", campaignSnap.id),
              where("approve", "==", true)
            );
            const getReviews = await getDocs(queryTestimonial);
            avgRating = getReviews.docs
              .map((review) => review.data()?.rating)
              .filter((rating) => !!rating);
            avgRating =
              avgRating.length > 0
                ? avgRating.reduce((sum, rating) => sum + rating, 0) /
                  avgRating.length
                : 0;
            approvedReviews = getReviews.size;
            // console.log('from mapping', { avgRating, approvedReviews })
          }

          return {
            ...campaignSnap.data(),
            id: campaignSnap.id,
            avgRating: avgRating || 0,
            totalReviews: approvedReviews,
            customName,
          };
        })
      );
      setCampaigns(getCampaigns);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <Stack
      // mt={10}
      bg={"gray.50"}
      rounded={"xl"}
      py={{ base: 4, sm: 6, md: 8 }}
      px={{ base: 2, sm: 4, md: 8 }}
      width='full'
      spacing={8}>
      <SitemapUpdater />

      <HStack w='full' justifyContent='space-between' alignItems='flex-start'>
        <VStack alignItems='flex-start' spacing={3}>
          <Heading
            color={"brand.1"}
            lineHeight={1.1}
            fontSize={{ base: "xl", sm: "2xl" }}>
            Campaign URL
          </Heading>
          <Text fontSize={["lg"]}>Below are the latest 10 Campaigns</Text>
        </VStack>
        <Button
          size='sm'
          onClick={fetchLatestCampaigns}
          variant='solid'
          disabled={loading}>
          Fetch Urls
        </Button>
      </HStack>
      <VStack w='full' py={4} minH='200px'>
        {loading ? (
          <VStack justifyContent='center' alignItems='center' width='full'>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='teal.500'
              size={["md", "xl"]}
            />
          </VStack>
        ) : !campaigns?.length ? (
          <Text
            fontSize={"xl"}
            fontStyle='italic'
            mt={5}
            color='gray.500'
            fontWeight='bold'>
            Campaigns Not Fetched yet
          </Text>
        ) : (
          <SimpleGrid
            spacing={["4"]}
            // columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
            // mx={'auto'}
            maxW='1450px'
            minChildWidth={{ base: "full", sm: "280px", md: "300px" }}
            justifyItems='stretch'
            w='full'>
            {campaigns.map((data) => (
              <CampaignURLCard key={data.id} data={data} />
            ))}
          </SimpleGrid>
        )}
      </VStack>
    </Stack>
  );
};

export default CampaignUrl;
