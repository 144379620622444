import React from 'react'
import { Box, chakra, Text, useColorModeValue as mode } from '@chakra-ui/react'
import { cookieData } from './cookieData'
import { Helmet } from 'react-helmet-async'
import { Seo } from '../../components/Seo'

function replaceWithBr() {
  return cookieData.replace(/\n/g, '<br />')
}

function Manage() {
  return (
    <>
      <Seo
        title='Sendmea Real Video Reviews | Cookie Management'
        description='Cookie management covers how personal information and data is stored and used. Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand.'
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='privacy policy, cookies, cookie management, video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />

      <Box
        p='6'
        my='8'
        bg='bg-surface'
        boxShadow={mode('lg', 'lg-dark')}
        _groupHover={{ boxShadow: mode('xl', 'xl-dark') }}
        transition='all 0.2s'
        w='90%'
      >
        <Text fontSize={['1.85rem', '2rem']} fontWeight={'600'} m={'4'} textAlign={'center'}>
          <chakra.span color={'brand.1'}>Cookie</chakra.span>{' '}
          <chakra.span color={'brand.2'}>Policy</chakra.span>
        </Text>

        <Box>
          <p dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
        </Box>
      </Box>
    </>
  )
}

export default Manage
