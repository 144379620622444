import {
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useColorModeValue as mode,
  chakra,
  Circle,
  HStack,
  Icon,
  useColorModeValue,
  Flex,
  useBreakpointValue,
  Link,
  Button,
} from '@chakra-ui/react'
import React from 'react'
import { faqs, faqsAffiliate } from './data'
import { FiMinus } from 'react-icons/fi'
import { Seo } from '../../components/Seo'

function Faq() {
  return (
    <>
      <Seo
        title='Sendmea Real Video Reviews | FAQs'
        description='Sendmea FAQ has the answers you seek! Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand. Let Sendmea help your happy customers increase your market share, improve referrals, and gain greater industry credibility.'
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='faq, questions, answers, video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />

      <Flex justify={'center'} alignItems='center' direction={'column'}>
        <Flex
          direction={useBreakpointValue({ base: 'column', md: 'row', sm: 'column' })}
          w='100%'
          py='6'
          mb='16'
          justify='center'
          alignContent={'center'}
        >
          <Box px='4' w={useBreakpointValue({ base: 'full', md: '22rem', sm: 'full' })}>
            <Text
              color='brand.1'
              fontSize={['1.85rem', '2rem']}
              fontWeight={'500'}
              my={'8'}
              textAlign={'center'}
            >
              Send<chakra.span color={'brand.2'}>mea </chakra.span> FAQS
            </Text>
          </Box>
          <Box
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'md'}
            p={8}
          >
            <Accordion defaultIndex={[0]} allowMultiple>
              {faqs.map((faq) => (
                <AccordionItem p={['2', '4']} key={faq.id}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton _expanded={{ bg: 'brand.1', color: 'white' }}>
                        <Box as='section' flex='1' textAlign='left'>
                          {isExpanded ? (
                            <Text fontSize={['1rem', '1.2rem']} color='white' fontWeight={'400'}>
                              {' '}
                              {faq.question}
                            </Text>
                          ) : (
                            <Text fontSize={['1rem', '1.2rem']} color='gray.500' fontWeight={'400'}>
                              {faq.question}
                            </Text>
                          )}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel bg={'rgba(180, 224, 226, 0.1)'} pb={4}>
                        {faq.answers.map((answer) => (
                          <HStack key={answer} as='li' spacing='3'>
                            <Circle size='6' bg={mode('blue.50', 'whiteAlpha.50')}>
                              <Icon as={FiMinus} color='accent' />
                            </Circle>
                            <Text color='muted'>{answer}</Text>
                          </HStack>
                        ))}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default Faq
