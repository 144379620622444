import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs, } from '@chakra-ui/react'
import React, { useState } from 'react'
import DashboardSupport from './components/DashboardSupport/DashboardSupport'
import ContactSupport from './components/ContactSupport/Contact'

const Support = () => {

    const [tabIndex, setTabIndex] = useState(0)


    return (
        <Stack
            // mt={10}
            bg={'gray.50'}
            rounded={'xl'}
            py={{ base: 4, sm: 6, md: 8 }}
            px={{ base: 2, sm: 4, md: 8 }}
            width='full'
            spacing={8}
        >
            {/* <HStack w='full' justifyContent='space-between' alignItems='flex-start'>
                <VStack alignItems='flex-start' spacing={3}>
                    <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
                        Support
                    </Heading>
                    <Text fontSize={['lg']}>
                        Below are the latest 50 Support Logs
                    </Text>
                </VStack>
                </HStack> 
            </HStack> */}
            <Tabs onChange={(index) => {
                setTabIndex(index)
            }} defaultIndex={1} index={tabIndex} size={'sm'}>

                <TabList gap="4px" justifyContent={["center", "center", "flex-start"]} mx={[0]}
                    sx={{
                        // position: 'absolute',
                        width: "max-content",
                        border: "2px solid",
                        borderRadius: "3px",
                        '& > button': {
                            fontWeight: "medium",
                            minW: "100px",
                            mb: 0,
                            _selected: {
                                borderColor: 'inherit',
                                color: 'white', bg: 'teal.500',
                            }
                        },
                    }}
                    borderColor={theme => theme.colors.teal["500"] + " !important"}
                >
                    <Tab>Support</Tab>
                    <Tab>Contact</Tab>
                </TabList>

                <TabPanels sx={{
                    // '& > .chakra-tabs__tab-panel': {
                    //     paddingTop: '80px',
                    // },
                    // '& > .chakra-tabs__tab-panel > div': {
                    //     // marginTop: '100px'
                    // }
                }}>
                    <TabPanel p={[0, 2]}>
                        <DashboardSupport />
                    </TabPanel>
                    <TabPanel p={[0, 2]}>
                        <ContactSupport />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack >
    )
}

export default Support