import { CloseIcon } from '@chakra-ui/icons'
import { AspectRatio, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, chakra } from '@chakra-ui/react'
import React from 'react'

function WelcomeVideoModal({ onClose, isOpen, campaign }) {
    return (
        <Modal
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            scrollBehavior='inside'
        >
            <ModalOverlay backgroundColor={['#000000cc', '-moz-initial']} />
            <ModalContent maxWidth={['100%', null, '500px']}>
                <ModalHeader pb='2'>
                    {campaign?.liveWelcomeVideo && <Text fontSize={['22px']} marginTop={{ base: 6, sm: 0 }} color='brand.1' fontWeight='bold'
                        textTransform={'capitalize'}>{campaign?.welcomeVideoTitle || 'Welcome Video!'}</Text>}
                </ModalHeader>
                {/* <ModalCloseButton /> */}
                <Button onClick={onClose} variant={'ghost'}
                    colorScheme='teal'
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        position: 'absolute',
                        right: 2,
                        px: 1
                    }}
                    top={[1, null, 3]}
                >Close welcome video &nbsp; <CloseIcon /></Button>
                <ModalBody minHeight={['100px']}>
                    {campaign?.liveWelcomeVideo && (
                        <VStack w={'100%'}>
                            <AspectRatio ratio={{ base: 18 / 9 }}
                                bg={'blackAlpha.300'} borderRadius={'8px'}
                                w={['100%']}
                            >
                                <chakra.video src={campaign?.welcomeVideo}
                                    borderRadius={'8px'}
                                    objectFit='cover'
                                    w={'100%'} loop controls playsInline autoPlay
                                />
                            </AspectRatio>
                        </VStack>
                    )}
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default WelcomeVideoModal