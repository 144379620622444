import { lazy, Suspense } from 'react'
import NewHero from './components/Hero'
import Benefits from './components/Benefits'
import GetStarted from './components/GetStarted'
import StartWith from './components/StartWith'
import Sales from './components/Sales'
import Campaigns from './components/Campaigns'
import Pricing from './components/Pricing'
import Awareness from './components/Awareness'
import { Box, useBreakpointValue } from '@chakra-ui/react'

import LazyLoad from 'react-lazyload'
import { Seo } from '../../components/Seo'

const LandingComponent = lazy(() => import('./Landing.js' /* webpackPrefetch: true */))

const Landing = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
  })

  return (
    <Suspense>
      <Seo
        title='Sendmea Real Video Review - 5 Star Reviews'
        description='Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand. Video reviews are the most trusted and valuable of all reviews, and build consumer trust and confidence. Let Sendmea help your happy customers increase your market share, improve referrals, and gain greater industry credibility.'
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />
      <Box id='landingPage' maxW={'7xl'} m={'auto'}>
        <Box id='getting-started' minH='100px' py={2}>
          <LazyLoad height={200}>
            <NewHero />
          </LazyLoad>
        </Box>

        <Box id='benefits' minH='100px' py={2}>
          <LazyLoad height={200}>
            <Benefits />
          </LazyLoad>
        </Box>

        <LazyLoad height={200}>
          <Sales />
        </LazyLoad>

        <Box id='pricing' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <Pricing />
          </LazyLoad>
        </Box>

        <Box id='startWith' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <StartWith />
          </LazyLoad>
        </Box>

        <Box id='awareness' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <Awareness />
          </LazyLoad>
        </Box>

        {!isMobile && <Box id='getStarted' minH='100px' pt={2}>
          <LazyLoad height={200}>
            <GetStarted />
          </LazyLoad>
        </Box>}

        {!isMobile && <Box id='campaigns' minH='100px' pt={0}>
          <LazyLoad height={200}>
            <Campaigns />
          </LazyLoad>
        </Box>}
      </Box>
    </Suspense>
  )
}

export default Landing
