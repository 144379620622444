import {
  Button,
  Container,
  Heading,
  Spinner,
  chakra,
  VStack,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { db, firebaseFunction } from "../../firebase/config";
import { getCustomClaimRole } from "../../firebase/stripeConfig";
import { userState } from "../../recoil/atoms/auth";
import {
  DeletePrompt,
  cloudfunctionsBaseURL,
  discoutConstant,
} from "../../utils";
import { useCustomToast } from "../../hooks/customToast";

const Billing = () => {
  const {
    isOpen: openCancel,
    onOpen: onOpenCancel,
    onClose: onCloseCancel,
  } = useDisclosure();

  // const [plans, setPlans] = useState(null)
  const { addToast } = useCustomToast();
  const [stripeLoading, setStripeLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [user] = useRecoilState(userState);
  const [priceData, setPriceData] = useState(null);
  const [subscriptionInfo, setSubscription] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [role, setRole] = useState("");
  // useEffect(() => {
  //     (async () => {
  //         const plans = await getPlans()
  //         setPlans(plans)
  //     })()
  // }, [])

  // Billing portal handler

  useEffect(() => {
    /**
     * Data listeners
     */
    const startDataListeners = async () => {
      // Get all our products and render them to the page
      // const products = document.querySelector(".products");
      // const template = document.querySelector("#product");
      // [...] add below your existing code
      // Get all active or trialing subscriptions for the customer
      const userSubsRef = collection(db, "users", user.uid, "subscriptions");
      // const userPaymentRef = collection(db, 'users', user.uid, 'payments')
      // const snapPayment = await getDocs(userPaymentRef)
      // console.log(snapPayment.data(), ' asfas')
      const q = query(
        userSubsRef,
        where("status", "in", ["trialing", "active"])
      );
      onSnapshot(q, async (snapshot) => {
        if (snapshot.empty) {
          // Show products
          // document.querySelector("#subscribe").style.display = "block";
          return;
        }
        setRole(await getCustomClaimRole());
        // document.querySelector("#subscribe").style.display = "none";
        // document.querySelector("#my-subscription").style.display = "block";
        // In this implementation we only expect one Subscription to exist
        const subscription = snapshot.docs[0].data();
        const subscriptionId = snapshot.docs[0].id;
        setSubscriptionId(subscriptionId);
        const price = subscription.items?.[0]?.price;
        setSubscription(subscription);
        setPriceData(price);
        // document.querySelector(
        //     "#my-subscription p"
        // ).textContent = `You are paying ${new Intl.NumberFormat("en-US", {
        //     style: "currency",
        //     currency: priceData?.currency
        // }).format((priceData.unit_amount / 100).toFixed(2))} per ${priceData.recurring.interval
        //     }, giving you the role: ${await getCustomClaimRole()}. 🥳`;
      });
    };
    startDataListeners();
  }, []);

  const handleCustomerPortal = async (event) => {
    try {
      // document.querySelectorAll('button').forEach((b) => (b.disabled = true));
      setStripeLoading(true);
      // Call billing portal function
      const functionRef = httpsCallable(
        firebaseFunction,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const result = await functionRef({
        returnUrl: `${window.location.origin}/dashboard`,
        locale: "auto",
      });
      const data = result.data;
      window.location.assign(data.url);
    } catch (err) {
      console.log(err.message);
      setStripeLoading(false);
    }
  };
  const handleCancelSubscription = async (event) => {
    try {
      setCancelLoading(true);
      // cancel billing portal function
      const res = await fetch(
        `${cloudfunctionsBaseURL}handleCancelSubscription`,
        {
          // const res = await fetch('http://127.0.0.1:5001/sendmea-c7d45/us-central1/handleCancelSubscription', {
          method: "POST",
          body: JSON.stringify({
            subscription_id: subscriptionId,
          }),
        }
      );
      setCancelLoading(false);
      addToast({
        title: "Subscription!",
        description: "Upcoming subscription cancelled",
        status: "success",
        variant: "left-accent",
        duration: 5000,
      });
      onCloseCancel();
    } catch (err) {
      console.log(err.message);
      setCancelLoading(false);
    }
  };
  return (
    <Container maxW={["container.lg"]} px={{ base: 0, sm: 2 }}>
      <Heading
        color={"brand.1"}
        lineHeight={1.1}
        fontSize={{ base: "2xl", sm: "4xl" }}>
        Billing Info
      </Heading>
      <HStack ml={"2"} mt={"5"}>
        <section className='products'></section>
        {!priceData || !role ? (
          <VStack
            justifyContent='center'
            alignItems='center'
            width='full'
            minH='calc(100vh - 20vh)'>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='teal.500'
              size={["md", "xl"]}
            />
          </VStack>
        ) : (
          <section id='my-subscription'>
            <Heading
              color={"brand.1"}
              lineHeight={1.1}
              fontSize={{ base: "xl", sm: "2xl" }}
              my={"2"}
              fontWeight={"bold"}>
              Your subscription
            </Heading>
            <chakra.p fontSize={"md"}>
              You are paying{" "}
              <chakra.span textTransform={"capitalize"} fontWeight={"bold"}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: priceData.currency,
                }).format(
                  (
                    (priceData.unit_amount / 100) *
                    (user?.referral ? discoutConstant : 1)
                  ).toFixed(2)
                )}
              </chakra.span>{" "}
              per{" "}
              <chakra.span textTransform={"capitalize"} fontWeight={"bold"}>
                {priceData.recurring.interval}
              </chakra.span>
              , giving you the role:{" "}
              <chakra.span textTransform={"capitalize"} fontWeight={"bold"}>
                {role}
              </chakra.span>
              . 🥳
            </chakra.p>
            <chakra.p fontSize={"md"}>
              Your subscription ends at{" "}
              <chakra.span textTransform={"capitalize"} fontWeight={"bold"}>
                {format(
                  subscriptionInfo?.current_period_end?.toDate(),
                  "MM-dd-yyyy"
                )}
                .
              </chakra.span>
            </chakra.p>

            <chakra.h3 mb={"2"} fontSize={"lg"} fontWeight={"500"}>
              View invoices, update subscription & payment methods
            </chakra.h3>
          </section>
        )}
      </HStack>
      <Button
        ml={"4"}
        variant={"outline"}
        id='billing-portal-button'
        fontWeight={"bold"}
        isLoading={stripeLoading}
        onClick={handleCustomerPortal}>
        Access User portal
      </Button>

      <VStack alignItems={"flex-start"} ml={"4"}>
        <Heading
          color={"brand.1"}
          lineHeight={1.1}
          fontSize={{ base: "lg" }}
          mt={"16"}
          fontWeight={'medium'}
        >
          If you would like to check your account to modify or cancel, Access
          User Portal by clicking the button above.
        </Heading>
        {/* <Button
                    my={'10px !important'}
                    variant={'solid'}
                    bg='red.500'
                    _hover={{
                        bg: 'red.400'
                    }}
                    fontWeight={'bold'}
                    isLoading={cancelLoading}
                    onClick={() => onOpenCancel()}
                    disabled={subscriptionInfo?.cancel_at_period_end}
                >
                    Click Here!
                </Button> */}
        <chakra.p
          color={"red.400"}
          lineHeight={1.1}
          fontSize={{ base: "sm", sm: "md" }}
        //   fontWeight={"bold"}
          maxW={"500px"}>
          {subscriptionInfo?.cancel_at_period_end
            ? "**You have already cancel the upcoming subscription**"
            : "Note: If you cancel, This will end your subscription on the last paid up to date, and does not provide for a refund."}
        </chakra.p>
      </VStack>
      <DeletePrompt
        isOpen={openCancel}
        onClose={() => {
          onCloseCancel();
        }}
        accept={handleCancelSubscription}
        loading={cancelLoading}
        text={"Are you sure you want to cancel the subscription?"}
      />
    </Container>
  );
};

export default Billing;
