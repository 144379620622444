import React from 'react'
import { Box, chakra, Text, useColorModeValue as mode, Stack, VStack } from '@chakra-ui/react'
import { privacyData } from './privacyData'
import { Seo } from '../../components/Seo'

function replaceWithBr() {
  return privacyData.replace(/\n/g, '<br />')
}

function Policy() {
  return (
    <Stack px={['4', '6']}>
      <Seo
        title='Sendmea Real Video Reviews | Privacy Policy'
        description='Privacy policy covers terms and policy related to personal information and data. Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand. '
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='privacy policy, video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />

      <Box
        p='6'
        my='8'
        bg='bg-surface'
        boxShadow={mode('xl', 'xl-dark')}
        _groupHover={{ boxShadow: mode('xl', 'xl-dark') }}
        transition='all 0.2s'
        maxW={'container.lg'}
        mx='auto'
      >
        <Text fontSize={['1.85rem', '2rem']} fontWeight={'600'} m={'4'} textAlign={'center'}>
          <chakra.span color={'brand.1'}>Privacy</chakra.span>{' '}
          <chakra.span color={'brand.2'}>Policy</chakra.span>
        </Text>

        <VStack alignItems={'flex-start'} spacing={2}>
          <p dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
        </VStack>
      </Box>
    </Stack>
  )
}

export default Policy
