import React, { useEffect, useState, useRef } from "react"
import { db } from '../../../../firebase/config'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { useForm, useFieldArray } from 'react-hook-form'
import {
    Button, Input, FormControl,
    FormErrorMessage, VStack, FormLabel, Textarea, Box, Image, Text, Flex, HStack, Icon, useBreakpointValue
} from "@chakra-ui/react"
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from "../../../../firebase/config"
import { BsCamera } from 'react-icons/bs'
import { useCustomToast } from '../../../../hooks/customToast'
import { convertToMb } from "../../../../utils"

const CampaignDetailsForm = ({ selectedCampaign, tabIndex }) => {
    const [campaign, setCampaign] = useState(selectedCampaign)
    const [progress, setProgress] = useState(0)
    const [isEditable, setIsEditable] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const { addToast } = useCustomToast()

    const isMobile = useBreakpointValue({
        base: true,
        sm: true,
        md: false,
    })

    const initialValues = {
        campaignLogo: ''
    }

    const {
        handleSubmit,
        register,
        reset,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: initialValues,
    })

    const uploadToFirestore = async (values, campaignLogo) => {
        const docRef = doc(db, 'campaigns', selectedCampaign?.id)
        await updateDoc(docRef, { campaignDetails: { ...values, campaignLogo } })


        addToast({
            title: 'Campaign',
            description: 'Updated Successfully',
            status: 'success',
            variant: 'left-accent',
        })
        setIsEditable(false)
        setIsSubmitting(false)
    }


    const onSubmit = async (state) => {
        let { campaignLogo, ...values } = state
        setIsSubmitting(true)

        try {
            if (typeof campaignLogo === 'object') {
                uploadFile(campaignLogo, (e) => uploadToFirestore(values, e))
            } else {
                uploadToFirestore(values, campaignLogo)
            }
        } catch (error) {
            addToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                variant: 'left-accent',
            })
            setIsSubmitting(false)
        }
    }

    const handleImage = (e) => {
        const fileObj = e.target.files[0]
        let sizeInMB = convertToMb(fileObj)
        if (!fileObj) return
        if (!fileObj.type.includes('image')) {
            return addToast({
                title: 'Image',
                description: 'Can only upload images',
                status: 'error',
                variant: 'left-accent',
            })
        } else if (sizeInMB > 5) {
            addToast({
                title: 'Video',
                description: 'Size is greater than 5mb',
                status: 'error',
                variant: 'left-accent',
            })
        } else {
            setValue('campaignLogo', fileObj)
        }
    }

    const uploadInputRef = useRef(null)

    const uploadFile = (file, callback = () => { }) => {
        if (!file) return
        const storageRef = ref(storage, `campaign_pics/${selectedCampaign.userId}/${selectedCampaign?.id}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                setProgress(progress)
            },
            (error) => {
                addToast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    variant: 'left-accent',
                })
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setValue('campaignLogo', downloadURL)
                    callback(downloadURL)
                })
            }
        )
    }


    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                if (selectedCampaign) {
                    const docRef = doc(db, 'campaigns', selectedCampaign?.id)
                    const getCampaign = await getDoc(docRef)
                    console.log({ ...getCampaign.data() })
                    setCampaign({ ...getCampaign.data(), id: getCampaign.id })
                    if (getCampaign?.data()) {
                        reset({ ...campaign?.campaignDetails })
                    }
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
        })()
    }, [selectedCampaign, tabIndex])

    return (
        <div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack
                    spacing={3}
                    alignItems='stretch'
                    maxWidth={'700px'}
                >
               
            
                    <FormControl>
                        <FormLabel>Public Campaign Name</FormLabel>
                        <Input label="Public Campaign Name" name='publicCampaignName' {...register("publicCampaignName")} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Campaign Description</FormLabel>
                        <Textarea label="Public Campaign Name" name='campaignDescription' {...register("campaignDescription")} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Review Wall Url</FormLabel>
                        <Input label="Public Campaign Name" name='reviewWallUrl' {...register("reviewWallUrl")} />
                    </FormControl>
                    <Flex
                        flexWrap='wrap'
                        gap='10px'
                        alignItems='center'
                        minH={['max-content', 'max-content', '75px']}
                    >
                        <FormControl
                            maxWidth='540px'
                            isInvalid={!!errors?.campaignLogo}
                            width='full'
                            pos='relative'
                        >
                            <HStack
                                alignItems='center'
                                px={['2']}
                                py={['2', '3']}
                                bg={'gray.200'}
                                borderRadius='4px'
                                maxH='45px'
                                whiteSpace='pre'
                                overflow='hidden'
                                position={['absolute', 'absolute', 'relative']}
                                width='full'
                            >
                                <Button
                                    leftIcon={
                                        <Icon
                                            as={BsCamera}
                                            fontSize={['18px', '18px', '22px']}
                                            mr={['-2', '-2', '0']}
                                        />
                                    }
                                    size={['sm']}
                                    variant='solid'
                                    minW={['32px', '32px', '90px']}
                                    rounded={['full', 'full', 'md']}
                                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}

                                >
                                    {isMobile ? '' : 'Upload'}
                                </Button>
                                <Text
                                    fontSize={['sm', 'md']}
                                    pr={20}
                                    overflow='hidden'
                                    whiteSpace='pre'
                                    userSelect='none'
                                    fontStyle='italic'
                                >

                                    {watch('campaignLogo')
                                        ? typeof watch('campaignLogo') === 'object'
                                            ? watch('campaignLogo')?.name
                                            : watch('campaignLogo')
                                        : 'Click to upload logo'
                                    }
                                </Text>
                            </HStack>
                            <input
                                type='file'
                                accept='image/*'
                                style={{ display: 'none' }}
                                {...register('campaignLogo', {
                                    // required: 'This is required',
                                })}
                                ref={uploadInputRef}
                                onChange={handleImage}
                            />
                            {errors.campaignLogo && (
                                <FormErrorMessage>{errors.campaignLogo.message}</FormErrorMessage>
                            )}
                        </FormControl>

                        {watch('campaignLogo') && (
                            <Image
                                mt={['50px', '50px', '0px']}
                                objectFit='contain'
                                src={
                                    typeof watch('campaignLogo') === 'object'
                                        ? URL.createObjectURL(watch('campaignLogo'))
                                        : watch('campaignLogo')
                                }
                                alt='Dan Abrams'
                                width={['80px', '100px']}
                                height={['80px', '80px']}
                            />
                        )}
                    </Flex>


                </VStack>
                <HStack mt={5} justify={'space-between'}>  <Button type="submit">Submit</Button>   <Button as='a' href={`/wall/${selectedCampaign?.id}` } target='_blank' variant='solid' w='30%'>View Campaign</Button></HStack>
                
            </form>

        </div>
    )
};

export default CampaignDetailsForm;
