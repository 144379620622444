import React, { useEffect, useMemo, useState } from 'react'
import {
    Text,
    ModalOverlay,
    Modal,
    ModalBody,
    ModalHeader,
    ModalContent,
    chakra,
    VStack,
    ModalCloseButton,
    HStack,
    Spinner,
    SimpleGrid,
    TagLabel,
    TagLeftIcon,
    Tag,
    Checkbox,
    Tooltip,
    useDisclosure,
    IconButton,
    Input,
} from '@chakra-ui/react'
import { useCustomToast } from '../../../hooks/customToast'
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { format } from 'date-fns'
import { HiOutlineMail } from 'react-icons/hi'
import { AiFillCheckCircle, AiFillPhone } from 'react-icons/ai'
import { BiPencil, BiSave, BiSend } from 'react-icons/bi'
import { useForm } from 'react-hook-form'
import { MdCancel, MdPendingActions } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { cloudfunctionsBaseURL, errorToast, fireCanvaParticles, getCountryTelCode } from '../../../utils'
import { AsYouType } from 'libphonenumber-js'
import { userState } from '../../../recoil/atoms/auth'
import emailjs from '@emailjs/browser'


const iconMap = {
    email: HiOutlineMail,
    phone: AiFillPhone,
};

const MarkAsReceivedCheckBox = ({ onClick, val }) => {

    const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

    return (
        <Tooltip
            hasArrow
            border='1px solid'
            borderColor='teal.300'
            bg='teal.500'
            borderRadius='md'
            placement={'top-start'}
            isOpen={isOpen}
            label={!val?.received ? 'Mark As Received' : 'Received'}
        >
            <Checkbox
                type='checkbox'
                colorScheme={val?.received ? 'teal' : 'red'}
                disabled={!!val?.received}
                isChecked={Boolean(val?.received)}
                borderColor={val?.received ? 'gray.300' : 'red.200'}
                onChange={() => {
                    onClick()
                    onToggle()
                }}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                position={'absolute'} top='1%' right={'5%'}
            />
        </Tooltip>
    )
}
const InviteeInput = ({ log, campaign, countryCode }) => {

    const { isOpen, onOpen, onToggle, onClose } = useDisclosure()
    const { isOpen: isSendOpen, onOpen: onSendOpen, onClose: onSendClose } = useDisclosure()
    const [isEditName, setIsEditName] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSendLoading, setIsSendLoading] = useState(false)
    const { addToast } = useCustomToast()
    const user = useRecoilValue(userState)


    const {
        handleSubmit,
        register,
        reset,
        watch,
    } = useForm({
        defaultValues: {
            inviteeName: ''
        },
    })

    useEffect(() => {
        reset({ inviteeName: log?.inviteeName || '' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [log])

    const handleSaveName = async ({ inviteeName }) => {
        try {
            onToggle()
            if (!isEditName) return setIsEditName(true);
            if (!inviteeName || inviteeName === log?.inviteeName) return setIsEditName(false);
            setIsLoading(true)
            const inviteRef = doc(db, 'campaigns', campaign.id, 'invites', log?.id)
            await updateDoc(inviteRef, {
                inviteeName,
            })
            setIsEditName(false)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const onResendInvite = async () => {
        try {
            setIsSendLoading(true)
            // console.log(log)
            if (log?.type === 'phone') {
                const url = cloudfunctionsBaseURL + 'sendsms'
                let parsedNumber = new AsYouType().input(`${log.to}`)
                let raw = JSON.stringify({
                    to: parsedNumber,
                    messagingServiceSid: 'MGa9bf4774a2e1c820b3df43feaf1a7445',
                    body: `${log.message} ${window.location.origin}/review/${campaign.id}?inviteId=${log.id}`,
                })

                let requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow',
                }

                const response = await fetch(url, requestOptions)
                const result = JSON.parse(await response.text())
                if (result.statusCode === 200) {
                    await updateDoc(doc(db, 'campaigns', campaign?.id, 'invites', log.id), {
                        count: log?.count + 1
                    })
                    addToast({
                        title: 'Message Status!',
                        description: `Invite Sent Again Successfully`,
                        status: 'success',
                    })
                    onClose()
                } else {
                    throw Error(result.message)
                }
            } else if (log.type === 'email') {
                const name = log?.to?.split('@')[0]
                const templateParams = {
                    to_email: log?.to,
                    to_name: name.charAt(0).toUpperCase() + name.slice(1),
                    from_name: `${user?.firstName} ${user?.lastName}`,
                    campaign_link: `<a href="${window.location.origin}/review/${campaign.id}?inviteId=${log?.id}" target="_blank">${window.location.origin}/review/${campaign.id}?inviteId=${log.id}</a>`,
                    message: log?.message,
                }
                const response = await emailjs.send(
                    process.env.REACT_APP_SERVICE_ID,
                    process.env.REACT_APP_TEMPLATE_ID,
                    templateParams,
                    process.env.REACT_APP_PUBLIC_KEY
                )
                if (response.status === 200) {
                    await updateDoc(doc(db, 'campaigns', campaign?.id, 'invites', log.id), {
                        count: log?.count + 1
                    })
                    addToast({
                        title: 'Email Status!',
                        description: `Invite Sent Again Successfully`,
                        status: 'success',
                    })
                    onClose()
                }
            }
            setIsSendLoading(false)
            fireCanvaParticles()
        } catch (err) {
            setIsSendLoading(false)
            errorToast(err, addToast)
            console.log(err)
        }
    }

    return (
        <VStack w={'full'} alignItems={'flex-start'} spacing={1.5} as={'form'} onSubmit={handleSubmit(handleSaveName)}>
            <HStack w='full'>
                <Text fontSize={['sm']} fontWeight={'500'}>Name:</Text>
                <Tooltip
                    hasArrow
                    border='1px solid'
                    borderColor='teal.300'
                    bg='teal.500'
                    borderRadius='md'
                    placement={'top-start'}
                    isOpen={isOpen}
                    label={isEditName ? 'Save Name' : 'Edit Name'}
                >
                    <IconButton
                        size={'xs'} p={'1'}
                        type='submit'
                        isLoading={isLoading}
                        onMouseEnter={onOpen}
                        onMouseLeave={onClose}
                    >
                        {isEditName ? <BiSave fontSize={'16px'} /> : <BiPencil fontSize={'16px'} />}
                    </IconButton>
                </Tooltip>
                <Tooltip
                    hasArrow
                    border='1px solid'
                    borderColor='teal.300'
                    bg='teal.500'
                    borderRadius='md'
                    placement={'top-start'}
                    isOpen={isSendOpen}
                    label={'Resend Invite'}
                >
                    <IconButton
                        size={'xs'} p={'1'}
                        isLoading={isSendLoading}
                        onMouseEnter={onSendOpen}
                        onMouseLeave={onSendClose}
                        ml={'auto !important'}
                        variant={'solid'}
                        onClick={onResendInvite}
                    >
                        <BiSend fontSize={'16px'} />
                    </IconButton>
                </Tooltip>
            </HStack>
            {isEditName ?
                <Input
                    width={'full'}
                    placeholder='Name'
                    bg={'gray.100'}
                    border={0}
                    borderRadius={'4px'}
                    color={'gray.500'}
                    _placeholder={{
                        color: 'gray.500',
                    }}
                    fontSize={'14px'}
                    size={'sm'}
                    {...register('inviteeName')}
                /> :
                <Text fontSize={['sm']} textTransform='capitalize' color='gary.400' noOfLines={2}>
                    {watch('inviteeName') || '--'}
                </Text>
            }
        </VStack>
    )
}

const InvitesDataModal = ({
    isOpen,
    onClose,
    campaign,
    // getCampaigns
}) => {
    const { addToast } = useCustomToast()
    const [invitesData, setInvitesData] = useState(null)
    const [invitesLoading, setInvitesLoading] = useState(null)
    let countryCode = useMemo(() => getCountryTelCode('USA') || '', [])

    useEffect(() => {
        if (isOpen && campaign?.id) {
            (async () => {
                try {
                    setInvitesLoading(true)
                    const subCollInvites = query(
                        collection(db, 'campaigns', campaign.id, 'invites'),
                        orderBy('createdAt', 'desc'),
                        limit(50)
                    )
                    // let getData = await getDocs(subCollInvites)
                    const unsubscribe = onSnapshot(subCollInvites, async getData => {

                        const data = await Promise.all(
                            getData.docs.map(async doc => {
                                const queryColl = query(collection(db, 'reviews'), where('inviteId', '==', doc.id))
                                const getReview = await getDocs(queryColl)
                                return {
                                    ...doc.data(),
                                    id: doc.id,
                                    count: doc.data()?.count || 1,
                                    review: !getReview.empty ? { ...getReview.docs[0].data(), id: getReview.docs[0].id } : null
                                }
                            })
                        )
                        setInvitesData(data)
                        setInvitesLoading(false)
                    })
                    return () => unsubscribe()
                } catch (error) {
                    setInvitesLoading(false)
                    addToast({ title: 'Error', description: error.message, status: 'error' })
                    console.log(error)
                }
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, campaign])

    const handleApproveInvite = async (log) => {
        try {
            const subDocInviteRef = doc(db, 'campaigns', campaign.id, 'invites', log.id)
            await updateDoc(subDocInviteRef, {
                received: !log?.received
            })
            addToast({ id: 'Mark', title: 'Review Request', description: 'Review Request Mark As Received', status: 'success' })

        } catch (error) {
            addToast({ title: 'Error', description: error.message, status: 'error' })
            console.log(error)
        }
    }

    const getReviewStatus = (label, property) => {
        const reviewOptions = [
            { label: 'Pending', icon: MdPendingActions, colorScheme: 'yellow' },
            { label: 'Approved', icon: AiFillCheckCircle, colorScheme: 'green' },
            { label: 'Rejected', icon: MdCancel, colorScheme: 'red' }
        ];

        const option = reviewOptions.find(option => option.label === label);

        return option ? option[property] : null;
    };


    return (
        <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose} scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent mx={[2, 4, 6]} maxW={['full', '700px', '900px']}
            // minHeight={'500px'}
            >
                <ModalHeader pt={2} pb={2}>
                    <Text
                        fontSize={['20px', '24px']}
                        fontWeight='600'
                        lineHeight={'38px'}
                        color='#474747'
                        mt={[2]}
                    >
                        Invites <chakra.span color='brand.1'>Data</chakra.span>
                    </Text>
                    {campaign &&
                        <HStack >
                            <Text color='gray.700' fontWeight={'500'} fontSize={['md']}>Total Invites Sent:</Text>
                            <Text color='gray.500' fontSize={['md']}>{campaign?.sentRequests}</Text>
                        </HStack>
                    }
                </ModalHeader>
                <ModalCloseButton
                    bg={{ base: 'blackAlpha.300', sm: 'whiteAlpha.500' }}
                    color={{ base: 'white', sm: 'black' }}
                    stroke={'currentColor'}
                    mt={2}
                />
                <ModalBody as={VStack} alignItems='flex-start' >
                    <VStack w='full' py={4}>
                        {invitesLoading ? (
                            <VStack
                                justifyContent='center'
                                alignItems='center'
                                width='full'
                            >
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='teal.500'
                                    size={['md', 'lg']}
                                />
                            </VStack>
                        ) : !invitesData?.length ?
                            <Text fontSize={'xl'} fontStyle='italic' mt={5} color='gray.500' fontWeight='bold'>
                                No invites found.
                            </Text>
                            : (
                                <SimpleGrid
                                    spacing={['3']}
                                    // columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
                                    // mx={'auto'}
                                    minChildWidth={{ base: 'full', sm: '160px', md: '200px' }}
                                    justifyItems='stretch'
                                    w='full'
                                >
                                    {invitesData.map((log) => (
                                        <VStack w='full'
                                            key={log.id + (Math.random() + 1).toFixed(0)}
                                            p={{ base: 2, md: 3, }}
                                            alignItems='flex-start'
                                            bg="white"
                                            boxShadow="0px -1px 15px 0px rgba(0, 0, 0, 0.1), 2px 0px 7px 1px rgba(0, 0, 0, 0.05)"
                                            rounded="lg"
                                            maxW={['full', '270px']}
                                            sx={{ position: 'relative', '& p': { overflowWrap: 'anywhere' } }}
                                        >
                                            <VStack alignItems={'flex-start'} spacing={2} flex={1}>
                                                <VStack alignItems={'flex-start'} spacing={0.8}>
                                                    <Text fontSize={['sm']} fontWeight={'500'} textTransform={'capitalize'}>Sent At:</Text>
                                                    <Text fontSize={['xs']} textTransform='capitalize' color='gary.400' textAlign='center'>
                                                        {format(new Date(log?.createdAt?.toDate()), "do MMM, yyyy 'at' h:mm a")}
                                                    </Text>
                                                </VStack>
                                                <InviteeInput log={log} campaign={campaign} countryCode={countryCode} />
                                                <VStack alignItems={'flex-start'} spacing={1.5}>
                                                    <Text fontSize={['sm']} fontWeight={'500'}>Type:</Text>
                                                    <Tag size={['md']} variant='subtle' colorScheme={'green'}>
                                                        <TagLabel textTransform={'capitalize'}>{log?.type}</TagLabel>
                                                        <TagLeftIcon boxSize='12px' as={iconMap[log?.type]} ml='5px !important' mr='0 !important' />
                                                    </Tag>
                                                </VStack>
                                                {log?.review && (
                                                    <VStack alignItems={'flex-start'} spacing={1.5}>
                                                        <Text fontSize={['sm']} fontWeight={'500'}>Review Status:</Text>
                                                        <Tag size={['md']} variant='subtle' colorScheme={getReviewStatus(
                                                            !log?.review?.reviewed ? 'Pending' : (log?.review?.approve ? 'Approved' : 'Rejected'), 'colorScheme'
                                                        )}>
                                                            <TagLabel textTransform={'capitalize'}>
                                                                {!log?.review?.reviewed ? 'Pending' : (log?.review?.approve ? 'Approved' : 'Rejected')}
                                                            </TagLabel>
                                                            <TagLeftIcon boxSize='12px'
                                                                as={getReviewStatus(
                                                                    !log?.review?.reviewed ? 'Pending' : (log?.review?.approve ? 'Approved' : 'Rejected'), 'icon'
                                                                )}
                                                                ml='5px !important'
                                                                mr='0 !important'
                                                            />
                                                        </Tag>
                                                    </VStack>
                                                )}
                                                <VStack alignItems={'flex-start'} spacing={0.8} flex={1}>
                                                    <Text fontSize={['sm']} fontWeight={'500'}>Sent To:</Text>
                                                    <Text fontSize={['sm']} color='gary.400'>{log?.to}</Text>
                                                </VStack>
                                                <VStack alignItems={'flex-start'} spacing={0.8} flex={1}>
                                                    <Text fontSize={['sm']} fontWeight={'500'}>Count:</Text>
                                                    <Text fontSize={['sm']} color='gary.400'>{log?.count}</Text>
                                                </VStack>
                                                <VStack alignItems={'flex-start'} spacing={0.8}>
                                                    <Text fontSize={['sm']} fontWeight={'500'}>Message:</Text>
                                                    <Text fontSize={['sm']} textTransform='capitalize' color='gary.400' noOfLines={2}>
                                                        {log?.message}
                                                    </Text>
                                                </VStack>
                                            </VStack>
                                            <MarkAsReceivedCheckBox
                                                val={log}
                                                onClick={() => handleApproveInvite(log)}
                                            />
                                        </VStack>
                                    ))}

                                </SimpleGrid>
                            )}
                    </VStack >
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default InvitesDataModal
