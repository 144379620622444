import React, { useEffect, useState } from "react";
import { Box, Stat, StatLabel, StatNumber, SimpleGrid } from "@chakra-ui/react";
import {
  collection,
  collectionGroup,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/config";
import CountUp from "react-countup";

const UpdatedUserData = () => {
  const [paidUsers, setPaidUsers] = useState(0);
  const [campaignsCount, setCampaignsCount] = useState(0);
  const [videoReviewsCount, setVideoReviewsCount] = useState(0);

  useEffect(() => {
    const handleFetch = async () => {
      const qCampaigns = query(
        collection(db, "campaigns"),
        where("active", "==", true)
      );
      const qReviews = query(collection(db, "reviews"));
      const getReviews = await getDocs(qReviews);
      const getCampaigns = await getDocs(qCampaigns);

      const qUsers = query(
        collectionGroup(db, "subscriptions"),
        where("status", "==", "active"),
        where("role", "==", "premium")
      );
      const getPaidUsers = await getDocs(qUsers);
      const activeUsers = [];
      getPaidUsers.forEach((doc) => {
        // Get the full path of the document
        const fullPath = doc.ref.path;
        // Extract the user ID from the path
        const userId = fullPath.split("/")[1];
        activeUsers.push({
          userId: userId,
          subscriptionData: doc.data(),
        });
      });

      setCampaignsCount(getCampaigns.size || 0);
      setVideoReviewsCount(getReviews.size || 0);
      setPaidUsers(
        activeUsers?.filter(
          (schema, index, self) =>
            index === self.findIndex((obj) => obj.userId === schema.userId)
        )?.length || 0
      );
    };
    handleFetch();
  }, []);

  return (
    <Box p={5} shadow='md' borderWidth='1px' borderRadius='lg'>
      <SimpleGrid columns={3} spacing={10}>
        <Stat>
          <StatLabel>Users</StatLabel>
          <StatNumber>{paidUsers}</StatNumber>
          <StatLabel fontSize='sm' color='gray.500'>
            Paid users
          </StatLabel>
        </Stat>
        <Stat>
          <StatLabel>Campaigns</StatLabel>
          <StatNumber>
            <CountUp end={campaignsCount} />
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Video Reviews</StatLabel>
          <StatNumber>
            <CountUp end={videoReviewsCount} />
          </StatNumber>
        </Stat>
      </SimpleGrid>
    </Box>
  );
};

export default UpdatedUserData;
