import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Container,
  Box,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../recoil/atoms/auth";
import TeamInfo from "./components/TeamInfo";
import Blog from "./components/Blog";
import BlogPosts from "./components/BlogPosts";
// import UserData from "./components/UserData";
// import { Dropzone } from './components/HeroClips/Drop'
// import { HeroClips } from './components/HeroClips/HeroClips'
import CampaignUrl from "./components/CampaignUrl/CampaignUrl";
import { useResize } from "../../hooks/useResize";
import Logs from "./components/Logs/Logs";
import Support from "./components/Support/Support";
import Users from "./components/Users/Users";
import UpdatedUserData from "./components/UpdatedUserData";

const Team = () => {
  // const navigate = useNavigate()
  const [user] = useRecoilState(userState);
  const [fetchBlog, setFetchBlog] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const containerRef = useRef(null);
  const { width } = useResize(containerRef);

  return (
    <Container
      maxW={["1200px"]}
      px={{ base: 0, sm: 2 }}
      mb={["5%", 10]}
      mt={["2"]}
      ref={containerRef}
      sx={{ position: "relative" }}>
      <Tabs
        onChange={(index) => {
          setFetchBlog(false);
          setTabIndex(index);
        }}
        defaultIndex={1}
        index={tabIndex}>
        <Box
          sx={{
            position: "absolute",
            width: width,
            overflowX: "auto",
            pb: 3,
          }}>
          <TabList
            gap='4px'
            justifyContent={["center", "center", "flex-start"]}
            mx={[0]}
            sx={{
              // position: 'absolute',
              width: "max-content",
              border: "2px solid",
              borderRadius: "3px",
              "& > button": {
                fontWeight: "medium",
                minW: "100px",
                mb: 0,
                _selected: {
                  borderColor: "inherit",
                  color: "white",
                  bg: "teal.500",
                },
              },
            }}
            borderColor={(theme) => theme.colors.teal["500"] + " !important"}>
            <Tab>Team Info</Tab>
            <Tab>User Data</Tab>
            <Tab>Blog Form</Tab>
            <Tab>Blog Posts</Tab>
            <Tab>Campaign Url</Tab>
            <Tab>Support</Tab>
            <Tab>Logs</Tab>
            <Tab>Users</Tab>
          </TabList>
        </Box>

        <TabPanels
          sx={{
            "& > .chakra-tabs__tab-panel": {
              paddingTop: "80px",
            },
            "& > .chakra-tabs__tab-panel > div": {
              // marginTop: '100px'
            },
          }}>
          <TabPanel p={[0, 2]}>
            <TeamInfo />
          </TabPanel>
          <TabPanel p={[0, 2]}>
            {/* <UserData
              fetchBlog={fetchBlog || {}}
              setFetchBlog={setFetchBlog}
              tabIndex={tabIndex}
              user={user}
            /> */}
            <UpdatedUserData />
          </TabPanel>
          <TabPanel p={[0]}>
            <Blog
              setFetchBlog={setFetchBlog}
              fetchBlog={fetchBlog || {}}
              tabIndex={tabIndex}
              user={user}
            />
          </TabPanel>
          <TabPanel p={[0]}>
            <BlogPosts
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              user={user}
              setFetchBlog={setFetchBlog}
            />
          </TabPanel>
          {/* <TabPanel p={[0]}>
            <HeroClips
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              user={user}
              setFetchBlog={setFetchBlog}
            />
          </TabPanel> */}
          <TabPanel p={[0]}>
            <CampaignUrl
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              user={user}
              setFetchBlog={setFetchBlog}
            />
          </TabPanel>
          <TabPanel p={[0]}>
            <Support
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              user={user}
              setFetchBlog={setFetchBlog}
            />
          </TabPanel>
          <TabPanel p={[0]}>
            <Logs
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              user={user}
              setFetchBlog={setFetchBlog}
            />
          </TabPanel>
          <TabPanel p={[0]}>
            <Users
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              user={user}
              setFetchBlog={setFetchBlog}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default Team;
