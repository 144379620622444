import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  Stack,
  Heading,
  Input,
  Button,
  FormControl,
  FormErrorMessage,
  GridItem,
  CircularProgress,
  FormLabel,
  HStack,
  InputGroup,
  Box,
  chakra,
  Textarea,
} from '@chakra-ui/react'
import { useForm, useFieldArray } from 'react-hook-form'
import { db } from '../../firebase/config'
import { doc, updateDoc } from 'firebase/firestore'
import { useCustomToast } from '../../hooks/customToast'
import { campaignDataAtom } from '../../recoil/atoms/campaignData'

export const CustomMessageForm = ({ isEditable, campaignD, setIsEditable, dataLoading }) => {
  const campaignId = useRecoilValue(campaignDataAtom)

  const { addToast } = useCustomToast()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customRequestMessage: '',
    },
  })

  const onSubmit = async (state) => {
    let { ...values } = state
    try {
      setIsSubmitting(true)
      if (campaignId) {
        const docRef = doc(db, 'campaigns', campaignId)
        await updateDoc(docRef, {
          customRequestMessage: values,
        })
        addToast({
          id: 'customRequest-success',
          title: 'custom Request !',
          description: 'Updated successfully',
          status: 'success',
          variant: 'left-accent',
        })
        setIsEditable(false)
      } else {
        addToast({
          id: 'customRequestMessageError',
          title: 'Error',
          description: 'customRequest',
          status: 'warning',
          variant: 'left-accent',
        })
      }
      setIsSubmitting(false)
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (campaignD?.customRequestMessage) {
      reset({ ...campaignD?.customRequestMessage })
    } else {
      setIsEditable(true)
    }
  }, [campaignD, dataLoading])

  useEffect(() => {
    setIsEditable(true)
  }, [])

  return (
    <Stack
      mt={4}
      bg={'gray.50'}
      rounded={'xl'}
      py={{ base: 4, sm: 6, md: 8 }}
      px={{ base: 2, sm: 4, md: 8 }}
      width='full'
      spacing={8}
    >
      <HStack justifyContent='space-between' w='full'>
        <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
          {campaignD?.campaignDetails.publicCampaignName} Custom Review Request Message
        </Heading>
      </HStack>

      <Box
        as={'form'}
        mt={10}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          'input, select, textarea': {
            color: 'gray.700',
          },
        }}
      >
        <FormControl p='6'>
          <HStack alignItems='end'>
            <FormControl isInvalid={!!errors?.customRequestMessage}>
              <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                Your custom request message:
              </FormLabel>
              <Textarea
                placeholder='Message'
                bg={'gray.100'}
                border={0}
                color={'gray.800'}
                _placeholder={{
                  color: 'gray.500',
                }}
                py={2}
                {...register('customRequestMessage', {
                  maxLength: {
                    value: 170,
                    message: 'should be less than 170 characters',
                  },
                })}
              />
              {/* {errors.message && <FormErrorMessage>{errors.message.message}</FormErrorMessage>} */}
            </FormControl>
          </HStack>
          {/* {errors.seo?.[index]?.keyword.message && (
              <FormErrorMessage>{errors.seo?.[index]?.keyword?.message}</FormErrorMessage>
            )} */}
        </FormControl>

        <GridItem colSpan={[12]} order={14}>
          {!campaignD?.customRequestMessage ? (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
              // w={'full'}
              variant='solid'
              ml='auto'
            >
              Save
            </Button>
          ) : !isEditable ? (
            <Box
              type='button'
              as='button'
              name='edit'
              mt={3}
              w={'20%'}
              borderRadius='8'
              py='2'
              bg='brand.1'
              variant='solid'
              ml='auto'
              color='white'
              fontWeight={'bold'}
              onClick={() => setIsEditable(true)}
            >
              Edit
            </Box>
          ) : (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
              // w={'full'}
              variant='solid'
              ml='auto'
              // onClick={(e) => setIsEditable(e.target.checked)}
            >
              Update
            </Button>
          )}
        </GridItem>
      </Box>
    </Stack>
  )
}
