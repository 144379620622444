import {
  Box,
  Stack,
  Flex,
  Image,
  Text,
  VStack,
  chakra,
  Spinner,
  Avatar,
  Icon,
  HStack,
  SimpleGrid,
} from '@chakra-ui/react'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import { db } from '../../firebase/config'
import './details.css'
import { FaArrowLeft } from 'react-icons/fa'
import { NewsLetter } from './Newsletter'
import { format } from 'date-fns'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinIcon,
  LinkedinShareButton,
  EmailIcon,
  EmailShareButton,
} from 'react-share'

import MDEditor from '@uiw/react-md-editor'
import { Seo } from '../../components/Seo'

function BlogDetails() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState(null)
  const { state: historyState } = useLocation()

  const handleBlogFetch = async () => {
    let blog = {}
    if (id && historyState) {
      blog = { ...historyState }
      // console.log('from historyState')
    } else if (id && !historyState) {
      const blogRefQuery = query(collection(db, 'blogs'), where('wordsUrl', '==', id))
      const blogData = await getDocs(blogRefQuery)
      if (!blogData.empty) {
        blog = { ...blogData.docs[0].data(), id: blogData.docs[0].id }
        // console.log('from words')
      } else {
        const docRef = doc(db, 'blogs', id)
        const snap = await getDoc(docRef)
        blog = snap.exists() ? { ...snap.data(), id: snap.id } : {}
      }
      // console.log({ blog, historyState })

      if (!Object.keys(blog).length) return navigate('/blog')
      else if (blog?.blogs) {
        const nestedBlogsQuery = query(
          collection(db, 'blogs'),
          where(
            'id',
            'in',
            blog?.blogs?.map((obj) => obj.value)
          )
        )
        let nestedBlogsData = await getDocs(nestedBlogsQuery)
        nestedBlogsData = nestedBlogsData.docs.map((blogSnap) => ({
          ...blogSnap.data(),
          id: blogSnap.id,
        }))
        blog = { ...blog, blogs: nestedBlogsData }
        setState(blog)
        // console.log(blog)
      } else {
        setState(blog)
        // console.log('no nested blogs', blog)
      }
    }
  }

  useEffect(() => {
    handleBlogFetch()
  }, [])

  return (
    <>
      <Box bg='white'>
        {!state ? (
          <VStack
            justifyContent='center'
            alignItems='center'
            width='full'
            minH='calc(100vh - 20vh)'
          >
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='teal.500'
              size={['md', 'xl']}
            />
          </VStack>
        ) : (
          <Flex
            mx={{ base: '4', md: '10' }}
            alignSelf={'center'}
            direction='column'
            px={['3', '6']}
            maxW='1500px'
          >
            <HStack
              _hover={{
                color: 'brand.2',
              }}
              color='brand.1'
              pt='3'
              as={Link}
              // to='/blog'
              onClick={() => navigate(-1)}
            >
              <Icon as={FaArrowLeft} />
              <Text>Go Back</Text>
            </HStack>

            {/* <Heading
              as='h1'
              size='20rem'
              py='5'
              textAlign='center'
              color='brand.1'
              fontWeight='bold'
            >
              Our <chakra.span color={'brand.2'}> Blog</chakra.span>
            </Heading> */}
            {/* <Heading>Sendmea Blog</Heading> */}
            <Box
              my={3}
              sx={{
                boxShadow:
                  'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
              }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'stretch'}
              w={'full'}
              as={VStack}
              pb={3}
            >
              <Seo
                title={state.blogTitle}
                description={state.blogTitle}
                type='article'
                url={`https://www.sendmea.io/blog/${state?.wordsUrl}`}
                imgUrl={state.headerImg}
                vidUrl='https://youtu.be/aXOVKVrk1n4'
                keywords='blog, news, sendmea news, latest news, updates, new features,video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
              />

              <Box h='20rem'>
                <Image
                  objectFit={'cover'}
                  objectPosition={'center'}
                  src={state.headerImg}
                  alt={state.blogTitle}
                  name={state.blogTitle}
                  w={{ base: '100%' }}
                  h={{ base: '100%' }}
                />
              </Box>
              <Stack px='2' py='4'>
                <Text pt='2' fontSize={'3xl'} textAlign={'center'} px={3} fontWeight={'500'}>
                  {state.blogTitle}
                </Text>
              </Stack>
              <chakra.div data-color-mode='light' px={8} py={4}>
                <MDEditor.Markdown source={state?.blogContent} style={{ whiteSpace: 'pre-wrap' }} />

                {/* <span dangerouslySetInnerHTML={{ __html: state?.blogContent }}>
                  {state?.blogContent}
                </span> */}
              </chakra.div>
              <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'}>
                <HStack justify='space-around' p='4' fontSize='sm'>
                  <Text color='brand.1' fontWeight='bold' fontSize={'md'}>
                    Share:{' '}
                  </Text>
                  <EmailShareButton
                    url={`https://www.sendmea.io/blog/${state?.wordsUrl}`}
                    quote={state?.blogTitle}
                    hashtag='#muo'
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <LinkedinShareButton
                    url={`https://www.sendmea.io/blog/${state?.wordsUrl}`}
                    quote={state?.blogTitle}
                    hashtag='#muo'
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>

                  <RedditShareButton
                    url={`https://www.sendmea.io/blog/${state?.wordsUrl}`}
                    quote={state?.blogTitle}
                    hashtag='#muo'
                  >
                    <RedditIcon size={32} round />
                  </RedditShareButton>

                  <TwitterShareButton
                    url={`https://www.sendmea.io/blog/${state?.wordsUrl}`}
                    quote={state?.blogTitle}
                    hashtag='#muo'
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <FacebookShareButton
                    url={`https://www.sendmea.io/blog/${state?.wordsUrl}`}
                    quote={state?.blogTitle}
                    hashtag='#muo'
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </HStack>
                <HStack p='4' fontSize='sm'>
                  <Text color='brand.1' fontWeight='bold' fontSize='lg'>
                    Sendmea
                  </Text>
                  <Text>
                    {state?.publishedAt?.toDate
                      ? format(
                          new Date(state?.publishedAt?.toDate()?.toDateString() || '01-01-2023'),
                          'MMM dd yyyy'
                        )
                      : format(
                          new Date(
                            state?.publishedAt?.seconds * 1000 +
                              state?.publishedAt?.nanoseconds / 1000000
                          ),
                          'MMM dd yyyy'
                        )}
                  </Text>
                  <Avatar
                    src={
                      'https://res.cloudinary.com/codeshock/image/upload/v1675392551/cool_q9bunp.png'
                    }
                    boxSize='6'
                  />
                </HStack>
              </Flex>
              {state?.blogs?.length > 0 ? (
                <>
                  <Text
                    // as='h4'
                    fontSize={['xl', '3xl', '4xl']}
                    mt='10 !important'
                    textAlign='center'
                    color='brand.1'
                    fontWeight='bold'
                  >
                    Related <chakra.span color={'brand.2'}>Articles</chakra.span>
                  </Text>
                  <SimpleGrid
                    spacing={['4']}
                    // columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
                    mx={'auto !important'}
                    py={['6', '10']}
                    px={['4']}
                    minChildWidth={{ base: 'full', sm: '260px', md: '285px' }}
                    justifyItems='stretch'
                    maxW='1200px'
                    w='full'
                  >
                    {state?.blogs.map((blogDoc) => (
                      <VStack
                        key={blogDoc.id}
                        w='full'
                        bg='white'
                        shadow='lg'
                        rounded='lg'
                        border='1px solid'
                        borderColor={'teal.200'}
                        spacing={0}
                        overflow='hidden'
                        maxW={[state?.blogs.length === 1 ? '370px' : 'full']}
                        cursor='pointer'
                        _hover={{
                          '& img': {
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        <VStack px={4} py={2} w='full' flex={1} alignItems='flex-start'>
                          <chakra.h1
                            py={2}
                            noOfLines={[3, 4]}
                            color='gray.800'
                            fontWeight='bold'
                            fontSize='xl'
                            _dark={{
                              color: 'white',
                            }}
                            onClick={() =>
                              window.open(`https://sendmea.io/blog/${blogDoc?.wordsUrl}`, '_blank')
                            }
                          >
                            {blogDoc.blogTitle}
                          </chakra.h1>
                          <HStack alignItems='flex-end' spacing={0} flex={1}>
                            <chakra.p
                              noOfLines={3}
                              mt={1}
                              fontSize='sm'
                              color='gray.600'
                              _dark={{
                                color: 'gray.400',
                              }}
                              onClick={() =>
                                window.open(
                                  `https://sendmea.io/blog/${blogDoc?.wordsUrl}`,
                                  '_blank'
                                )
                              }
                            >
                              {blogDoc.blogDesc}
                            </chakra.p>
                          </HStack>
                        </VStack>

                        <Box h={'48'} w='full' overflow='hidden'>
                          <Image
                            h={'full'}
                            w='full'
                            objectFit='cover'
                            mt={2}
                            src={blogDoc.headerImg}
                            alt='blog header'
                            transition='all 0.6s ease'
                          />
                        </Box>
                        <HStack
                          w='full'
                          alignItems='center'
                          justifyContent='space-between'
                          px={2}
                          py={2}
                          bg='teal.500'
                          roundedBottom='lg'
                        >
                          <Text
                            noOfLines={2}
                            color='#d2fdff'
                            fontWeight='600'
                            fontSize={['sm', 'sm']}
                            fontStyle='italic'
                            textAlign='center'
                            onClick={() =>
                              window.open(`${window.origin}/blog/${blogDoc?.wordsUrl}`, '_blank')
                            }
                            _hover={{
                              color: '#b1fcff',
                            }}
                          >
                            {`${window.origin}/blog/${blogDoc?.wordsUrl}`}
                          </Text>
                        </HStack>
                      </VStack>
                    ))}
                  </SimpleGrid>
                </>
              ) : null}

              <HStack color='brand.1' px='7' py='3' as={Link} to='/blog'>
                {' '}
                <Icon as={FaArrowLeft} />
                <Text>Go Back</Text>
              </HStack>
            </Box>
          </Flex>
        )}
      </Box>
      <NewsLetter />
    </>
  )
}

export default BlogDetails
