import { Box, Button, Container, FormControl, FormErrorMessage, FormHelperText, FormLabel, GridItem, HStack, Heading, Icon, Input, SimpleGrid, Spinner, Stack, Text, VStack, chakra, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCustomToast } from '../../hooks/customToast'
import { userState } from '../../recoil/atoms/auth'
import { useRecoilValue } from 'recoil'
import { collection, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'
import { db, storage } from '../../firebase/config'
import { Controller, useForm } from 'react-hook-form'
import { inputProps } from '../OldReviewProcess/components/step3'
import InputField from '../OldReviewProcess/components/InputField'
import Rating from '../../components/Rating'
import { Select } from 'chakra-react-select'
import { BsCamera } from 'react-icons/bs'
import { blobToBase64, cloudfunctionsBaseURL, convertToMb, getVideoDuration, sendNotification } from '../../utils'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import emailjs from '@emailjs/browser'
import { WaitModal } from '../OldReviewProcess/components/waitModal'
import {
    isBrowser, isMobile, browserName, browserVersion, fullBrowserVersion,
    deviceType, engineName, engineVersion, osName, osVersion,
} from 'react-device-detect';

const reviewOptions = [
    {
        label: 'SoTellUs',
        value: 'SoTellUs',
    },
    {
        label: 'VideoTouch',
        value: 'VideoTouch',
    },
    {
        label: 'YouTube',
        value: 'YouTube',
    },
    {
        label: 'Instagram',
        value: 'Instagram',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]

const UploadCustomReview = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const { addToast } = useCustomToast()
    const user = useRecoilValue(userState)
    const [campaignLoading, setCampaignLoading] = useState(true)
    const [campaign, setCampaign] = useState(null)
    const [storagePath, setStoragePath] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const videoInputRef = useRef(null)
    const reviewCollRef = collection(db, 'reviews')


    const getCampaignById = async () => {
        const docRef = doc(db, 'campaigns', id)
        try {
            let campaignDoc = await getDoc(docRef)
            if (campaignDoc.exists()) {
                let campaignData = { ...campaignDoc.data(), id: campaignDoc.id }
                setCampaign(campaignData)
                setStoragePath(`reviews/${campaign?.id}`)
            } else {
                addToast({
                    title: 'Error',
                    description: 'campaign not found',
                    status: 'error',
                })
            }
            setCampaignLoading(false)
        } catch (error) {
            addToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                variant: 'left-accent',
            })
            setCampaignLoading(false)
        }
    }

    useEffect(() => {
        id && getCampaignById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const initialValues = {
        name: '',
        email: '',
        testimonial: '',
        duration: '',
        date: '',
        userId: '',
        like: false,
        videoOriginWebsite: ''
    }

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        reset,
        control,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: initialValues,
    })


    const handleUploadVideo = async (e) => {
        const fileObj = e.target.files[0];
        try {
            let sizeInMB = convertToMb(fileObj);
            if (fileObj && !fileObj.type.includes("video")) {
                return addToast({ title: 'Video', description: "Can only upload videos", status: 'error' })
            }
            else if (sizeInMB > 200 || sizeInMB < 0.230) {
                addToast({ title: 'Video', description: sizeInMB < 0.230 ? "Size is less than 230kb" : "Size is greater than 200mb", status: 'error' })
            }
            else {
                if (fileObj.type.includes("quicktime")) {
                    return setValue("testimonial", fileObj)
                }
                let duration = await getVideoDuration(fileObj)
                if (duration === Infinity || (Math.floor(duration) > 150)) {
                    addToast({ title: 'Video', description: duration === Infinity ? `Problem with this file` : `Duration is greater than 150 seconds`, status: 'error' })
                }
                else {
                    // console.log("data", { fileObj, duration, sizeInMB })
                    setValue("testimonial", fileObj)
                    setValue('duration', duration)
                    // setStep(prev => prev + 1)
                }
            }
        } catch (error) {
            addToast({ title: 'Video', description: error.message, status: 'error' })
        }
    }

    const uploadToFirestore = async (values, testimonial, isUploaded = true) => {
        const {
            campaignDetails: { publicCampaignName, internalCampaignName },
        } = campaign
        let { newDocId, ...rest } = values

        await setDoc(doc(db, 'reviews', newDocId), {
            ...rest,
            userId: campaign?.userId,
            campaignId: campaign?.id,
            reviewed: false,
            review: testimonial,
            date: serverTimestamp(),
            videoOriginWebsite: values?.videoOriginWebsite?.value
        })
        const payload = {
            title: `Review Added!`,
            message: `
            You have uploaded a review of ${values?.name} (${values.email}) for campaign ${(publicCampaignName)}
            `,
            sender_id: values?.email || values?.name || '',
            receiver_id: campaign?.userId,
            reviewRef: newDocId,
        }
        addToast({
            title: 'Review Uploaded',
            description: 'Review uploaded for the campaign',
            status: 'success',
            variant: 'left-accent',
        })
        sendNotification(payload)
        window.scrollTo({
            top: 20,
            behavior: 'smooth',
        })
        const templateParams = {
            to_email: user?.email,
            to_name: user?.firstName + " " + user?.lastName,
            from_name: values?.name,
            from_email: values?.email,
            message: `
                <p>
                    You have uploaded a video review of a user <b>"${values?.name}"</b>  
                     for the campaign <b>"${publicCampaignName || internalCampaignName}"</b>
                    ${values?.reviewMessage ? `<br/> with a message "${values.reviewMessage}"` : ''}
                    ${values?.rating ? `<br/> and a "${values.rating}" star rating` : ''}
                    <br/>Checkout and approved whole review by logging in to your sendmea dashboard
                    <a href="https://sendmea.io/dashboard/" target="_blank" rel="noreferrer">
                        https://sendmea.io/dashboard/
                    </a>
                </p>
            `,
        }
        const response = await emailjs.send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_REVIEW_NOTIFICATION_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_PUBLIC_KEY
        )

        if (response.status === 200) {
            addToast({
                title: 'Email Status!',
                description: `You also notified via email`,
                status: 'success',
                variant: 'left-accent',
            })
        }
        if (!isUploaded) {
            // const result = await fetch(`http://127.0.0.1:5001/sendmea-c7d45/us-central1/mediaUploadToCloudinary`, {
            await fetch(`${cloudfunctionsBaseURL}mediaUploadToCloudinary`, {
                method: 'POST',
                body: JSON.stringify({
                    file: testimonial,
                    resource_type: 'video',
                    raw_convert: 'google_speech:srt:vtt:en-US',
                    filename: newDocId,
                    folder: 'sendmea/reviews',
                    isSafari: true,
                    storagePath: `${storagePath}/${newDocId}`,
                    isUploaded: !isUploaded,
                }),
            })
        }
        onClose()
        reset()
        setIsSubmitting(false)
    }


    const onSubmit = async (state) => {
        setIsSubmitting(true)
        onOpen()
        let { testimonial, ...values } = state
        try {
            const newDocId = doc(reviewCollRef).id
            const base64Video = await blobToBase64(testimonial)
            // console.log(state)
            // if (testimonial || !testimonial) return false;
            if (convertToMb(testimonial) < 20) {
                let result = await fetch(`${cloudfunctionsBaseURL}mediaUploadToCloudinary`, {
                    method: 'POST',
                    body: JSON.stringify({
                        file: base64Video,
                        resource_type: 'video',
                        filename: newDocId,
                        folder: 'sendmea/reviews',
                        isSafari: false,
                    }),
                })
                result = await result.json()
                uploadToFirestore(
                    { ...values, duration: result.data?.duration || values.duration, newDocId },
                    result.data.secure_url
                )
            } else {
                uploadFile({ file: testimonial, storagePath: `${storagePath}/${newDocId}` }, (e) =>
                    uploadToFirestore({ ...values, newDocId }, e, convertToMb(testimonial) < 20)
                )
            }
        } catch (error) {
            const logCollRef = collection(db, 'logs')
            const newDocId = doc(logCollRef).id
            const resip = await fetch('https://api.ipify.org/?format=json')
            const { ip } = await resip.json()
            await setDoc(doc(db, 'logs', newDocId), {
                createdAt: serverTimestamp(),
                errorMessage: error?.message || " ",
                errorName: error?.name || " ",
                browserName,
                type: 'error',
                logId: id,
                userEmail: values?.email || ' ',
                originatedFrom: 'record_a_review',
                campaignLinkId: campaign?.id,
                campaignName: campaign?.campaignDetails?.publicCampaignName || campaign?.campaignDetails?.testimonialCampaignName,
                ip, isBrowser, isMobile, browserVersion, fullBrowserVersion,
                deviceType, engineName, engineVersion, osName, osVersion,
            })
            addToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                variant: 'left-accent',
            })
            setIsSubmitting(false)
            onClose()
        }
    }

    const uploadFile = async ({ file, storagePath }, callback = () => { }) => {
        if (!file) return
        const storageRef = ref(storage, storagePath)
        const uploadTask = uploadBytesResumable(storageRef, file)
        uploadTask.on(
            'state_changed',
            () => { },
            (error) => {
                addToast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    variant: 'left-accent',
                })
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setValue('testimonial', downloadURL)
                    callback(downloadURL)
                })
            }
        )
    }

    return (
        <Container maxW={['container.lg']} px={{ base: 0, sm: 2 }}>
            <Stack
                mt={5}
                bg={'gray.50'}
                rounded={'xl'}
                py={{ base: 4, sm: 6 }}
                px={{ base: 2, sm: 4, md: 6 }}
                width='full'
                // maxW={'800px'}
                spacing={8}
            >
                {campaignLoading ? (
                    <VStack
                        justifyContent='center'
                        alignItems='center'
                        width='full'
                        minH='calc(20vh)'
                    >
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='teal.500'
                            size={['md', 'xl']}
                        />
                    </VStack>
                ) : !campaign ? (
                    <HStack alignItems={'center'} justifyContent={'center'} width={'100%'} pb={8}>
                        <Heading
                            color={'gray.400'}
                            lineHeight={1.1}
                            fontSize={{ base: 'xl', sm: '2xl' }}
                            fontWeight={'bold'}
                        >
                            Campaign Not found!
                        </Heading>
                    </HStack>
                ) : (
                    <Box
                        as='form'
                        pt={'5'}
                        pb={'4'}
                        px={'4'}
                        borderRadius={'8px'}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Text color={'brand.1'} fontWeight={'bold'} fontSize={'xx-large'} onClick={() => console.log(errors)}>
                            Please, fill in the fields below to upload a review for campaign
                            (<chakra.span color={'brand.2'}>{campaign?.campaignDetails?.publicCampaignName}</chakra.span>)
                        </Text>
                        <SimpleGrid
                            mt={8}
                            mb={4}
                            columns={12}
                            spacing={[3, 4, 5]}
                            sx={{
                                'input, select, textarea': {
                                    color: 'gray.700',
                                },
                            }}
                        >
                            <FormControl key={'name'} isInvalid={!!errors['name']} as={GridItem} colSpan={[12]}>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                                    Name <chakra.span color={'red.500'}>{'*'}</chakra.span>
                                </FormLabel>
                                <Input
                                    isInvalid={!!errors['name']}
                                    {...inputProps}
                                    {...register('name', {
                                        required: 'This is required',
                                    })}
                                />
                                {errors['name'] && <FormErrorMessage>{errors['name']?.message}</FormErrorMessage>}
                            </FormControl>
                            <FormControl key={'email'} isInvalid={!!errors['email']} as={GridItem} colSpan={[12]}>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                                    Email <chakra.span color={'red.500'}>{'*'}</chakra.span>
                                </FormLabel>
                                <Input
                                    isInvalid={!!errors['email']}
                                    {...inputProps}
                                    {...register('email', {
                                        required: 'This is required',
                                    })}
                                />
                                {errors['email'] && <FormErrorMessage>{errors['email']?.message}</FormErrorMessage>}
                            </FormControl>
                            <FormControl key={'recording'} isInvalid={!!errors['originalDate']} as={GridItem} colSpan={[12]}>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                                    Original Date of Review <chakra.span color={'red.500'}>{'*'}</chakra.span>
                                </FormLabel>
                                <Input
                                    {...inputProps}
                                    placeholder="Select Date and Time"
                                    type="date"
                                    isInvalid={!!errors['originalDate']}
                                    {...register('originalDate', {
                                        required: 'This is required',
                                    })}
                                    max={new Date().toISOString().split('T')[0]}
                                />
                                {errors['originalDate'] && <FormErrorMessage>{errors['originalDate']?.message}</FormErrorMessage>}
                                <FormHelperText ml={1}>Approximate if unknown</FormHelperText>
                            </FormControl>
                            <FormControl isInvalid={!!errors['rating']} as={GridItem} colSpan={[12]}>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                                    Rating <chakra.span color={'red.500'}>{'*'}</chakra.span>
                                </FormLabel>
                                <Input
                                    type='number'
                                    defaultValue={0}
                                    {...register('rating', {
                                        required: true,
                                        validate: (val) => {
                                            if (Number(val) < 1 || typeof val === 'string')
                                                return 'Please give a rating'
                                        },
                                    })}
                                    display='none'
                                />
                                <Rating
                                    total={5}
                                    value={watch('rating')}
                                    setValue={(e) => {
                                        setValue('rating', e)
                                        clearErrors('rating')
                                    }}
                                />
                                {errors['rating'] && <FormErrorMessage>{errors['rating']?.message}</FormErrorMessage>}
                            </FormControl>
                            <FormControl isInvalid={!!errors['reviewMessage']} as={GridItem} colSpan={[12]}>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} textTransform='capitalize'>
                                    Review Message <chakra.span color={'red.500'}>{'*'}</chakra.span>
                                </FormLabel>
                                <InputField
                                    name={'reviewMessage'}
                                    required={true}
                                    label={'Review Title'}
                                    register={register}
                                />
                                {errors['reviewMessage'] && <FormErrorMessage>{errors['reviewMessage']?.message}</FormErrorMessage>}
                            </FormControl>
                            <Controller
                                control={control}
                                name='videoOriginWebsite'
                                rules={{ required: 'Please Select an option' }}
                                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                    <FormControl colSpan={[12]} as={GridItem} isInvalid={!!error}>
                                        <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5} onClick={() => console.log(error)}>
                                            Select where the video was made
                                        </FormLabel>
                                        <Select
                                            name={name}
                                            ref={ref}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={reviewOptions}
                                        />
                                        {!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
                                    </FormControl>
                                )}
                            />
                            <FormControl colSpan={[12]} as={GridItem} isInvalid={!!errors?.testimonial} width='full' pos='relative'>
                                <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                                    Video Review
                                </FormLabel>
                                <HStack
                                    alignItems='center'
                                    px={['2']}
                                    py={['2', '3']}
                                    bg={'gray.200'}
                                    borderRadius='4px'
                                    maxH='45px'
                                    whiteSpace='pre'
                                    overflow='hidden'
                                    // position={["absolute", "absolute", "relative"]}
                                    width='full'
                                >
                                    <Button
                                        leftIcon={<Icon as={BsCamera} fontSize={['18px']} mr={['-2', '-2', '0']} />}
                                        size={['sm']}
                                        variant='solid'
                                        py={4}
                                        minW={['32px', '32px', '90px']}
                                        rounded={['full', 'full', 'md']}
                                        onClick={() => videoInputRef.current && videoInputRef.current.click()}
                                    >
                                        {isMobile ? '' : 'Upload'}
                                    </Button>
                                    <Text
                                        fontSize={['sm', 'md']}
                                        pr={20}
                                        overflow='hidden'
                                        whiteSpace='pre'
                                        userSelect='none'
                                        color={'gray.700'}
                                        fontStyle='italic'
                                    >
                                        {watch('testimonial')
                                            ? typeof watch('testimonial') === 'object'
                                                ? watch('testimonial')?.name
                                                : watch('testimonial')
                                            : 'Click to upload video review'}
                                    </Text>
                                </HStack>
                                <input
                                    type='file'
                                    accept='video/*'
                                    style={{ display: 'none' }}
                                    {...register('testimonial', {
                                        required: 'This is required',
                                    })}
                                    ref={videoInputRef}
                                    onChange={(e) => handleUploadVideo(e, 'testimonial')}
                                />
                                {(errors?.testimonial && typeof (watch('testimonial')) !== 'object') && <FormErrorMessage>{errors.testimonial?.message}</FormErrorMessage>}
                            </FormControl>

                        </SimpleGrid>
                        <Button
                            mt={5}
                            variant='outline'
                            ml='auto'
                            mr={'3'}
                            onClick={(_) => navigate(-1)}
                        >
                            Back
                        </Button>
                        <Button
                            type='submit'
                            isLoading={isSubmitting}
                            mt={5}
                            variant='solid'
                            ml='auto'
                        >
                            Save
                        </Button>
                    </Box>
                )}
            </Stack>
            <WaitModal
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={() => {
                    onClose()
                    setIsSubmitting(false)
                }}
            />
        </Container>
    )
}

export default UploadCustomReview