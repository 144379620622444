import {
    Text,
    chakra,
    VStack,
    HStack,
    Image,
    Box,
    AspectRatio,
    Tooltip,
    Stack,
    Avatar,
    Icon,
    IconButton
} from "@chakra-ui/react"
import playIcon from "../../assets/icons/video_play.png"
import { extractNameParts, handleOpenLink, secToMin } from "../../utils"
import { FcApproval, FcCancel } from "react-icons/fc"
import { MdArchive } from "react-icons/md"
import Rating from "../Rating"
import { useState } from "react"
import { BiWorld } from "react-icons/bi"
import { MdPendingActions } from 'react-icons/md'
import Reject from '../../assets/images/reject.png'

const TestimonialVideoCard = ({
    cardInfo = {},
    onOpen = () => { },
    aspectRatio,
    setCardInfo = () => { },
    showInformationTags = false,
    showReviewInfo = false,
    hideThumbnailInfo = false,
    showShadow = true,
    sx,
}) => {

    const [duration, setDuration] = useState(null)

    const handleLoadedMetadata = (videoEl) => {
        const video = videoEl?.target;
        let getDuration = (cardInfo.duration || (video.duration >= Infinity ? 0 : video.duration))
        setDuration(getDuration)
    };
    //console.log(cardInfo)

    return (
        <VStack
            key={cardInfo.id}
            bg="white"
            p={{ base: 2, sm: 2.5 }}
            py={3}
            boxShadow={showShadow ? "0px 0px 15.6628px rgba(234, 234, 234, 0.7)" : 'none'}
            borderRadius="13.0523px"
            maxW={["full", "360px", "320px"]}
            minW={["300px", "280px", "260px"]}
            // height={["300px"]}
            alignItems="flex-start"
            {...sx}
        >
            <Box width={"100%"} height="100%" position={"relative"} cursor='pointer'
                onClick={(e) => { onOpen(e); setCardInfo({ ...cardInfo, autoPlay: false }) }}
            >
                <Image src={playIcon}
                    alt="play"
                    cursor="pointer"
                    position="absolute"
                    left={"50%"}
                    top={"50%"}
                    transform="translate(-50%, -50%)"
                    zIndex={'4'}
                    onClick={(e) => { e.stopPropagation(); onOpen(e); setCardInfo({ ...cardInfo, autoPlay: true }) }}
                    width="50px"
                    height="50px"
                    background="rgba(0,0,0,0.03)"
                    borderRadius="full"
                    transition='all .3s ease-in-out'
                    _hover={{
                        transform: 'translate(-50%, -50%) scale(1.3)',
                        boxShadow: '0 0 10px 0px #00989e'
                    }}
                />

                <AspectRatio ratio={aspectRatio || 4 / 4} height={'100%'}>
                    <Box width="full" height="full" borderRadius="13.0523px">
                        <Image as={'video'} preload={'metadata'} src={cardInfo.review + '#t=0.2'} width={"100%"} height="100%" borderRadius="10px"
                            objectFit="cover"
                            zIndex={2}
                            onLoadedMetadata={handleLoadedMetadata}
                        />
                        <chakra.div zIndex={3} position="absolute" width="full" height="full" borderRadius="13.0523px"
                            backgroundImage="linear-gradient(0deg, rgba(0, 121, 125, 0.72) 17.36%, rgba(3, 152, 158, 0) 100%)"
                        />
                        {showInformationTags &&
                            <>

                                <HStack position="absolute" left="2" top="2" zIndex={4}>
                                    {(('reviewed' in cardInfo) && !cardInfo?.reviewed) &&

                                        <Tooltip label={'Pending'} fontSize="smaller"
                                            placement='right'
                                            color='white'
                                            bg='teal.400'
                                            px={2}

                                            rounded='md'
                                        >
                                            <chakra.span >
                                                <HStack position="absolute" left="2" top="2" zIndex={4}>
                                                    <Icon as={MdPendingActions} fontSize={['2rem', '3rem']} color={'teal.200'} transition='all .1s ease-in-out' cursor={'pointer'} _hover={{
                                                        transform: "scale(1.2)",
                                                    }} />
                                                    <Text color='white' fontWeight='bold' fontSize={['2xl', '3xl', '3xl']}
                                                        textShadow={"2px 7px 5px rgba(0,0,0,0.3),  0px -4px 10px rgba(255,255,255,0.3)"}
                                                    >
                                                        Pending
                                                    </Text>
                                                </HStack>
                                            </chakra.span>
                                        </Tooltip>
                                    }
                                    {cardInfo?.approve &&
                                        <>
                                            <Tooltip label={'Approved'} fontSize="smaller"
                                                placement='bottom'
                                                color='white'
                                                bg='teal.400'
                                                px={2}
                                                rounded='md'
                                                hasArrow>
                                                <chakra.span>
                                                    <Icon as={FcApproval} fontSize={['2rem', '3rem']} color={'teal.400'} transition='all .1s ease-in-out' cursor={'pointer'} _hover={{
                                                        transform: "scale(1.2)",
                                                    }} />

                                                </chakra.span>

                                            </Tooltip>
                                            <Text fontSize={['2xl', '3xl', '3xl']} fontWeight='bold' color='white'
                                                textShadow={"2px 7px 5px rgba(0,0,0,0.3),  0px -4px 10px rgba(255,255,255,0.3)"}
                                            >
                                                Approved
                                            </Text>
                                        </>
                                    }
                                    {cardInfo?.reviewed && !cardInfo?.approve &&

                                        <Box width="80%" zIndex={4} border="0px solid black" borderRadius='full'>
                                            <Image src={Reject} alt='reject' />
                                        </Box>
                                    }
                                    {/* {cardInfo?.archive &&
                                        <Tooltip label={'Archived'} fontSize="smaller"
                                            placement='bottom'
                                            color='white'
                                            bg='teal.400'
                                            px={2}
                                            rounded='md'
                                            hasArrow>
                                            <chakra.span>
                                                <Icon as={MdArchive} fontSize={['2rem', '4rem']} color={'white'} transition='all .1s ease-in-out' cursor={'pointer'} _hover={{
                                                    transform: "scale(1.2)",
                                                }} />
                                            </chakra.span>
                                        </Tooltip>
                                    } */}
                                </HStack>
                            </>
                        }
                    </Box>
                </AspectRatio>
                {!hideThumbnailInfo &&
                    <VStack spacing={"5px"} p={2} align="flex-start"
                        width="100%"
                        position="absolute"
                        bottom={"1%"}
                        zIndex={3}
                    >
                        <Text fontSize={"14px"} fontWeight="bold" lineHeight={"16px"} color="#fff" textTransform={'capitalize'}>
                            {cardInfo?.name}
                        </Text>
                        <Text fontSize={"13px"} textTransform="capitalize" fontWeight="500" lineHeight={"16px"} color="#fff">
                            {cardInfo?.campaignName}
                        </Text>
                        <HStack spacing={"5px"} width="100%" justify="space-between">
                            <Text fontWeight="600" fontSize="10px" color="#fff">
                                {cardInfo?.date?.toDate()?.toDateString()}
                            </Text>
                            {duration &&
                                <Text fontWeight="600" fontSize="10px" color="#fff">
                                    {secToMin(duration)}
                                </Text>
                            }
                        </HStack>
                    </VStack>
                }
            </Box>
            {showReviewInfo &&
                <VStack spacing={2} flex={1} p={1} alignItems="flex-start" minH={["max-content", "max-content", "218px"]} w="full" position="relative">
                    <Stack direction="row" spacing="10px" align="center">
                        <Avatar name={cardInfo.name} src={cardInfo?.photo} size={"md"} />
                        <VStack spacing={"0px"} alignItems="flex-start">
                            <Text fontSize={["14px", "16px", "18.66px"]} lineHeight="30px" fontWeight="500">
                                {!cardInfo?.showLastNameInitial ? cardInfo?.name : extractNameParts(cardInfo?.name)}
                            </Text>
                            <HStack direction="row" alignItems="center" spacing={"10px"} >
                                <Rating
                                    total={5}
                                    value={cardInfo?.rating || 0}
                                    size={["16px"]}
                                    isStatic={true}
                                    showNumber={false}
                                />
                                <Text fontSize="16px" lineHeight={0} color="gray.500" pb={1}>
                                    ({parseFloat(cardInfo?.rating || 0)})
                                </Text>
                            </HStack>
                        </VStack>
                        <HStack justifyContent="flex-end" alignItems="center" spacing={0} gap="0px" position="absolute" right="0" top="5%">
                            {cardInfo?.webPage &&
                                <Tooltip
                                    label={'Web Page'}
                                    placement='bottom'
                                    color='white'
                                    bg='teal.400'
                                    px={2}
                                    rounded='md'
                                    hasArrow
                                >
                                    <IconButton
                                        variant={"link"}
                                        icon={<BiWorld fontSize={"22px"} />}
                                        cursor="pointer"
                                        size="sm"
                                        p={0}
                                        color="teal.500"
                                        onClick={_ => handleOpenLink(cardInfo?.webPage)}
                                    />
                                </Tooltip>
                            }
                            {cardInfo?.profileURL &&
                                <Tooltip
                                    label={'Profile URL'}
                                    placement='bottom'
                                    color='white'
                                    bg='teal.400'
                                    px={2}
                                    rounded='md'
                                    hasArrow
                                >
                                    <IconButton
                                        variant={"link"}
                                        icon={<BiWorld fontSize={"22px"} />}
                                        cursor="pointer"
                                        size="sm"
                                        p={0}
                                        color="teal.500"
                                        onClick={_ => handleOpenLink(cardInfo?.profileURL)}
                                    />
                                </Tooltip>
                            }
                        </HStack>
                    </Stack>
                    <VStack spacing={0} flex={1} alignItems="flex-start" width="100%">
                        <chakra.p
                            fontWeight="400"
                            fontSize="16px"
                            lineHeight="24px"
                            color="gray.500"
                            pt={2}
                            noOfLines={[3, 3, 4]}
                        >
                            "{cardInfo?.reviewMessage || "No review by customer"}"
                            {/* "{cardInfo?.review ? add3Dots(cardInfo?.review, 125) : "No review by customer"}" */}
                        </chakra.p>
                        <HStack spacing={"5px"} width="100%" alignItems="flex-end" justify="space-between" flex={1} mt={"1rem !important"}>
                            <Text fontWeight="400" fontSize="14px" lineHeight="24px" color="gray.500">
                                <chakra.span fontWeight="bold">Date: </chakra.span>{cardInfo?.date?.toDate()?.toDateString()}
                            </Text>
                            <Text fontWeight="500" fontSize="14px" lineHeight="24px" color="gray.600">
                                {duration && secToMin(duration)}
                            </Text>
                        </HStack>
                    </VStack>
                </VStack>
            }
        </VStack>
    )
}

export default TestimonialVideoCard