import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  CircularProgress,
  Avatar,
  WrapItem,
} from "@chakra-ui/react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/config";
import { updatedTeamList } from "../../../utils";
import { format } from "date-fns";

const TeamMembersList = () => {
  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const fetchLoggingLogs = async () => {
      setLoading(true);
      try {
        const collRef = collection(db, "logs");
        const queryRef = query(
          collRef,
          orderBy("createdAt", "desc"),
          where("userEmail", "in", updatedTeamList),
          where("type", "==", "login"),
          limit(10)
        );
        let data = await getDocs(queryRef);

        let getLogs = await Promise.all(
          data.docs.map(async (logSnap) => {
            const userData = await getDoc(
              doc(db, "users", logSnap.data().userId)
            );
            return {
              ...logSnap.data(),
              id: logSnap.id,
              user: { ...userData.data(), userId: userData.id },
            };
          })
        );
        setTeamMembers(
          getLogs.map((log) => ({
            name: log.user.firstName + " " + log.user.lastName,
            lastSignIn: log.createdAt,
            ...log,
            ...log.user,
          }))
        );
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    fetchLoggingLogs();
  }, []);

  // useEffect(() => {
  //   console.log({ teamMembers });
  // }, [teamMembers]);

  return (
    <>
      <Box
        width={["100%", "100%", "80%", "33%"]}
        borderWidth={1}
        borderRadius='lg'
        boxShadow='md'>
        <Heading size='md' mb={4} p={4}>
          Team
        </Heading>
        <VStack
          align='stretch'
          spacing={3}
          minHeight={"50vh"}
          maxHeight={"90vh"}
          overflowY={"auto"}>
          {teamMembers.map((member, index) => (
            <Box key={index} p={3} borderWidth={1} borderRadius='md'>
              <WrapItem gap={2}>
                <Avatar
                  boxSize={["50px"]}
                  borderRadius={"50%"}
                  name={member?.firstName + " " + member?.lastName}
                  src={member?.photoURL}
                  sx={{
                    "& img": { objectFit: "cover" },
                  }}
                  {...(member?.uid && { bg: "transparent" })}
                />
                <Box>
                  <Text fontWeight='bold'>{member.name}</Text>
                  <Text fontSize='sm' fontWeight={"semibold"}>
                    {format(
                      new Date(member?.lastSignIn?.toDate()),
                      "do MMM, yyyy 'at' h:mm a"
                    )}
                  </Text>
                </Box>
              </WrapItem>
            </Box>
          ))}
          {loading && (
            <CircularProgress
              isIndeterminate
              color='brand.1'
              sx={{ m: "auto" }}
            />
          )}
        </VStack>
      </Box>
    </>
  );
};

export default TeamMembersList;
