import { Container, chakra, shouldForwardProp, Text } from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const AnimatedBanner = ({ click }) => {
  return (
    // <Container>
    <ChakraBox
      //   as={'button'}
      onClick={click}
      _hover={{ bg: "teal.500" }}
      borderRadius='5px'
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        default: {
          duration: 0.6,
          ease: [0, 0.71, 0.2, 1.01]
        },
        scale: {
          type: "spring",
          damping: 5,
          stiffness: 100,
          restDelta: 0.001
        }
      }}
      padding="2"
      bgGradient="linear(to-l, brand.2, brand.1)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="full"
      height="50px"
    >
      <Text color='white' fontSize='4xl'>YOU ARE THE TOP EARNER TODAY! </Text>
    </ChakraBox>
    // </Container>
  )
}