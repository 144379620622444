import React from 'react';
import {
    VStack,
    Text,
    Image,
    Button,
    chakra,
    shouldForwardProp,
    Link
} from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';

//  both the image or button can be clickable

const ChakraBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});


export const LeaveUsAReview = () => {
    return (

        <VStack alignContent={'center'} p='5' py='2'>

            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    default: {
                        // duration: 5,
                        ease: [0, 0.71, 0.2, 1.01]
                    },
                    scale: {
                        type: "spring",
                        damping: 2,
                        stiffness: 100,
                        restDelta: 0.001
                    },
                    duration: 300,
                }}>

                <Button variant='link' href='https://sendmea.io/review/YjJsN8HRWhnqHva7jBXF' isExternal as={Link} _hover={{
                    bg: 'teal.400',
                    transform: "scale(1.05)"
                }}>
                    <Image boxSize='200px' src='/images/love.png' />
                </Button>

            </motion.div>

            <ChakraBox
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems={'center'}
                w='90%'
            >

                <Text textAlign='center' fontWeight='600' fontSize='lg' mx='6' mb='4' color='white'>
                    Click here to leave us your
                    <Button href='https://sendmea.io/review/YjJsN8HRWhnqHva7jBXF'
                        isExternal
                        as={Link}
                        variant='link'
                        transition={"all 0.2s ease"}
                        _hover={{
                            ml: 2,
                            transform: "scale(1.2)",
                            textDecoration: "none"
                        }}>
                        <chakra.span pl='1' pt='1' fontSize='xl' color='brand.2'> review!</chakra.span>
                    </Button>
                </Text>

            </ChakraBox>
        </VStack>
    )
}