import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  Stack,
  Heading,
  Input,
  Button,
  FormControl,
  FormErrorMessage,
  GridItem,
  CircularProgress,
  FormLabel,
  HStack,
  InputGroup,
  Box,
  chakra,
} from '@chakra-ui/react'
import { useForm, useFieldArray } from 'react-hook-form'
import { db } from '../../firebase/config'
import { doc, updateDoc } from 'firebase/firestore'
import { useCustomToast } from '../../hooks/customToast'
import { campaignDataAtom } from '../../recoil/atoms/campaignData'

const SEOForm = ({ isEditable, campaignD, setIsEditable, dataLoading }) => {
  const campaignId = useRecoilValue(campaignDataAtom)

  const { addToast } = useCustomToast()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      seo: [{ keyword: '' }],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'seo',
  })

  const onSubmit = async (state) => {
    let { ...values } = state
    try {
      setIsSubmitting(true)
      if (campaignId) {
        const docRef = doc(db, 'campaigns', campaignId)
        await updateDoc(docRef, {
          seo: values,
        })
        addToast({
          id: 'seo-success',
          title: 'seo !',
          description: 'Updated successfully',
          status: 'success',
          variant: 'left-accent',
        })
        setIsEditable(false)
      } else {
        addToast({
          id: 'seo-error',
          title: 'Error',
          description: 'seo',
          status: 'warning',
          variant: 'left-accent',
        })
      }
      setIsSubmitting(false)
    } catch (error) {
      addToast({
        title: 'Error',
        description: error.message,
        status: 'error',
        variant: 'left-accent',
      })
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (campaignD?.seo) {
      reset({ ...campaignD?.seo })
    } else {
      setIsEditable(true)
    }
  }, [campaignD, dataLoading])

  useEffect(() => {
    setIsEditable(true)
  }, [])

  return (
    <Stack
      mt={4}
      bg={'gray.50'}
      rounded={'xl'}
      py={{ base: 4, sm: 6, md: 8 }}
      px={{ base: 2, sm: 4, md: 8 }}
      width='full'
      spacing={8}
    >
      <HStack justifyContent='space-between' w='full'>
        <Heading color={'brand.1'} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
          {campaignD?.campaignDetails.publicCampaignName} SEO
        </Heading>
      </HStack>

      <Box
        as={'form'}
        mt={10}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          'input, select, textarea': {
            color: 'gray.700',
          },
        }}
      >
        {fields.map((field, index) => (
          <FormControl isInvalid={!!errors?.seo?.[index]?.keyword} key={field.id} p='6'>
            <HStack alignItems='end'>
              <FormLabel fontSize={['sm', 'md']} color='gray.500' ml={0.5}>
                {index + 1}.
              </FormLabel>
              <InputGroup>
                <Input
                  // size={["sm", "md", "lg"]}
                  name='keyword'
                  placeholder={'Type your SEO keyword here, max 48 characters'}
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  disabled={!isEditable}
                  {...register(`seo.${index}.keyword`, {
                    required: 'This field is required',
                    maxLength: {
                      value: 60,
                      message: 'too many characters',
                    },
                    minLength: {
                      value: 2,
                      message: 'cannot be blank',
                    },
                  })}
                />
              </InputGroup>

              {fields.length !== 1 && (
                <Button disabled={!isEditable} className='mr10' onClick={() => remove(index)}>
                  Remove
                </Button>
              )}
              {fields.length - 1 === index && (
                <Button disabled={!isEditable} onClick={() => append({ keyword: '' })}>
                  New
                </Button>
              )}
            </HStack>
            {errors.seo?.[index]?.keyword.message && (
              <FormErrorMessage>{errors.seo?.[index]?.keyword?.message}</FormErrorMessage>
            )}
          </FormControl>
        ))}

        <GridItem colSpan={[12]} order={14}>
          {!campaignD?.seo ? (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
              // w={'full'}
              variant='solid'
              ml='auto'
            >
              Save
            </Button>
          ) : !isEditable ? (
            <Box
              type='button'
              as='button'
              name='edit'
              mt={3}
              w={'20%'}
              borderRadius='8'
              py='2'
              bg='brand.1'
              variant='solid'
              ml='auto'
              color='white'
              fontWeight={'bold'}
              onClick={() => setIsEditable(true)}
            >
              Edit
            </Box>
          ) : (
            <Button
              type='submit'
              disabled={isSubmitting}
              mt={3}
              leftIcon={isSubmitting && <CircularProgress isIndeterminate size={'6'} />}
              // w={'full'}
              variant='solid'
              ml='auto'
              // onClick={(e) => setIsEditable(e.target.checked)}
            >
              Update
            </Button>
          )}
        </GridItem>
      </Box>
    </Stack>
  )
}

export default SEOForm
