import { Text } from '@chakra-ui/react'
import { Seo } from '../../components/Seo'

const About = () => {
  return (
    <>
      <Seo
        title='Sendmea Real Video Reviews | About'
        description='Sendmea simple and fast video reviews, learn how to get 5 star reviews and how to get customer reviews that help you grow your brand. Video reviews are the most trusted and valuable of all reviews, and build consumer trust and confidence. Let Sendmea help your happy customers increase your market share, improve referrals, and gain greater industry credibility.'
        type='webapp'
        url='https://sendmea.io'
        imgUrl='https://sendmea.io/images/sendmeaimage.png'
        vidUrl='https://youtu.be/aXOVKVrk1n4'
        keywords='video reviews, social proof, sendmea, benefits of video reviews, authentic video reviews for businesses, how to get 5 star reviews, how to get customer reviews, social proof marketing, examples of social proof, social proof tools, social proof advertising, social proof app, define social proof'
      />
      <div>
        <Text p={16} minH={'md'}>
          About
        </Text>
      </div>
    </>
  )
}

export default About
