import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
// import { auth } from "../../firebase/config";
// import { onAuthStateChanged } from "firebase/auth";

const { persistAtom } = recoilPersist();

export const userState = atom({
  key: "user",
  default: null,
  effects_UNSTABLE: [
    persistAtom,
    // ({ setSelf }) => {
    //     const unsubscribe = onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             console.log('onAuthStateChanged', user);
    //             // setSelf(user);
    //         } else {
    //             // Perform any cleanup or additional actions here
    //             console.log('User is not logged in');
    //         }
    //     });

    //     return () => {
    //         unsubscribe(); // Cleanup the onAuthStateChanged subscription
    //     };
    // },
  ],
});

export const userLoggedIn = atom({
  key: "userLoggedIn",
  default: false,
  // effects_UNSTABLE: [persistAtom]
});

export const stripePlanId = atom({
  key: "stripePlanId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const sidebarState = atom({
  key: "sidebarState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
export const permissionTimerState = atom({
  key: "permissionTimerState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
